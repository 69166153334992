import { RegisterActionTypes } from "./registration.types";

// export const setBasicDetailsSubmittedStatus = (status: boolean): any => {
//   return {
//     type: RegisterActionTypes.SET_BASIC_DETAILS_SUBMITTED_STATUS,
//     payload: status,
//   };
// };

// export const setStatutoryDetailsSubmittedStatus = (status: boolean): any => {
//   return {
//     type: RegisterActionTypes.SET_STATUTORY_DETAILS_SUBMITTED_STATUS,
//     payload: status,
//   };
// };

export const setRegistrationData = (registrationData: any): any => {
  return {
    type: RegisterActionTypes.SET_REGISTRATION_DATA,
    payload: registrationData,
  };
};
