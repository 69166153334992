/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import "./login.styles.scss";

import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";

import {
  setUserLoggedInStatus,
  setUserData,
  setIdleModal,
  setAuthData,
} from "../../redux/user/user.actions";
import { selectUserData } from "../../redux/user/user.selectors";

import {
  Layout,
  Form,
  Input,
  Button,
  message,
  Modal,
  Spin,
  Typography,
} from "antd";
import jwtDecode from "jwt-decode";

import {
  loginService,
  forgotPwService,
  changePwService,
} from "../../services/login-services";

import {
  CopyrightOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { ReactComponent as LogoITC } from "../../assets/itc_trade_next_logo.svg";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { UserOutlined, LockOutlined, LoginOutlined } from "@ant-design/icons";
import { BarLoader } from "react-spinners";
import { User } from "../../models";
import packageJson from "../../../package.json";
import triggerSleepClient from "../../services/timeout-services";
import { resetCommonReducer } from "../../redux/common/common.actions";
import QRCode from "react-qr-code";

const { Footer } = Layout;
const { confirm } = Modal;

const LoginPage: React.FC = (props: any) => {
  const [loader, setLoader] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [forgotPwModal, setForgotPwModal] = useState(false);
  const [qrModal, setQrModal] = useState(false);
  const [mfaData, setMfa] = useState<any>({
    mfa_configured: 0,
    mfa_enabled: 0,
    qrCode: "",
  });
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();

  const { setUserLoggedIn, setUser, setAuthData } = props;
  const history = useHistory();
  useEffect(() => {
    let timer;
    if (qrModal === true) {
      timer = setTimeout(() => {
        // setMfa({});
        setLoader(false);
        setQrModal(false);
      }, 300000);
    } else {
      clearTimeout(timer);
    }
  }, [qrModal, mfaData]);
  const onFinish = async (values: {
    username: string;
    password: string;
    captcha: string;
    totp?: number;
    reset?: boolean;
  }) => {
    if (values.reset || validateCaptcha(values.captcha)) {
      try {
        const dataToSend = {
          username: values.username,
          password: values.password,
          supplier: false,
          totp_token: values.totp,
          mfa_configured: values.totp && true,
        };

        setLoader(true);

        const loginResponse = await loginService(dataToSend);
        //TODO: autologout to be setup
        if (loginResponse.status === 200) {
          setAuthData(loginResponse.data.data);
          const idDetails: any = jwtDecode(loginResponse.data.data.id_token);
          setUser({
            ...idDetails,
            locationFeatureArray: idDetails.location_access.map(
              (item: any) => item.feature_code
            ),
            username: values.username,
            logout_timestamp:
              Math.round(new Date().getTime() / 1000) +
              loginResponse.data.data.refresh_token_expiry,
          });
          setUserLoggedIn(true);
          triggerSleepClient.onInit(history);
          history.replace("/home");
        } else {
          message.error("Login Failed.");
          setLoader(false);
        }
      } catch (error: any) {
        if (error.response) {
          if (error.response.data.hasOwnProperty("mfa_enabled")) {
            setMfa({
              ...mfaData,
              mfa_enabled: error.response.data.mfa_enabled,
              qrCode: error.response.data.totp_uri,
            });
            setQrModal(true);
            message.warn(error.response.data.message);
          } else message.error(error.response.data.message);
        } else {
          console.log(error);
          message.error("Server Unavailable.");
        }
        setLoader(false);
      }
    } else {
      message.error("Please enter correct captcha value!");
      form.resetFields(["captcha"]);
    }
  };
  const onFinishSubmit = (values) => {
    if (values && values.otp.length === 6) {
      onFinish({ ...form.getFieldsValue(), totp: values.otp });
    } else {
      message.error("Please enter valid OTP !");
    }
  };
  const onFinishReset = async (values: {
    old_password: string;
    new_password: string;
    confirm_password: string;
  }) => {
    try {
      setResetting(true);
      const dataToSend = {
        user_name: form.getFieldValue("username"),
        old_password: values.old_password,
        new_password: values.new_password,
      };

      const response = await changePwService(dataToSend);

      if (response.status === 200) {
        setForgotPwModal(false);
        form.setFieldsValue({
          password: values.new_password,
        });
        onFinish({
          username: form.getFieldValue("username"),
          password: values.new_password,
          captcha: "",
          reset: true,
        });
      } else {
        message.error("Cannot reset password..!");
      }
    } catch (error: any) {
      message.error(
        error?.response?.data?.msg ||
          "Something went wrong , Please try again later."
      );
    }
    setResetting(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onFinishFailedReset = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const forgotPwClicked = async () => {
    if (
      form.getFieldValue("username") !== null &&
      form.getFieldValue("username") !== "" &&
      form.getFieldValue("username") !== undefined
    ) {
      setSpinning(true);
      try {
        const response = await forgotPwService({
          user_name: form.getFieldValue("username"),
          supplier: false,
        });
        if (response.data.status) {
          setForgotPwModal(true);
          setSpinning(false);
        } else {
          message.warning("Error sending email...!");
          setSpinning(false);
        }
      } catch (error: any) {
        console.log(error);
        message.warning(
          error?.response?.data?.message ||
            "Something went wrong , Please try again later."
        );
        setSpinning(false);
      }
    } else {
      message.warning("Please enter username..!");
    }
  };

  function showPromiseConfirm() {
    confirm({
      title: "Reset Password ?",
      icon: <ExclamationCircleOutlined />,
      content:
        "A password reset code will be sent to your registered Email id, please use it to reset the password..",
      okText: "Reset",
      cancelText: "Cancel",
      onOk() {
        forgotPwClicked();
      },
      onCancel() {},
    });
  }
  const logo = <LogoITC className="itc-logo" />;

  const ModalContent = ({ onClose }) => {
    return (
      <Modal
        visible={qrModal}
        footer={false}
        maskClosable={false}
        closable={false}
        className="otp-modal"
      >
        <div className="reset-pw">
          <div className="header" style={{ textAlign: "center" }}>
            {mfaData.mfa_enabled === 0
              ? "Set up multi-factor authentication"
              : "OTP Verification"}
          </div>
          <div className="login-form">
            <Form
              form={form3}
              name="reset_pw"
              initialValues={{ remember: false }}
              onFinish={onClose}
              onFinishFailed={onFinishFailed}
            >
              {mfaData.mfa_enabled === 0 ? (
                <>
                  <Form.Item>
                    <div className="left-container" style={{ width: "100%" }}>
                      <p style={{ marginBottom: "0px" }}>
                        <b>Step 1 -</b> To set up your multi-factor
                        authentication, scan QR code.
                      </p>
                    </div>
                  </Form.Item>
                  <Form.Item
                    style={{ textAlign: "center", marginBottom: "10px" }}
                  >
                    <QRCode
                      size={256}
                      style={{ height: "auto", maxWidth: "100%", width: "30%" }}
                      value={mfaData && mfaData.qrCode}
                      viewBox={`0 0 256 256`}
                    ></QRCode>
                  </Form.Item>
                  <Form.Item>
                    <div className="left-container" style={{ width: "100%" }}>
                      <p style={{ marginBottom: "0px" }}>
                        <b>Step 2 -</b> Once you have scanned the QR code,
                        complete the set up by entering the OTP listed in your
                        Authenticator App.
                      </p>
                    </div>
                  </Form.Item>
                </>
              ) : (
                <Form.Item>
                  <div className="left-container">
                    <p style={{ marginBottom: "0px" }}>
                      <b>Step 1 -</b> Enter the OTP generated by your
                      authenticator app.
                    </p>
                  </div>
                </Form.Item>
              )}
              <Form.Item
                name="otp"
                rules={[
                  {
                    required: true,
                    message: "Please input OTP !",
                  },
                ]}
                label="OTP"
                labelAlign="left"
                required
                style={{ marginBottom: "10px" }}
              >
                <Input placeholder={" Enter OTP"} maxLength={6} size="middle" />
              </Form.Item>
              <Form.Item>
                <div className="left-container" style={{ width: "100%" }}>
                  <p
                    className="password-guideline"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>Step {mfaData.mfa_enabled === 0 ? "3" : "2"} - </b>
                    Click verify after entering OTP.
                  </p>
                </div>
              </Form.Item>
              <Form.Item style={{ marginBottom: "0px", marginTop: "0px" }}>
                <div className="reset-btn-container" style={{ width: "100%" }}>
                  <Button type="primary" size={"large"} block htmlType="submit">
                    Verify
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    );
  };
  const memoizedModalContent = useMemo(
    () => <ModalContent onClose={onFinishSubmit} />,
    [qrModal]
  );
  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);
  useEffect(() => {
    const reloadCaptcha = document.getElementById("reload_href");
    document.addEventListener("click", function (event) {
      if (event.target === reloadCaptcha) {
        form.resetFields(["captcha"]);
      }
    });
  }, []);
  return (
    <Spin spinning={spinning}>
      <Modal
        visible={forgotPwModal}
        footer={false}
        maskClosable={false}
        closable={false}
        className="forgot-pw-modal"
      >
        <div className="reset-pw">
          <div className="header">
            A password reset code has been sent to the registered Email id.
          </div>
          <div className="login-form">
            <Form
              form={form2}
              name="reset_pw"
              initialValues={{ remember: false }}
              size="large"
              onFinish={onFinishReset}
              onFinishFailed={onFinishFailedReset}
            >
              <Form.Item
                name="old_password"
                rules={[
                  {
                    required: true,
                    message: "Please input password !",
                  },
                ]}
                label="Reset Code"
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  // type="password"
                  placeholder={"Reset Code"}
                />
              </Form.Item>
              <Form.Item
                name="new_password"
                dependencies={["old_password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please input password !",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("old_password") !== value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The new password should not be same as reset Code !"
                      );
                    },
                  }),
                ]}
                label="New Password"
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  // type="password"
                  placeholder={"New Password"}
                />
              </Form.Item>
              <Form.Item
                name="confirm_password"
                dependencies={["new_password"]}
                hasFeedback
                label="Confirm Password"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password !",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Confirm password does not match with new password !"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  // type="password"
                  placeholder={"Confirm Password"}
                />
              </Form.Item>

              <Form.Item>
                <div className="reset-btn-container">
                  <Button
                    type="primary"
                    size={"large"}
                    block
                    htmlType="submit"
                    loading={resetting}
                  >
                    Reset Password
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
      {qrModal && memoizedModalContent}
      <BarLoader loading={loader} width={"100%"} color="#fdbc2c" />

      <div className="login">
        <div className="login-container">
          <div className="left-container">
            {/* <div className="main-header">Reverse Auction</div>
            <div className="sub-header">Login as the trader</div> */}
            <div className="logo-container">{logo}</div>
          </div>
          <div className="right-container">
            {/* <div className="logo-container"> <img src={LogoITC} alt="ITCTRADENEXT" /> </div> */}
            {/* <div className="logo-container">{logo}</div> */}
            {/* <div className="head">E-Auction Platform</div> */}
            {/* <div className="page-construction">
              Disclaimer: This website is under development.
            </div> */}
            <div className="header">Sign in</div>
            <div className="sub-header">
              Sign in and start managing your auctions
            </div>
            {/* <div className="login-form"> */}
            <Form
              form={form}
              // name="normal_login"
              //   className="login-form"
              // initialValues={{ remember: true }}
              size="large"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                style={{ marginBottom: 15 }}
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input username !",
                  },
                ]}
              >
                <Input
                  // prefix={<UserOutlined className="site-form-item-icon" />}
                  style={{ borderRadius: "20px" }}
                  placeholder={"Username"}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 15 }}
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input password !",
                  },
                ]}
              >
                <Input.Password
                  style={{ borderRadius: "20px" }}
                  // prefix={<LockOutlined className="site-form-item-icon" />}
                  // type="password"
                  placeholder={"Password"}
                />
              </Form.Item>
              <Form.Item style={{ marginBottom: 5 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "0.5em",
                  }}
                >
                  <Form.Item name="captcha" style={{ marginBottom: 0 }}>
                    <Input
                      style={{ height: 33, borderRadius: "20px" }}
                      placeholder="Captcha"
                    />
                  </Form.Item>
                  <LoadCanvasTemplate />
                </div>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  className="login-btn"
                  // icon={<LoginOutlined />}
                  size={"large"}
                  block
                  htmlType="submit"
                >
                  Login
                </Button>
              </Form.Item>
              <div style={{ textAlign: "center" }}>
                <a className="custom-link" onClick={() => showPromiseConfirm()}>
                  Forgot password ?
                </a>
              </div>
            </Form>
            {/* </div> */}
            {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "0.5em",
                }}
              >
                <LoadCanvasTemplate />
                <Input
                  style={{ height: 30 }}
                  placeholder="Please enter captcha"
                />
              </div> */}
          </div>
        </div>

        {/* <div className="login-container">
          <div className="login-card">
            <div className="form-header">
              <div className="main-header">Sign In</div>
              <div className="sub-header">
                Hi user, sign in and start managing your auctions
              </div>
            </div>
            <div className="login-form">
              <Form
                form={form}
                name="normal_login"
                //   className="login-form"
                initialValues={{ remember: true }}
                size="large"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input username !",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={"Username"}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input password !",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    // type="password"
                    placeholder={"Password"}
                  />
                </Form.Item>

                <Form.Item className="text-align-center">
                  <a
                    className="custom-link"
                    onClick={() => showPromiseConfirm()}
                  >
                    Forgot password ?
                  </a>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    icon={<LoginOutlined />}
                    size={"large"}
                    block
                    htmlType="submit"
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </div> 
          </div>
        </div>*/}
      </div>
      <Footer
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography.Text strong>
          <CopyrightOutlined /> ITC Limited v{packageJson.version}
        </Typography.Text>
      </Footer>
    </Spin>
  );
};

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setUserLoggedIn: (status: boolean) =>
      dispatch(setUserLoggedInStatus(status)),
    setAuthData: (authData: User) => dispatch(setAuthData(authData)),
    setUser: (userData: User) => dispatch(setUserData(userData)),
    setIdleModal: (show: boolean) => dispatch(setIdleModal(show)),
    resetReducer: () => dispatch(resetCommonReducer()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
