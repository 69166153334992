import React, { Component } from "react";
import "./reports-landing.styles.scss";

import { ReactComponent as UpcomingImage } from "../../assets/upcoming-auction.svg";
import { ReactComponent as OpenImage } from "../../assets/open-auction.svg";
import { ReactComponent as ArchiveImage } from "../../assets/archive.svg";
import { ReactComponent as IntermediateImage } from "../../assets/intermediate.svg";
import { ReactComponent as ApprovalImage } from "../../assets/approved.svg";
import { ReactComponent as RefundImage } from "../../assets/refund.svg";

import AuctionReport from "../auctionReports/auctionReports.page";
import VendorReport from "../vendorReports/vendorReports.page";
import UpcomingAuction from "../upcoming-auction/upcoming-auction.page";
import IntermediateScreen from "../intermediate-screen/intermediate-screen.page";
import ApprovalScreen from "../approval-screen/approval-screen.page";
import RefundApprovalScreen from "../refund-approval-screen/refund-approval-screen.page";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Dispatch } from "react";

import { setViewAuctionPage } from "../../redux/common/common.actions";
import { selectViewAuctionPage } from "../../redux/common/common.selectors";

import { Tabs } from "antd";
import BidReports from "../bidReports/bidReports.page";

const { TabPane } = Tabs;

const ReportsLanding: React.FC = (props: any) => {
  const { setViewAuctionPage, selectedKey } = props;
  return (
    <div className="view-auction-landing">
      <Tabs
        defaultActiveKey={sessionStorage.getItem("reportPage") || "60"}
        onChange={(activeKey) => {
          setViewAuctionPage(activeKey);
          sessionStorage.setItem("reportPage", activeKey);
        }}
      >
        <TabPane
          tab={
            <span className="tabname-container">
              {" "}
              <ArchiveImage className="tab-img" />{" "}
              <div className="tab-name">Auction</div>
            </span>
          }
          key="60"
        >
          <AuctionReport />
        </TabPane>
        <TabPane
          tab={
            <span className="tabname-container">
              {" "}
              <IntermediateImage className="tab-img" />{" "}
              <div className="tab-name">Vendor</div>
            </span>
          }
          key="61"
        >
          <VendorReport />
        </TabPane>
        <TabPane
          tab={
            <span className="tabname-container">
              <IntermediateImage className="tab-img" />
              <div className="tab-name">Bids</div>
            </span>
          }
          key="62"
        >
          <BidReports />
        </TabPane>
      </Tabs>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedKey: selectViewAuctionPage,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setViewAuctionPage: (activeKey: string) =>
      dispatch(setViewAuctionPage(activeKey)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsLanding);
