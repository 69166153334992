import axios from "axios";
import { dataConstants } from "../constants/data-constants";

export const getSlotServiceMaster = async (params: any) => {
  return axios.post(dataConstants.getSlotUrlMaster, params);
};

export const postAddSlotMaster = async (data: any) => {
  return axios.post(dataConstants.postAddSlotUrlMaster, data);
};
export const putUpdateSlotMaster = async (data: any) => {
  return axios.put(dataConstants.putUpdateSlotUrlMaster, data);
};
export const getSlotServiceToday = async (params: any) => {
  return axios.post(dataConstants.getSlotUrlToday, params);
};

export const postAddSlotToday = async (data: any) => {
  return axios.post(dataConstants.postAddSlotUrlToday, data);
};
export const putUpdateSlotToday = async (data: any) => {
  return axios.put(dataConstants.putUpdateSlotUrlToday, data);
};
