import axios from "axios";
import { dataConstants } from "../constants/data-constants";

export const getRegisterUser = async (params: any) => {
  return axios.post(dataConstants.getRegisterUrl, params);
};

export const postRegisterUser = async (body: any) => {
  return axios.post(dataConstants.postRegisterUrl, body);
};

export const getBranches = async () => {
  return axios.post(dataConstants.getRegBranchesUrl);
};
