import React, { useEffect, useState, useCallback } from "react";
import "./details.styles.scss";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { ReactComponent as CropIcon } from "../../assets/wheat.svg";
import { ReactComponent as VarietyIcon } from "../../assets/plant.svg";
import { ReactComponent as HubIcon } from "../../assets/warehouse.svg";
import { ReactComponent as TimerIcon } from "../../assets/timer.svg";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import { SyncOutlined } from "@ant-design/icons";

import {
  Button,
  Table,
  Progress,
  Slider,
  Select,
  message,
  Spin,
  Modal,
  Tabs,
} from "antd";

import Timer from "../../components/timer/timer.component";
import moment from "moment";

import {
  getSupplierData,
  getHubComparison,
  getBidComparison,
  postSaudha,
} from "../../services/saudha-services";

import {
  getRebateMatrixService,
  getHubsService,
} from "../../services/common-services";

import {
  getSelectedHubs,
  postSelectedHubs,
} from "../../services/saudha-services";

import { useHistory, useLocation } from "react-router-dom";

import {
  selectSelectedCrop,
  selectCrops,
} from "../../redux/common/common.selectors";

import { ReactComponent as BasicDetailsIcon } from "../../assets/basicDetails.svg";
import { ReactComponent as StatutoryDetailsIcon } from "../../assets/statutoryDetails.svg";
import { ReactComponent as BankDetailsIcon } from "../../assets/bankDetails.svg";

import BasicDetails from "../../components/userBasicDetails/userDetails";
import StatutoryDetails from "../../components/userStatutoryDetails/userStatutoryDetails";
import BankDetails from "../../components/bankDetails/bankDetails";

const { Option } = Select;
const { confirm } = Modal;
const { TabPane } = Tabs;

const rebateColumns = [
  {
    title: "Head",
    dataIndex: "head",
    key: "head",
    render(_: any, row: any) {
      return {
        children: row.head,
        props: {
          rowSpan: row.rowSpan,
        },
      };
    },
  },
  {
    title: "Slab",
    dataIndex: "slab",
    key: "slab",
  },
  {
    title: "Rebate %",
    dataIndex: "rate_display",
    key: "rate_display",
  },
];

const columns = [
  {
    title: "Supplier Name",
    dataIndex: "supplierName",
    key: "supplierName",
  },
  {
    title: "Quantity (MT)",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Price (₹/MT)",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Alotted Qty (MT)",
    dataIndex: "alottedQty",
    key: "alottedQty",
  },
];

// const supplierData=[]

// for()

const Saudha: React.FC = (props: any) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const history = useHistory();
  const query = useQuery();
  const objString = query.get("data");
  const dataObject = JSON.parse(objString + "");
  const { state } = useLocation();
  const { selectedCrop, crops, existingTrader } = props;
  const [cropName, setCropName] = useState("");
  const [urlData, setUrlData] = useState<any>({});
  const [hubs, setHubs] = useState<any>([]);
  const [supplierInfo, setSupplierInfo] = useState<any>({});
  const [tableData, setTableData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingHub, setIsLoadingHub] = useState(false);
  const [isLoadingBid, setIsLoadingBid] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedHubs, setSelectedHubs] = useState<Array<number>>([]);
  const [compareHubs, setCompareHubs] = useState<Array<any>>([]);
  const [compareBids, setCompareBids] = useState<Array<any>>([]);
  const [sumOfAllocated, setSumOfAllocated] = useState<any>();
  const [saudaStatus, setSaudaStatus] = useState<string>();
  const [localCropID, setLocalCropID] = useState<any>();
  const [DataRequired, setDataRequired] = useState<any>(state);

  const [rebateModalVisible, setRebateModalVisible] = useState(false);
  const [rebateTableData, setRebateTableData] = useState<Array<any>>();

  const [clicked, setClicked] = useState<any>("one");
  const [indexes, setIndexes] = useState({
    previousIndex: 0,
    currentIndex: 0,
    nextIndex: 1,
  });
  // const DataRequired = JSON.parse(
  //   window.location.href.split("=")[1].replaceAll("%22", '"')
  // );

  useEffect(() => {
    if (state === null || crops.length === 0) {
      history.replace("/home");
    } else {
      setUrlData(state);
      setCropName(crops.find((item: any) => item.id === selectedCrop).name);
    }
  }, []);

  useEffect(() => {
    setLocalCropID(selectedCrop);
  }, [selectedCrop]);

  //get methods
  const setSupplierData = async (auctionId: number) => {
    setIsLoading(true);
    try {
      const response = await getSupplierData({
        auction_id: auctionId,
      });
      if (response.data.status) {
        const data = response.data.data;
        setSaudaStatus(
          data.saudha_status == 1
            ? "Accepted"
            : data.saudha_status == 2
            ? "Rejected"
            : "Not Processed"
        );
        var totalAlloted = 0;
        const dd = data.saudhas.map((item: any, idx: number) => {
          totalAlloted += item.alloted_qty;
          return {
            key: idx,
            supplierName: item.user_name,
            quantity: item.qty,
            price: item.price,
            alottedQty: item.alloted_qty,
          };
        });
        // setSumOfAllocated(
        //   response.data.data.reduce(
        //     (sum: number, { qty_fullfilled }: { qty_fullfilled: number }) =>
        //       sum + qty_fullfilled,
        //     0
        //   )
        // );
        setTableData(dd);
        setSupplierInfo({ ...data, alloted_total_qty: totalAlloted });
        setIsLoading(false);
      } else {
        console.log("Error getting supplier list...");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // const getHubsList = async () => {
  //   try {
  //     const response = await getHubsService({ branch_id: null });
  //     if (response.data.status) {
  //       const data = response.data.data.map((item: any) => {
  //         return {
  //           id: item.territory_id,
  //           name: item.territory_name,
  //         };
  //       });
  //       setHubs(data);
  //       //TODO: call funtion to get selected hubs
  //     } else {
  //       console.log("Error getting hublist...");
  //     }
  //     getSelectedHubList();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getSelectedHubList = async () => {
  //   try {
  //     const response = await getSelectedHubs();
  //     if (response.data.status) {
  //       setSelectedHubs(response.data.data.user_hub);
  //       //TODO: set selected hubs in state
  //     } else {
  //       console.log("Error getting selected hubs");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getHubComparisonFn = async (varietyId: number) => {
  //   const params = {
  //     variety_id: varietyId,
  //     crop_id: localCropID,
  //   };
  //   setIsLoadingHub(true);
  //   try {
  //     const response = await getHubComparison(params);
  //     setCompareHubs(response.data.data);
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoadingHub(false);
  //   }
  //   setIsLoadingHub(false);
  // };
  // const getBidComparisonFn = async (varietyId: number, hubId: number) => {
  //   const params = {
  //     variety_id: varietyId,
  //     crop_id: localCropID,
  //     hub_id: hubId,
  //   };
  //   setIsLoadingBid(true);
  //   try {
  //     const response = await getBidComparison(params);
  //     setCompareBids(response.data.data);
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoadingBid(false);
  //   }
  //   setIsLoadingHub(false);
  // };

  // //post methods
  // const onAddHubs = async () => {
  //   const data = {
  //     hub_list: selectedHubs,
  //   };
  //   try {
  //     const response = await postSelectedHubs(data);
  //     if (response.data.status) {
  //       message.success(
  //         `${
  //           selectedHubs.length === 0
  //             ? "Removed all hubs..!"
  //             : "Updated hub list successfully..!"
  //         }`,
  //         1
  //       );
  //       getHubComparisonFn(urlData.varietyId);
  //     } else {
  //       message.error("Error adding hubs..!", 1);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     message.error("Error adding hubs..!", 1);
  //     setModalVisible(false);
  //   }
  //   setModalVisible(false);
  // };

  // const submitSaudha = async (status: boolean) => {
  //   setIsPosting(true);
  //   const saudha = supplierInfo.saudhas.map((item: any) => {
  //     return { saudha_id: item.saudha_id, allotted_qty: item.alloted_qty };
  //   });
  //   const data = {
  //     accepted: status,
  //     no_of_bids: supplierInfo.no_of_bids,
  //     total_bid_qty: supplierInfo.total_bid_qty,
  //     auction_id: urlData.key,
  //     saudha_details: saudha,
  //   };
  //   try {
  //     const response = await postSaudha(data);
  //     if (response.data.status) {
  //       message.success(status ? "Accepted sauda..!" : "Rejected sauda..!");
  //       await setSupplierData(dataObject.key);
  //     } else {
  //       console.log("Cannot submit sauda, please retry...");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setIsPosting(false);
  //   }
  //   setIsPosting(false);
  // };

  //general methods
  // const onChangeSelect = (value: any, option: any) => {
  //   setSelectedHubs(value);
  // };

  // function showPromiseConfirm(status: boolean) {
  //   if (
  //     moment(urlData.slotEndTime, "HH:mm:ss")
  //       .add(moment.duration(urlData.bufferTime).asMinutes(), "minutes")
  //       .isAfter(moment())
  //   ) {
  //     const msg = status
  //       ? "Are you sure you want to accept this sauda ?"
  //       : "Are you sure you want to reject this sauda ?";
  //     confirm({
  //       title: status ? "Accept sauda ?" : "Reject sauda ?",
  //       icon: <ExclamationCircleOutlined />,
  //       content: msg,
  //       onOk() {
  //         submitSaudha(status);
  //       },
  //       onCancel() {},
  //     });
  //   } else {
  //     message.warning("Cannot accept/reject sauda after buffer time..!");
  //   }
  // }

  // const getRebateMatrix = async (
  //   rebateId: string,
  //   branchId: number | undefined
  // ) => {
  //   const params = {
  //     rebate_id: rebateId,
  //     branch_id: branchId,
  //   };
  //   try {
  //     const response = await getRebateMatrixService(params);
  //     if (response.data.status) {
  //       setRebateTableData(response.data.data);
  //       setRebateModalVisible(true);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const createNewArr = (data: any) => {
  //   if (data)
  //     return data
  //       .reduce((result: any, item: any) => {
  //         //First, take the name field as a new array result
  //         if (result.indexOf(item.head) < 0) {
  //           result.push(item.head);
  //         }
  //         return result;
  //       }, [])
  //       .reduce((result: any, head: any) => {
  //         //Take the data with the same name as a new array, and add a new field * * rowSpan inside it**
  //         const children = data.filter((item: any) => item.head === head);
  //         result = result.concat(
  //           children.map((item: any, index: any) => ({
  //             ...item,
  //             rowSpan: index === 0 ? children.length : 0, //Add the first row of data to the rowSpan field
  //           }))
  //         );
  //         return result;
  //       }, []);
  // };

  const cardItems = [
    {
      id: 1,
      component: (
        <BasicDetails
          // loginRoute={loginRoute}
          history={history}
          DataRequired={DataRequired}
          // registeredAs={props.match.params.type}
          handleRouteClick={(tabID: any, text: any) =>
            handleRouteClick(tabID, text)
          }
        />
      ),
    },
    {
      id: 2,
      component: (
        <StatutoryDetails
          clicked={clicked}
          DataRequired={DataRequired}
          // registeredAs={props.match.params.type}
          handleRouteClick={(tabID: any, text: any) =>
            handleRouteClick(tabID, text)
          }
        />
      ),
    },
    {
      id: 3,
      component: (
        <BankDetails
          // loginRoute={loginRoute}
          DataRequired={DataRequired}
          history={history}
          handleRouteClick={(tabID: any, text: any) =>
            handleRouteClick(tabID, text)
          }
          // registeredAs={props.match.params.type}
        />
      ),
    },
  ];

  const handleRouteClick = (tabID: any, text: any) => {
    handleCardTransition(tabID);
    setClicked(text);
  };

  const determineClasses = (indexes: any, cardIndex: number) => {
    if (indexes.currentIndex === cardIndex) {
      return "active";
    } else if (indexes.nextIndex === cardIndex) {
      return "next";
    } else if (indexes.previousIndex === cardIndex) {
      return "prev";
    }
    return "inactive";
  };

  const handleCardTransition = useCallback(
    (index: number) => {
      setIndexes({
        previousIndex: index - 1,
        currentIndex: index,
        nextIndex: index + 1,
      });
    },
    [indexes.currentIndex]
  );

  return (
    <>
      <div className="details-header">
        <div
          className="back-btn"
          onClick={() => {
            history.goBack();
          }}
        >
          ←
        </div>
        <div className="title">DETAILS</div>
      </div>
      <div className="registration-page">
        <div className="left-container">
          <div className="nav-container">
            <nav>
              <div
                className={`nav-items ${clicked === "one" && "active"}`}
                onClick={() => {
                  handleCardTransition(0);
                  setClicked("one");
                }}
              >
                <BasicDetailsIcon className="nav-item-icon" />
                <div>Basic Details</div>
              </div>
              {/* {props.match.params.type !== "member" ? (
                  <> */}
              {!urlData.existingTrader && (
                <>
                  <div
                    className={`nav-items ${clicked === "two" && "active"}`}
                    onClick={() => {
                      // if (registrationData.isBasicDetailsSubmitted) {
                      handleCardTransition(1);
                      setClicked("two");
                      // } else {
                      //   message.info(
                      //     "Please save the basic details to continue..."
                      //   );
                      // }
                    }}
                  >
                    <StatutoryDetailsIcon className="nav-item-icon" />
                    <div>Statutory Details</div>
                  </div>

                  <div
                    className={`nav-items ${clicked === "three" && "active"}`}
                    onClick={() => {
                      // if (
                      //   registrationData.isBasicDetailsSubmitted &&
                      //   registrationData.isStatutoryDetailsSubmitted
                      // ) {
                      handleCardTransition(2);
                      setClicked("three");
                      // } else {
                      //   message.info(
                      //     "Please save the basic and statutory details to continue..."
                      //   );
                      // }
                    }}
                  >
                    <BankDetailsIcon className="nav-item-icon" />
                    <div>Bank Details</div>
                  </div>
                </>
              )}
              {/* </>
                ) : null} */}
              <div
                className={
                  urlData.existingTrader
                    ? `line-existing ${clicked}`
                    : `line ${clicked}`
                }
              ></div>
            </nav>
          </div>
        </div>
        <div className="right-container">
          <div className="container">
            <ul className="card-carousel">
              {cardItems.map((card, index) => (
                <li
                  key={card.id}
                  className={`card ${determineClasses(indexes, index)}`}
                >
                  {card.component}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedCrop: selectSelectedCrop,
  crops: selectCrops,
});

export default connect(mapStateToProps)(Saudha);
