// import { baseURL } from "../api";
//import { socketURL } from "../api";
// const endpointurl = `http://abdsourcingwfe.southindia.cloudapp.azure.com:8086/aashirvaad/v1`; // UAT  Env
// const mainSocketURL = `http://abdsourcingwfe.southindia.cloudapp.azure.com:8086`; // change the port according to the endpointurl

const endpointurl = process.env.REACT_APP_API_URL; // Development Env
const mainSocketURL = process.env.REACT_APP_SOCKET_URL; // change the port according to the endpointurl

// const endpointurl = `https://tradenext.centralindia.cloudapp.azure.com:8082/aashirvaad/v1`; // Production URL
// const mainSocketURL = `https://tradenext.centralindia.cloudapp.azure.com:8082`; // change the port according to the endpointurl

//const endpointurl = `https://tradenext.centralindia.cloudapp.azure.com:8443/aashirvaad/v1`; // New Production URL
//const mainSocketURL = `https://tradenext.centralindia.cloudapp.azure.com:8443`; // change the port according to the endpointurl

// console.log("url ", endpointurl, mainSocketURL);
export const dataConstants = {
  //Sockets
  socketURL: `${mainSocketURL}/auctions`,
  //Authentication URLs
  loginUrl: `${endpointurl}/login`,
  logoutUrl: `${endpointurl}/logout`,
  refreshTokenUrl: `${endpointurl}/refresh`,
  removeTokenUrl: `${endpointurl}/remove_token`,
  forgotPasswordUrl: `${endpointurl}/forgotpassword`,
  changePasswordUrl: `${endpointurl}/changepassword`,
  //DefineSlots URLs
  getSlotUrlMaster: `${endpointurl}/getmasterslots`,
  postAddSlotUrlMaster: `${endpointurl}/masterslots`,
  putUpdateSlotUrlMaster: `${endpointurl}/masterslots`,

  getSlotUrlToday: `${endpointurl}/gettodayslots`,
  postAddSlotUrlToday: `${endpointurl}/todayslots`,
  putUpdateSlotUrlToday: `${endpointurl}/todayslots`,
  //common URLs
  getBranchesUrl: `${endpointurl}/branches`,
  getHubsUrl: `${endpointurl}/hubs`,
  getVarietiesUrl: `${endpointurl}/varieties`,
  getCropsUrl: `${endpointurl}/crop`,
  getSlotIdsUrl: `${endpointurl}/branchslots`,
  getRebateUrl: `${endpointurl}/rebate`,
  getRebateMatrixUrl: `${endpointurl}/rebatematrix`,
  getTCUrl: `${endpointurl}/termscondition`,
  //systemParam URLs
  sysParamUrl: `${endpointurl}/parameter`,
  getSysParamUrl: `${endpointurl}/getparameter`,
  //discount params
  discountParamUrl: `${endpointurl}/cash_discount`,
  //Auctions
  upcomingAuctionUrl: `${endpointurl}/upcoming_auctions`,
  openAuctionUrl: `${endpointurl}/open_auctions`,
  archivesUrl: `${endpointurl}/archive_auctions`,
  activeAuctionsExportUrl: `${endpointurl}/active_action_export`,
  buyingDecisionUrl: `${endpointurl}/buying_decision`,
  copyAuctionsDataUrl: `${endpointurl}/copy_auction`,
  auctionSlotDetailsUrl: `${endpointurl}/auctionslotdetails`,
  postAuctionUrl: `${endpointurl}/auction`,
  checkAuctionExistsUrl: `${endpointurl}/checkauction`,
  cancelAuctionUrl: `${endpointurl}/cancel_auction`,
  editAuctionUrl: `${endpointurl}/editauction`,
  SaudaDetailsUrl: `${endpointurl}/saudhadetails`,
  MaterialCodesUrl: `${endpointurl}/material_code`,
  ConditionCodesUrl: `${endpointurl}/condition_codes`,
  getBagTypesUrl: `${endpointurl}/bag_type`,
  getdivisionsUrl: `${endpointurl}/getdivision`,
  getCropYearUrl: `${endpointurl}/list_crop_year`,

  getRegisterUrl: `${endpointurl}/get_vendor_details`,
  postRegisterUrl: `${endpointurl}/vendor_details`,
  getRegBranchesUrl: `${endpointurl}/branches`,
  getExistingVendorUrl: `${endpointurl}/get_existing_vendor_details`,
  getNewVendorUrl: `${endpointurl}/get_new_vendor_details`,
  approvalData: `${endpointurl}/approve_new_vendor_details`,
  existingApprovalData: `${endpointurl}/approve_existing_vendor_details`,
  getRefundApprovalUrl: `${endpointurl}/all_refund_requests`,
  putRefundApprovalUrl: `${endpointurl}/approve_refund_requests`,
  getRefundHistoryUrl: `${endpointurl}/user_refund_trail`,
  //saudha
  getSupplierUrl: `${endpointurl}/saudha`,
  getSelectedHubsUrl: `${endpointurl}/getuserhubs`,
  postSelectedHubsUrl: `${endpointurl}/userhubs`,
  processSaudhaUrl: `${endpointurl}/processsaudha`,
  postBulkApproveUrl: `${endpointurl}/processsaudha/bulk`,
  hubComparisonUrl: `${endpointurl}/hub_comparison`,
  bidComparisonUrl: `${endpointurl}/bid_comparison`,
  auctionReportsUrl: `${endpointurl}/auction_report`,
  bidReportsUrl: `${endpointurl}/bids_report`,
  vendorReportsUrl: `${endpointurl}/vendor_report`,
  vendorsListUrl: `${endpointurl}/branchwise_vendor`,
  checkVendorAvailabilityUrl: `${endpointurl}/check_vendor_code_exists`,
  updateMobileAppVersionUrl: `${endpointurl}/appversion`,
  getMobileAppVersionUrl: `${endpointurl}/mobile_apk_version`,
  usersListUrl: `${endpointurl}/all_user_data`,
  putUserStatusUrl: `${endpointurl}/block_trader`,
  approveAuctionUrl: `${endpointurl}/approve_auctions`,
  approveSaudaUrl: `${endpointurl}/approve_saudhas`,
  saudaDownloadUrl: `${endpointurl}/generate_sauda`,
  updateUserList: `${endpointurl}/users`,
};

export const FeatureCodes = {
  // VIEW_ONLY_ACCESS: "VIEWO",
  COMPANY_LEVEL_EDIT: "COMPP",
  BRANCH_EDIT_ALL: "EDITB",
  PROCESS_SAUDA: "SAUDA",
  PROCESS_APPROVALS: "APRVL",

  // New Feature Codes
  ACCESS_EDIT_COMPANY_LEVEL_PARAM: "COMPP",
  VIEW_ONLY_ACCESS: "VIEWO",
  COMPANY_LEVEL_PREFERENCES: "CPREF",
  BRANCH_LEVEL_PREFERENCES: "BPREF",
  SLOTS: "SLOTS",
  EXISTING_VENDOR_CHECKER: "EVCHK",
  NEW_VENDOR_CHECKER: "NVCHK",
  AUCTION_MAKER: "AUCMK",
  AUCTION_CHECKER: "AUCCK",
  AUCTION_MODIFICATION: "AUCMD",
  AUCTION_APPROVE_VIEW: "AUCAV",
  SAUDA_PROCESS: "SAUDA",
  IM_SCREEN_MAKER: "IMMKR",
  IM_SCREEN_CHECKER: "IMCHK",
  REFUND_APPROVAL: "REFAP",
  REPORTS: "REPRT",
  EXISTING_VENDOR_APPROVAL: "EVIEW",
};

export const sleepTimer: number = 1800000;
