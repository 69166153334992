import React, { useEffect, useState } from "react";
import "./user-management.styles.scss";
import { Table, Select, Input, message, Button } from "antd";

// import API from "../../api";
import { getUsersList, putUserStatus } from "../../services/auction-services";

const { Search } = Input;
const { Option } = Select;

const status = [
  { name: "Accepted", id: 1 },
  { name: "Rejected", id: 2 },
  { name: "Not Processed", id: 3 },
];

const UserManagement: React.FC = (props: any) => {
  const { branches, varieties, selectedCropId, userData, crops } = props;
  const [searchTextData, setSearchTextData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [userList, setUserData] = useState([
    // contact_number= undefined,
    // email: "",
    // location: undefined,
    // name: "",
    // name_firm: undefined,
    // role_id: undefined,
    // role_name: "",
    // state: undefined,
    // territory_name: "",
    // user_id: undefined,
    // user_name: "",
    // vendor_code: undefined
  ]);

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Phone Number",
      key: "contact_number",
      dataIndex: "contact_number",
    },
    {
      title: "E-mail",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "State",
      key: "state",
      dataIndex: "state",
    },
    // {
    //     title: "Vendor Code",
    //     key: "contact_number",
    //     dataIndex: "contact_number",
    //   },
    //   {
    //     title: "Vendor Code",
    //     key: "vendor_code",
    //     dataIndex: "vendor_code",
    //   },
    {
      title: "Role Name",
      key: "role_name",
      dataIndex: "role_name",
    },
    {
      title: "Name of the Firm",
      key: "name_firm",
      dataIndex: "name_firm",
    },
    {
      title: "Branch",
      key: "territory_name",
      dataIndex: "territory_name",
    },
    {
      key: "actions",
      title: "Actions",
      dataIndex: "actions",
      width: 170,
      // render: (actions, record) => (
      //   <div className="user-action-container">
      //    <Select
      //                     showSearch
      //                     optionFilterProp="children"
      //                     defaultValue={
      //                       rowRecord.is_active === true ? "Yes" : "No"
      //                     }
      //                     value={
      //                       rowRecord.is_active === true ? "Yes" : "No"
      //                     }
      //                     onChange={(value, option) =>
      //                       this.handleDropdownChange(
      //                         value,
      //                         rowRecord,
      //                         "is_active",
      //                         index,
      //                         option.props.children,
      //                         false
      //                       )
      //                     }
      //                     style={{ width: "70%" }}
      //                   >
      //                     <Option key={1} value={1}>
      //                       {"Yes"}
      //                     </Option>
      //                     <Option key={0} value={0}>
      //                       {"No"}
      //                     </Option>
      //                   </Select>

      //   </div>
      // ),
    },
  ];

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      setIsLoading(true);
      const userRoleResponse = await getUsersList({});
      if (userRoleResponse.data.status) {
        // setUserData(userRoleResponse.data.data);
        setIsLoading(false);
      } else {
        message.error("Something went wrong :(", 1);
        // setUserData([]);
        setIsLoading(false);
      }
    } catch (error) {
      message.error("Something went wrong :(", 1);
      setIsLoading(false);
    }
  };

  const submitUser = async (status, userRecord: any) => {
    try {
      setIsLoading(true);

      let a: any = userList;

      const statusResponse = await putUserStatus({
        user_id: a[userRecord.key].user_id,
        is_active: a[userRecord.key].is_active,
      });

      if (statusResponse.data.status) {
        getUserData();
        setIsLoading(false);
      } else {
        setIsLoading(false);
        message.error(
          "Could not change status at the moment. Please try again later."
        );
      }
    } catch (error) {
      setIsLoading(false);
      message.error(
        "Could not change status at the moment. Please try again later."
      );
    }
  };

  const setUserTableData = () => {
    if (userList !== undefined || userList !== null || userList !== "") {
      const userTableData = userList.map((user: any, index) => {
        return {
          key: index,
          contact_number: user.contact_number,
          email: user.email,
          location: user.location,
          name: user.name,
          name_firm: user.name_firm,
          role_id: user.role_id,
          role_name: user.role_name,
          state: user.state,
          territory_name: user.territory_name,
          user_id: user.user_id,
          user_name: user.user_name,
          vendor_code: user.vendor_code,
          territories: user.territories,
          is_active: user.is_active,
        };
      });

      let dataAfterSearch = userTableData;

      if (
        searchTextData !== null ||
        searchTextData !== undefined ||
        searchTextData !== ""
      ) {
        dataAfterSearch = dataAfterSearch.filter(
          (item) =>
            item.name !== null &&
            item.name.toLowerCase().includes(searchTextData)
        );
      }

      //   if (this.state.searchUserType !== 0) {
      // dataAfterSearch = dataAfterSearch.filter(
      //   (item) => item.actions.role_id === this.state.searchUserType
      // );
      //   }

      return dataAfterSearch;
    } else {
      return userList;
    }
  };

  const createFilters = (label) => {
    let filterData = userList;
    //#region remove duplicate objects
    let uniqueFilterData: any = [];

    if (label === "is_active" && userList && userList.length > 0) {
      uniqueFilterData = [
        { text: "Active", value: 1 },
        { text: "Inactive", value: 0 },
        { text: "Block", value: 2 },
      ];
    } else {
      filterData.map((mainItem) =>
        uniqueFilterData.filter((item) => item[label] === mainItem[label])
          .length > 0
          ? null
          : uniqueFilterData.push(mainItem)
      );

      uniqueFilterData = uniqueFilterData.map((item) => {
        return {
          text: item[label],
          value: item[label],
        };
      });

      uniqueFilterData.sort((a, b) => a.text.localeCompare(b.text));
    }

    return uniqueFilterData;
  };

  const handleDropdownChange = (value, row, optionlabel) => {
    let a: any = userList;
    a[row.key].is_active = value;
    //   setUserData((prevState) => ({
    //     ...prevState,
    //     a
    //   }));
  };

  return (
    <div className="userManagement">
      <div className="filter-container">
        <div style={{ width: "100%" }}>
          <Search
            placeholder="Search by Username"
            onChange={(e) => setSearchTextData(e.target.value)}
            // onSearch={value => console.log(value)}
            style={{ width: "30%", margin: "10px 10px 5px 10px" }}
          />
        </div>
      </div>

      <div className="user-table-container">
        <Table
          loading={isLoading}
          //   columns={columns}
          dataSource={setUserTableData()}
          pagination={false}
          scroll={{ y: window.innerHeight - 295 }}
          columns={[
            {
              title: "Name",
              key: "name",
              dataIndex: "name",
            },
            {
              title: "Phone Number",
              key: "contact_number",
              dataIndex: "contact_number",
            },
            {
              title: "E-mail",
              key: "email",
              dataIndex: "email",
            },
            {
              title: "State",
              key: "state",
              dataIndex: "state",
            },
            // {
            //     title: "Vendor Code",
            //     key: "contact_number",
            //     dataIndex: "contact_number",
            //   },
            {
              title: "Vendor Code",
              key: "vendor_code",
              dataIndex: "vendor_code",
            },
            {
              title: "Role Name",
              key: "role_name",
              dataIndex: "role_name",
              filters: createFilters("role_name"),
              onFilter: (value, record) => record.role_name.includes(value),
            },
            {
              title: "Name of the Firm",
              key: "name_firm",
              dataIndex: "name_firm",
            },
            //   {
            //     title: "Branch",
            //     key: "territory_name",
            //     dataIndex: "territory_name",
            //   },
            {
              key: "is_active",
              title: "Actions",
              dataIndex: "is_active",
              filters: createFilters("is_active"),
              onFilter: (value, record) => record.is_active === value,
              width: 250,
              render: (value, record) => (
                <div className="user-action-container">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    defaultValue={
                      record.is_active === 1
                        ? "Active"
                        : record.is_active === 0
                        ? "Inactive"
                        : "Block"
                    }
                    // value={
                    //     record.is_active === 1 ? "Active" : record.is_active === 0 ?"Inactive":"Block"
                    // }
                    onChange={(value, option) =>
                      handleDropdownChange(value, record, "is_active")
                    }
                    style={{ width: "50%", marginRight: "10px" }}
                  >
                    <Option key={1} value={1}>
                      {"Active"}
                    </Option>
                    <Option key={0} value={0}>
                      {"Inactive"}
                    </Option>
                    <Option key={2} value={2}>
                      {"Block"}
                    </Option>
                  </Select>

                  <Button
                    style={{ backgroundColor: "rgb(32, 52, 106)" }}
                    onClick={() => submitUser(true, record)}
                    className="edit-button"
                  >
                    <h5 style={{ margin: "3px 0 0 0", color: "white" }}>
                      Submit
                    </h5>
                  </Button>
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default UserManagement;
