import React, { Component } from "react";
import "./preferences-landing.styles.scss";

import { ReactComponent as SlotImage } from "../../assets/auctionslot.svg";
import { ReactComponent as PreferencesImage } from "../../assets/preferences.svg";

import AuctionSlots from "../define-slots/define-slots.page";
import AuctionPreferences from "../system-parameters/system-parameters.page";

import { Dispatch } from "redux";
import { Tabs } from "antd";
import SlotTabs from "../slot-tabs/slot-tabs.page";
import { connect } from "react-redux";
import { selectViewAuctionPage } from "../../redux/common/common.selectors";
import { setViewAuctionPage } from "../../redux/common/common.actions";
import { createStructuredSelector } from "reselect";

const { TabPane } = Tabs;

const PreferencesLanding: React.FC = (props: any) => {
  const { setViewAuctionPage, selectedKey } = props;
  return (
    <div className="preferences-landing">
      <Tabs
        defaultActiveKey={
          sessionStorage.getItem("preferencesPage") || selectedKey
        }
        onChange={(activeKey) => {
          setViewAuctionPage(activeKey);
          sessionStorage.setItem("preferencesPage", activeKey);
        }}
      >
        {/* <TabPane
          tab={
            <span className="tabname-container">
              {" "}
              <UpcomingImage className="tab-img" />{" "}
              <div className="tab-name">User Preferences</div>
            </span>
          }
          key="1"
        >
          <UserPreferences />
        </TabPane> */}
        <TabPane
          tab={
            <span className="tabname-container">
              {" "}
              <SlotImage className="tab-img" />{" "}
              <div className="tab-name">Auction Slots</div>
            </span>
          }
          key="70"
        >
          <SlotTabs />
        </TabPane>
        <TabPane
          tab={
            <span className="tabname-container">
              {" "}
              <PreferencesImage className="tab-img" />{" "}
              <div className="tab-name">Auction Preferences</div>
            </span>
          }
          key="71"
        >
          <AuctionPreferences />
        </TabPane>
      </Tabs>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedKey: selectViewAuctionPage,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setViewAuctionPage: (activeKey: string) =>
      dispatch(setViewAuctionPage(activeKey)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreferencesLanding);
