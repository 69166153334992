import React, { Component } from "react";
import "./approvals-landing.styles.scss";

import { ReactComponent as UpcomingImage } from "../../assets/upcoming-auction.svg";
import { ReactComponent as OpenImage } from "../../assets/open-auction.svg";
import { ReactComponent as ArchiveImage } from "../../assets/archive.svg";
import { ReactComponent as IntermediateImage } from "../../assets/intermediate.svg";
import { ReactComponent as ApprovalImage } from "../../assets/approved.svg";
import { ReactComponent as RefundImage } from "../../assets/refund.svg";

import Archives from "../archives/archives.page";
import OpenAuction from "../open-auction/open-auction.page";
import UpcomingAuction from "../upcoming-auction/upcoming-auction.page";
import IntermediateScreen from "../intermediate-screen/intermediate-screen.page";
import ApprovalScreen from "../approval-screen/approval-screen.page";
import EVRApprovalScreen from "../EVR approval-screen/EVR-approval-screen.page";
import EVRSubmitScreen from "../EVR submit-screen/EVR-submit-screen.page";
import RefundApprovalScreen from "../refund-approval-screen/refund-approval-screen.page";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Dispatch } from "react";
import { canUserAccess } from "../../utils/utils";
import { FeatureCodes } from "../../constants/data-constants";

import { setViewAuctionPage } from "../../redux/common/common.actions";
import { selectViewAuctionPage } from "../../redux/common/common.selectors";

import { Tabs } from "antd";
import { selectUserData } from "../../redux/user/user.selectors";

const { TabPane } = Tabs;

const ApprovalsLanding: React.FC = (props: any) => {
  const { setViewAuctionPage, selectedKey, userData } = props;
  return (
    <div className="view-auction-landing">
      <Tabs
        defaultActiveKey={selectedKey}
        onChange={(activeKey) => setViewAuctionPage(activeKey)}
      >
        {(canUserAccess(userData, FeatureCodes.EXISTING_VENDOR_CHECKER) ||
          canUserAccess(userData, FeatureCodes.PROCESS_APPROVALS) ||
          canUserAccess(userData, FeatureCodes.EXISTING_VENDOR_APPROVAL)) && (
          <>
            <TabPane
              tab={
                <span className="tabname-container">
                  <ApprovalImage className="tab-img" />
                  <div className="tab-name">Submit Vendor Code</div>
                </span>
              }
              key="50"
            >
              <EVRSubmitScreen {...props} />
            </TabPane>
            <TabPane
              tab={
                <span className="tabname-container">
                  <ApprovalImage className="tab-img" />
                  <div className="tab-name">Approve Vendor Code</div>
                </span>
              }
              key="51"
            >
              <EVRApprovalScreen {...props} />
            </TabPane>
          </>
        )}
        {/* {(canUserAccess(userData, FeatureCodes.NEW_VENDOR_CHECKER) ||
          canUserAccess(userData, FeatureCodes.PROCESS_APPROVALS)) && (
          <TabPane
            tab={
              <span className="tabname-container">
                {" "}
                <ApprovalImage className="tab-img" />{" "}
                <div className="tab-name">New Vendor Approval</div>
              </span>
            }
            key="2"
          >
            <ApprovalScreen {...props} />
          </TabPane>
        )} */}
        {/* {(canUserAccess(userData, FeatureCodes.REFUND_APPROVAL) ||
          canUserAccess(userData, FeatureCodes.PROCESS_APPROVALS)) && (
          <TabPane
            tab={
              <span className="tabname-container">
                {" "}
                <ApprovalImage className="tab-img" />{" "}
                <div className="tab-name">Refund Approval</div>
              </span>
            }
            key="3"
          >
            <RefundApprovalScreen {...props} />
          </TabPane>
        )} */}
      </Tabs>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedKey: selectViewAuctionPage,
  userData: selectUserData,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setViewAuctionPage: (activeKey: string) =>
      dispatch(setViewAuctionPage(activeKey)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalsLanding);
