import React, { useEffect, useState, useCallback, useRef } from "react";
import { Dispatch } from "redux";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment";
import { getRegisterUser } from "../../services/register-services";
import { setRegistrationData } from "../../redux/registration/registration.actions";
import { selectRegisterData } from "../../redux/registration/registration.selectors";
import { postRegisterUser } from "../../services/register-services";
import { createStructuredSelector } from "reselect";
import { connect, useSelector } from "react-redux";
import Base64Downloader from "react-base64-downloader";
import "./bankDetails.style.scss";
import {
  Spin,
  Form,
  Input,
  Button,
  message,
  Checkbox,
  Row,
  Col,
  DatePicker,
  Space,
  Radio,
  Modal,
  Image,
} from "antd";
// import ReactImageZoom from 'react-image-zoom';

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 20 },
};
const BankDetails: React.FC<any> = (props: any) => {
  const inputRef = useRef(null);
  const {
    loginRoute,
    registrationData,
    registeredAs,
    DataRequired,
    handleRouteClick,
  } = props;
  const [paid, setPaid] = useState(true);
  const [declared, setDeclared] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isChequeModalVisible, setIsChequeModalVisible] = useState(false);
  const [isSignModalVisible, setIsSignModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showImage, setShowImage] = useState();
  const [signatureShowImage, setSignatureShowImage] = useState();
  const [setImgName, setImageName] = useState();
  const [setSignatureImgName, setSignatureImageName] = useState();

  const [form] = Form.useForm();
  const [bankDetails, setBankDetails] = useState({
    bank_account_holder_name: "",
    branch_name: "",
    bank_name: "",
    account_number: "",
    account_type: "",
    IFSC_code: "",
    branch_code: "",
    bank_address: "",
    bank_branch_location: "",
    bank_state: "",
    cancelled_cheque: "",
    approval_status: undefined,
    approval_remarks: undefined,
    cancelled_cheque_name: "",
    declaration_name: "",
    declaration_signature: "",
    declaration_designation: "",
    declaration_signature_name: "",
    declaration_date: "",
    declaration_place: "",
    caution_deposit_mode: "",
    caution_deposit_reference_no: "",
    caution_deposit_amount: "",
    caution_deposit_date: "",
  });
  useEffect(() => {
    if (registrationData !== undefined) {
      setBankDetails(registrationData);
    }
  }, [registrationData]);

  useEffect(() => {
    if (registrationData !== undefined) {
      setBankDetails(registrationData);
    }
  }, []);

  // const onFinish = async (values: any) => {
  //   try {
  //     console.log("VAlues", values, declared);
  //     var warningMessage = "";
  //     const validate = () => {
  //       console.log("Save Basic Details: ====>  ", bankDetails);
  //       if (!declared) {
  //         warningMessage = "Please accept the Acknowledgment / Declaration..!";
  //         return false;
  //       }
  //       return true;
  //     };
  //     if (validate()) {
  //       var dataToSendUpdated;
  //       let flag = false;
  //       const dataToSend = {
  //         user_id: values.user_id,
  //         contact_number: values.contact_number,
  //         bank_account_holder_name: values.bank_account_holder_name,
  //         branch_name: values.branch_name,
  //         bank_name: values.bank_name,
  //         account_number: values.account_number,
  //         account_type: values.account_type,
  //         IFSC_code: values.IFSC_code,
  //         branch_code: values.branch_code,
  //         bank_address: values.bank_address,
  //         bank_branch_location: values.bank_branch_location,
  //         bank_state: values.bank_state,
  //         declaration_date: values.declaration_date,
  //         declaration_place: values.declaration_place,
  //         declaration_name: values.declaration_name,
  //         declaration_designation: values.declaration_designation,
  //         caution_deposit_mode: values.caution_deposit_mode,
  //         caution_deposit_reference_no: values.caution_deposit_reference_no,
  //         caution_deposit_amount: values.caution_deposit_amount,
  //         caution_deposit_date: values.caution_deposit_date,
  //         submission_type: 2,
  //         registered_as: registeredAs === "member" ? "MEMBER" : "TRADER",
  //       };
  //       if (
  //         registrationData.ORIGINAL_cancelled_cheque_name !==
  //         values.cancelled_cheque_name
  //       ) {
  //         dataToSendUpdated = {
  //           ...dataToSend,
  //           cancelled_cheque: values.cancelled_cheque,
  //           cancelled_cheque_name: values.cancelled_cheque_name,
  //         };
  //         flag = true;
  //       }
  //       if (
  //         registrationData.ORIGINAL_declaration_signature_name !==
  //         values.declaration_signature_name
  //       ) {
  //         dataToSendUpdated = {
  //           ...dataToSend,
  //           declaration_signature: values.declaration_signature,
  //           declaration_signature_name: values.declaration_signature_name,
  //         };
  //         flag = true;
  //       }
  //       try {
  //         const response = await postRegisterUser(
  //           flag ? dataToSendUpdated : dataToSend
  //         );
  //         console.log("Response on save ", response.data.status);
  //         if (response.data.status) {
  //           setRegistrationData({
  //             ...bankDetails,
  //             isbankDetailsSubmitted: true,
  //           });
  //           setIsModalVisible(true);
  //           // message.info(
  //           //   `Your registration form submitted successfully for approval.We will get back to you with login credentials once it is approved.Thank you..!`,
  //           //   10, loginRoute());
  //           // loginRoute();
  //         }
  //       } catch (error) {
  //         message.error("Something went Wrong. Please Try again..!", 5);
  //         console.log("Akhil Error caught :", error);
  //         setRegistrationData({
  //           ...bankDetails,
  //           isbankDetailsSubmitted: false,
  //         });
  //         setBankDetails({ ...bankDetails });
  //       }
  //     } else {
  //       console.log("Validation required");
  //       setRegistrationData({
  //         ...bankDetails,
  //         isbankDetailsSubmitted: false,
  //       });
  //       setBankDetails({ ...bankDetails });
  //       message.error(warningMessage);
  //     }
  //   } catch (error) {
  //     message.error("Something went Wrong. Please Try again..!", 5);
  //     console.log("Raj Error caught :", error);
  //     setRegistrationData({
  //       ...bankDetails,
  //       isbankDetailsSubmitted: false,
  //     });
  //     setBankDetails({ ...bankDetails });
  //   }
  // };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  /*******************************************************************/
  const handleFileSelect = (event: any) => {
    var reader = new FileReader();
    var file = event.target.files[0];
    if (file !== undefined) {
      if (
        file.type.split("/").includes("pdf") ||
        file.type.split("/").includes("png") ||
        file.type.split("/").includes("jpg") ||
        file.type.split("/").includes("jpeg")
      ) {
        reader.onload = (upload) => {
          if (event.target.name === "cancelled_cheque") {
            setBankDetails((prevState) => ({
              ...prevState,
              cancelled_cheque_name: file.name,
              [event.target.name]:
                upload &&
                upload !== null &&
                upload.target !== null &&
                upload.target.result,
            }));
          } else {
            setBankDetails((prevState) => ({
              ...prevState,
              declaration_signature_name: file.name,
              [event.target.name]:
                upload &&
                upload !== null &&
                upload.target !== null &&
                upload.target.result,
            }));
          }
        };
        reader.readAsDataURL(file);
      } else {
        event.target.value = "";
        message.error("Please select JPG,JPEG,PNG or PDF file..!");
      }
    } else {
      event.target.value = "";
      message.error("Please select file..!");
    }
  };

  const onChangeDate = (date: any, dateString: any) => {
    handleChange(
      {
        target: {
          name: "caution_deposit_date",
          value: dateString,
        },
      },
      ""
    );
  };
  const onChange = (date: any, dateString: any) => {
    handleChange(
      {
        target: {
          name: "declaration_date",
          value: dateString,
        },
      },
      ""
    );
  };
  /*******************************************************************/
  const onChangeCaution = (e: any) => {
    setPaid(e.target.checked);
  };

  const setImage = (image, imageName) => {
    setImageName(imageName);
    setShowImage(image);
    setIsChequeModalVisible(true);
  };

  const setSignatureImage = (image, imageName) => {
    setSignatureImageName(imageName);
    setSignatureShowImage(image);
    setIsSignModalVisible(true);
  };

  const onChangeDeclaration = (e: any) => {
    setDeclared(e.target.checked);
  };
  const handleChange = (e: any, key: any) => {
    if (key === "account_type" && e.target.checked) {
      setBankDetails((prevState) => ({
        ...prevState,
        account_type: e.target.value,
      }));
    }
    if (key === "account_type" && !e.target.checked) {
      setBankDetails((prevState) => ({
        ...prevState,
        account_type: "",
      }));
    } else {
      setBankDetails((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };
  return (
    <>
      {/* <Spin spinning={loading} className="bank-details-ant-spin">
    </Spin> */}
      <div className="bank-details">
        <Modal
          title="Registration Successfull"
          visible={isModalVisible}
          footer={false}
          onCancel={() => loginRoute()}
          style={{ textAlign: "center" }}
        >
          <p>Your registration form submitted successfully for approval.</p>
          <p>
            We will get back to you with login credentials once it is approved.
          </p>
          <p>Thank you..!</p>
          <div className="btn-container">
            <Button
              onClick={() => loginRoute()}
              className="btn-container"
              style={{
                marginTop: "-25px",
                marginLeft: "5px",
                padding: "2px 8px !important",
                backgroundColor: "#20346a",
                width: "fit-content !important",
                borderRadius: "20px",
                color: "white",
                float: "right",
              }}
            >
              {" "}
              OK{" "}
            </Button>
          </div>
        </Modal>
        <Modal
          title="Cancel Cheque Image"
          visible={isChequeModalVisible}
          footer={false}
          onCancel={() => setIsChequeModalVisible(false)}
          style={{ textAlign: "center" }}
        >
          <Image
            preview={true}
            alt="Cancel Cheque image"
            src={showImage}
            className="itc-logo"
          />
          {/* <ReactImageZoom width="300" height="250" zoomPosition="original" zoomWidth="480"  img={bankDetails.cancelled_cheque} /> */}

          <div className="btn-container" style={{ height: 15 }}>
            {setImgName !== "pdf" && setImgName !== "PDF" && (
              <Base64Downloader
                style={{
                  marginTop: "5px",
                  marginLeft: "5px",
                  padding: "2px 8px !important",
                  backgroundColor: "#20346a",
                  width: "fit-content !important",
                  borderRadius: "20px",
                  color: "white",
                  float: "right",
                }}
                base64={showImage}
                downloadName={bankDetails.cancelled_cheque_name}
              >
                Download
              </Base64Downloader>
            )}
            {setImgName === "pdf" && setImgName === "PDF" && (
              <Button
                onClick={() => window.open(showImage)}
                className="btn-container"
                style={{
                  marginTop: "5px",
                  marginLeft: "5px",
                  padding: "2px 8px !important",
                  backgroundColor: "#20346a",
                  width: "fit-content !important",
                  borderRadius: "20px",
                  color: "white",
                  float: "right",
                }}
              >
                {" "}
                Download{" "}
              </Button>
            )}
          </div>
        </Modal>
        <Modal
          title="Signature Image"
          visible={isSignModalVisible}
          footer={false}
          onCancel={() => setIsSignModalVisible(false)}
          style={{ textAlign: "center" }}
        >
          <Image
            preview={true}
            alt="Signature image"
            src={signatureShowImage}
            className="itc-logo"
          />
          {/* <ReactImageZoom width="300" height="250" zoomPosition="original" zoomWidth="480"  img={bankDetails.declaration_signature} /> */}

          <div className="btn-container" style={{ height: 15 }}>
            {setSignatureImgName !== "pdf" && setSignatureImgName !== "PDF" && (
              <Base64Downloader
                style={{
                  marginTop: "5px",
                  marginLeft: "5px",
                  padding: "2px 8px !important",
                  backgroundColor: "#20346a",
                  width: "fit-content !important",
                  borderRadius: "20px",
                  color: "white",
                  float: "right",
                }}
                base64={showImage}
                downloadName={bankDetails.cancelled_cheque_name}
              >
                Download
              </Base64Downloader>
            )}
            {setSignatureImgName !== "pdf" && setSignatureImgName !== "PDF" && (
              <Button
                onClick={() => window.open(signatureShowImage)}
                className="btn-container"
                style={{
                  marginTop: "5px",
                  marginLeft: "5px",
                  padding: "2px 8px !important",
                  backgroundColor: "#20346a",
                  width: "fit-content !important",
                  borderRadius: "20px",
                  color: "white",
                  float: "right",
                }}
              >
                {" "}
                Download{" "}
              </Button>
            )}
          </div>
        </Modal>
        <div className="form-container" style={{ textAlign: "left" }}>
          {bankDetails.approval_status === 5 ||
            (bankDetails.approval_status === null &&
              bankDetails.approval_remarks !== null &&
              bankDetails.approval_remarks !== "" && (
                <div className="row">
                  <p className="remarks-text">{bankDetails.approval_remarks}</p>
                </div>
              ))}
          <div className="row">
            <div className="dualColumnRow" style={{ display: "inline-flex" }}>
              <div className="left">
                A/c Holder Name <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  maxLength={50}
                  required={true}
                  value={bankDetails.bank_account_holder_name}
                  name="bank_account_holder_name"
                  onChange={(e) => handleChange(e, "")}
                />
              </div>
              <div
                className="left"
                style={{ width: "140px", marginLeft: "15px" }}
              >
                Branch Name <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  maxLength={100}
                  required={true}
                  name="branch_name"
                  value={bankDetails.branch_name}
                  onChange={(e) => handleChange(e, "")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="dualColumnRow" style={{ display: "inline-flex" }}>
              <div className="left">
                Bank Name <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  maxLength={100}
                  required={true}
                  value={bankDetails.bank_name}
                  name="bank_name"
                  onChange={(e) => handleChange(e, "")}
                />
              </div>
              <div
                className="left"
                style={{ width: "140px", marginLeft: "15px" }}
              >
                Account Number <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  maxLength={20}
                  required={true}
                  name="account_number"
                  value={bankDetails.account_number}
                  onChange={(e) => handleChange(e, "")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="dualColumnRow"
              style={{ display: "inline-flex", marginTop: "0px" }}
            >
              <div
                className="left"
                style={{ marginTop: "0px", alignSelf: "flex-start" }}
              >
                Account Type <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Radio.Group
                  value={bankDetails.account_type}
                  onChange={(e) => handleChange(e, "")}
                  name="account_type"
                  disabled={true}
                >
                  <Radio value={1}>CURRENT</Radio>
                  <Radio value={2}>SAVINGS</Radio>
                  <Radio value={3}>CASH CREDIT</Radio>
                </Radio.Group>
              </div>
              <div
                className="left"
                style={{ width: "140px", marginLeft: "-5px" }}
              >
                IFSC Code <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  maxLength={20}
                  required={true}
                  name="IFSC_code"
                  value={bankDetails.IFSC_code}
                  onChange={(e) => handleChange(e, "")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="dualColumnRow" style={{ display: "inline-flex" }}>
              <div className="left">
                Branch Code <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  maxLength={25}
                  required={true}
                  value={bankDetails.branch_code}
                  name="branch_code"
                  onChange={(e) => handleChange(e, "")}
                />
              </div>
              <div
                className="left"
                style={{ width: "140px", marginLeft: "15px" }}
              >
                Address of the bank <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  maxLength={200}
                  required={true}
                  name="bank_address"
                  value={bankDetails.bank_address}
                  onChange={(e) => handleChange(e, "")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="dualColumnRow" style={{ display: "inline-flex" }}>
              <div className="left">
                Branch Location/City <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  maxLength={50}
                  required={true}
                  value={bankDetails.bank_branch_location}
                  name="bank_branch_location"
                  onChange={(e) => handleChange(e, "")}
                />
              </div>
              <div
                className="left"
                style={{ width: "140px", marginLeft: "15px" }}
              >
                State <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  maxLength={20}
                  required={true}
                  name="bank_state"
                  value={bankDetails.bank_state}
                  onChange={(e) => handleChange(e, "")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="dualColumnRow" style={{ display: "inline-flex" }}>
              <div
                className="left"
                style={{
                  width: 170,
                  marginTop: 10,
                  paddingTop: 0,
                  display: "inline-block",
                  alignSelf: "normal",
                }}
              >
                Cancelled Cheque <label style={{ color: "red" }}>*</label>:{" "}
              </div>
              <div
                className="right"
                style={{
                  display: "inline-block",
                  margin: "10px 10px 10px 0px",
                }}
              >
                {bankDetails &&
                (bankDetails.cancelled_cheque_name === "" ||
                  bankDetails.cancelled_cheque_name === null ||
                  bankDetails.cancelled_cheque_name === undefined) ? (
                  <Input
                    disabled={true}
                    ref={inputRef}
                    accept="image/x-png,image/png,image/jpg,image/jpeg,application/pdf"
                    // ref="fileCheque"
                    type="file"
                    name="cancelled_cheque"
                    className="upload-file"
                    id="fileCheque"
                    onChange={(e) => handleFileSelect(e)}
                    required
                    style={{
                      font: "small-caption",
                      textAlign: "center",
                      border: "ridge",
                    }}
                  />
                ) : (
                  <>
                    <span className="image-name">
                      {" "}
                      {bankDetails.cancelled_cheque_name}
                      {/* <span
                        className="image-name-close"
                        onClick={() =>
                          setBankDetails({
                            ...bankDetails,
                            cancelled_cheque_name: "",
                            cancelled_cheque: "",
                          })
                        }
                      >
                        x
                    </span>{" "} */}
                    </span>
                    <Button
                      onClick={
                        () =>
                          bankDetails.cancelled_cheque_name.split(".")[1] !==
                            "pdf" &&
                          bankDetails.cancelled_cheque_name.split(".")[1] !==
                            "PDF"
                            ? setImage(
                                "data:image/png;base64," +
                                  bankDetails.cancelled_cheque.split("'")[1],
                                bankDetails.cancelled_cheque_name.split(".")[1]
                              )
                            : window.open(bankDetails.cancelled_cheque)
                        // setIsChequeModalVisible(true)
                      }
                    >
                      View Cancel Cheque
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* <div style={{ marginLeft: 10 }}>
            <Checkbox onChange={onChangeCaution} checked={true} disabled={true}>
              <p className="content-text">Caution Deposit Paid </p>
            </Checkbox>
          </div>
          {paid && (
            <div className="form-container-1">
              <div className="row">
                <div
                  className="dualColumnRow"
                  style={{ display: "inline-flex" }}
                >
                  <div className="left">
                    Payment Mode <label style={{ color: "red" }}>*</label>:
                  </div>
                  <div className="right">
                    <Input
                      disabled={true}
                      maxLength={50}
                      required={true}
                      name="caution_deposit_mode"
                      value={bankDetails.caution_deposit_mode}
                      onChange={(e) => handleChange(e, "")}
                    />
                  </div>
                  <div
                    className="dualColumnRow"
                    style={{ display: "inline-flex" }}
                  >
                    <div className="left">
                      Payment Mode <label style={{ color: "red" }}>*</label>:
                  </div>
                    <div className="right">
                      <Input
                        disabled={true}
                        maxLength={50}
                        required={true}
                        name="caution_deposit_mode"
                        value={bankDetails.caution_deposit_mode}
                        onChange={(e) => handleChange(e, "")}
                      />
                    </div>
                    <div
                      className="left"
                      style={{ width: "140px", marginLeft: "15px" }}
                    >
                      Payment Ref No <label style={{ color: "red" }}>*</label>:
                  </div>
                    <div className="right">
                      <Input
                        disabled={true}
                        maxLength={30}
                        required={true}
                        name="caution_deposit_reference_no"
                        value={bankDetails.caution_deposit_reference_no}
                        onChange={(e) => handleChange(e, "")}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="dualColumnRow"
                    style={{ display: "inline-flex" }}
                  >
                    <div className="left">
                      Payment Amount <label style={{ color: "red" }}>*</label>:
                </div>
                    <div className="right">
                      <Input
                        disabled={true}
                        maxLength={50}
                        required={true}
                        name="caution_deposit_amount"
                        value={bankDetails.caution_deposit_amount}
                        onChange={(e) => handleChange(e, "")}

                      />
                    </div>
                    <div className="left" style={{ width: "140px", marginLeft: "15px" }}>
                      Payment Date <label style={{ color: "red" }}>*</label>:
                  </div>
                    <div className="right">
                      <DatePicker
                        disabled={true}
                        name="caution_deposit_date"
                        disabledDate={disabledDate}
                        format="YYYY-MM-DD"
                        value={
                          bankDetails.caution_deposit_date !== null
                            ? moment(
                              bankDetails.caution_deposit_date,
                              "YYYY-MM-DD"
                            )
                            : undefined
                        }
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}
          <div style={{ marginLeft: 10 }}>
            <Checkbox
              onChange={onChangeDeclaration}
              checked={true}
              disabled={true}
            >
              <p className="content-text">
                {" "}
                All the information provided by me is true to the best of my
                knowledge{" "}
              </p>{" "}
            </Checkbox>
          </div>
          <div className="form-container-2" style={{ textAlign: "left" }}>
            No column to be left Blank. All Documents provided should be legible
            to ensure correctness of the details specified above.
          </div>
          <div className="row">
            <div className="dualColumnRow" style={{ display: "inline-flex" }}>
              <div
                className="left"
                style={{
                  width: 170,
                  marginTop: 10,
                  display: "inline-block",
                  paddingTop: 0,
                  alignSelf: "normal",
                }}
              >
                {" "}
                Signature <label style={{ color: "red" }}>*</label>:{" "}
              </div>
              <div
                className="right"
                style={{
                  display: "inline-block",
                  margin: "10px 10px 10px 0px",
                }}
              >
                {bankDetails &&
                (bankDetails.declaration_signature_name === "" ||
                  bankDetails.declaration_signature_name === null ||
                  bankDetails.declaration_signature_name === undefined) ? (
                  <Input
                    disabled={true}
                    ref={inputRef}
                    accept="image/x-png,image/png,image/jpg,image/jpeg,application/pdf"
                    // ref="fileSign"
                    type="file"
                    name="declaration_signature"
                    className="upload-file"
                    id="fileSign"
                    onChange={(e) => handleFileSelect(e)}
                    required
                    style={{
                      font: "small-caption",
                      textAlign: "center",
                      border: "ridge",
                    }}
                  />
                ) : (
                  <>
                    <span className="image-name">
                      {" "}
                      {bankDetails.declaration_signature_name}
                      {/* <span
                      className="image-name-close"
                      onClick={() =>
                        setBankDetails({
                          ...bankDetails,
                          declaration_signature_name: "",
                          declaration_signature: "",
                        })
                      }
                    >
                      x
                    </span>{" "} */}
                    </span>
                    <Button
                      onClick={
                        () =>
                          bankDetails.declaration_signature_name.split(
                            "."
                          )[1] !== "pdf" &&
                          bankDetails.declaration_signature_name.split(
                            "."
                          )[1] !== "PDF"
                            ? setImage(
                                "data:image/png;base64," +
                                  bankDetails.declaration_signature.split(
                                    "'"
                                  )[1],
                                bankDetails.declaration_signature_name.split(
                                  "."
                                )[1]
                              )
                            : window.open(bankDetails.declaration_signature)
                        // setIsSignModalVisible(true)
                      }
                    >
                      View Signature
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="dualColumnRow" style={{ display: "inline-flex" }}>
              <div className="left">
                Designation <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  maxLength={30}
                  required={true}
                  value={bankDetails.declaration_designation}
                  name="declaration_designation"
                  onChange={(e) => handleChange(e, "")}
                />
              </div>
              <div
                className="left"
                style={{ width: "140px", marginLeft: "15px" }}
              >
                Name <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  maxLength={50}
                  required={true}
                  name="declaration_name"
                  value={bankDetails.declaration_name}
                  onChange={(e) => handleChange(e, "")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="dualColumnRow" style={{ display: "inline-flex" }}>
              <div className="left">
                Date <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right" style={{ width: "305px" }}>
                <DatePicker
                  disabled={true}
                  name="declaration_date"
                  style={{ width: "300px", borderRadius: "40px" }}
                  disabledDate={disabledDate}
                  format="YYYY-MM-DD"
                  value={
                    bankDetails.declaration_date !== null
                      ? moment(bankDetails.declaration_date, "YYYY-MM-DD")
                      : undefined
                  }
                  onChange={onChange}
                />
              </div>
              <div
                className="left"
                style={{ width: "140px", marginLeft: "15px" }}
              >
                Place <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  maxLength={50}
                  required={true}
                  name="declaration_place"
                  value={bankDetails.declaration_place}
                  onChange={(e) => handleChange(e, "")}
                />
              </div>
            </div>
          </div>
          <Button
            className="login-btn"
            type="primary"
            size={"large"}
            style={{
              // marginLeft: 670,
              alignSelf: "right",
              padding: 0,
              // maxWidth: "max-content",
              borderRadius: 30,
              backgroundColor: "#20346a",
            }}
            block
            htmlType="submit"
            onClick={() => handleRouteClick(1, "two")}
          >
            Previous
          </Button>

          {/* <Button
            // onClick={() => onFinish(bankDetails)}
            type="primary"
            htmlType="submit"
            className="login-btn pushback-btn"
          >
            Push Back
          </Button>
          <Button
            // onClick={() => onFinish(bankDetails)}
            type="primary"
            htmlType="submit"
            className="login-btn reject-btn"
          >
            Reject
          </Button>

          <Button
            // onClick={() => onFinish(bankDetails)}
            type="primary"
            htmlType="submit"
            className="login-btn approve-btn"
          >
            Approve
          </Button> */}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  registrationData: selectRegisterData,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setRegistrationData: (registrationDataCopy: any) =>
      dispatch(setRegistrationData(registrationDataCopy)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BankDetails);
