import React, { useState, useRef, useEffect } from "react";
import { Dispatch } from "redux";
import {
  Spin,
  Form,
  Input,
  Button,
  message,
  Radio,
  Calendar,
  Image,
  DatePicker,
} from "antd";
import "./userStatutoryDetails.scss";
import Base64Downloader from "react-base64-downloader";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment";
import { postRegisterUser } from "../../services/register-services";
import { setRegistrationData } from "../../redux/registration/registration.actions";
import { selectRegisterData } from "../../redux/registration/registration.selectors";
import { createStructuredSelector } from "reselect";
import { connect, useSelector } from "react-redux";
import Modal from "antd/lib/modal/Modal";
// import ReactImageZoom from 'react-image-zoom';

const StatutoryDetails: React.FC<any> = (props: any) => {
  const {
    clicked,
    handleRouteClick,
    registrationData,
    setRegistrationData,
    registeredAs,
    DataRequired,
  } = props;
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showImage, setShowImage] = useState();
  const [setImgName, setImageName] = useState();
  const [statutoryDetails, setStatutoryDetails] = useState({
    PAN_no: "",
    CST_date: "",
    CST_regn_no: "",
    GST_number: "",
    MSM_Act_date: "",
    MSM_Act_regn_no: "",
    VAT_TIN_no: "",
    VAT_date: "",
    is_active: "",
    pan_image: "",
    pan_image_name: "",
    tds_exempted: 2,
    tds_exemption_certificate_name: "",
    tds_exemption_certificate: "",
    approval_status: undefined,
    approval_remarks: undefined,
    registered_CST: "",
    registered_MSM_Act: "",
    registered_Service_Tax: "",
    registered_VAT: "",
    service_tax_date: "",
    service_tax_regn_no: "",
    tds_section_applicable1: "",
    tds_section_applicable2: "",
    tds_section_applicable3: "",
    type_of_service1: "",
    type_of_service2: "",
    type_of_service3: "",
  });

  useEffect(() => {
    if (registrationData !== undefined) {
      setStatutoryDetails({
        ...registrationData,
        tds_exempted:
          registrationData.tds_exemption_certificate_name === null ||
          registrationData.tds_exemption_certificate_name === undefined ||
          registrationData.tds_exemption_certificate_name === ""
            ? 2
            : 1,
      });
    }
  }, [registrationData]);

  useEffect(() => {
    if (registrationData !== undefined) {
      setStatutoryDetails({
        ...registrationData,
        tds_exempted:
          registrationData.tds_exemption_certificate_name === null ||
          registrationData.tds_exemption_certificate_name === undefined ||
          registrationData.tds_exemption_certificate_name === ""
            ? 2
            : 1,
      });
    }
  }, []);

  // const onSave = async (values: any) => {
  //   //   PAN_no: string,
  //   //   pan_image: string,
  //   //   type_of_service1: string,
  //   //   type_of_service2: string,
  //   //   type_of_service3: string,
  //   //   tds_section_applicable1: string,
  //   //   tds_section_applicable2: string,
  //   //   tds_section_applicable3: string,
  //   //   registered_VAT: BigInteger,
  //   //   VAT_TIN_no: string,
  //   //   VAT_date: Date,
  //   //   registered_CST: BigInteger,
  //   //   CST_regn_no: string,
  //   //   CST_date: Date,
  //   //   registered_Service_Tax: BigInteger,
  //   //   service_tax_regn_no: string,
  //   //   service_tax_date: Date,
  //   //   registered_MSM_Act: string,
  //   //   MSM_Act_regn_no: string,
  //   //   MSM_Act_date: Date,
  //   //   GST_number: string,
  //   //   submission_type: BigInteger,
  //   //   registered_as: string
  //   // })=>{
  //   try {
  //     console.log("VAlues", values);
  //     var warningMessage = "";
  //     const validate = () => {
  //       console.log("Save Basic Details: ====>  ", statutoryDetails);
  //       if (
  //         statutoryDetails.PAN_no === "" ||
  //         statutoryDetails.PAN_no === undefined ||
  //         statutoryDetails.PAN_no === null
  //       ) {
  //         warningMessage = "Enter PAN Number..!";
  //         return false;
  //       }

  //       if (
  //         statutoryDetails.pan_image === "" ||
  //         statutoryDetails.pan_image === undefined ||
  //         statutoryDetails.pan_image === null ||
  //         statutoryDetails.pan_image_name === "" ||
  //         statutoryDetails.pan_image_name === undefined ||
  //         statutoryDetails.pan_image_name === null
  //       ) {
  //         warningMessage = "Upload PAN card image..!";
  //         return false;
  //       }

  //       if (
  //         (statutoryDetails.type_of_service1 === "" ||
  //           statutoryDetails.type_of_service1 === undefined ||
  //           statutoryDetails.type_of_service1 === null) &&
  //         (statutoryDetails.type_of_service2 === "" ||
  //           statutoryDetails.type_of_service2 === undefined ||
  //           statutoryDetails.type_of_service2 === null) &&
  //         (statutoryDetails.type_of_service3 === "" ||
  //           statutoryDetails.type_of_service3 === undefined ||
  //           statutoryDetails.type_of_service3 === null)
  //       ) {
  //         warningMessage = "Enter Type of service..!";
  //         return false;
  //       }

  //       if (
  //         (statutoryDetails.tds_section_applicable1 === "" ||
  //           statutoryDetails.tds_section_applicable1 === undefined ||
  //           statutoryDetails.tds_section_applicable1 === null) &&
  //         (statutoryDetails.tds_section_applicable2 === "" ||
  //           statutoryDetails.tds_section_applicable2 === undefined ||
  //           statutoryDetails.tds_section_applicable2 === null) &&
  //         (statutoryDetails.tds_section_applicable3 === "" ||
  //           statutoryDetails.tds_section_applicable3 === undefined ||
  //           statutoryDetails.tds_section_applicable3 === null)
  //       ) {
  //         warningMessage = "Enter TDS section..!";
  //         return false;
  //       }

  //       if (
  //         (statutoryDetails.registered_VAT === "" ||
  //           statutoryDetails.registered_VAT === undefined ||
  //           statutoryDetails.registered_VAT === null) &&
  //         (statutoryDetails.registered_CST === "" ||
  //           statutoryDetails.registered_CST === undefined ||
  //           statutoryDetails.registered_CST === null) &&
  //         (statutoryDetails.registered_Service_Tax === "" ||
  //           statutoryDetails.registered_Service_Tax === undefined ||
  //           statutoryDetails.registered_Service_Tax === null) &&
  //         (statutoryDetails.registered_MSM_Act === "" ||
  //           statutoryDetails.registered_MSM_Act === undefined ||
  //           statutoryDetails.registered_MSM_Act === null)
  //       ) {
  //         warningMessage = "Select VAT/CST/Service Tax/MSM Act..!";
  //         return false;
  //       }

  //       if (
  //         statutoryDetails.registered_VAT === "1" &&
  //         (statutoryDetails.VAT_date === "" ||
  //           statutoryDetails.VAT_date === undefined ||
  //           statutoryDetails.VAT_date === null)
  //       ) {
  //         warningMessage = "Select VAT date..!";
  //         return false;
  //       }

  //       if (
  //         statutoryDetails.registered_VAT === "1" &&
  //         (statutoryDetails.VAT_TIN_no === "" ||
  //           statutoryDetails.VAT_TIN_no === undefined ||
  //           statutoryDetails.VAT_TIN_no === null)
  //       ) {
  //         warningMessage = "Enter VAT Tin no..!";
  //         return false;
  //       }

  //       if (
  //         statutoryDetails.registered_CST === "1" &&
  //         (statutoryDetails.CST_regn_no === "" ||
  //           statutoryDetails.CST_regn_no === undefined ||
  //           statutoryDetails.CST_regn_no === null)
  //       ) {
  //         warningMessage = "Enter CST Regn no..!";
  //         return false;
  //       }

  //       if (
  //         statutoryDetails.registered_CST === "1" &&
  //         (statutoryDetails.CST_date === "" ||
  //           statutoryDetails.CST_date === undefined ||
  //           statutoryDetails.CST_date === null)
  //       ) {
  //         warningMessage = "Select CST date..!";
  //         return false;
  //       }

  //       if (
  //         statutoryDetails.registered_Service_Tax === "1" &&
  //         (statutoryDetails.service_tax_regn_no === "" ||
  //           statutoryDetails.service_tax_regn_no === undefined ||
  //           statutoryDetails.service_tax_regn_no === null)
  //       ) {
  //         warningMessage = "Enter Service tax regn no ..!";
  //         return false;
  //       }

  //       if (
  //         statutoryDetails.registered_Service_Tax === "1" &&
  //         (statutoryDetails.service_tax_date === "" ||
  //           statutoryDetails.service_tax_date === undefined ||
  //           statutoryDetails.service_tax_date === null)
  //       ) {
  //         warningMessage = "Select Service tax date..!";
  //         return false;
  //       }

  //       if (
  //         statutoryDetails.registered_MSM_Act === "1" &&
  //         (statutoryDetails.MSM_Act_regn_no === "" ||
  //           statutoryDetails.MSM_Act_regn_no === undefined ||
  //           statutoryDetails.MSM_Act_regn_no === null)
  //       ) {
  //         warningMessage = "Enter MSM act regn no ..!";
  //         return false;
  //       }

  //       if (
  //         statutoryDetails.registered_MSM_Act === "1" &&
  //         (statutoryDetails.MSM_Act_date === "" ||
  //           statutoryDetails.MSM_Act_date === undefined ||
  //           statutoryDetails.MSM_Act_date === null)
  //       ) {
  //         warningMessage = "Select MSM act date..!";
  //         return false;
  //       }

  //       return true;
  //     };
  //     if (validate()) {
  //       // console.log("VAlues", values);
  //       var dataToSendUpdated;
  //       let flag = false;
  //       const dataToSend = {
  //         user_id: values.user_id,
  //         contact_number: values.contact_number,
  //         PAN_no: values.PAN_no,
  //         type_of_service1: values.type_of_service1,
  //         type_of_service2: values.type_of_service2,
  //         type_of_service3: values.type_of_service3,
  //         tds_section_applicable1: values.tds_section_applicable1,
  //         tds_section_applicable2: values.tds_section_applicable2,
  //         tds_section_applicable3: values.tds_section_applicable3,
  //         registered_VAT: values.registered_VAT,
  //         VAT_TIN_no: values.registered_VAT == 1 ? values.VAT_TIN_no : null,
  //         VAT_date: values.registered_VAT == 1 ? values.VAT_date : null,
  //         registered_CST: values.registered_CST,
  //         CST_regn_no: values.registered_CST == 1 ? values.CST_regn_no : null,
  //         CST_date: values.registered_CST == 1 ? values.CST_date : null,
  //         registered_Service_Tax: values.registered_Service_Tax,
  //         service_tax_regn_no:
  //           values.registered_Service_Tax == 1
  //             ? values.service_tax_regn_no
  //             : null,
  //         service_tax_date:
  //           values.registered_Service_Tax == 1 ? values.service_tax_date : null,
  //         registered_MSM_Act: values.registered_MSM_Act,
  //         MSM_Act_regn_no:
  //           values.registered_MSM_Act == 1 ? values.MSM_Act_regn_no : null,
  //         MSM_Act_date:
  //           values.registered_MSM_Act == 1 ? values.MSM_Act_date : null,
  //         GST_number: values.GST_number,
  //         submission_type: 1,
  //         registered_as: registeredAs === "member" ? "MEMBER" : "TRADER",
  //       };
  //       if (
  //         registrationData.ORIGINAL_pan_image_name !== values.pan_image_name
  //       ) {
  //         dataToSendUpdated = {
  //           ...dataToSend,
  //           pan_image: values.pan_image,
  //           pan_image_name: values.pan_image_name,
  //         };
  //         flag = true;
  //       }
  //       const response = await postRegisterUser(
  //         flag ? dataToSendUpdated : dataToSend
  //       );
  //       console.log("Response on save ", response.data.status);
  //       if (response.data.status) {
  //         setRegistrationData({
  //           ...statutoryDetails,
  //           isStatutoryDetailsSubmitted: true,
  //         });
  //         handleRouteClick(2, "three");
  //       }
  //     } else {
  //       console.log("Validation required");
  //       setRegistrationData({
  //         ...statutoryDetails,
  //         isStatutoryDetailsSubmitted: false,
  //       });
  //       setStatutoryDetails({ ...statutoryDetails });
  //       message.error(warningMessage);
  //     }
  //   } catch (error) {
  //     message.error("Something went Wrong. Please Try again..!", 5);
  //     console.log("Akhil Error caught :", error);
  //     setRegistrationData({
  //       ...statutoryDetails,
  //       isStatutoryDetailsSubmitted: false,
  //     });
  //     setStatutoryDetails({ ...statutoryDetails });
  //   }
  // };

  const onFinish = async () => {
    try {
    } catch (error) {
      console.log("Error caught :", error);
    }
  };

  const handlePanUpload = (event: any) => {
    // var self = this;
    var reader = new FileReader();
    var file = event.target.files[0];
    const fileExtension = file.type.split("/");
    if (file !== undefined) {
      if (
        fileExtension[1] === "pdf" ||
        fileExtension[1] === "png" ||
        fileExtension[1] === "jpg" ||
        fileExtension[1] === "jpeg"
      ) {
        reader.onload = (upload) => {
          setStatutoryDetails({
            ...statutoryDetails,
            pan_image_name: file.name,
            [event.target.name]:
              upload &&
              upload !== null &&
              upload.target !== null &&
              upload.target.result,
          });
        };
        reader.readAsDataURL(file);
      } else {
        event.target.value = "";
        message.error("Please select JPG,JPEG,PNG or PDF file..!");
      }
    } else {
      message.error("Please select file..!");
    }
  };

  const handleChange = (e: any) => {
    setStatutoryDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const canEnableTextBox = (value: any, featureCode: string) => {
    return value.includes(featureCode);
  };

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 25 },
  };

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };
  const setImage = (image, imagename) => {
    setImageName(imagename);
    setShowImage(image);
    setIsModalVisible(true);
  };
  return (
    <>
      {/* <Spin spinning={loading} className="statutory-details-ant-spin">
      </Spin> */}
      <Modal
        title="PAN Card Image"
        visible={isModalVisible}
        footer={false}
        onCancel={() => setIsModalVisible(false)}
        style={{ textAlign: "center", width: "550px" }}
      >
        <Image
          preview={true}
          alt="PAN image"
          src={showImage}
          className="itc-logo"
        />
        {/* <ReactImageZoom width="300" height="250" zoomPosition="original" zoomWidth="480"  img={statutoryDetails.pan_image} /> */}

        <div className="btn-container" style={{ height: 15 }}>
          {setImgName !== "pdf" && setImgName !== "PDF" && (
            <Base64Downloader
              style={{
                marginTop: "5px",
                marginLeft: "5px",
                padding: "2px 8px !important",
                backgroundColor: "#20346a",
                width: "fit-content !important",
                borderRadius: "20px",
                color: "white",
                float: "right",
              }}
              base64={showImage}
              downloadName={statutoryDetails.pan_image_name}
            >
              Download
            </Base64Downloader>
          )}
          {setImgName === "pdf" && setImgName === "PDF" && (
            <Button
              onClick={() => window.open(showImage)}
              className="btn-container"
              style={{
                marginTop: "5px",
                marginLeft: "5px",
                padding: "2px 8px !important",
                backgroundColor: "#20346a",
                width: "fit-content !important",
                borderRadius: "20px",
                color: "white",
                float: "right",
              }}
            >
              {" "}
              Download{" "}
            </Button>
          )}
        </div>
      </Modal>
      <div className="statutory-details">
        <div className="form-container">
          {statutoryDetails.approval_status === 5 ||
            (statutoryDetails.approval_status === null &&
              statutoryDetails.approval_remarks !== null &&
              statutoryDetails.approval_remarks !== "" && (
                <div className="row">
                  <p className="remarks-text">
                    {statutoryDetails.approval_remarks}
                  </p>
                </div>
              ))}
          <div
            className="row"
            style={{ height: 40, marginBlock: 0, marginBottom: 0 }}
          >
            <div
              className="dualColumnRow"
              style={{ display: "inline-flex", marginBlock: 0 }}
            >
              <div className="left">
                PAN Number <label style={{ color: "red" }}>*</label> :
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  name="PAN_no"
                  maxLength={20}
                  value={statutoryDetails.PAN_no}
                  onChange={(e) => handleChange(e)}
                />
                     
              </div>
              <div
                className="left"
                style={{
                  width: 120,
                  marginTop: 5,
                  paddingTop: 0,
                  alignSelf: "normal",
                }}
              >
                Upload PAN card <label style={{ color: "red" }}>*</label>:{" "}
              </div>
              <div className="right" style={{ marginTop: "8px" }}>
                 
                {statutoryDetails &&
                (statutoryDetails.pan_image_name === "" ||
                  statutoryDetails.pan_image_name === null ||
                  statutoryDetails.pan_image_name === undefined) ? (
                  <Input
                    disabled={true}
                    ref={inputRef}
                    accept="image/x-png,image/png,image/jpg,image/jpeg,application/pdf"
                    type="file"
                    name="pan_image"
                    style={{
                      font: "small-caption",
                      textAlign: "center",
                      border: "ridge",
                    }}
                    className="upload-file"
                    id="file"
                    onChange={handlePanUpload}
                    required
                  />
                ) : (
                  <>
                    <span className="pan-image-name">
                      {" "}
                      {statutoryDetails.pan_image_name}
                      {/* <span
                      className="pan-image-name-close"
                      onClick={() =>
                        setStatutoryDetails({
                          ...statutoryDetails,
                          pan_image_name: "",
                          pan_image: "",
                        })
                      }
                      >
                      x
                    </span>{" "}  setIsModalVisible(true) */}
                    </span>
                    <Button
                      onClick={() =>
                        // setImage('data:image/png;base64,'+statutoryDetails.pan_image.split("'")[1] )

                        statutoryDetails.pan_image_name.split(".")[1] !==
                          "pdf" &&
                        statutoryDetails.pan_image_name.split(".")[1] !== "PDF"
                          ? setImage(
                              "data:image/png;base64," +
                                statutoryDetails.pan_image.split("'")[1],
                              statutoryDetails.pan_image_name.split(".")[1]
                            )
                          : window.open(statutoryDetails.pan_image)
                      }
                    >
                      View PAN Card
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              height: 40,
              marginBlock: 0,
              marginBottom: 0,
              marginTop: 2,
            }}
          >
            <div
              className="dualColumnRow"
              style={{
                display: "inline-flex",
                marginBlock: 0,
                marginBottom: 0,
              }}
            >
              <div className="left">GST Number :</div>
              <div className="right">
                <Input
                  disabled={true}
                  maxLength={30}
                  style={{ marginBottom: 0 }}
                  name="GST_number"
                  value={statutoryDetails.GST_number}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{ marginBlock: "auto", height: 40, marginTop: 0 }}
          >
            <div
              className="dualColumnRow"
              style={{ display: "inline-flex", marginBlock: 0 }}
            >
              <div className="left" style={{ width: 200 }}>
                Type of Service<label style={{ color: "red" }}>*</label>:
              </div>
              <label style={{ marginBlock: "auto" }}>1.</label>
              <div className="right">
                <Input
                  disabled={true}
                  style={{ width: 220 }}
                  maxLength={30}
                  name="type_of_service1"
                  value={statutoryDetails.type_of_service1}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <label style={{ marginBlock: "auto" }}>2.</label>
              <div className="right">
                <Input
                  disabled={true}
                  style={{ width: 220 }}
                  maxLength={30}
                  name="type_of_service2"
                  value={statutoryDetails.type_of_service2}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <label style={{ marginBlock: "auto" }}>3.</label>
              <div className="right" style={{ marginBottom: 0, marginTop: 0 }}>
                <Input
                  disabled={true}
                  style={{ width: 220 }}
                  maxLength={30}
                  name="type_of_service3"
                  value={statutoryDetails.type_of_service3}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="row" style={{ marginBottom: 5, marginTop: 2 }}>
            <div className="dualColumnRow" style={{ display: "block" }}>
              <div
                className="left"
                style={{
                  width: 170,
                  fontWeight: "bold",
                  display: "inline-block",
                }}
              >
                {" "}
                {/*  display inline block for TDS Changes */}
                Exempted from TDS <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right" style={{ display: "inline-block" }}>
                {" "}
                  
                <Radio.Group
                  name="registered_VAT"
                  value={statutoryDetails.tds_exempted}
                  onChange={(e) =>
                    setStatutoryDetails({
                      ...statutoryDetails,
                      tds_exempted: e.target.value,
                    })
                  }
                  disabled={true}
                >
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2}>No</Radio>
                </Radio.Group>
                (If exempted from TDS, please provide exemption certificate)
              </div>

              {statutoryDetails.tds_exempted === 1 ? (
                // {statutoryDetails.tds_exempted === 0 ? ( // comment it for TDS changes
                <div style={{ display: "block" }}>
                  <div
                    className="left"
                    style={{
                      // width: 120,
                      marginTop: 5,
                      paddingTop: 0,
                      alignSelf: "normal",
                      display: "inline-block",
                    }}
                  >
                    Upload TDS Certificate{" "}
                    <label style={{ color: "red" }}>*</label>:{" "}
                    <p
                      style={{
                        color: "red",
                        fontSize: 12,
                        whiteSpace: "nowrap",
                      }}
                    >
                      File size should be less than 1Mb
                      <br />
                      Accepted formats PDF/JPG/JPEG/PNG
                    </p>
                  </div>
                  <div
                    className={
                      statutoryDetails.tds_exemption_certificate_name === "" ||
                      statutoryDetails.tds_exemption_certificate_name ===
                        null ||
                      statutoryDetails.tds_exemption_certificate_name ===
                        undefined
                        ? "right"
                        : "right tds-section"
                    }
                    style={{ marginTop: "10px", display: "inline-block" }}
                  >
                    {statutoryDetails &&
                    (statutoryDetails.tds_exemption_certificate_name === "" ||
                      statutoryDetails.tds_exemption_certificate_name ===
                        null ||
                      statutoryDetails.tds_exemption_certificate_name ===
                        undefined) ? (
                      <div style={{ marginTop: "-45px", position: "absolute" }}>
                        <Input
                          ref={inputRef}
                          accept="image/x-png,image/png,image/jpg,image/jpeg,application/pdf"
                          type="file"
                          value=""
                          name="tds_exemption_certificate"
                          style={{
                            font: "small-caption",
                            textAlign: "center",
                            border: "ridge",
                            marginLeft: 65,
                          }}
                          className="upload-file"
                          id="file"
                          onChange={handlePanUpload}
                          required
                          disabled
                        />
                      </div>
                    ) : (
                      <>
                        <span className="pan-image-name">
                          {" "}
                          {statutoryDetails.tds_exemption_certificate_name}
                          {/* <span
                          className="pan-image-name-close"
                          onClick={() =>
                            setStatutoryDetails({
                              ...statutoryDetails,
                              tds_exemption_certificate_name: "",
                              tds_exemption_certificate: "",
                            })
                          }
                        >
                          x
                        </span>{" "} */}
                        </span>
                        <Button
                          onClick={() =>
                            statutoryDetails.tds_exemption_certificate_name.split(
                              "."
                            )[1] !== "pdf" &&
                            statutoryDetails.tds_exemption_certificate_name.split(
                              "."
                            )[1] !== "PDF"
                              ? setImage(
                                  "data:image/png;base64," +
                                    statutoryDetails.tds_exemption_certificate.split(
                                      "'"
                                    )[1],
                                  statutoryDetails.tds_exemption_certificate.split(
                                    "."
                                  )[1]
                                )
                              : // setImage(
                                //     statutoryDetails.tds_exemption_certificate
                                //   )
                                window.open(
                                  statutoryDetails.tds_exemption_certificate
                                )
                          }
                        >
                          View TDS Certificate
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className="dualColumnRow"
                  style={{
                    display: "inline-flex",
                    marginBlock: 0,
                    marginBottom: 0,
                  }}
                >
                  <div className="left" style={{ marginRight: 0, width: 200 }}>
                    TDS Section Applicable{" "}
                    <label style={{ color: "red" }}>*</label>:
                  </div>
                  <div className="right" style={{ display: "inline-flex" }}>
                     
                    <label style={{ marginBlock: "auto" }}>
                      1.&nbsp;&nbsp;
                    </label>
                    <div className="right">
                      <Input
                        disabled={true}
                        style={{ width: 220 }}
                        maxLength={30}
                        name="tds_section_applicable1"
                        value={statutoryDetails.tds_section_applicable1}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <label style={{ marginBlock: "auto" }}>
                      &nbsp;2.&nbsp;
                    </label>
                    <div className="right">
                      <Input
                        disabled={true}
                        style={{ width: 220 }}
                        maxLength={30}
                        name="tds_section_applicable2"
                        value={statutoryDetails.tds_section_applicable2}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <label style={{ marginBlock: "auto" }}>
                      &nbsp;3.&nbsp; 
                    </label>
                    <div className="right">
                      <Input
                        disabled={true}
                        style={{ width: 220 }}
                        maxLength={30}
                        name="tds_section_applicable3"
                        value={statutoryDetails.tds_section_applicable3}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <div
            className="row"
            style={{ marginBottom: 0, marginTop: 0, height: 40 }}
          >
            <div
              className="dualColumnRow"
              style={{
                display: "inline-flex",
                marginBlock: 0,
                marginBottom: 0,
              }}
            >
              <div className="left" style={{ marginRight: 0, width: 200 }}>
                TDS Section Applicable <label style={{ color: "red" }}>*</label>
                :
              </div>
              <div className="right" style={{ display: "inline-flex" }}>
                 <label style={{ marginBlock: "auto" }}>1.</label>
                <div className="right">
                  <Input
                    disabled={true}
                    style={{ width: 220 }}
                    maxLength={30}
                    name="tds_section_applicable1"
                    value={statutoryDetails.tds_section_applicable1}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <label style={{ marginBlock: "auto" }}>2.</label>
                <div className="right">
                  <Input
                    disabled={true}
                    style={{ width: 220 }}
                    maxLength={30}
                    name="tds_section_applicable2"
                    value={statutoryDetails.tds_section_applicable2}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <label style={{ marginBlock: "auto" }}>3.</label>
                <div className="right">
                  <Input
                    disabled={true}
                    style={{ width: 220 }}
                    maxLength={30}
                    name="tds_section_applicable3"
                    value={statutoryDetails.tds_section_applicable3}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
           
          <p
            className="contet-text"
            style={{ alignContent: "screenLeft", marginBottom: 10 }}
          >
                          (If exempted from TDS, please provide exemption certificate)
                        
          </p> */}
          <div className="row" style={{ marginBottom: 0 }}>
            <div className="dualColumnRow" style={{ display: "inline-flex" }}>
              <div className="left" style={{ width: 170, fontWeight: "bold" }}>
                Are you registered under{" "}
                <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <label style={{ width: 100, fontWeight: "normal" }}>
                                    VAT :                 
                </label>
              </div>
              <div className="right">
                  
                <Radio.Group
                  name="registered_VAT"
                  value={statutoryDetails.registered_VAT}
                  onChange={(e) =>
                    setStatutoryDetails({
                      ...statutoryDetails,
                      registered_VAT: e.target.value,
                    })
                  }
                  disabled={true}
                >
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2}>No</Radio>
                                  
                </Radio.Group>
              </div>
              <div className="right" style={{ marginLeft: 5 }}>
                   
                <Input
                  style={{ width: 200, marginRight: 10 }}
                  maxLength={30}
                  name="VAT_TIN_no"
                  value={statutoryDetails.VAT_TIN_no}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="TIN No"
                  disabled={true}
                ></Input>
              </div>
              <div className="right" style={{ marginLeft: 6 }}>
                    
                <DatePicker
                  name="VAT_date"
                  clearIcon={false}
                  disabled={true}
                  format="YYYY-MM-DD"
                  value={
                    statutoryDetails.VAT_date !== null
                      ? moment(statutoryDetails.VAT_date, "YYYY-MM-DD")
                      : undefined
                  }
                  onChange={(value, date) =>
                    setStatutoryDetails({ ...statutoryDetails, VAT_date: date })
                  }
                  disabledDate={disabledDate}
                />
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: 10 }}>
            <div className="dualColumnRow" style={{ display: "inline-flex" }}>
              <div className="left" style={{ width: 170 }}></div>
              <div className="right">
                <label style={{ width: 100, fontWeight: "normal" }}>
                                   CST :&nbsp;                 
                </label>
              </div>
              <div className="right">
                  
                <Radio.Group
                  name="registered_CST"
                  value={statutoryDetails.registered_CST}
                  onChange={(e) =>
                    setStatutoryDetails({
                      ...statutoryDetails,
                      registered_CST: e.target.value,
                    })
                  }
                  disabled={true}
                >
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2}>No</Radio>
                                  
                </Radio.Group>
              </div>
              <div className="right" style={{ marginLeft: 3 }}>
                   
                <Input
                  style={{ width: 200, marginRight: 10 }}
                  width={30}
                  name="CST_regn_no"
                  value={statutoryDetails.CST_regn_no}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Regn No"
                  disabled={true}
                ></Input>
              </div>
              <div className="right" style={{ marginLeft: 11 }}>
                   
                <DatePicker
                  name="CST_date"
                  clearIcon={false}
                  disabled={true}
                  format="YYYY-MM-DD"
                  value={
                    statutoryDetails.CST_date !== null
                      ? moment(statutoryDetails.CST_date, "YYYY-MM-DD")
                      : undefined
                  }
                  onChange={(value, date) =>
                    setStatutoryDetails({ ...statutoryDetails, CST_date: date })
                  }
                  disabledDate={disabledDate}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="dualColumnRow" style={{ display: "inline-flex" }}>
              <div className="left" style={{ width: 170 }}></div>
              <div className="right">
                <label style={{ width: 100, fontWeight: "normal" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;Service Tax :&nbsp;                 
                </label>
              </div>
              <div className="right">
                  
                <Radio.Group
                  name="registered_Service_Tax"
                  style={{ marginLeft: 0 }}
                  value={statutoryDetails.registered_Service_Tax}
                  onChange={(e) =>
                    setStatutoryDetails({
                      ...statutoryDetails,
                      registered_Service_Tax: e.target.value,
                    })
                  }
                  disabled={true}
                >
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2}>No</Radio>
                                  
                </Radio.Group>
              </div>
              <div className="right">
                    
                <Input
                  style={{ width: 200, marginRight: 10 }}
                  maxLength={30}
                  name="service_tax_regn_no"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Regn No"
                  value={statutoryDetails.service_tax_regn_no}
                  disabled={true}
                ></Input>
              </div>
              <div className="right" style={{ marginLeft: 7 }}>
                    
                <DatePicker
                  name="service_tax_date"
                  clearIcon={false}
                  disabled={true}
                  format="YYYY-MM-DD"
                  value={
                    statutoryDetails.service_tax_date !== null
                      ? moment(statutoryDetails.service_tax_date, "YYYY-MM-DD")
                      : undefined
                  }
                  onChange={(value, date) =>
                    setStatutoryDetails({
                      ...statutoryDetails,
                      service_tax_date: date,
                    })
                  }
                  disabledDate={disabledDate}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="dualColumnRow" style={{ display: "inline-flex" }}>
              <div className="left" style={{ width: 170 }}></div>
              <div className="right">
                <label style={{ width: 100, fontWeight: "normal" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MSM Act :
                                  
                </label>
              </div>
              <div className="right">
                   
                <Radio.Group
                  name="registered_MSM_Act"
                  value={statutoryDetails.registered_MSM_Act}
                  onChange={(e) =>
                    setStatutoryDetails({
                      ...statutoryDetails,
                      registered_MSM_Act: e.target.value,
                    })
                  }
                  disabled={true}
                >
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2}>No</Radio>
                                  
                </Radio.Group>
              </div>
              <div className="right">
                    
                <Input
                  style={{ width: 200, marginRight: 10 }}
                  maxLength={30}
                  name="MSM_Act_regn_no"
                  value={statutoryDetails.MSM_Act_regn_no}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Regn No"
                  disabled={true}
                ></Input>
              </div>
              <div className="right">
                      
                <DatePicker
                  name="MSM_Act_date"
                  clearIcon={false}
                  disabled={true}
                  format="YYYY-MM-DD"
                  value={
                    statutoryDetails.MSM_Act_date !== null
                      ? moment(statutoryDetails.MSM_Act_date, "YYYY-MM-DD")
                      : undefined
                  }
                  onChange={(value, date) =>
                    setStatutoryDetails({
                      ...statutoryDetails,
                      MSM_Act_date: date,
                    })
                  }
                  disabledDate={disabledDate}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="dualColumnRow" style={{ display: "inline-flex" }}>
              <p
                className="contet-text"
                style={{ marginTop: 5, marginBottom: 5 }}
              >
                ( In case Party gets registered under MSM Act (Micro, Small and
                Medium Enterprises Development Act), subsequently, Party has to
                provide the registration details under that Act. If no details
                are provided by Party, it shall be assumed that Party is not
                covered under this Act. )
              </p>
            </div>
          </div>
          <Button
            className="login-btn"
            type="primary"
            size={"large"}
            style={{
              // marginLeft: 670,
              alignSelf: "right",
              padding: 0,
              // maxWidth: "max-content",
              borderRadius: 30,
              backgroundColor: "#20346a",
            }}
            block
            htmlType="submit"
            onClick={() => handleRouteClick(0, "one")}
          >
            Previous
          </Button>
          <Button
            className="login-btn"
            type="primary"
            size={"large"}
            style={{
              marginLeft: 750,
              alignSelf: "right",
              padding: 0,
              // maxWidth: "max-content",
              borderRadius: 30,
              backgroundColor: "#20346a",
            }}
            block
            htmlType="submit"
            onClick={() => handleRouteClick(2, "three")}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  registrationData: selectRegisterData,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setRegistrationData: (registrationDataCopy: any) =>
      dispatch(setRegistrationData(registrationDataCopy)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatutoryDetails);
