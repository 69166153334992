import React, { useEffect, useState } from "react";
import "./EVR-submit-screen.styles.scss";

import { getCompanyParams } from "../../services/sys-params-services";

import moment from "moment";
import { ReactComponent as CloseIcon } from "../../assets/cancel.svg";
import { ReactComponent as CheckedIcon } from "../../assets/checked.svg";
import CircleImage from "../../assets/circle.svg";

import { SearchOutlined, SyncOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import { createStructuredSelector } from "reselect";

import {
  selectBranches,
  selectCrops,
  selectCurrentPage,
  selectDefaultParams,
  selectSelectedCrop,
  selectVarieties,
} from "../../redux/common/common.selectors";

// import { setBranchIndex } from "../../redux/common/common.actions";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  cancelAuction,
  editAuction,
  getExistingApproval,
  putExistingApproval,
  getMaterialCodes,
  getConditionCodes,
  checkVendorAvailability,
} from "../../services/auction-services";
import {
  getRebateMatrixService,
  getRebateService,
  getHubsService,
  getSlotIdsService,
} from "../../services/common-services";

import { FeatureCodes, dataConstants } from "../../constants/data-constants";
import { canUserAccess } from "../../utils/utils";

import {
  Table,
  Form,
  message,
  Checkbox,
  Popover,
  Modal,
  Button,
  Select,
  Input,
  Progress,
  Spin,
  Space,
  Col,
  Row,
  Typography,
} from "antd";

import { Dispatch } from "redux";

import CustomFilter from "../../components/custom-filter/custom-filter.component";
import { selectUserData } from "../../redux/user/user.selectors";
import DummyData from "./dummyData.json";
import { Link } from "react-router-dom";
import { Content, Footer } from "antd/lib/layout/layout";
import Axios from "axios";
import ButtonGroup from "antd/lib/button/button-group";

const { confirm } = Modal;
const { Option } = Select;
const FileDownLoad = require("js-file-download");

const editDefault = {
  qty_rqd: undefined,
  rebate_matrix_id: undefined,
  auction_id: undefined,
};

const EVRSubmitScreen: React.FC = (props: any) => {
  const {
    branches,
    varieties,
    selectedCropId,
    history,
    userData,
    crops,
    currentpage,
    defaultParams,
  } = props;

  const [approvalsData, setApprovalsData] = useState<any>();
  // console.log(uniqueFirmName, uniqueRoles, "unique");
  const [isLoading, setIsLoading] = useState(false); //true
  const [isEdit, setIsEdit] = useState(false);
  const [rebates, setRebates] = useState<any>();
  const [valueToEdit, setValueToEdit] = useState<any>(editDefault);
  const [rebateModalVisible, setRebateModalVisible] = useState(false);
  const [conditionsModalVisible, setConditionsModalVisible] = useState(false);
  const [rebateTableData, setRebateTableData] = useState<Array<any>>();
  const [materialCode, setMaterialCodesData] = useState<any>();
  const [conditionCodes, setConditionCodesData] = useState<any>();
  const [clickedRowData, setClickedRowData] = useState<any>();
  const [checkAll, setCheckAll] = useState<any>(false);
  const [filteredCrop, setFilteredCrop] = useState<any>([selectedCropId]);
  const [isSpinnerOnProgress, setIsSpinnerOnProgress] = useState(false);

  const [slots, setSlots] = useState();
  const [hubs, setHubs] = useState();
  const [selectedBranch, setSelectedBranch] = useState(branches[0]?.id); //set it to 0 def
  const [selectedVariety, setSelectedVariety] = useState();
  const [selectedDates, setSelectedDates] = useState([
    sessionStorage.getItem("startDateString") || moment().format("YYYY-MM-DD"),
    sessionStorage.getItem("endDateString") || moment().format("YYYY-MM-DD"),
  ]);
  const [selectedHub, setSelectedHub] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const [registrationStatus, setRegistrationStatus] = useState<any>(true);
  const [vendorCodeError, setVendorCodeError] = useState<boolean>(false);
  const [downloaddata, setdownloaddata] = useState<any>();
  const [isProgressClicked, setisProgressClicked] = useState(false);
  const [filtersData, setFilters] = useState<any>();
  const [filtersDropdown, setFiltersDropdown] = useState<any>();
  const [filtersRole, setRoleFilters] = useState();
  const fileDownload = async () => {
    setIsSpinnerOnProgress(true);
    const body = {
      registered_through: 2,
      download: true,
      associated_branches: userData.user_territories,
      approval_status: Array.isArray(selectedStatus)
        ? selectedStatus.length > 0
          ? selectedStatus
          : undefined
        : [selectedStatus],
      start_date: selectedDates[0],
      end_date: selectedDates[1],
      crops:
        filteredCrop.length === 0 ? crops.map((item) => item.id) : filteredCrop,
    };

    // const fileresponse = await getAuctionReports(body);
    // console.log("file response ", fileresponse);
    // if (fileresponse) {
    //   if (fileresponse.status === 200) {
    //     console.log(
    //       "file response1 ",
    //       fileresponse.data,
    //     );
    //#region file download
    Axios({
      url: dataConstants.getExistingVendorUrl,
      method: "POST",
      data: body,
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        let progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setdownloaddata(progress);
      },
    })
      .then((response: any) => {
        FileDownLoad(
          response.data,
          `Existing_Vendor_Report_${new Date().toLocaleDateString()}.xlsx`
        );
        message.success("File has been downloaded successfully!");
        setIsSpinnerOnProgress(false);
        setTimeout(
          function () {
            setisProgressClicked(false);
          }.bind(this),
          5000
        );
      })
      .catch((err: any) => {
        message.error("File cannot be downloaded");
        setIsSpinnerOnProgress(false);
        setisProgressClicked(false);
      });
    //#endregion
    // } else {
    //   message.warning("File cannot be downloaded");
    //   setIsSpinnerOnProgress(false);
    //   setisProgressClicked(false);
    // }
    // } else {
    //   message.error("Error downloading file");
    //   setIsSpinnerOnProgress(false);
    //   setisProgressClicked(false);
    // }
  };

  const status = registrationStatus
    ? [
        { name: "Verification Pending", id: 2 },
        { name: "Verified", id: 3 },
        { name: "Rejected", id: 4 },
        { name: "Pushed back", id: 5 },
        { id: 8, name: "Sent to SAP PAN Verification" },
        { id: 9, name: "SAP PAN verified and Approved" },
        { id: 10, name: "SAP PAN verification failed" },
      ]
    : [
        { name: "Verification Pending", id: 2 },
        { name: "Verified", id: 3 },
        { name: "Verified By TO", id: 6 },
        { name: "Rejected", id: 4 },
        { name: "Pushed back", id: 5 },
        { name: "Pushed back By TM", id: 7 },
      ];

  const storedValue = sessionStorage.getItem("approvalStatus");
  const initialValue = storedValue ? JSON.parse(storedValue) : status[0].id;
  const [selectedStatus, setSelectedStatus] = useState(initialValue);

  const { TextArea } = Input;

  /**
   * @component
   * @description Reusable component to display table header.
   * @property {string} title Title of header
   * @property {string} subtitle Subtitle of header
   * @memberof Approvals
   */
  const TableColumnHeader = (props: any) => {
    const { title, subtitle } = props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontWeight: 400,
            color: "white",
            fontSize: "14px",
            whiteSpace: "nowrap",
            textAlign: "left",
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: "#cbdfff",
            fontSize: "11px",
            fontWeight: "normal",
            textAlign: "left",
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "Firm Name",
      dataIndex: "nameFirm",
      key: "name_firm",
      width: 110,
      filters: filtersData,
      filterSearch: true,
      sorter: (a, b) => a.nameFirm.localeCompare(b.nameFirm),
      ellipsis: true,
    },
    {
      title: "PAN Number",
      dataIndex: "PANNo",
      key: "PAN_no",
      width: 90,
    },
    {
      title: "Phone Number",
      dataIndex: "contactNumber",
      key: "contact_number",
      width: 100,
    },
    // {
    //   title: "SAP Branch",
    //   dataIndex: "sapBranch",
    //   key: "sap_branch",
    //   width: 120,
    // },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 70,
      filters: filtersRole,
      // width: 130,
      // render: (value: number, row: any) => {
      //   return (
      //     <div className={"rebate-info-upcoming"}>

      //       {console.log("Row Data--------------->", row.rolesList, row.role_id, row.rolesList.filter(
      //         (item: any) => item.id == row.role_id
      //       )[0], row.rolesList.filter(
      //         (item: any) => item.id == row.role_id
      //       )[0] && row.rolesList.filter(
      //         (item: any) => item.id == row.role_id
      //       ).length ? row.rolesList.filter(
      //         (item: any) => item.id == row.role_id
      //       )[0].id : "Select Role"
      //       )}

      //       <Select
      //         // className="remarks-select"
      //         // disabled={row.approvalStatus !== 2 && !row.checked}
      //         disabled={row.disableRow}
      //         value={row.rolesList.filter(
      //           (item: any) => item.id == row.role_id
      //         )[0] && row.rolesList.filter(
      //           (item: any) => item.id == row.role_id
      //         ).length ? row.rolesList.filter(
      //           (item: any) => item.id == row.role_id
      //         )[0].id : "Select Role"
      //         }
      //         onSelect={(value) =>
      //           setRoleData(value, row.contactNumber, row.userId)
      //         }
      //         style={{ width: "100%" }}
      //       >
      //         {row.rolesList &&
      //           row.rolesList != undefined &&
      //           row.rolesList.length &&
      //           row.rolesList.map((role: any, idx: number) => (
      //             <Option key={role.id} value={role.id}>
      //               {role.val}
      //             </Option>
      //           ))}
      //       </Select>
      //     </div>
      //   );
      // },
    },

    // {
    //   title: "GST Number",
    //   dataIndex: "GSTNumber",
    //   key: "GST_number",
    //   width: 120,
    // },
    // {
    //   title: "Payment Date",
    //   dataIndex: "cautionDepositDate",
    //   key: "pricaution_deposit_datece",
    //   width: 90,
    // },
    // {
    //   title: "Payment Mode",
    //   dataIndex: "cautionDepositMode",
    //   key: "caution_deposit_mode",
    //   width: 90,
    // },
    // {
    //   title: "Payment Amount",
    //   dataIndex: "cautionDepositAmount",
    //   key: "caution_deposit_amount",
    //   width: 90,
    // },
    // {
    //   title: "Payment Ref. Number",
    //   dataIndex: "cautionDepositReferenceNo",
    //   key: "caution_deposit_reference_no",
    //   width: 160,
    // },

    {
      title: "Approval Status",
      // children: [
      //   {
      dataIndex: "approvalStaus",
      key: "approval_status",
      width: 150,
      render: (value: number, row: any) => {
        return (
          <div className={"rebate-info-upcoming"}>
            {/* {console.log("Row Data--------------->", row.approvalStatus
              ? row.approvalTypes.filter(
                (item: any) => item.id == row.approvalStatus
              )[0].id
              : row.approvalTypes[0].id, row.approvalStatus, row.approvalTypes.filter(
                (item: any) => item.id == row.approvalStatus
              )[0], row)} */}

            <Select
              // className="remarks-select"
              // disabled={row.approvalStatus !== 2 && !row.checked}
              disabled={
                row.disableRow || userData.role_name === "Trading Manager"
              }
              value={
                row.approvalTypes.filter(
                  (item: any) => item.id === row.approvalStatus
                )[0]?.id
              }
              onSelect={(value) =>
                setApprovalStatus(value, row.contactNumber, row.userId)
              }
              style={{ width: "100%" }}
            >
              {row.approvalTypes &&
                row.approvalTypes != undefined &&
                row.approvalTypes.length &&
                row.approvalTypes.map((status: any, idx: number) => (
                  <Option
                    key={status.id}
                    value={status.id}
                    disabled={
                      status.id === 6 || status.id === 4 || status.id === 5
                        ? false
                        : true
                    }
                  >
                    {status.val}
                  </Option>
                ))}
            </Select>
          </div>
        );
      },
      //   },
      // ],
    },
    {
      title: "Vendor Code",
      dataIndex: "vendorCode",
      key: "vendor_code",
      width: 150,
      render: (value: any, row: any) => (
        <div className={"select-remarks"}>
          <Input
            style={{ verticalAlign: "middle" }}
            maxLength={9}
            minLength={9}
            // type="number"
            value={row.vendorCode}
            // disabled={row.vendorCode !== null}
            disabled={
              (!row.disableRow && row.approvalStatus === 6 ? false : true) ||
              userData.role_name === "Trading Manager"
            }
            // onBlur={(e) => checkVendorIsDuplicate(e)}
            onChange={(e) =>
              setVendorCode(e.target.value, row.contactNumber, row.userId)
            }
          />
        </div>
      ),
    },
    {
      title: "Remarks",
      key: "remarks",
      width: 180,
      // children: [
      //   {
      dataIndex: "remarks",
      // key: "remarks",
      // width: 180,
      render: (value: any, row: any) => (
        <div
          className={`select-remarks ${
            userData.role_name === "Trading Manager"
              ? "disable-field"
              : !row.disableRow &&
                (row.approvalStatus === 6 ||
                  row.approvalStatus === 4 ||
                  row.approvalStatus === 5)
              ? ""
              : "disable-field"
          }`}
        >
          <TextArea
            // disabled={row.approvalStatus !== 2 && !row.checked}
            disabled={
              (!row.disableRow &&
              (row.approvalStatus === 6 ||
                row.approvalStatus === 4 ||
                row.approvalStatus === 5)
                ? false
                : true) || userData.role_name === "Trading Manager"
            }
            autoSize={{ minRows: 0, maxRows: 2 }}
            style={{ verticalAlign: "middle" }}
            maxLength={35}
            value={row.remarks}
            onChange={(e) =>
              setRemarks(e.target.value, row.contactNumber, row.userId)
            }
          />
        </div>
      ),
      //   },
      // ],
    },
    {
      title: "Submit",
      // children: [
      //   {
      dataIndex: "actions",
      key: "actions",
      width: 60,
      render: (value: number, row: any) => {
        //actions, record
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {/* <Checkbox onChange={() => onCheckBoxChange(row)} /> */}
            <div
              onClick={() => {
                onCheckBoxChange([row], "single");
              }}
              // className={`action-button`}
              className={`action-button  ${
                row.disableRow ||
                // vendorCodeError ||
                userData.role_name === "Trading Manager"
                  ? "action-button-disabled"
                  : ""
              } `}
            >
              <Popover
                overlayClassName="competitor-hover-container"
                title={"Submit"}
                style={{ width: "20px !important" }}
              >
                <img
                  style={{
                    // width: "10px",
                    // height: "10px",
                    margin: "0px auto",
                  }}
                  src={CircleImage}
                  alt="Submit"
                />
              </Popover>
            </div>
          </div>
        );
      },
      //   },
      // ],
    },
    {
      title: (
        <div
          style={{
            fontSize: "12px",
            color: "white",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            paddingRight: "13px",
          }}
        >
          <TableColumnHeader title="Action" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              paddingLeft: "6px",
            }}
          >
            {/* {console.log("-----------> Check All", this.props.hubTableData && this.props.hubTableData.region && this.props.hubTableData.region && this.props.hubTableData.region.length && this.props.hubTableData.region.filter((obj) => obj.checked === true).length, "===>",
              (this.props.hubTableData && this.props.hubTableData.region && this.props.hubTableData.region.length && (this.props.hubTableData && this.props.hubTableData.region && this.props.hubTableData.region && this.props.hubTableData.region.length && this.props.hubTableData.region.filter((obj) => obj.checked === true).length) === (this.props.hubTableData && this.props.hubTableData.region && this.props.hubTableData.region.length)),
              "===>",
              this.props.hubTableData && this.props.hubTableData.region && this.props.hubTableData.region.length,
              "==> checkAll",
              this.state.checkAll)} */}
            <Checkbox
              disabled={userData.role_name === "Trading Manager"}
              // width="10px"
              // height="10px"
              //{checkAll}
              checked={
                approvalsData &&
                approvalsData.length !== 0 &&
                approvalsData
                  .filter((obj: any) => obj.disableRow === false)
                  .every((item: { approvalStatus: number; checked: boolean }) =>
                    item.approvalStatus !== 2 ? item.checked === true : false
                  )
                // (approvalsData &&
                //   approvalsData.length &&
                //   approvalsData.filter((obj: any) => obj.checked === true)
                //     .length) ===
                //   (approvalsData &&
                //     approvalsData.length -
                //       approvalsData.filter(
                //         (obj: any) => obj.disableRow === true
                //       ).length)
              }
              onChange={(e) => {
                // let data = setHubDataForTable();
                const modifiedData =
                  approvalsData && approvalsData.length
                    ? approvalsData.map((item: any) => {
                        console.log("item", item);
                        // if (item.approvalStatus === 2 && !item.vendorCode) {
                        if (!item.disableRow && item.approvalStatus !== 2) {
                          return {
                            ...item,
                            checked: e.target.checked,
                          };
                        } else {
                          return item;
                        }
                      })
                    : [];
                setApprovalsData(modifiedData);
              }}
            ></Checkbox>
          </div>
        </div>
      ),
      key: "action",
      dataIndex: "action",
      width: 70,
      render: (value: number, row: any) => {
        return (
          <div className="approval-table-checkbox">
            <Checkbox
              disabled={
                (!row.disableRow &&
                (row.approvalStatus === 6 ||
                  row.approvalStatus === 4 ||
                  row.approvalStatus === 5)
                  ? false
                  : true) || userData.role_name === "Trading Manager"
              }
              checked={row.checked}
              onChange={(e) => {
                const modifiedData = approvalsData.map(
                  (item: any, index: any) => {
                    if (item.userId === row.userId) {
                      return {
                        ...item,
                        checked: e.target.checked,
                      };
                    } else {
                      return { ...item };
                    }
                  }
                );
                setApprovalsData(modifiedData);
              }}
            />
          </div>
        );
      },
    },
    {
      title: "",
      children: [
        {
          dataIndex: "details",
          key: "details2",
          render: (value: number, rowData: any) => {
            const data = {
              contactNumber: rowData.contactNumber,
              archives: true,
              existingTrader: true,
              PAN_no: rowData.PANNo,
            };

            return (
              <Link
                to={{
                  pathname: `/details`,
                  // pathname: `/details/data=${JSON.stringify({
                  //   ...data,
                  // })}`,
                  state: data,
                }}
              >
                {/* <div onClick={() => history.push("/details")}> */}
                {"View Details"}
                {/* </div> */}
              </Link>
            );
          },
          width: 90,
        },
      ],
    },
  ];

  // const handleSearch = (confirm: any) => {
  //   setTimeout(() => confirm(), 100);
  // };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => {
      return (
        <Space direction="vertical">
          <Input.Search
            placeholder="Search"
            onSearch={(value) => {
              if (value.trim()) {
                setSelectedKeys([value.toUpperCase()]);
                confirm();
              } else clearFilters();
            }}
            allowClear
            enterButton
          />
        </Space>
      );
    },
    filterIcon: (filtered: any) => <SearchOutlined />,
    onFilter: (value: any, record: any) =>
      record[dataIndex].toString().includes(value),
    // onFilterDropdownVisibleChange: (visible: any) => {
    //   console.log("visible", visible);
    //   if (visible) {
    //     setTimeout(
    //       () => document.getElementById("search-input")?.onselect,
    //       100
    //     );
    //   }
    // },
  });
  const filteredData = (term: any) => {
    const filters = filtersData
      .flatMap((e: any) => {
        if (e.value.toLowerCase().includes(term.toLowerCase()))
          return {
            label: e.value,
            value: e.value,
          };
      })
      .filter((value) => value !== undefined);
    setFiltersDropdown(filters);
  };
  const getColumnFiltersProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={"Search"}
          onChange={(e) => {
            filteredData(e.target.value.toUpperCase());
          }}
          style={{ width: 180, marginBottom: 8, display: "flex" }}
          allowClear
        />
        <Space direction="vertical">
          <Checkbox.Group
            value={selectedKeys}
            onChange={(e) => {
              setSelectedKeys(e ? e : []);
            }}
            style={{ width: "180px", whiteSpace: "nowrap", overflow: "hidden" }}
          >
            <Row>
              {filtersDropdown &&
                filtersDropdown.map((e: any) => {
                  return (
                    <Col span={24} key={e.value}>
                      <Checkbox value={e.value}>
                        <Typography.Paragraph
                          style={{
                            display: "inline-block",
                            margin: 0,
                            verticalAlign: "bottom",
                            width: 130,
                          }}
                          ellipsis
                        >
                          {e.label}
                        </Typography.Paragraph>
                      </Checkbox>
                    </Col>
                  );
                })}
            </Row>
          </Checkbox.Group>
          <ButtonGroup>
            <Button type="primary" onClick={() => confirm()}>
              Search
            </Button>
            <Button
              danger
              onClick={() => clearFilters()}
              style={{ borderLeftColor: "inherit", left: "10%" }}
            >
              Reset
            </Button>
          </ButtonGroup>
        </Space>
      </div>
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    filterSearch: true,
  });
  const getColumnSortProps = (dataIndex: any) => ({
    onFilter: (value: any, record: any) =>
      record[dataIndex].indexOf(value) === 0,
  });
  const columnsWithSearch = columns.map((col) => {
    if (col.dataIndex === "contactNumber" || col.dataIndex === "PANNo") {
      return {
        ...col,
        ...getColumnSearchProps(col.dataIndex),
      };
    } else if (col.dataIndex === "nameFirm") {
      return {
        ...col,
        ...getColumnFiltersProps(col.dataIndex),
      };
    } else {
      return {
        ...col,
        ...getColumnSortProps(col.dataIndex),
      };
    }
  });
  const onCheckBoxChange = async (dataToPut: any, key: any) => {
    setIsLoading(true);
    if (key === "multi") {
      dataToPut = dataToPut
        .map((element: any) => {
          if (element.checked) return element;
          else return undefined;
        })
        .filter((item: any) => item !== undefined);
    }

    if (await validate(dataToPut)) {
      const reqBody = dataToPut.map((eachRow: any) => {
        return {
          user_id: eachRow.userId,
          // role_id: eachRow.role_id,
          approval_status: eachRow.approvalStatus,
          approval_remarks: eachRow.remarks,
          vendor_code: eachRow.vendorCode,
        };
      });
      try {
        const response = await putExistingApproval(reqBody);
        if (response.data.status) {
          message.success("Data Updated Successfully", 3);
          setApprovalsData([]);
          getApprovalData(
            filteredCrop,
            selectedDates[0],
            selectedDates[1],
            selectedStatus,
            registrationStatus
          );
        } else {
          message.error(response.data.message, 3);
          // setApprovalsData([]);
        }
        setIsLoading(false);
      } catch (error) {
        message.error("Something went wrong :(", 3);
        console.log(error);
        setIsLoading(false);
      }
    }
    // else {
    //   message.error("Enter valid details and select atleast one row.", 1)
    //   setIsLoading(false);
    // }
    setIsLoading(false);
  };

  const checkVendorIsDuplicate = async (vendor_code: string | undefined) => {
    var regex = /^[0-9]+$/;
    if (
      vendor_code !== undefined &&
      +vendor_code !== 0 &&
      vendor_code.match(regex)
    ) {
      const response = await checkVendorAvailability({ vendor_code });
      if (response.data.data) {
        return false;
      } else {
        message.error("Vendor code already assigned to other user.", 3);
        return true;
      }
    } else {
      message.error("Please enter valid numeric Vendor code.", 3);
      return true;
    }
  };

  const validate = async (dataToPut: any) => {
    if (
      dataToPut === null ||
      dataToPut === undefined ||
      dataToPut === "" ||
      dataToPut.length === 0
    ) {
      message.error("Enter valid details and select atleast one row.", 3);
      return false;
    } else {
      for (let i = 0; i < dataToPut.length; i++) {
        let eachRow = dataToPut[i];
        if (eachRow.approvalStatus === 2 || eachRow.approvalStatus === 7) {
          message.error("Please select Approval Status!", 3);
          setIsLoading(false);
          return false;
        } else if (
          eachRow.approvalStatus === 6 &&
          (eachRow.vendorCode === null ||
            eachRow.vendorCode === undefined ||
            eachRow.vendorCode.length !== 9 ||
            eachRow.vendorCode.trim() === "")
        ) {
          message.error("Please enter valid Vendor Code!", 3);
          setIsLoading(false);
          return false;
        } else if (
          eachRow.approvalStatus === 6 &&
          eachRow.vendorCode.trim() !== "" &&
          (await checkVendorIsDuplicate(eachRow.vendorCode))
        ) {
          setIsLoading(false);
          return false;
        } else if (
          eachRow.remarks === null ||
          eachRow.remarks.trim() === "" ||
          eachRow.remarks === undefined
        ) {
          message.error("Please enter Remarks!", 3);
          setIsLoading(false);
          return false;
        }
      }
    }
    return true; // true
  };

  useEffect(() => {
    // onBranchChange();
    if (currentpage === "5") {
      getRegistrationStatusParameter();
    }
  }, [currentpage]);

  const setApprovalStatus = async (
    statusId: number,
    contactNumber: number,
    userId: number
  ) => {
    const modifiedData = approvalsData.map((item: any) => {
      if (item.userId === userId && item.contactNumber === contactNumber) {
        return {
          ...item,
          approvalStatus: statusId,
          checked: true,
          vendorCode: "",
        };
      } else {
        return item;
      }
    });
    setApprovalsData(modifiedData);
  };

  const setRoleData = async (
    roleId: number,
    contactNumber: number,
    userId: number
  ) => {
    const modifiedData = approvalsData.map((item: any) => {
      if (item.userId === userId && item.contactNumber === contactNumber) {
        return {
          ...item,
          role: roleId === 51 ? "TRADER" : "BROKER",
          role_id: roleId,
          checked: true,
        };
      } else {
        return item;
      }
    });
    setApprovalsData(modifiedData);
  };

  const setSelectedMaterialCode = async (
    materialId: any,
    varietyId: number,
    saudaId: number
  ) => {
    const modifiedData = approvalsData.map((item: any) => {
      if (item.saudhaId === saudaId && item.varietyId === varietyId) {
        return {
          ...item,
          materialId: materialId,
          checked: true,
        };
      } else {
        return item;
      }
    });
    setApprovalsData(modifiedData);
  };

  const setSelectedConditions = async (e: any, row: any, index: any) => {
    // console.log("Selected SAuda Data ------->", e.target.name, e.target.value, row, index);
    const modifiedData = approvalsData.map((item: any) => {
      if (
        item.saudhaId === row.saudhaId &&
        item.varietyId === row.varietyId &&
        e.target.value >= 0
      ) {
        conditionCodes[index].condition_value = Number(e.target.value);
        return {
          ...item,
          checked: true,
          conditionCodes,
        };
      } else {
        return item;
      }
    });
    setConditionCodesData(conditionCodes);
    setApprovalsData(modifiedData);
  };

  const setVendorCode = async (
    vendorCode: any,
    contactNumber: number,
    userId: number
  ) => {
    const modifiedData = approvalsData.map((item: any) => {
      if (
        item.userId === userId &&
        item.contactNumber === contactNumber &&
        !isNaN(vendorCode)
      ) {
        vendorCode = vendorCode.replace("", "");
        vendorCode = vendorCode.replaceAll(".", "");
        return {
          ...item,
          vendorCode: vendorCode,
        };
      } else {
        return item;
      }
    });
    setApprovalsData(modifiedData);
  };

  const setRemarks = async (
    remarks: any,
    contactNumber: number,
    userId: number
  ) => {
    const modifiedData = approvalsData.map((item: any) => {
      if (item.userId === userId && item.contactNumber === contactNumber) {
        return {
          ...item,
          remarks: remarks,
          // checked: true,
        };
      } else {
        return item;
      }
    });
    setApprovalsData(modifiedData);
  };

  const getRegistrationStatusParameter = async () => {
    console.log("defaultParams", defaultParams);

    setRegistrationStatus(
      defaultParams.registration_sap_required === "0" ? false : true
    );
    getApprovalData(
      filteredCrop,
      selectedDates[0],
      selectedDates[1],
      selectedStatus,
      defaultParams.registration_sap_required === "0" ? false : true
    );
    // const params = {
    //   branch_id: branchId,
    //   // parameter_type: "auction_closing_duration",
    // };
    // try {
    //   const response = await getCompanyParams({});
    //   if (response.data.status) {
    //     let valueCheck = response.data.data.filter(
    //       (item) => item.parameter_type === "registration_sap_required"
    //     );

    //   } else {
    //     console.log("Could not fetch Registration Sap Required flag !");
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const getApprovalData = async (
    cropIds: any[] | [],
    startDate: string,
    endDate: string,
    status: any,
    regParam: Boolean
  ) => {
    try {
      setIsLoading(true);
      const params = {
        registered_through: 2,
        associated_branches: userData.user_territories,
        approval_status:
          status.length === 0
            ? undefined
            : status && status.length
            ? status
            : [status],
        start_date: startDate,
        end_date: endDate,
        crops: cropIds.length === 0 ? crops.map((item) => item.id) : cropIds,
      };
      const response = await getExistingApproval(params);
      if (response.data.status) {
        const data = response.data.data.map((item: any, index: any) => {
          return {
            key: index,
            // GSTNumber: item.GST_number,
            // cautionDepositDate: item.caution_deposit_date,
            // cautionDepositMode: item.caution_deposit_mode,
            // cautionDepositReferenceNo: item.caution_deposit_reference_no,
            sapBranch: item.sap_branch,
            contactNumber: item.contact_number,
            name: item.name,
            nameFirm: item.name_firm,
            approvalStatus: item.approval_status,
            declarationDate: item.declaration_date,
            role: item.role,
            role_id: item.role_id,
            PANNo: item.PAN_no,
            rolesList: [
              { id: 51, val: "TRADER" },
              { id: 52, val: "BROKER" },
            ],
            userId: item.user_id,
            vendorCode: item.vendor_code,
            disableRow:
              item.approval_status !== 2 && item.approval_status !== 7
                ? true
                : false,
            approvalTypes: regParam
              ? [
                  { id: 2, val: "Verification Pending" },
                  { id: 3, val: "Verified" },
                  { id: 4, val: "Reject" },
                  { id: 5, val: "Push Back" },
                  { id: 8, val: "Sent to SAP PAN Verification" },
                  { id: 9, val: "SAP PAN verified and Approved" },
                  { id: 10, val: "SAP PAN verification failed" },
                ]
              : [
                  { id: 2, val: "Verification Pending" },
                  { id: 3, val: "Verified" },
                  { id: 6, val: "Verified By TO" },
                  { id: 4, val: "Reject" },
                  { id: 5, val: "Push Back" },
                  { id: 7, val: "Push Back By TM" },
                ],
            remarks: item.approval_remarks,
            checked: false,
          };
        });
        setApprovalsData(data);
        // const filtersNameFirm = Array.from(
        //   new Set(data.map((data) => data.nameFirm))
        // ).map((elements) => ({ text: elements, value: elements }));
        // const filtersRole = Array.from(
        //   new Set(approvalsData.map((data) => data.role))
        // ).map((elements) => ({ text: elements, value: elements }));
        const filters = data
          .map((e: any) => {
            return {
              label: e.nameFirm,
              value: e.nameFirm,
            };
          })
          .filter(
            (nameFirm, index, self) =>
              index ===
              self.findIndex(
                (r) => r.label === nameFirm.label && r.value === nameFirm.value
              )
          );
        const roleFilters = data
          .map((e: any) => {
            return {
              text: e.role,
              value: e.role,
            };
          })
          .filter(
            (role, index, self) =>
              index ===
              self.findIndex(
                (r) => r.text === role.text && r.value === role.value
              )
          );
        setFilters(filters);
        setFiltersDropdown(filters);
        setRoleFilters(roleFilters);
      } else {
        message.error("Something went wrong :(", 1);
        setApprovalsData([]);
      }
      setIsLoading(false);
    } catch (error) {
      message.error("Something went wrong :(", 1);
      console.log(error);
      setIsLoading(false);
    }
  };

  const onDateChange = (value: string | number | any, timeString: any) => {
    setSelectedDates(timeString);
    sessionStorage.setItem("startDateString", timeString[0]);
    sessionStorage.setItem("endDateString", timeString[1]);
    getApprovalData(
      filteredCrop,
      timeString[0],
      timeString[1],
      selectedStatus,
      registrationStatus
    );
  };

  const onStatusChange = (value: string | number | any, option: any) => {
    setSelectedStatus(value);
    console.log(value);
    sessionStorage.setItem("approvalStatus", JSON.stringify(value));
    getApprovalData(
      filteredCrop,
      selectedDates[0],
      selectedDates[1],
      value === selectedStatus ? null : value,
      registrationStatus
    );
  };

  const onCropChange = (value: string | number | any, option: any) => {
    setFilteredCrop(value);
    getApprovalData(
      value,
      selectedDates[0],
      selectedDates[1],
      selectedStatus,
      registrationStatus
    );
  };

  // const getHubsFn = async (branchId: number) => {
  //   try {
  //     const response = await getHubsService({ branch_id: branchId });
  //     console.log("Hub response: ", response.data.data);
  //     const data = response.data.data.map((item: any) => {
  //       return { id: item.territory_id, name: item.territory_name };
  //     });
  //     setHubs(data);
  //   } catch (error) { }
  // };

  const getSlotsFn = async (branchId: number) => {
    try {
      const response = await getSlotIdsService({
        branch_id: branchId,
        crop_id: selectedCropId,
      });
      const data = response.data.data.map((item: any) => {
        return { id: item.slot_id, name: item.slot_name };
      });
      setSlots(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="upcoming-auction">
      <Spin
        spinning={isSpinnerOnProgress}
        tip="Please wait while the file is being Downloaded..."
      >
        <div className="filter-container">
          {/* <CustomFilter
          type="single-select"
          value={selectedBranch}
          options={branches}
          onChange={onBranchChange}
          head="ITC Branch Office"
          returnId
        /> */}
          <CustomFilter
            type="range-picker"
            head="Date Range"
            startDate={moment(selectedDates[0])}
            endDate={moment(selectedDates[1])}
            onChange={onDateChange}
          />
          {/* <CustomFilter
          type="single-select"
          options={crops}
          placeholder="Select Crop"
          head="Crop"
        /> */}
          {/* <CustomFilter
          type="single-select"
          value={selectedBranch}
          options={branches}
          onChange={onBranchChange}
          placeholder="Select Branch"
          head="ITC Branch Office"
          returnId
        />
        <CustomFilter
          // type="single-select"
          type="multi-select"
          value={selectedHub}
          options={hubs}
          onChange={onHubChange}
          placeholder="Select Hub"
          head="Hub"
          returnId
        />
        <CustomFilter
          // type="single-select"
          type="multi-select"
          value={selectedVariety}
          onChange={onVarietyChange}
          options={varieties}
          placeholder="Select Variety"
          head="Variety"
          returnId
        /> */}
          {/* <CustomFilter
          type="single-select"
          value={selectedSlot}
          options={slots}
          onChange={onSlotChange}
          placeholder="Select Slot"
          head="Slot"
          returnId
        /> */}
          <CustomFilter
            type="multi-select"
            value={selectedStatus}
            options={status}
            onChange={onStatusChange}
            placeholder="Select Status"
            head="Status"
            className="status-filter"
            returnId
          />
          <CustomFilter
            type="multi-select"
            value={filteredCrop}
            options={crops}
            onChange={onCropChange}
            placeholder="Select Crop"
            head="Crop"
            className="status-filter"
            returnId
          />
          <Button
            className="refresh"
            icon={<SyncOutlined spin={isLoading} />}
            onClick={() =>
              getApprovalData(
                filteredCrop,
                selectedDates[0],
                selectedDates[1],
                selectedStatus,
                registrationStatus
              )
            }
          >
            Refresh
          </Button>
        </div>
        <div className="upcoming-table-container">
          <Table
            loading={isLoading}
            columns={columnsWithSearch}
            dataSource={approvalsData}
            pagination={false}
            scroll={{ y: window.innerHeight - 339 }}
            footer={() => {
              return (
                approvalsData?.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      alignItems: "baseline",
                      gap: "1em",
                      position: "fixed",
                      right: "25px",
                    }}
                  >
                    <div className="progress-download-container">
                      {isProgressClicked && (
                        <Progress
                          type="circle"
                          percent={downloaddata}
                          width={37}
                          strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
                          style={{
                            right: "125px",
                            position: "absolute",
                            display: "flex",
                            marginTop: "-45px",
                          }}
                          trailColor="#003285"
                        />
                      )}
                    </div>
                    <div className="download-button-container">
                      <Button
                        onClick={() => {
                          setdownloaddata(0);
                          setisProgressClicked(true);
                          fileDownload();
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        className="download-btn"
                      >
                        <div>Download</div>
                        <div
                          style={{
                            fontSize: "11px",
                            lineHeight: "7px",
                            color: "#b4c8dc",
                          }}
                        >
                          .xlsx report
                        </div>
                      </Button>
                    </div>
                    {userData.role_name !== "Trading Manager" &&
                      approvalsData?.some(
                        (item) => item.disableRow === false
                      ) && (
                        <Button
                          // className={"submit-btn"}
                          // onClick={() => <ActionItem
                          //   canUserSubmit={canUserEdit(
                          //     user,
                          //     selectedBranchID,
                          //     FeatureCodes.BUYING_DECISION_SUBMIT
                          //   )}
                          //   key="submitAll"
                          //   // this.props.handleSubmitClick(this.props.hubTableData)}
                          //   postBuyingDecisionHub={this.props.handleSubmitClick}
                          //   rowRecord={this.props.hubTableData}
                          //   // postHubRowId={record.actions.hubID}
                          //   // sourceType={record.mandiBuying.sourceType}
                          //   // srp={record.srp}
                          // />
                          // }
                          className={`submit-btn`}
                          onClick={() =>
                            onCheckBoxChange(approvalsData, "multi")
                          }
                        >
                          Submit
                        </Button>
                      )}
                  </div>
                )
              );
            }}
          />
        </div>
        {/* <div style={{ display: "flex", justifyContent: "flex-end", width: "93%" }} >
        <div>
          <Button className={"submit-btn"}>Save</Button>
        </div>
      </div> */}
      </Spin>
    </div>
  );
};
// const mapDispatchToProps = (dispatch: Dispatch) => {
//   return {
//     setBranchIndex: (index: number) => dispatch(setBranchIndex(index)),
//   };
// };
const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  branches: selectBranches,
  crops: selectCrops,
  selectedCropId: selectSelectedCrop,
  varieties: selectVarieties,
  currentpage: selectCurrentPage,
  defaultParams: selectDefaultParams,
});

export default withRouter(connect(mapStateToProps)(EVRSubmitScreen));
