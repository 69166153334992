import axios from "axios";
import { dataConstants } from "../constants/data-constants";

export const loginService = async (body: any) => {
  return axios.post(dataConstants.loginUrl, body);
};

export const logoutService = async () => {
  return axios.post(dataConstants.logoutUrl);
};
export const refreshService = async (body: any) => {
  return axios.post(dataConstants.refreshTokenUrl, body, {
    headers: {
      Authorization: "Bearer " + body.refresh_token,
    },
  });
};
export const forgotPwService = async (body: any) => {
  return axios.put(dataConstants.forgotPasswordUrl, body);
};
export const changePwService = async (body: any) => {
  return axios.put(dataConstants.changePasswordUrl, body);
};
