import axios from "axios";
import { dataConstants } from "../constants/data-constants";

export const getBranchesService = async () => {
  return axios.post(dataConstants.getBranchesUrl);
};
export const getHubsService = async (params: any) => {
  return axios.post(dataConstants.getHubsUrl, params);
};
export const getSlotIdsService = async (params: any) => {
  return axios.post(dataConstants.getSlotIdsUrl, params);
};
export const getVarietiesService = async (params: any) => {
  return axios.post(dataConstants.getVarietiesUrl, params);
};
export const getCropsService = async () => {
  return axios.post(dataConstants.getCropsUrl);
};
export const getTCService = async () => {
  return axios.post(dataConstants.getTCUrl);
};
export const getRebateService = async (params: any) => {
  return axios.post(dataConstants.getRebateUrl, params);
};
export const getRebateMatrixService = async (params: any) => {
  return axios.post(dataConstants.getRebateMatrixUrl, params);
};
