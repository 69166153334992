import axios from "axios";
import { dataConstants } from "../constants/data-constants";

export const getSysParams = async (params: any) => {
  return axios.post(dataConstants.getSysParamUrl, params);
};

export const getCompanyParams = async (data: any) => {
  return axios.post(dataConstants.getSysParamUrl, data);
};
export const putSysParams = async (data: any) => {
  return axios.put(dataConstants.sysParamUrl, data);
};
export const getDiscountParams = async (params: any) => {
  return axios.post(dataConstants.discountParamUrl, params);
};
