import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/es/storage/session";
import { encryptTransform } from "redux-persist-transform-encrypt";

import userReducer from "./user/user.reducer";
import commonReducer from "./common/common.reducer";
import registraionReducer from "./registration/registration.reducer";

const encryptor: any = encryptTransform({
  secretKey: "my-super-secret-key",
  onError: function (error) {
    // Handle the error.
    console.log(error);
  },
});

const persistConfig = {
  key: "root",
  storage: sessionStorage,
  whitelist: ["user", "common"],
  transforms: [encryptor],
};

const rootReducer = combineReducers({
  user: userReducer,
  common: commonReducer,
  registraionReducer: registraionReducer,
});

export default persistReducer(persistConfig, rootReducer);
