import React, { useState } from "react";
import "./slot-tabs.styles.scss";
import { Dispatch } from "redux";

import { ReactComponent as SlotImage } from "../../assets/auctionslot.svg";
import { ReactComponent as PreferencesImage } from "../../assets/preferences.svg";
import AuctionPreferences from "../system-parameters/system-parameters.page";

import { Tabs } from "antd";
import DefineSlot from "../define-slots/define-slots.page";
import DefineSlotToday from "../define-slots-today/define-slots-today.page";
import { connect } from "react-redux";
import {
  setSlotPage,
  setViewAuctionPage,
} from "../../redux/common/common.actions";
import {
  selectSlotPage,
  selectViewAuctionPage,
} from "../../redux/common/common.selectors";
import { createStructuredSelector } from "reselect";

const { TabPane } = Tabs;

const SlotTabs: React.FC<any> = (props) => {
  const { selectedKey, setSlotPageIndex } = props;
  return (
    <div className="preferences-landing">
      <Tabs
        defaultActiveKey={
          sessionStorage.getItem("preferenceTabs") || selectedKey
        }
        onChange={(activeKey) => {
          // console.log(activeKey);
          setSlotPageIndex(activeKey);
          sessionStorage.setItem("preferenceTabs", activeKey);
        }}
      >
        <TabPane
          tab={
            <span className="tabname-container">
              {" "}
              <PreferencesImage className="tab-img" />{" "}
              <div className="tab-name">Master</div>
            </span>
          }
          key="701"
        >
          <DefineSlot />
        </TabPane>
        <TabPane
          tab={
            <span className="tabname-container">
              {" "}
              <PreferencesImage className="tab-img" />{" "}
              <div className="tab-name">Today</div>
            </span>
          }
          key="702"
        >
          <DefineSlotToday />
        </TabPane>
      </Tabs>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedKey: selectSlotPage,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setSlotPageIndex: (activeKey: string) => dispatch(setSlotPage(activeKey)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SlotTabs);
