import React, { Component, useState } from "react";
import "./view-auction-landing.styles.scss";

import { ReactComponent as UpcomingImage } from "../../assets/upcoming-auction.svg";
import { ReactComponent as OpenImage } from "../../assets/open-auction.svg";
import { ReactComponent as ArchiveImage } from "../../assets/archive.svg";
import { ReactComponent as IntermediateImage } from "../../assets/intermediate.svg";
import { ReactComponent as ApprovalImage } from "../../assets/approved.svg";
import { ReactComponent as RefundImage } from "../../assets/refund.svg";

import Archives from "../archives/archives.page";
import OpenAuction from "../open-auction/open-auction.page";
import ApproveSauda from "../approve-sauda/approve-sauda.page";
import ApproveAuction from "../auction-approval/auction-approval.page";
import UpcomingAuction from "../upcoming-auction/upcoming-auction.page";
import IntermediateScreen from "../intermediate-screen/intermediate-screen.page";
import ApprovalScreen from "../approval-screen/approval-screen.page";
import RefundApprovalScreen from "../refund-approval-screen/refund-approval-screen.page";
import { canUserAccess } from "../../utils/utils";
import { FeatureCodes } from "../../constants/data-constants";
import { selectUserData } from "../../redux/user/user.selectors";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Dispatch } from "react";

import {
  setSelectedBranch,
  setViewAuctionPage,
} from "../../redux/common/common.actions";
import {
  selectBranches,
  selectSelectedBranch,
  selectViewAuctionPage,
} from "../../redux/common/common.selectors";

import { Tabs } from "antd";

const { TabPane } = Tabs;

const ViewAuctionLanding: React.FC = (props: any) => {
  const {
    setViewAuctionPage,
    selectedKey,
    globalSocket,
    userData,
    branches,
    selectedBranch,
    setSelectedBranch,
  } = props;
  // const [selectedBranch, setSelectedBranch] = useState(branches[0]?.id);
  return (
    <div className="view-auction-landing">
      <Tabs
        defaultActiveKey={
          (canUserAccess(userData, FeatureCodes.AUCTION_CHECKER) &&
            selectedKey === 1) ||
          selectedKey === null ||
          selectedKey === undefined
            ? "30"
            : `${sessionStorage.getItem("auctionPage")}` || "30"
        }
        onChange={(activeKey) => {
          sessionStorage.setItem("auctionPage", activeKey);
          setViewAuctionPage(activeKey);
        }}
      >
        {(canUserAccess(userData, FeatureCodes.AUCTION_CHECKER) ||
          canUserAccess(userData, FeatureCodes.AUCTION_APPROVE_VIEW)) && (
          <TabPane
            tab={
              <span className="tabname-container">
                {" "}
                <ApprovalImage className="tab-img" />{" "}
                <div className="tab-name">Approve Auctions</div>
              </span>
            }
            key="30"
          >
            <ApproveAuction
              {...props}
              setBranch={setSelectedBranch}
              selectedBranch={selectedBranch}
            />
          </TabPane>
        )}
        <TabPane
          tab={
            <span className="tabname-container">
              {" "}
              <UpcomingImage className="tab-img" />{" "}
              <div className="tab-name">Upcoming Auctions</div>
            </span>
          }
          key="31"
        >
          <UpcomingAuction
            {...props}
            setBranch={setSelectedBranch}
            selectedBranch={selectedBranch}
          />
        </TabPane>
        <TabPane
          tab={
            <span className="tabname-container">
              {" "}
              <OpenImage className="tab-img" />{" "}
              <div className="tab-name">Active Auctions</div>
            </span>
          }
          key="32"
        >
          <OpenAuction
            {...props}
            setBranch={setSelectedBranch}
            selectedBranch={selectedBranch}
          />
        </TabPane>
        <TabPane
          tab={
            <span className="tabname-container">
              <ApprovalImage className="tab-img" />
              <div className="tab-name">Approve Sauda</div>
            </span>
          }
          key="34"
        >
          <ApproveSauda
            {...props}
            setBranch={setSelectedBranch}
            selectedBranch={selectedBranch}
          />
        </TabPane>
        <TabPane
          tab={
            <span className="tabname-container">
              {" "}
              <IntermediateImage className="tab-img" />{" "}
              <div className="tab-name">Archives</div>
            </span>
          }
          key="33"
        >
          <Archives
            {...props}
            setBranch={setSelectedBranch}
            selectedBranch={selectedBranch}
          />
        </TabPane>
        {/* <TabPane
          tab={
            <span className="tabname-container">
              {" "}
              <ApprovalImage className="tab-img" />{" "}
              <div className="tab-name">Intermediate Sauda Screen</div>
            </span>
          }
          key="4"
        >
          <IntermediateScreen {...props} />
        </TabPane> */}
        {/* <TabPane
          tab={
            <span className="tabname-container">
              {" "}
              <ApprovalImage className="tab-img" />{" "}
              <div className="tab-name">Approval Screen</div>
            </span>
          }
          key="5"
        >
          <ApprovalScreen />
        </TabPane>
        <TabPane
          tab={
            <span className="tabname-container">
              {" "}
              <ApprovalImage className="tab-img" />{" "}
              <div className="tab-name">Refund Approval Screen</div>
            </span>
          }
          key="6"
        >
          <RefundApprovalScreen />
        </TabPane> */}
      </Tabs>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedKey: selectViewAuctionPage,
  userData: selectUserData,
  branches: selectBranches,
  selectedBranch: selectSelectedBranch,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setViewAuctionPage: (activeKey: string) =>
      dispatch(setViewAuctionPage(activeKey)),
    setSelectedBranch: (index: number) => dispatch(setSelectedBranch(index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAuctionLanding);
