import { Reducer, Action } from "redux";
import { UserActionTypes } from "./user.types";
import { User } from "../../models";

const INITIAL_STATE: User = {
  isLogged: false,
  idleModal: false,
  authData: {
    access_token: "",
    access_token_expiry: 0,
    refresh_token: "",
    refresh_token_expiry: 0,
    id_token: "",
    id_token_expiry: 0,
  },
  userData: {
    iat: 0,
    nbf: 0,
    jti: "",
    exp: 0,
    is_first_time_login: false,
    role_id: 0,
    name: "",
    non_location_access: null,
    user_id: 0,
    account_group: null,
    user_name: "",
    role_name: "",
    user_territories: null,
    crops: null,
    location_access: null,
    locationFeatureArray: null,
    logout_timestamp: 0,
  },
};

const userReducer: Reducer<User, Action> = (
  state = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case UserActionTypes.SET_LOGGED_IN_STATUS:
      return {
        ...state,
        isLogged: action.payload,
      };
    case UserActionTypes.SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case UserActionTypes.SET_AUTH_DATA:
      return {
        ...state,
        authData: action.payload,
      };
    case UserActionTypes.SET_IDLE_MODAL:
      return {
        ...state,
        idleModal: action.payload,
      };
    case UserActionTypes.RESET_USER:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default userReducer;
