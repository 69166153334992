import React from "react";
import "./custom-select.styles.scss";

import { Select } from "antd";

const { Option } = Select;

interface SelectProps {
  className?: string;
  options: Array<{ id: number | string; name: string }>;
  onChange?: (value: string | number | any, option: any) => void;
  value: string | number | undefined;
  returnId?: boolean;
  disabled?: boolean;
  isCropParam?: boolean;
}

const CustomSelect: React.FC<SelectProps> = (props: SelectProps) => {
  const {
    className,
    onChange,
    options,
    value,
    returnId,
    disabled = false,
    isCropParam,
  } = props;
  // const propsForSelect = {
  //   ...(value && { value: value }),
  //   onChange: onChange,
  // };
  return (
    <div
      className={`custom-select-container ${className ? className : ""}`}
      style={isCropParam ? { height: 35 } : {}}
    >
      {/* <Select {...propsForSelect}> */}
      <Select
        onChange={onChange}
        value={value}
        disabled={disabled}
        style={isCropParam ? { height: 10, fontSize: 25 } : { height: 40 }}
      >
        {options &&
          options.map((item) => (
            <Option value={returnId ? item.id : item.name} key={item.id}>
              {item.name}
            </Option>
          ))}
      </Select>
    </div>
  );
};

export default CustomSelect;
