import { Reducer, Action } from "redux";

import { RegisterActionTypes } from "./registration.types";

const INITIAL_STATE = {
  registrationData: {},
};

const registraionReducer: Reducer<any, Action> = (
  state = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    // case RegisterActionTypes.SET_BASIC_DETAILS_SUBMITTED_STATUS:
    //   return {
    //     ...state,
    //     isBasicDetailsSubmitted: action.payload,
    //   };
    //   case RegisterActionTypes.SET_STATUTORY_DETAILS_SUBMITTED_STATUS:
    //     return {
    //       ...state,
    //       isStatutoryDetailsSubmitted: action.payload,
    //     };
    case RegisterActionTypes.SET_REGISTRATION_DATA:
      return {
        ...state,
        registrationData: action.payload,
      };
    default:
      return {
        ...state,
        registrationData: action.payload,
      };
  }
};

export default registraionReducer;
