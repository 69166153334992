/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useRef } from "react";
import "./upcoming-auction.styles.scss";

import moment from "moment";
import { ReactComponent as CloseIcon } from "../../assets/cancel.svg";
import { ReactComponent as CheckedIcon } from "../../assets/checked.svg";

import { SyncOutlined } from "@ant-design/icons";

import { connect } from "react-redux";

// import { getCompanyParams } from "../../services/sys-params-services";
// import {
//   getSysParams,
//   getCompanyParams,
// } from "../../services/sys-params-services";

import { createStructuredSelector } from "reselect";

import {
  selectBagtypeList,
  selectBranches,
  selectCrops,
  selectCurrentPage,
  selectDefaultParams,
  selectDivisionList,
  selectFreezingTime,
  selectMinQty,
  selectRebates,
  selectSelectedCrop,
  selectVarieties,
  selectViewAuctionPage,
} from "../../redux/common/common.selectors";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  //   getUpcomingAuction,
  cancelAuction,
  editAuction,
} from "../../services/auction-services";
import {
  getRebateMatrixService,
  getRebateService,
  // getRebateService,
} from "../../services/common-services";

import { FeatureCodes } from "../../constants/data-constants";
import { canUserAccess } from "../../utils/utils";

import { Table, message, Modal, Button, Select, Input } from "antd";

import CustomFilter from "../../components/custom-filter/custom-filter.component";
import {
  selectAuthData,
  selectUserData,
} from "../../redux/user/user.selectors";
// import { setBranchIndex } from "../../redux/common/common.actions";
// import { Dispatch } from "redux";

const { confirm } = Modal;
const { Option } = Select;

const editDefault = {
  qty_rqd: undefined,
  rebate_matrix_id: undefined,
  auction_id: undefined,
  bagType: undefined,
  maxPrice: undefined,
  division: undefined,
  variety: undefined,
};

const UpcomingAuction: React.FC = (props: any) => {
  const {
    branches,
    selectedCropId,
    user,
    globalSocketData,
    crops,
    currentpage,
    viewAuctionPage,
    selectedBranch,
    setBranch,
    // auth,
    parametersData,
    freezingTime,
    rebates,
    minQuantity,
    divisions,
    bagType,
    varieties,
  } = props;

  const [upcomingData, setUpcomingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  // const [rebates, setRebates] = useState<any>();
  const [valueToEdit, setValueToEdit] = useState<any>(editDefault);
  const [rebateModalVisible, setRebateModalVisible] = useState(false);
  const [rebateTableData, setRebateTableData] = useState<Array<any>>();
  // const [localCropID, setLocalCropID] = useState<any>();
  // const [minQuantity, setMinQuantity] = useState<any>([]);
  const [error, setError] = useState<String>("");
  const [rebateList, setRebateList] = useState<any>([]);

  let upcomingAuctionsIntervalID = useRef<NodeJS.Timeout>();

  const columns = [
    {
      title: "ID",
      sorter: (a, b) => a.id - b.id,
      children: [
        {
          dataIndex: "id",
          key: "id2",
        },
      ],
    },
    {
      title: "Hub",
      sorter: (a, b) => a.hub.localeCompare(b.hub),
      children: [
        {
          dataIndex: "hub",
          key: "hub2",
          width: 100,
        },
      ],
    },
    {
      title: "Division",
      sorter: (a, b) => a.division - b.division,
      children: [
        {
          dataIndex: "division",
          key: "division2",
          render: (value: number) => {
            return (
              <div>{value === 1 ? "ABD" : value === 2 ? "FBD" : "NCDEX"}</div>
            );
          },
          width: 90,
          align: "center",
        },
      ],
    },
    {
      title: "Claim",
      children: [
        {
          dataIndex: "claim",
          key: "claim2",
          render: (value: number) => {
            return (
              <div className="claim-container">
                {value === 1 ? <CheckedIcon /> : <CloseIcon />}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Variety",
      sorter: (a, b) => a.variety.localeCompare(b.variety),
      children: [
        {
          dataIndex: "variety",
          key: "variety2",
          width: 120,
        },
      ],
    },
    {
      title: "Qty Reqd",
      children: [
        {
          title: "MT",
          dataIndex: "qtyReqd",
          key: "qtyReqd2",
        },
      ],
    },
    {
      title: "Max Price",
      children: [
        {
          title: "₹/MT",
          dataIndex: "maxPrice",
          key: "maxPrice2",
        },
      ],
    },

    {
      title: "Sauda Type",
      children: [
        {
          dataIndex: "saudhaType",
          key: "saudhaType2",
          width: 100,
          render: (value: number) => {
            return <div>{value === 1 ? "Net basis" : "Gross basis"}</div>;
          },
        },
      ],
    },
    {
      title: "Role",
      children: [
        {
          dataIndex: "roleType",
          key: "Role",
          width: 100,
          render: (value: number) => {
            return (
              <div>
                {value === 1
                  ? "Broker/Trader"
                  : value === 2
                  ? "FPO"
                  : value === 3
                  ? "Broker/Trader & FPO"
                  : "Not Selected"}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Start Time",
      children: [
        {
          dataIndex: "startTime",
          key: "startTime2",
          render: (value: string) => {
            return <div>{moment(value, "HH:mm:ss").format("hh:mm A")}</div>;
          },
        },
      ],
    },
    {
      title: "End Time",
      children: [
        {
          dataIndex: "endTime",
          key: "endtime2",
          render: (value: string) => {
            return <div>{moment(value, "HH:mm:ss").format("hh:mm A")}</div>;
          },
        },
      ],
    },
    {
      title: "Rebate Name",
      children: [
        {
          dataIndex: "rebateName",
          key: "rebateName2",
          width: 200,
          render: (value: number, row: any) => {
            return (
              <div className={"rebate-info-upcoming"}>
                {value}
                {value && (
                  <span
                    className="info"
                    onClick={() => {
                      getRebateMatrix(row.rebateId, selectedBranch);
                    }}
                  >
                    ?
                  </span>
                )}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Action",
      children: [
        {
          dataIndex: "action",
          key: "action2",
          render: (value: boolean, rowData: any) => {
            return (
              <div
                className={`cancel-container ${
                  !canUserAccess(user, FeatureCodes.AUCTION_CHECKER) &&
                  "disabled"
                }`}
                onClick={() => showPromiseConfirm(rowData.key)}
              >
                Cancel
              </div>
            );
          },
          width: 80,
        },
      ],
    },
    {
      title: "",
      children: [
        {
          dataIndex: "edit",
          key: "edit2",
          render: (value: boolean, rowData: any) => {
            return (
              <div
                className={`edit-container ${
                  !canUserAccess(user, FeatureCodes.AUCTION_CHECKER) &&
                  "disabled"
                }`}
                onClick={() => onEditClicked(rowData)}
              >
                Edit
              </div>
            );
          },
          width: 80,
        },
      ],
    },
  ];

  useEffect(() => {
    if (currentpage === "3" && viewAuctionPage === "31" && globalSocketData) {
      upcomingAuctionsIntervalID.current &&
        clearInterval(upcomingAuctionsIntervalID.current);
      // onBranchChange(selectedBranch, []);
      // getParamsMinQty({ branch_id: selectedBranch, crop_id: selectedCropId });
      upcomingAuctionsDataSocketEmition();
      upcomingAuctionsIntervalID.current = setInterval(() => {
        upcomingAuctionsDataSocketEmition();
      }, 10000);
    }
    return () => {
      upcomingAuctionsIntervalID.current &&
        clearInterval(upcomingAuctionsIntervalID.current);
    };
  }, [
    currentpage,
    viewAuctionPage,
    globalSocketData,
    selectedBranch,
    selectedCropId,
  ]);

  // useEffect(() => {
  //   console.log(selectedCropId);
  //   setLocalCropID(selectedCropId);
  //   sessionStorage.setItem(
  //     "upcomingTMParams",
  //     JSON.stringify({
  //       crop_id: selectedCropId,
  //       branch_id: selectedBranch,
  //       approval_status: 1,
  //     })
  //   );
  // }, [selectedCropId, selectedBranch]);

  // const getParamsMinQty = useCallback(async (body) => {
  //   var tempData: any = {};
  //   const sysResponse = await getSysParams(body);
  //   sysResponse.data.data.map((item: any) => {
  //     Object.assign(tempData, {
  //       [item.parameter_type]: item.parameter_data_value,
  //     });
  //   });
  //   setMinQuantity({ ...tempData });
  // }, []);

  // useEffect(() => {
  //   onBranchChange(branches[branchIndex].id, []);
  //   getParams();
  //   setBranch(branches[branchIndex].id);
  // }, [branchIndex]);

  /**
   *
   * @function
   * @description Establish socket.io connection for getting auction cards data.
   * @memberof App.MainLayout
   */
  const upcomingAuctionsDataSocketEmition = () => {
    // let socketCheck =
    // globalSocketData !== undefined ? globalSocketData : passedSocket;
    // let params = sessionStorage.getItem("upcomingTMParams");
    // params = params && JSON.parse(params);
    if (globalSocketData) {
      globalSocketData.emit("tm_upcoming_auction_event", {
        crop_id: selectedCropId,
        branch_id: selectedBranch,
        approval_status: 1,
      });
      globalSocketData.on("tm_upcoming_auctions", (data: any) => {
        const modifiedData = data.map((item: any) => {
          return {
            key: item.auction_id,
            id: item.auction_id,
            hub: item.hub_name,
            division: item.division,
            claim: item.claim_type,
            variety: item.variety_name,
            qtyReqd: item.qty_rqd,
            maxPrice: item.max_price,
            saudhaType: item.saudha_type,
            roleType: item.role_mapping,
            rebateName: item.rebate_name,
            startTime: item.start_time,
            endTime: item.end_time,
            rebateId: item.rebate_id,
            bagType: item.bag_type,
            minPrice: item.min_price,
          };
        });
        setUpcomingData(modifiedData);
        setIsLoading(false);
      });
    }
  };

  // useEffect(() => {
  //   if (auth && auth.access_token && globalSocketData) {
  //     clearInterval(upcomingAuctionsIntervalID);
  //     upcomingAuctionsDataSocketEmition(globalSocketData);
  //     upcomingAuctionsIntervalID = setInterval(() => {
  //       upcomingAuctionsDataSocketEmition(globalSocketData);
  //     }, 30000);
  //   }
  // }, [auth, globalSocketData, selectedCropId]);

  // const getFreezeTime = async () => {
  //   // const params = {
  //   //   branch_id: branchId,
  //   //   // parameter_type: "auction_closing_duration",
  //   // };
  //   try {
  //     const response = await getCompanyParams({});
  //     if (response.data.status) {
  //       const time = moment(
  //         response.data.data[2].parameter_data_value,
  //         "HH:mm:ss"
  //       );

  //       setFreezingTime(response.data.data[2].parameter_data_value);
  //     } else {
  //       console.log("Could not fetch Freeze time !");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const onEditClicked = async (data: any) => {
    // getParamsMinQty({ branch_id: selectedBranch, crop_id: selectedCropId });
    if (
      moment(
        moment(new Date(), "HH:mm:ss").add(freezingTime, "minutes"),
        "HH:mm:ss"
      ).isAfter(moment(data.startTime, "HH:mm:ss"))
    ) {
      message.error("cannot edit the auction during freezing period");
    } else {
      setValueToEdit({
        qty_rqd: data.qtyReqd,
        rebate_matrix_id: data.rebateId,
        auction_id: data.id,
        claim: data.claim,
        maxPrice: data.maxPrice,
        division: data.division,
        bagType: data.bagType,
        variety: data.variety,
      });
      const rebateParams = {
        crop_id: selectedCropId,
        branch_id: selectedBranch,
        variety_id: varieties.filter(
          (item: any) => item.name === data.variety
        )[0].id,
        division: divisions.filter((item: any) => item.id === data.division)[0]
          .name,
      };
      getVarietyData(rebateParams);
      setIsEdit(true);
    }
  };
  const getVarietyData = async (params: any) => {
    try {
      const rebateListResponse = await getRebateService(params);
      if (rebateListResponse.data.status) {
        setRebateList(rebateListResponse.data.data);
      }
    } catch (error) {
      console.warn("error", error);
    }
  };
  // const getUpcomingData = async (branchId: number) => {
  //   setIsLoading(true);
  //   const params = {
  //     crop_id: localCropID,
  //     branch_id: branchId,
  //     approval_status: 1,
  //   };
  //   try {
  //     const response = await getUpcomingAuction(params);
  //     if (response.data.status) {
  //       const data = response.data.data.map((item: any) => {
  //         return {
  //           key: item.auction_id,
  //           id: item.auction_id,
  //           hub: item.hub_name,
  //           division: item.division,
  //           claim: item.claim_type,
  //           variety: item.variety_name,
  //           qtyReqd: item.qty_rqd,
  //           maxPrice: item.max_price,
  //           saudhaType: item.saudha_type,
  //           roleType: item.role_mapping,
  //           rebateName: item.rebate_name,
  //           startTime: item.start_time,
  //           endTime: item.end_time,
  //           rebateId: item.rebate_id,
  //         };
  //       });
  //       setUpcomingData(data);
  //     } else {
  //       message.error("Something went wrong :(", 1);
  //       setUpcomingData([]);
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //     message.error("Something went wrong :(", 1);
  //     console.log(error);
  //     setIsLoading(false);
  //   }
  // };
  // const onCropChange = () => {
  //   console.log("YEt to implement");
  // };
  // const onBranchChange = async (value: string | number | any, option: any) => {
  //   sessionStorage.setItem(
  //     "upcomingTMParams",
  //     JSON.stringify({
  //       crop_id: localCropID,
  //       branch_id: value,
  //       approval_status: 1,
  //     })
  //   );
  //   getParamsMinQty({ branch_id: value, crop_id: selectedCropId });
  //   setBranch(value);
  //   // setBranchIndex(branches.findIndex((branch) => branch.id == value));
  //   await getRebate(value);
  //   // await getUpcomingData(value);
  //   upcomingAuctionsDataSocketEmition(globalSocketData);
  // };

  const cancelAuctionFn = async (auctionId: number) => {
    const dataToSend = {
      auction_id: auctionId,
    };
    try {
      const response = await cancelAuction(dataToSend);
      if (response.data.status) {
        message.success("Auction cancelled successfully...!");
        // await getUpcomingData(selectedBranch);
        upcomingAuctionsDataSocketEmition();
      } else message.warning("Cannot cancel auction...!");
    } catch (error) {
      console.log(error);
    }
  };

  function showPromiseConfirm(auctionId: number) {
    confirm({
      title: "Cancel Auction",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to cancel the auction ?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        cancelAuctionFn(auctionId);
      },
      onCancel() {},
    });
  }

  // const getRebate = async (branchId: number) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await getRebateService({
  //       branch_id: branchId,
  //       crop_id: selectedCropId,
  //     });
  //     setRebates(response.data.data);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoading(false);
  //   }
  //   setIsLoading(false);
  //   // setIsHubLoading(false);
  // };

  const getRebateMatrix = async (
    rebateId: string,
    branchId: number | undefined
  ) => {
    const params = {
      rebate_id: rebateId,
      branch_id: branchId,
      crop_id: selectedCropId,
    };
    try {
      const response = await getRebateMatrixService(params);
      if (response.data.status) {
        setRebateModalVisible(true);
        setRebateTableData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const rebateColumns = [
    {
      title: "Head",
      dataIndex: "head",
      key: "head",
      render(_: any, row: any) {
        return {
          children: row.head,
          props: {
            rowSpan: row.rowSpan,
          },
        };
      },
    },
    {
      title: "Slab",
      dataIndex: "slab",
      key: "slab",
    },
    {
      title: "Rebate",
      dataIndex: "rate_display",
      key: "rate_display",
    },
  ];
  const createNewArr = (data: any) => {
    if (data)
      return data
        .reduce((result: any, item: any) => {
          //First, take the name field as a new array result
          if (result.indexOf(item.head) < 0) {
            result.push(item.head);
          }
          return result;
        }, [])
        .reduce((result: any, head: any) => {
          //Take the data with the same name as a new array, and add a new field * * rowSpan inside it**
          const children = data.filter((item: any) => item.head === head);
          result = result.concat(
            children.map((item: any, index: any) => ({
              ...item,
              rowSpan: index === 0 ? children.length : 0, //Add the first row of data to the rowSpan field
            }))
          );
          return result;
        }, []);
  };

  const updateAuction = async () => {
    try {
      // if (Number(valueToEdit.qty_rqd) >= Number(minQuantity)) {
      let matrix_id = {};
      if (valueToEdit.rebate_matrix_id) {
        matrix_id = { rebate_matrix_id: valueToEdit.rebate_matrix_id };
      }
      const maxPrice = varieties.filter(
        (item: any) => item.name === valueToEdit.variety
      )[0].maxPrice;
      const minPrice = varieties.filter(
        (item: any) => item.name === valueToEdit.variety
      )[0].minPrice;
      if (
        Number(valueToEdit.maxPrice) <= minPrice ||
        Number(valueToEdit.maxPrice) >= maxPrice
      ) {
        message.warning(
          `Max price should be between the range ${minPrice} (₹/MT) to ${maxPrice} (₹/MT)...`
        );
        return false;
      }
      const param = {
        qty_rqd:
          valueToEdit.qty_rqd !== null ||
          valueToEdit.qty_rqd !== undefined ||
          valueToEdit.qty_rqd !== ""
            ? Number(valueToEdit.qty_rqd)
            : 0,
        ...matrix_id,
        auction_id: valueToEdit.auction_id,
        max_price: Number(valueToEdit.maxPrice),
        bag_type: valueToEdit.bagType,
        division: valueToEdit.division,
      };
      const response = await editAuction(param);
      if (response.data.status) {
        message.success("Updated auction successfully..!");

        // await getUpcomingData(selectedBranch);
        upcomingAuctionsDataSocketEmition();
        setIsEdit(false);
        setError("");
      } else message.error("Could not update auction..!");
      /* } else {
        message.error(
          `Quantity can not be lower than minimum truckable quantity : ${minQuantity}`
        ); */
      // }
    } catch (error: any) {
      console.log(error);
      message.error(
        error.response.data.message || "Could not update auction..!"
      );
    }
  };

  return (
    <div className="upcoming-auction">
      <Modal
        className="edit-modal"
        visible={isEdit}
        onCancel={() => {
          setIsEdit(false);
          setValueToEdit(editDefault);
          setError("");
        }}
        footer={false}
        maskClosable={false}
      >
        <div className="header">Edit Auction</div>
        <div className="form-container">
          <div className={`form-items`}>
            <div className="label">Quantity (MT)</div>
            <Input
              value={valueToEdit.qty_rqd}
              type="number"
              placeholder="Slot Qty"
              onChange={(e) => {
                /*  if (Number(e.target.value) < Number(minQuantity)) {
                  setError(`Minimum Truckable quantity is ${minQuantity} MT`);
                } else {
                  setError("");
                } */
                setValueToEdit((prevState: any) => ({
                  ...prevState,
                  qty_rqd: e.target.value,
                }));
              }}
            />
            <p style={{ color: "red" }} className="label">
              {error}
            </p>
          </div>

          {valueToEdit.claim === 1 && (
            <div className={`form-items`}>
              <div className="label help">
                Rebate Name {/* {rebateId && ( */}
                <div
                  className="info"
                  onClick={() => {
                    getRebateMatrix(
                      valueToEdit.rebate_matrix_id,
                      selectedBranch
                    );
                  }}
                >
                  ?
                </div>
                {/* )} */}
              </div>

              <Select
                placeholder="Rebate Name"
                value={valueToEdit.rebate_matrix_id}
                showSearch
                filterOption={(inputValue, option: any) =>
                  option.children
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                }
                onChange={(value) =>
                  setValueToEdit((prevState: any) => ({
                    ...prevState,
                    rebate_matrix_id: value,
                  }))
                }
              >
                {rebateList &&
                  rebateList.map((item: any) => (
                    <Option
                      value={item.rebate_id}
                      id={item.rebate_id}
                      key={item.rebate_id}
                    >
                      {item.rebate_name}
                    </Option>
                  ))}
              </Select>
            </div>
          )}
          <div className={`form-items`}>
            <div className="label">Max Price (₹/MT)</div>
            <Input
              value={valueToEdit.maxPrice}
              type="number"
              placeholder="Max Price"
              onChange={(e) => {
                setValueToEdit((prevState: any) => ({
                  ...prevState,
                  maxPrice: e.target.value,
                }));
              }}
            />
          </div>
          <div className={`form-items`}>
            <div className="label help">Division</div>
            <Select
              placeholder="Variety"
              showSearch
              filterOption={(inputValue, option: any) =>
                option.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              }
              value={valueToEdit.division}
              onChange={(value) =>
                setValueToEdit((prevState: any) => ({
                  ...prevState,
                  division: value,
                }))
              }
            >
              {divisions &&
                divisions.map((item: any) => (
                  <Option value={item.id} id={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className={`form-items`}>
            <div className="label help">Bag Type</div>
            <Select
              placeholder="Bag Type"
              showSearch
              filterOption={(inputValue, option: any) =>
                option.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              }
              value={valueToEdit.bagType}
              onChange={(value) =>
                setValueToEdit((prevState: any) => ({
                  ...prevState,
                  bagType: value,
                }))
              }
            >
              {bagType &&
                bagType.map((item: any) => (
                  <Option value={item.id} id={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
        <div className="update-btn-container">
          <Button onClick={updateAuction}>Update</Button>
        </div>
      </Modal>

      <Modal
        className="rebate-modal"
        visible={rebateModalVisible}
        onCancel={() => setRebateModalVisible(false)}
        footer={false}
        zIndex={1001}
      >
        <div className="rebate-header">Rebate Matrix</div>
        <div className="rebate-container">
          <Table
            columns={rebateColumns}
            // dataSource={tableData}
            dataSource={createNewArr(rebateTableData)}
            pagination={false}
            scroll={{ y: window.innerHeight - 165 }}
          />
        </div>
      </Modal>

      <div className="filter-container">
        <CustomFilter
          type="single-select"
          value={selectedBranch}
          options={branches}
          onChange={(value: any) => setBranch(value)}
          head="ITC Branch Office"
          returnId
        />
        {/* <CustomFilter
          type="single-select"
          value={selectedCrop}
          defaultValue={selectedCrop}
          options={crops}
          disabled={true}
          onChange={onCropChange}
          head="Selected Crop"
          returnId
        /> */}
        <div className="selected-wheat">
          <span className="selected-wheat-label">Selected Crop</span> <br />
          <span className="selected-wheat-value">
            <b>
              {crops &&
                crops.length &&
                crops.find(
                  (item: { id: number; name: string }) =>
                    item.id == selectedCropId
                ).name}
            </b>
          </span>
        </div>
        {/* <CustomFilter
          type="single-select"
          value={selectedBranch}
          options={branches}
          onChange={onBranchChange}
          head="Type"
          returnId
        /> */}
        <Button
          className="refresh"
          icon={<SyncOutlined spin={isLoading} />}
          onClick={
            () => upcomingAuctionsDataSocketEmition()
            // getUpcomingData(selectedBranch)
          }
        >
          Refresh
        </Button>
      </div>
      <div className="label noteText">
        <b>
          {parametersData !== undefined && parametersData !== null
            ? ` NOTE: Auction cannot be Edited or Approved ${parametersData.auction_freeze_period} minute(s) before the start time.`
            : ""}
        </b>
      </div>
      {/* {upcomingData.length === 0 && (
        <div className="time-data-container">
          <div>No upcoming Auctions</div>
          <div></div>
        </div>
      )} */}

      <div className="upcoming-table-container">
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={upcomingData}
          pagination={false}
          scroll={{ y: window.innerHeight - 265 }}
        />
      </div>
    </div>
  );
};
// const mapDispatchToProps = (dispatch: Dispatch) => {
//   return {
//     setBranchIndex: (index: number) => dispatch(setBranchIndex(index)),
//   };
// };
const mapStateToProps = createStructuredSelector({
  branches: selectBranches,
  crops: selectCrops,
  selectedCropId: selectSelectedCrop,
  user: selectUserData,
  auth: selectAuthData,
  currentpage: selectCurrentPage,
  viewAuctionPage: selectViewAuctionPage,
  parametersData: selectDefaultParams,
  freezingTime: selectFreezingTime,
  minQuantity: selectMinQty,
  rebates: selectRebates,
  bagType: selectBagtypeList,
  divisions: selectDivisionList,
  varieties: selectVarieties,
});

export default connect(mapStateToProps)(UpcomingAuction);
