import React, { Component, useEffect, useMemo, useState } from "react";
import "./intemediate-sauda-landing.styles.scss";

import { ReactComponent as UpcomingImage } from "../../assets/upcoming-auction.svg";
import { ReactComponent as OpenImage } from "../../assets/open-auction.svg";
import { ReactComponent as ArchiveImage } from "../../assets/archive.svg";
import { ReactComponent as IntermediateImage } from "../../assets/intermediate.svg";
import { ReactComponent as ApprovalImage } from "../../assets/approved.svg";
import { ReactComponent as RefundImage } from "../../assets/refund.svg";

import Archives from "../archives/archives.page";
import OpenAuction from "../open-auction/open-auction.page";
import ApproveAuction from "../auction-approval/auction-approval.page";
import UpcomingAuction from "../upcoming-auction/upcoming-auction.page";
import IntermediateScreen from "../intermediate-screen/intermediate-screen.page";
import ApprovalScreen from "../approval-screen/approval-screen.page";
import IntermediateApproveScreen from "../intermediate-approve-screen/intermediate-approve-screen.page";
import { canUserAccess } from "../../utils/utils";
import { FeatureCodes } from "../../constants/data-constants";
import { selectUserData } from "../../redux/user/user.selectors";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Dispatch } from "react";

import { setViewAuctionPage } from "../../redux/common/common.actions";
import {
  selectSelectedBranch,
  selectSelectedCrop,
  selectViewAuctionPage,
} from "../../redux/common/common.selectors";

import { Tabs, message } from "antd";
import {
  getBagTypeDetails,
  getConditionCodes,
} from "../../services/auction-services";

const { TabPane } = Tabs;

const IntemediateSaudaLanding: React.FC = (props: any) => {
  const {
    setViewAuctionPage,
    userData,
    selectedCropId,
    selectedBranch,
  } = props;
  const [bagType, setBagType] = useState([]);
  const [conditionCodes, setConditionCodesData] = useState<any>();
  useEffect(() => {
    if (
      !canUserAccess(userData, FeatureCodes.IM_SCREEN_MAKER) &&
      canUserAccess(userData, FeatureCodes.IM_SCREEN_CHECKER)
    ) {
      setViewAuctionPage("41");
      sessionStorage.setItem("interPage", "41");
    }
  }, []);
  useMemo(async () => {
    const bagTypeResponse = await getBagTypeDetails();
    if (bagTypeResponse.data.status) {
      setBagType(
        bagTypeResponse.data.data.reduce((prevState, currState) => {
          prevState.push({
            id: currState.parameter_data_value,
            val: currState.parameter_display_value,
          });
          return prevState;
        }, [])
      );
    }
  }, []);

  useMemo(async () => {
    try {
      const response = await getConditionCodes({
        branch_id: selectedBranch,
        crop_id: selectedCropId,
      });
      if (response.data.status) {
        // const data = response.data.data.map((item: any) => {
        //   return {
        //     key: item.material_no,
        //     id: item.material_no,
        //     value: item.material_code,
        //   };
        // });
        var item = response.data.data;
        if (item && item.length < 5) {
          for (let i = item.length; i < 5; i++) {
            item.push({
              condition_name: "",
              condition_type: "",
              condition_value: null,
              service_vendor_code: null,
            });
          }
        }
        setConditionCodesData(item);
        // setSelectConditions(item);
        // const modifiedData = upcomingData.map((item: any) => {
        //   if (item.saudhaId === saudaId && item.varietyId === varietyId) {
        //     return {
        //       ...item,
        //       conditionCodes: response.data.data,
        //     };
        //   } else {
        //     return item;
        //   }
        // });
        // setUpcomingData(modifiedData);
        // setMaterialCodesData(data);
      } else {
        message.error("Something went wrong :(", 1);
        setConditionCodesData([]);
      }
    } catch (error) {
      message.error("Something went wrong :(", 1);
      console.log(error);
    }
  }, [selectedBranch, selectedCropId]);

  return (
    <div className="intemediate-sauda-landing">
      <Tabs
        defaultActiveKey={sessionStorage.getItem("interPage") || "40"}
        onChange={(activeKey) => {
          setViewAuctionPage(activeKey);
          sessionStorage.setItem("interPage", activeKey);
        }}
      >
        {canUserAccess(userData, FeatureCodes.IM_SCREEN_MAKER) &&
          bagType.length && (
            <TabPane
              tab={
                <span className="tabname-container">
                  {" "}
                  <IntermediateImage className="tab-img" />{" "}
                  <div className="tab-name">Submit PO</div>
                </span>
              }
              key="40"
            >
              <IntermediateScreen
                conditionCodes={conditionCodes}
                bagTypeData={bagType}
                {...props}
              />
            </TabPane>
          )}
        {canUserAccess(userData, FeatureCodes.IM_SCREEN_CHECKER) &&
          bagType.length && (
            <TabPane
              tab={
                <span className="tabname-container">
                  {" "}
                  <ApprovalImage className="tab-img" />{" "}
                  <div className="tab-name">Approve PO</div>
                </span>
              }
              key="41"
            >
              <IntermediateApproveScreen
                conditionCodes={conditionCodes}
                bagType={bagType}
                {...props}
              />
            </TabPane>
          )}
      </Tabs>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedKey: selectViewAuctionPage,
  userData: selectUserData,
  selectedCropId: selectSelectedCrop,
  selectedBranch: selectSelectedBranch,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setViewAuctionPage: (activeKey: string) =>
      dispatch(setViewAuctionPage(activeKey)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntemediateSaudaLanding);
