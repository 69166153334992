/* eslint-disable react-hooks/rules-of-hooks */
import { sleepTimer } from "../constants/data-constants";
import { logoutService } from "./login-services";
import { store } from "../redux/store";
import { resetUser, setIdleModal } from "../redux/user/user.actions";
import { resetCommonReducer } from "../redux/common/common.actions";

class InactivityClass {
  // idle logout timer set to 15 minutes
  public idleLogoutTimer: any;
  public idleAlertModalTimer: any;
  public titleInterval: any;

  // First execute the timer and wait for hostListener
  onInit = (history: any) => {
    this.restartIdleLogoutTimer(history);
  };

  // Whenever users touch action occure this function will execute.
  onTouchStart = (history: any) => {
    this.restartIdleLogoutTimer(history);
  };

  // First this will clear timeout then again setTimeout fun and counting start again, After specific time if user did not active then log out function will execute.
  restartIdleLogoutTimer(history: any) {
    clearInterval(this.titleInterval);
    clearTimeout(this.idleLogoutTimer);
    clearTimeout(this.idleAlertModalTimer);
    store.dispatch(setIdleModal(false));
    document.title = "TradeNext TM";
    const alertTimer = sleepTimer - sleepTimer / 3;
    this.idleAlertModalTimer = setTimeout(() => {
      store.dispatch(setIdleModal(true));
      document.title = "Session Timeout !!!";
      this.titleInterval = setInterval(() => {
        if (document.title === "Session Timeout !!!") {
          document.title = "Alert !!!";
        } else {
          document.title = "Session Timeout !!!";
        }
      }, 750);
    }, alertTimer);
    this.idleLogoutTimer = setTimeout(() => {
      clearInterval(this.titleInterval);
      store.dispatch(setIdleModal(false));
      clearTimeout(this.idleAlertModalTimer);
      document.title = "Session Timedout !!!";
      this.logoutUser(history);
    }, sleepTimer);
  }

  stopTimers = () => {
    store.dispatch(setIdleModal(false));
    clearInterval(this.titleInterval);
    clearTimeout(this.idleLogoutTimer);
    clearTimeout(this.idleAlertModalTimer);
    this.titleInterval = null;
    this.idleLogoutTimer = null;
    this.idleAlertModalTimer = null;
  };

  consentLogoutUser = (history: any) => {
    store.dispatch(setIdleModal(false));
    clearInterval(this.titleInterval);
    clearTimeout(this.idleLogoutTimer);
    clearTimeout(this.idleAlertModalTimer);
    document.title = "TradeNext TM";
    this.logoutUser(history);
  };

  logoutUser = async (history: any, status: boolean = true) => {
    status && logoutService();
    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
      store.dispatch(resetUser());
      store.dispatch(resetCommonReducer());
      history.push("/login");
    }, 500);
  };
}

const triggerSleepClient = new InactivityClass();
export default triggerSleepClient;
