/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import "./vendorReports.styles.scss";
import { ReactComponent as CloseIcon } from "../../assets/cancel.svg";
import { ReactComponent as CheckedIcon } from "../../assets/checked.svg";

import { SyncOutlined } from "@ant-design/icons";

import moment from "moment";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Checkbox, Col, Input, Layout, Row, Space, Typography } from "antd";

import {
  getVendorReports,
  getVendorsList,
} from "../../services/auction-services";

import {
  getHubsService,
  getSlotIdsService,
} from "../../services/common-services";

import { Table, message, Button, Spin, Progress, Alert } from "antd";
import { Link } from "react-router-dom";

import CustomFilter from "../../components/custom-filter/custom-filter.component";
import {
  selectSelectedCrop,
  selectBranches,
  selectCrops,
  selectVarieties,
  selectCurrentPage,
  selectViewAuctionPage,
  selectSelectedBranch,
  selectHubs,
} from "../../redux/common/common.selectors";

import Axios from "axios";
// import API from "../../api";
import { selectUserData } from "../../redux/user/user.selectors";
import { Dispatch } from "redux";
import { dataConstants } from "../../constants/data-constants";
import { setSelectedBranch } from "../../redux/common/common.actions";
import ButtonGroup from "antd/lib/button/button-group";
const FileDownLoad = require("js-file-download");

const { Content, Footer } = Layout;

const status = [
  { name: "Accepted", id: 1 },
  { name: "Rejected", id: 2 },
  { name: "Not Processed", id: 3 },
];

const Archives: React.FC = (props: any) => {
  const {
    branches,
    varietiesList,
    selectedCropId,
    // userData,
    crops,
    currentpage,
    viewAuctionPage,
    selectedBranch,
    setSelectedBranchIndex,
    hubs,
  } = props;
  // const [selectedBranch, setSelectedBranch] = useState(branches[0]?.id);
  // const [hubs, setHubs] = useState();
  // const [slots, setSlots] = useState();
  // const [selectedBranch, setSelectedBranch] = useState(
  //   branches[branchIndex].id
  // );
  const [selectedVariety, setSelectedVariety] = useState();
  const [selectedDates, setSelectedDates] = useState([
    moment().format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ]);
  const [selectedHub, setSelectedHub] = useState();
  const [isTableVisible, setisTableVisible] = useState(true);
  // const [selectedSlot, setSelectedSlot] = useState();
  const [selectedVendor, setSelectedVendor] = useState();
  const [vendorReportData, setVendorReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isProgressClicked, setisProgressClicked] = useState(false);
  const [downloaddata, setdownloaddata] = useState<any>();
  // const [isSpinning, setIsSpinning] = useState(false);
  const [isSpinnerOnProgress, setIsSpinnerOnProgress] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [filtersData, setFilters] = useState<any>();
  const [filtersDropdown, setFiltersDropdown] = useState<any>();
  // const [selectedCropId, setselectedCropId] = useState<any>(selectedCropId);
  // const [varietiesList, setVarietiesData] = useState<any>([]);

  const columns = [
    {
      title: "Vendor Code",
      key: "vendorcode",

      children: [
        {
          dataIndex: "vendorCode",
          key: "vendorcode2",
          width: 90,
        },
      ],
    },
    {
      title: "Vendor Firm Name",
      key: "vendorname",
      filters: filtersData,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={"Search"}
            onChange={(e) => {
              filteredData(e.target.value.toUpperCase());
            }}
            style={{ width: 180, marginBottom: 8, display: "flex" }}
            allowClear
          />
          <Space direction="vertical">
            <Checkbox.Group
              value={selectedKeys}
              onChange={(e) => {
                setSelectedKeys(e ? e : []);
              }}
              style={{
                width: "180px",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <Row>
                {filtersDropdown &&
                  filtersDropdown.map((e: any) => {
                    return (
                      <Col span={24} key={e.value}>
                        <Checkbox value={e.value}>
                          <Typography.Paragraph
                            style={{
                              display: "inline-block",
                              margin: 0,
                              verticalAlign: "bottom",
                              width: 130,
                            }}
                            ellipsis
                          >
                            {e.label}
                          </Typography.Paragraph>
                        </Checkbox>
                      </Col>
                    );
                  })}
              </Row>
            </Checkbox.Group>
            <ButtonGroup>
              <Button type="primary" onClick={() => confirm()}>
                Search
              </Button>
              <Button
                danger
                onClick={() => clearFilters()}
                style={{ borderLeftColor: "inherit", left: "10%" }}
              >
                Reset
              </Button>
            </ButtonGroup>
          </Space>
        </div>
      ),
      onFilter: (value: any, record: any) =>
        record["vendorName"]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      filterSearch: true,
      children: [
        {
          dataIndex: "vendorName",
          key: "vendorname2",
          width: 150,
        },
      ],
    },
    {
      title: "Auction ID",
      sorter: (a, b) => {
        if (a.auctionNumber === b.auctionNumber) {
          return a.secondaryKey - b.secondaryKey;
        }
        return a.auctionNumber - b.auctionNumber;
      },
      children: [
        {
          dataIndex: "auctionNumber",
          key: "auctionnum2",
          width: 100,
        },
      ],
    },
    {
      title: "Auction Date",
      children: [
        {
          dataIndex: "date",
          key: "date2",
          width: 90,
        },
      ],
    },
    {
      title: "Auction Slot",
      children: [
        {
          dataIndex: "time",
          key: "time2",
          width: 110,
        },
      ],
    },
    {
      title: "Branch",
      children: [
        {
          dataIndex: "branchName",
          key: "branchName2",
          width: 90,
        },
      ],
    },
    {
      title: "Hub",
      children: [
        {
          dataIndex: "hub",
          key: "hub2",
          width: 100,
        },
      ],
    },
    {
      title: "Variety",
      sorter: (a, b) => a.variety.localeCompare(b.variety),
      children: [
        {
          dataIndex: "variety",
          key: "variety2",
          width: 90,
        },
      ],
    },
    {
      title: "Claim",
      children: [
        {
          dataIndex: "claim",
          key: "claim2",
          width: 60,
          render: (value: string) => {
            return (
              <div className="claim-container">
                {value === "Yes" ? <CheckedIcon /> : <CloseIcon />}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Division",
      children: [
        {
          dataIndex: "division",
          key: "division2",
          width: 70,
          render: (value: string) => {
            return <div>{value}</div>;
          },
        },
      ],
    },
    {
      title: "Sauda Type",
      children: [
        {
          dataIndex: "saudaType",
          key: "saudaType2",
          width: 90,
          render: (value: string) => {
            return <div>{value}</div>;
          },
        },
      ],
    },
    {
      title: "Bid Qty",
      children: [
        {
          title: "MT",
          dataIndex: "bidQty",
          key: "bidqty2",
          width: 70,
        },
      ],
    },
    {
      title: "Qty Allotted",
      children: [
        {
          title: "MT",
          dataIndex: "qtyFulfilled",
          key: "qtyFulfilled2",
          width: 80,
        },
      ],
    },
    {
      title: "Final Bid Price",
      children: [
        {
          title: "₹/MT",
          dataIndex: "bidPrice",
          key: "bidPrice2",
          width: 100,
        },
      ],
    },
    {
      title: "Bid Status",
      children: [
        {
          dataIndex: "bidStatus",
          key: "bidstatus2",
          width: 90,
          render: (value: string) => {
            return (
              <div
                className={`sauda-status ${
                  value === "Accept" ? "green" : value === "Reject" ? "red" : ""
                }`}
              >
                {value}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Sauda ID",
      children: [
        {
          dataIndex: "bidId",
          key: "bidId2",
          width: 90,
          render: (value: string) => {
            return <div>{value}</div>;
          },
        },
      ],
    },
    {
      title: "PO Number",
      children: [
        {
          dataIndex: "poNnumber",
          key: "poNnumber2",
          width: 100,
          render: (value: string) => {
            return <div>{value}</div>;
          },
        },
      ],
    },
  ];

  useEffect(() => {
    if (currentpage === "6" && viewAuctionPage === "61") {
      getVendorReportData();
    }
    // onBranchChange(selectedBranch, []);
  }, [
    currentpage,
    viewAuctionPage,
    selectedBranch,
    selectedCropId,
    selectedVariety,
    selectedDates,
    selectedVendor,
  ]);

  // useEffect(() => {
  //   getVendors(branches[branchIndex].id);
  //   onBranchChange(branches[branchIndex].id, []);
  // }, [branchIndex]);

  useEffect(() => {
    setSelectedVariety(undefined);
  }, [varietiesList]);

  // useEffect(() => {
  //   setselectedCropId(selectedCropId);
  // }, [selectedCropId]);

  useMemo(async () => {
    setIsLoading(true);
    const params = {
      branch_id: selectedBranch,
      crop_id: selectedCropId,
    };
    try {
      const response = await getVendorsList(params);
      if (response.data.status) {
        const data = response.data.data.map((item: any) => {
          return {
            key: item.vendor_code,
            id: item.user_id,
            value: item.user_id,
            name: item.name,
          };
        });
        setVendorList(data);
      } else {
        message.error("Something went wrong :(", 1);
        setVendorList([]);
      }
      setIsLoading(false);
    } catch (error) {
      message.error("Something went wrong :(", 1);
      setIsLoading(false);
    }
  }, [selectedBranch, selectedCropId]);

  const getVendorReportData = async (hubId?: any) => {
    setIsLoading(true);
    const params = JSON.stringify({
      start_date: selectedDates[0],
      end_date: selectedDates[1],
      crop_id: selectedCropId,
      branch_id: selectedBranch,
      hub_id: hubId ? (hubId.length ? hubId : undefined) : selectedHub,
      variety_id: selectedVariety,
      vendor_id: selectedVendor,
    });
    try {
      const response = await getVendorReports(params);
      setVendorReportData([]);
      if (
        response.data.status &&
        response.data.data &&
        response.data.data.length
      ) {
        const data = response.data.data
          .map((item: any, index: number) => {
            return {
              key: index,
              auctionNumber: item.auction_id,
              time: item.auction_time,
              bidId: item.bid_id,
              vendorCode: item.vendor_code,
              vendorName: item.name_firm,
              date: item.auction_date,
              hub: item.hub_name,
              division: item.division,
              claim: item.claim,
              variety: item.variety_name,
              crop_id: selectedCropId,
              bidQty: item.bid_quantity,
              qtyFulfilled: item.alloted_qty === null ? 0 : item.alloted_qty,
              branchId: selectedBranch,
              branchName: item.branch_name,
              bidPrice: item.bid_price,
              saudaType: item.sauda_type,
              bidStatus: item.bid_status,
              poNnumber: item.PO_number,
            };
          })
          .filter((item) => item.auctionNumber !== undefined);
        setVendorReportData(data);
        const filters = data
          .map((e: any) => {
            return {
              label: e.vendorName,
              value: e.vendorName,
            };
          })
          .filter(
            (vendorName, index, self) =>
              index ===
              self.findIndex(
                (r) =>
                  r.label === vendorName.label && r.value === vendorName.value
              )
          );
        setFilters(filters);
        setFiltersDropdown(filters);
      } else {
        setVendorReportData([]);
      }
      setIsLoading(false);
      setisTableVisible(true);
    } catch (error) {
      console.log(error);
      setisTableVisible(true);
      setIsLoading(false);
      message.warning("Cannot fetch data...");
      setVendorReportData([]);
    }
  };

  // const onBranchChange = (value: string | number | any, option: any) => {
  //   setSelectedBranchIndex(value);
  //   // setBranchIndex(branches.findIndex((branch) => branch.id == value));
  //   setSelectedHub(undefined);
  //   setSelectedSlot(undefined);
  //   getSlotsFn(value);
  //   getHubsFn(value);
  //   getVendors(value);
  //   let start = moment(selectedDates[0]);
  //   let end = moment(selectedDates[1]);
  //   let differenceInDays = end.diff(start, "days");

  //   if (differenceInDays > 14) {
  //     return setisTableVisible(false);
  //   }
  //   setisTableVisible(true);
  //   getVendorReportData(
  //     selectedDates[0],
  //     selectedDates[1],
  //     value,
  //     selectedVariety,
  //     selectedHub,
  //     selectedVendor
  //   );
  // };
  // const onVarietyChange = (value: string | number | any, option: any) => {
  //   if (value === selectedVariety) {
  //     setSelectedVariety(undefined);
  //   } else setSelectedVariety(value);
  //   getVendorReportData(
  //     selectedDates[0],
  //     selectedDates[1],
  //     selectedBranch,
  //     value === selectedVariety ? null : value,
  //     selectedHub,
  //     selectedVendor
  //   );
  // };
  // const onHubChange = (value: string | number | any, option: any) => {
  //   if (value === selectedHub) {
  //     setSelectedHub(undefined);
  //   } else setSelectedHub(value);
  //   getVendorReportData(
  //     selectedDates[0],
  //     selectedDates[1],
  //     selectedBranch,
  //     selectedVariety,
  //     value === selectedHub ? null : value,
  //     selectedVendor
  //   );
  // };

  // const onVendorChange = (value: string | number | any, option: any) => {
  //   if (value === selectedVendor) {
  //     setSelectedVendor(undefined);
  //   } else setSelectedVendor(value);
  //   getVendorReportData(
  //     selectedDates[0],
  //     selectedDates[1],
  //     selectedBranch,
  //     selectedVariety,
  //     selectedHub,
  //     value === selectedVendor ? null : value
  //   );
  // };
  const onDateChange = (value: string | number | any, timeString: any) => {
    setSelectedDates(timeString);
    let start = moment(timeString[0]);
    let end = moment(timeString[1]);
    let differenceInDays = end.diff(start, "days");

    if (differenceInDays > 14) {
      return setisTableVisible(false);
    }
    setisTableVisible(true);
  };
  const filteredData = (term: any) => {
    const filters = filtersData
      .flatMap((e: any) => {
        if (e.value.toLowerCase().includes(term.toLowerCase()))
          return {
            label: e.value,
            value: e.value,
          };
      })
      .filter((value) => value !== undefined);
    setFiltersDropdown(filters);
  };
  // const getHubsFn = async (branchId: number) => {
  //   try {
  //     const response = await getHubsService({ branch_id: branchId });
  //     const data = response.data.data.map((item: any) => {
  //       return { id: item.territory_id, name: item.territory_name };
  //     });
  //     setHubs(data);
  //   } catch (error) {}
  // };
  // const getSlotsFn = async (branchId: number) => {
  //   try {
  //     const response = await getSlotIdsService({
  //       branch_id: branchId,
  //       crop_id: selectedCropId,
  //     });
  //     const data = response.data.data.map((item: any) => {
  //       return { id: item.slot_id, name: item.slot_name };
  //     });
  //     setSlots(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fileDownload = async () => {
    setIsSpinnerOnProgress(true);
    const body = {
      start_date: selectedDates[0],
      end_date: selectedDates[1],
      branch_id: selectedBranch,
      hub_id: selectedHub,
      variety_id: selectedVariety,
      vendor_id: selectedVendor,
      download: true,
      crop_id: selectedCropId,
    };

    // const fileresponse = await getVendorReports(body);

    // if (fileresponse) {
    //   if (fileresponse.data.status) {
    //#region file download
    Axios({
      url: dataConstants.vendorReportsUrl,
      method: "POST",
      data: body,
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        let progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setdownloaddata(progress);
      },
    })
      .then((response: any) => {
        FileDownLoad(
          response.data,
          `Vendorwise_Report_${new Date().toLocaleDateString()}.xlsx`
        );
        message.success("File has been downloaded successfully!");
        setIsSpinnerOnProgress(false);
        setTimeout(
          function () {
            setisProgressClicked(false);
          }.bind(this),
          5000
        );
      })
      .catch((err: any) => {
        message.error("File cannot be downloaded");
        setIsSpinnerOnProgress(false);
        setisProgressClicked(false);
      });
    //#endregion
    // } else {
    //   message.warning("File has no data");
    //   setIsSpinnerOnProgress(false);
    //   setisProgressClicked(false);
    // }
    // } else {
    //   message.error("Error downloading file");
    //   setIsSpinnerOnProgress(false);
    //   setisProgressClicked(false);
    // }
  };

  return (
    <div className="archives">
      <Spin
        spinning={isSpinnerOnProgress}
        tip="Please wait while the file is bieng Downloaded..."
      >
        <Content style={{ height: "82vh" }}>
          <div className="filter-container">
            <CustomFilter
              type="range-picker"
              head="Date Range"
              startDate={moment()}
              endDate={moment()}
              onChange={onDateChange}
            />
            {/* <CustomFilter
          type="single-select"
          options={crops}
          placeholder="Select Crop"
          head="Crop"
        /> */}
            <CustomFilter
              type="single-select"
              value={selectedBranch}
              options={branches}
              onChange={(value: any) => {
                setSelectedHub(undefined);
                setSelectedBranchIndex(value);
              }}
              placeholder="Select Branch"
              head="ITC Branch Office"
              returnId
            />
            <CustomFilter
              // type="single-select"
              type="multi-select"
              value={selectedHub}
              options={hubs}
              onChange={(value: any) => {
                setSelectedHub(value);
                getVendorReportData(value);
              }}
              placeholder="Select Hub"
              head="Hub"
              returnId
            />
            <CustomFilter
              // type="single-select"
              type="multi-select"
              value={selectedVariety}
              onChange={(value: any) => setSelectedVariety(value)}
              options={varietiesList}
              placeholder="Select Variety"
              head="Variety"
              returnId
            />
            {/* <CustomFilter
          type="single-select"
          value={selectedSlot}
          options={slots}
          onChange={onSlotChange}
          placeholder="Select Slot"
          head="Slot"
          returnId
        /> */}
            {/* <CustomFilter
              type="single-select"
              value={selectedVendor}
              options={vendorList}
              onChange={(value: any) => setSelectedVendor(value)}
              placeholder="Select Vendor"
              head="Vendor"
              returnId
            /> */}
            <div className="selected-wheat">
              <span className="selected-wheat-label">Selected Crop</span> <br />
              <span className="selected-wheat-value">
                <b>
                  {crops &&
                    crops.length &&
                    crops.find(
                      (item: { id: number; name: string }) =>
                        item.id == selectedCropId
                    ).name}
                </b>
              </span>
            </div>
            <Button
              className="refresh"
              icon={<SyncOutlined spin={isLoading} />}
              onClick={() => {
                getVendorReportData();
              }}
            >
              Refresh
            </Button>
          </div>

          {isTableVisible ? (
            <div className="vendor-reports-table-container">
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={vendorReportData}
                pagination={false}
                scroll={{ y: window.innerHeight - 339 }}
                footer={() => {
                  return vendorReportData?.length ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        alignItems: "baseline",
                        gap: "1em",
                        position: "fixed",
                        right: "5px",
                      }}
                    >
                      <div className="progress-download-container">
                        {isProgressClicked && (
                          <Progress
                            type="circle"
                            percent={downloaddata}
                            width={37}
                            strokeColor={{
                              "0%": "#108ee9",
                              "100%": "#87d068",
                            }}
                            style={{
                              right: "125px",
                              position: "absolute",
                              display: "flex",
                              marginTop: "-45px",
                            }}
                            trailColor="#003285"
                          />
                        )}
                      </div>
                      <div className="download-button-container">
                        {vendorReportData?.length && (
                          <Button
                            onClick={() => {
                              setdownloaddata(0);
                              setisProgressClicked(true);
                              fileDownload();
                              // const fileHeaders = {
                              //   Authorization: "Bearer " + userData.access_token,
                              // };
                              // const param = {
                              //   start_date: selectedDates[0],
                              //   end_date: selectedDates[1],
                              //   branch_id: selectedBranch,
                              //   hub_id: selectedHub,
                              //   variety_id: selectedVariety,
                              //   vendor_id: selectedVendor,
                              //   download: true,
                              //   crop_id: selectedCropId,
                              // };

                              // console.log("response ", param);
                              // API.post(`/vendor_report`, {
                              //   headers: fileHeaders,
                              //   body: param,
                              // })
                              //   .then((fileresponse: any) => {
                              //     if (fileresponse.data.status) {
                              //       //#region file download
                              //       Axios({
                              //         url: `./${fileresponse.data.data}`,
                              //         method: "GET",
                              //         responseType: "arraybuffer",
                              //       })
                              //         .then((response: any) => {
                              //           FileDownLoad(
                              //             response.data,
                              //             `${fileresponse.data.data}`
                              //           );
                              //         })
                              //         .catch((err: any) => {
                              //           message.error("File cannot be downloaded");
                              //         });
                              //       //#endregion
                              //     } else {
                              //       message.warning("File has no data");
                              //     }
                              //   })
                              //   .catch((err: any) => {
                              //     message.error("Error downloading file");
                              //   });
                            }}
                            type="primary"
                            shape="round"
                            // icon="download"
                            size="large"
                            // style={{ display: "block" }}
                            className="download-btn-vendor"
                          >
                            <div>
                              <div style={{ marginTop: "-7px" }}>Download</div>
                              <div
                                style={{
                                  fontSize: "11px",
                                  lineHeight: "7px",
                                  color: "#b4c8dc",
                                }}
                              >
                                .xls report
                              </div>
                            </div>
                          </Button>
                        )}
                      </div>
                    </div>
                  ) : null;
                }}
              />
            </div>
          ) : (
            <Alert
              style={{ padding: "10px", width: "50%", margin: "auto" }}
              message="Informational Notes"
              description="The data for the selected time period is too big. You can use the button at the bottom right to download the file"
              type="info"
              showIcon
            />
          )}
        </Content>
        <Footer></Footer>
      </Spin>
    </div>
  );
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setSelectedBranchIndex: (index: number) =>
      dispatch(setSelectedBranch(index)),
  };
};
const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  branches: selectBranches,
  crops: selectCrops,
  varietiesList: selectVarieties,
  selectedCropId: selectSelectedCrop,
  currentpage: selectCurrentPage,
  viewAuctionPage: selectViewAuctionPage,
  selectedBranch: selectSelectedBranch,
  hubs: selectHubs,
});

export default connect(mapStateToProps, mapDispatchToProps)(Archives);
