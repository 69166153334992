import React, { useState, useCallback, useEffect } from "react";
import { Dispatch } from "redux";
import { Spin, Form, Input, Button, message, Radio, Select, Modal } from "antd";
import "./userDetails.style.scss";
import {
  getRegisterUser,
  getBranches,
  postRegisterUser,
} from "../../services/register-services";
import MultiSelect from "../custom-filter/custom-filter.component";
import { setRegistrationData } from "../../redux/registration/registration.actions";
import { selectRegisterData } from "../../redux/registration/registration.selectors";
import { createStructuredSelector } from "reselect";
import { connect, useSelector } from "react-redux";
import { selectSelectedCrop } from "../../redux/common/common.selectors";
import { getCropsService } from "../../services/common-services";
import { useHistory } from "react-router-dom";
const { Option } = Select;

const BasicDetails: React.FC<any> = (props: any) => {
  const {
    loginRoute,
    handleRouteClick,
    registrationData,
    setRegistrationData,
    registeredAs,
    DataRequired,
    selectedCropId,
  } = props;
  console.log(DataRequired);
  const [basicDetails, setBasicDetails] = useState({
    contact_number: undefined,
    email: undefined,
    name_firm: undefined,
    name: undefined,
    address: undefined,
    location: undefined,
    state: undefined,
    postal_code: undefined,
    fax_no: undefined,
    approval_status: undefined,
    approval_remarks: undefined,
    registered_sap_branch: undefined,
    legal_status_of_party: undefined,
    legal_status_of_party_other: undefined,
    nature_of_supplier: undefined,
    nature_of_supplier_other: undefined,
    is_association_with_other_division: "",
    association_details: undefined,
    is_relationship_with_managerial_personnel: "",
    managerial_personnel_details: undefined,
    is_blacklisted_debarred_delisted: "",
    blacklisted_details: undefined,
    vendor_code: undefined,
    GST_number: undefined,
    PAN_no: undefined,
    crops: undefined,
    primary_branch: undefined,
  });
  const [branchList, setBranchData] = useState([]);
  const [cropsList, setCropsList] = useState([]);
  const [defBranchData, setDefBranchData] = useState<any>();
  const [defLabelData, setDefLabelData] = useState<any>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // const onSave = async (values: any) => {
  //   //   contact_number: string,
  //   //   email: string,
  //   //   name: string,
  //   //   address: string,
  //   //   location: string,
  //   //   state: string,
  //   //   postal_code: BigInteger,
  //   //   fax_no: BigInteger,
  //   //   registered_sap_branch: string,
  //   //   legal_status_of_party: string,
  //   //   legal_status_of_party_other: string,
  //   //   nature_of_supplier: string,
  //   //   nature_of_supplier_other: string,
  //   //   is_association_with_other_division:BigInteger,
  //   //   association_details: string,
  //   //   is_relationship_with_managerial_personnel:BigInteger,
  //   //   managerial_personnel_details: string,
  //   //   is_blacklisted_debarred_delisted:BigInteger,
  //   //   blacklisted_details: string,
  //   //   vendor_code: string,
  //   //   submission_type: BigInteger,
  //   //   registered_as: string,
  //   // })=>{
  //   try {
  //     setLoading(true);
  //     var warningMessage = "";
  //     const validate = () => {
  //       console.log("Save Basic Details: ====>  ", basicDetails);
  //       if (
  //         basicDetails.contact_number === "" ||
  //         basicDetails.contact_number === undefined ||
  //         basicDetails.contact_number === null
  //       ) {
  //         warningMessage = "Enter Phone number..!";
  //         return false;
  //       }

  //       if (
  //         basicDetails.email === "" ||
  //         basicDetails.email === undefined ||
  //         basicDetails.email === null
  //       ) {
  //         warningMessage = "Enter E-mail..!";
  //         return false;
  //       }

  //       if (
  //         basicDetails.name_firm === "" ||
  //         basicDetails.name_firm === undefined ||
  //         basicDetails.name_firm === null
  //       ) {
  //         warningMessage = "Enter name of the Entity/Firm..!";
  //         return false;
  //       }

  //       if (
  //         basicDetails.name === "" ||
  //         basicDetails.name === undefined ||
  //         basicDetails.name === null
  //       ) {
  //         warningMessage = "Enter your name..!";
  //         return false;
  //       }
  //       if (
  //         basicDetails.address === "" ||
  //         basicDetails.address === undefined ||
  //         basicDetails.address === null
  //       ) {
  //         warningMessage = "Enter Address..!";
  //         return false;
  //       }

  //       if (
  //         basicDetails.location === "" ||
  //         basicDetails.location === undefined ||
  //         basicDetails.location === null
  //       ) {
  //         warningMessage = "Enter Location..!";
  //         return false;
  //       }

  //       if (
  //         basicDetails.state === "" ||
  //         basicDetails.state === undefined ||
  //         basicDetails.state === null
  //       ) {
  //         warningMessage = "Enter State..!";
  //         return false;
  //       }

  //       if (
  //         basicDetails.postal_code === "" ||
  //         basicDetails.postal_code === undefined ||
  //         basicDetails.postal_code === null
  //       ) {
  //         warningMessage = "Enter Postal code..!";
  //         return false;
  //       }

  //       if (
  //         basicDetails.registered_sap_branch === "" ||
  //         basicDetails.registered_sap_branch === undefined ||
  //         basicDetails.registered_sap_branch === null
  //       ) {
  //         warningMessage = "Select Branch..!";
  //         return false;
  //       }

  //       if (
  //         basicDetails.legal_status_of_party === "" ||
  //         basicDetails.legal_status_of_party === undefined ||
  //         basicDetails.legal_status_of_party === null
  //       ) {
  //         warningMessage = "Select Legal status of the party..!";
  //         return false;
  //       }

  //       if (
  //         basicDetails.legal_status_of_party === "9" &&
  //         (basicDetails.legal_status_of_party_other === "" ||
  //           basicDetails.legal_status_of_party_other === undefined ||
  //           basicDetails.legal_status_of_party_other === null)
  //       ) {
  //         warningMessage = "Enter details for Legal status of the party..!";
  //         return false;
  //       }

  //       if (
  //         basicDetails.nature_of_supplier === "" ||
  //         basicDetails.nature_of_supplier === undefined ||
  //         basicDetails.nature_of_supplier === null
  //       ) {
  //         warningMessage = "Select Nature of supplier..!";
  //         return false;
  //       }

  //       if (
  //         basicDetails.nature_of_supplier === "10" &&
  //         (basicDetails.nature_of_supplier_other === "" ||
  //           basicDetails.nature_of_supplier_other === undefined ||
  //           basicDetails.nature_of_supplier_other === null)
  //       ) {
  //         warningMessage = "Enter details for Nature of supplier..!";
  //         return false;
  //       }

  //       if (
  //         basicDetails.is_association_with_other_division === "" ||
  //         basicDetails.is_association_with_other_division === undefined ||
  //         basicDetails.is_association_with_other_division === null
  //       ) {
  //         warningMessage =
  //           "Select Party associated with any other Divisions..!";
  //         return false;
  //       }

  //       if (
  //         basicDetails.is_association_with_other_division === "1" &&
  //         (basicDetails.association_details === "" ||
  //           basicDetails.association_details === undefined ||
  //           basicDetails.association_details === null)
  //       ) {
  //         warningMessage =
  //           "Enter details for Party associated with any other Divisions..!";
  //         return false;
  //       }

  //       if (
  //         basicDetails.is_relationship_with_managerial_personnel === "" ||
  //         basicDetails.is_relationship_with_managerial_personnel ===
  //         undefined ||
  //         basicDetails.is_relationship_with_managerial_personnel === null
  //       ) {
  //         warningMessage =
  //           "Select Party has relationship with ITC's key managerial personnel..!";
  //         return false;
  //       }

  //       if (
  //         basicDetails.is_relationship_with_managerial_personnel === "1" &&
  //         (basicDetails.managerial_personnel_details === "" ||
  //           basicDetails.managerial_personnel_details === undefined ||
  //           basicDetails.managerial_personnel_details === null)
  //       ) {
  //         warningMessage =
  //           "Enter details for Party has relationship with ITC's key managerial personnel..!";
  //         return false;
  //       }

  //       if (
  //         basicDetails.is_blacklisted_debarred_delisted === "" ||
  //         basicDetails.is_blacklisted_debarred_delisted === undefined ||
  //         basicDetails.is_blacklisted_debarred_delisted === null
  //       ) {
  //         warningMessage =
  //           "Select Party has been Blacklisted/ Debarred/ Delisted ..!";
  //         return false;
  //       }

  //       if (
  //         basicDetails.is_blacklisted_debarred_delisted === "1" &&
  //         (basicDetails.blacklisted_details === "" ||
  //           basicDetails.blacklisted_details === undefined ||
  //           basicDetails.blacklisted_details === null)
  //       ) {
  //         warningMessage =
  //           "Enter details for Party has been Blacklisted/ Debarred/ Delisted..!";
  //         return false;
  //       }
  //       return true;
  //     };
  //     if (validate()) {
  //       const dataToSend = {
  //         contact_number: values.contact_number,
  //         email: values.email,
  //         name: values.name,
  //         name_firm: values.name_firm,
  //         address: values.address,
  //         location: values.location,
  //         state: values.state,
  //         postal_code: values.postal_code,
  //         fax_no: values.fax_no,
  //         registered_sap_branch: values.registered_sap_branch,
  //         legal_status_of_party: values.legal_status_of_party,
  //         legal_status_of_party_other:
  //           values.legal_status_of_party == 9
  //             ? values.legal_status_of_party_other
  //             : null,
  //         nature_of_supplier: values.nature_of_supplier,
  //         nature_of_supplier_other:
  //           values.nature_of_supplier == 10
  //             ? values.nature_of_supplier_other
  //             : null,
  //         is_association_with_other_division:
  //           values.is_association_with_other_division,
  //         association_details:
  //           values.is_association_with_other_division == 1
  //             ? values.association_details
  //             : null,
  //         is_relationship_with_managerial_personnel:
  //           values.is_relationship_with_managerial_personnel,
  //         managerial_personnel_details:
  //           values.is_relationship_with_managerial_personnel == 1
  //             ? values.managerial_personnel_details
  //             : null,
  //         is_blacklisted_debarred_delisted:
  //           values.is_blacklisted_debarred_delisted,
  //         blacklisted_details: values.blacklisted_details,
  //         vendor_code: values.vendor_code,
  //         submission_type: 1,
  //         registered_as: registeredAs === "member" ? "MEMBER" : "TRADER",
  //       };

  //       try {
  //         const response = await postRegisterUser(dataToSend);
  //         console.log("Response on save ", response.data.status);
  //         if (response.data.status && registeredAs !== "member") {
  //           setRegistrationData({
  //             ...basicDetails,
  //             isBasicDetailsSubmitted: true,
  //           });
  //           handleRouteClick(1, "two");
  //         } else {
  //           setRegistrationData({
  //             ...basicDetails,
  //             isBasicDetailsSubmitted: true,
  //           });
  //           setIsModalVisible(true);
  //           // message.info(
  //           //   `Your registration form submitted successfully for approval.We will get back to you with login credentials once it is approved.Thank you..!`,
  //           //   10, loginRoute());
  //           // loginRoute();
  //         }
  //         setLoading(false);
  //       } catch (error) {
  //         message.error("Something went Wrong. Please Try again..!", 5);
  //         console.log("Akhil Error caught :", error);
  //         setRegistrationData({
  //           ...basicDetails,
  //           isBasicDetailsSubmitted: false,
  //         });
  //         setBasicDetails({ ...basicDetails });
  //         setLoading(false);
  //       }
  //     } else {
  //       console.log("Validation required");
  //       setRegistrationData({
  //         ...basicDetails,
  //         isBasicDetailsSubmitted: false,
  //       });
  //       setBasicDetails({ ...basicDetails });
  //       message.error(warningMessage);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     message.error("Something went Wrong. Please Try again..!", 5);
  //     console.log("Raj Error caught :", error);
  //     setRegistrationData({
  //       ...basicDetails,
  //       isBasicDetailsSubmitted: false,
  //     });
  //     setBasicDetails({ ...basicDetails });
  //     setLoading(false);
  //   }
  // };
  useEffect(() => {
    DataRequired === undefined && history.push("/home");
  }, []);

  function onBranchSelected(e: any) {
    setBasicDetails({ ...basicDetails, registered_sap_branch: e });
  }

  const checkUserExists = async (number: any) => {
    try {
      setLoading(true);
      if (basicDetails.PAN_no !== "") {
        const response = await getRegisterUser({
          // contact_number: basicDetails.contact_number,
          PAN_no: number,
          tm: true,
        });
        // if (
        //   response.data.data.approval_status === 2 ||
        //   response.data.data.approval_status === 3 ||
        //   response.data.data.approval_status === 4
        // ) {
        //   setIsModalVisible(true);
        // }
        setBasicDetails({
          ...response.data.data,
          //contact_number: basicDetails.contact_number,
          //contact_number: number,
          ORIGINAL_cancelled_cheque_name:
            response.data.data.cancelled_cheque_name,
          ORIGINAL_declaration_signature_name:
            response.data.data.declaration_signature_name,
          ORIGINAL_pan_image_name: response.data.data.pan_image_name,
        });
        setRegistrationData({
          ...response.data.data,
          isBasicDetailsSubmitted: false,
          ORIGINAL_cancelled_cheque_name:
            response.data.data.cancelled_cheque_name,
          ORIGINAL_declaration_signature_name:
            response.data.data.declaration_signature_name,
          ORIGINAL_pan_image_name: response.data.data.pan_image_name,
        });
        const cropsResponse = await getCropsService();
        let userBasedCrops = cropsResponse.data.data.filter((x: any) =>
          response.data.data.crops.includes(x.crop_id)
        );
        const cropsData = userBasedCrops.map((item: any) => {
          return {
            id: item.crop_id,
            name: item.crop_name,
          };
        });
        setCropsList(cropsData);
      }
      const branchResponse = await getBranches();
      const branchData = branchResponse.data.data.map((item: any) => {
        return {
          id: item.territory_id,
          name: item.territory_name,
        };
      });
      setBranchData(branchData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    DataRequired && DataRequired.PAN_no && checkUserExists(DataRequired.PAN_no);
  }, []);

  const canEnableTextBox = (value: any, featureCode: any) => {
    return value == featureCode;
    // return value.includes(featureCode);
  };
  // const { getFieldDecorator } = props.form;

  const handleChange = (e: any) => {
    if (e.target.name === "contact_number") {
      if (e.target.value.length <= 10) {
        setBasicDetails((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
        // e.target.value &&
        //   e.target.value.length === 10 &&
        //   checkUserExists(e.target.value);
      } else {
        setBasicDetails((prevState) => ({
          ...prevState,
        }));
      }
    } else {
      setBasicDetails((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  useEffect(() => {
    setDefBranchData(
      branchList.filter(
        (branch: any) =>
          branch.parameter_data_value == basicDetails.registered_sap_branch
      )[0]
    );
  }, [basicDetails, branchList]);

  useEffect(() => {
    defBranchData !== undefined &&
      setDefLabelData(defBranchData.parameter_description);
  }, [defBranchData]);

  return (
    <Spin spinning={loading} className="basic-details-ant-spin">
      <div className="basic-details">
        <Modal
          title="Application Update"
          visible={isModalVisible}
          footer={false}
          onCancel={() => loginRoute()}
          style={{ textAlign: "center" }}
        >
          {basicDetails.approval_status === 2 ? (
            <>
              <p>Your application already submitted successfully.</p>
              <p>You will receive an email shortly.</p>
              <p>Thank you..!</p>
            </>
          ) : basicDetails.approval_status === 3 ? (
            <>
              <p>Your application is already approved.</p>
              <p>Please login with your credentials.</p>
              <p>Thank you..!</p>
            </>
          ) : basicDetails.approval_status === 4 ? (
            <>
              <p>Your application is rejected.</p>
              <p>{basicDetails.approval_remarks}</p>
              <p>Thank you..!</p>
            </>
          ) : (
            <>
              <p>Your registration form submitted successfully for approval.</p>
              <p>
                {" "}
                We will get back to you with login credentials once it is
                approved.
              </p>
              <p>Thank you..!</p>
            </>
          )}
          <div className="btn-container">
            <Button
              onClick={() => loginRoute()}
              className="btn-container"
              style={{
                marginTop: "-25px",
                marginLeft: "5px",
                padding: "2px 8px !important",
                backgroundColor: "#20346a",
                width: "fit-content !important",
                borderRadius: "20px",
                color: "white",
                float: "right",
              }}
            >
              {" "}
              OK{" "}
            </Button>
          </div>
        </Modal>
        <div className="form-container">
          {basicDetails.approval_status === 5 ||
            (basicDetails.approval_status === null &&
              basicDetails.approval_remarks !== null &&
              basicDetails.approval_remarks !== "" && (
                <div className="row">
                  <p className="remarks-text">
                    {basicDetails.approval_remarks}
                  </p>
                </div>
              ))}
          <div className="row">
            <div className="dualColumnRow" style={{ display: "inline-flex" }}>
              <div className="left">
                PAN Number <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  autoComplete="off"
                  disabled={true}
                  type="text"
                  name="PAN_no"
                  maxLength={20}
                  value={basicDetails.PAN_no}
                  onChange={(e) => handleChange(e)}
                  // onBlur={(e) => handleMouseLeave(e)}
                />
              </div>
              <div
                className="left"
                style={{ width: "90px", marginLeft: "15px" }}
              >
                E-mail <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  name="email"
                  value={basicDetails.email}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className={
                DataRequired?.existingTrader
                  ? "dualColumnRow-existing"
                  : "dualColumnRow"
              }
              style={{ display: "inline-flex" }}
            >
              <div className="left">
                Name of the Entity/Firm{" "}
                <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  className="widthInput"
                  // style={{ width: "600px" }}
                  name="name"
                  value={basicDetails.name_firm}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div
                className="left"
                style={{ width: "90px", marginLeft: "15px" }}
              >
                Your Name <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  name="name"
                  value={basicDetails.name}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>
          {registeredAs === "member" && (
            <>
              <div className="row">
                <div
                  className="dualColumnRow"
                  style={{ display: "inline-flex" }}
                >
                  <div className="left">
                    User Name <label style={{ color: "red" }}>*</label>:
                  </div>
                  <div className="right">
                    <Input
                      disabled={true}
                      className="widthInput"
                      // style={{ width: "600px" }}
                      name="name"
                      value={basicDetails.name}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div
                    className="left"
                    style={{ width: "90px", marginLeft: "15px" }}
                  >
                    {/* Check Availability<label style={{ color: "red" }}>*</label>: */}
                  </div>
                  <div className="right">
                    <Button
                      name="name"
                      value={basicDetails.name}
                      onChange={(e) => handleChange(e)}
                    >
                      {" "}
                      Check Availability{" "}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="dualColumnRow"
                  style={{ display: "inline-flex" }}
                >
                  <div className="left">
                    Password <label style={{ color: "red" }}>*</label>:
                  </div>
                  <div className="right">
                    <Input
                      disabled={true}
                      className="widthInput"
                      // style={{ width: "600px" }}
                      name="name"
                      value={basicDetails.name}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div
                    className="left"
                    style={{ width: "90px", marginLeft: "15px" }}
                  >
                    Confirm Password <label style={{ color: "red" }}>*</label>:
                  </div>
                  <div className="right">
                    <Input
                      disabled={true}
                      name="name"
                      value={basicDetails.name}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="row">
            <div
              className={
                DataRequired?.existingTrader
                  ? "dualColumnRow-existing"
                  : "dualColumnRow"
              }
              style={{ display: "inline-flex" }}
            >
              <div className="left">
                Address <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  maxLength={100}
                  style={{ width: "713px" }}
                  className="widthInput"
                  name="address"
                  defaultValue={basicDetails.address}
                  value={basicDetails.address}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className={
                DataRequired?.existingTrader
                  ? "dualColumnRow-existing"
                  : "dualColumnRow"
              }
              style={{ display: "inline-flex" }}
            >
              <div className="left">
                Location <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  maxLength={25}
                  name="location"
                  value={basicDetails.location}
                  onChange={(e) => handleChange(e)}
                />
              </div>

              <div
                className="left"
                style={{ width: "90px", marginLeft: "15px" }}
              >
                State <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  maxLength={25}
                  name="state"
                  value={basicDetails.state}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className={
                DataRequired?.existingTrader
                  ? "dualColumnRow-existing"
                  : "dualColumnRow"
              }
              style={{ display: "inline-flex" }}
            >
              <div className="left">
                Postal Code <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  maxLength={6}
                  name="postal_code"
                  type="number"
                  value={basicDetails.postal_code}
                  onChange={(e) => handleChange(e)}
                />
              </div>

              <div
                className="left"
                style={{ width: "90px", marginLeft: "15px" }}
              >
                Fax No:
              </div>
              <div className="right">
                <Input
                  disabled={true}
                  name="fax_no"
                  value={basicDetails.fax_no}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>
          {!DataRequired?.existingTrader && (
            <>
              <div className="row">
                <div
                  className="dualColumnRow"
                  style={{ display: "inline-flex" }}
                >
                  {/* <div className="left">
                Branch<label style={{ color: "red" }}>*</label>:
              </div> */}
                  <div
                    className="right select-branch"
                    style={{ minWidth: 510, maxWidth: 690 }}
                  >
                    <MultiSelect
                      options={branchList}
                      onChange={onBranchSelected}
                      placeholder="Select Branch"
                      head="Branch"
                      type="multi-select"
                      returnId
                      value={basicDetails.registered_sap_branch}
                      className="total-width"
                      // value={selectedBranches.branches}
                    />
                    {/* <Select
                  className="branch-select"
                  // transitionName="registered_sap_branch"
                  disabled={true}
                  // showSearch
                   mode="multiple"
                  // defaultValue={defLabelData}
                  showArrow
                  placeholder="Select Branch"
                  onSelect={(e) => onBranchSelected(e)}
                  style={{ width: "260px" }}
                  value={defLabelData}
                >
                  {branchList.map((branch: any, idx) => (
                    <Option
                      key={branch.parameter_data_value}
                      value={branch.parameter_data_value}
                    >
                      {branch.parameter_description}
                    </Option>
                  ))}
                </Select> */}
                  </div>

                  {/* <div
                className="left"
                style={{ width: "100px", display: "block", marginLeft: "15px" }}
              >
                &nbsp;Vendor Code:
              </div> */}

                  {!DataRequired?.existingTrader && (
                    <div className="right vendor-width">
                      <p>Vendor Code</p>
                      <Input
                        disabled={true}
                        name="vendor_code"
                        value={basicDetails.vendor_code}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {DataRequired?.existingTrader && (
            <>
              <div
                className="row"
                style={{ height: 40, marginBlock: 0, marginBottom: 0 }}
              >
                <div
                  className={
                    DataRequired?.existingTrader
                      ? "dualColumnRow-existing"
                      : "dualColumnRow"
                  }
                  style={{ display: "inline-flex" }}
                >
                  <div className="left" style={{ marginBottom: 22 }}>
                    Phone Number <label style={{ color: "red" }}>*</label> :
                  </div>
                  <div className="right">
                    <Input
                      disabled={true}
                      maxLength={10}
                      type="number"
                      required={true}
                      value={basicDetails.contact_number}
                      name="contact_number"
                      // onBlur={() => checkUserExists()}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div
                    className="left"
                    style={{
                      marginLeft: "20px",
                      marginRight: "0px",
                      marginTop: "-15px",
                      width: "170px",
                    }}
                  >
                    {" "}
                    GST Number :
                  </div>
                  <div className="right">
                    <Input
                      autoComplete="off"
                      disabled={true}
                      type="text"
                      maxLength={30}
                      style={{ width: 220, marginLeft: "-78px" }}
                      name="GST_number"
                      value={basicDetails.GST_number}
                      onChange={(e) => handleChange(e)}
                      // onBlur={(e) => handleMouseLeave(e)}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {!DataRequired?.existingTrader && (
            <>
              <div className="row">
                <div
                  className="dualColumnRow"
                  style={{ display: "inline-flex", marginTop: "0px" }}
                >
                  <div
                    className="left"
                    style={{
                      marginTop: "0px",
                      width: "300px",
                      alignSelf: "flex-start",
                    }}
                  >
                    Legal Status of the Party{" "}
                    <label style={{ color: "red" }}>*</label>:
                  </div>
                  <div className="right">
                    <Radio.Group
                      value={Number(basicDetails.legal_status_of_party)}
                      onChange={(e) =>
                        setBasicDetails({
                          ...basicDetails,
                          legal_status_of_party: e.target.value,
                        })
                      }
                      disabled={true}
                    >
                      <Radio value={1}>Limited</Radio>
                      <Radio value={2}>PVT Limited</Radio>
                      <Radio value={3}>PARTNERSHIP</Radio>
                      <Radio value={4}>PROPRIETORSHIP</Radio>
                      <Radio value={5}>INDIVIDUAL</Radio>
                      <Radio value={6}>PSU</Radio>
                      <Radio value={7}>CO-OP SOCIETY</Radio>
                      <Radio value={8}>HUF</Radio>
                      <Radio value={9}>OTHERS</Radio>
                    </Radio.Group>

                    <Input
                      disabled={true}
                      maxLength={30}
                      className="inner-input"
                      name="legal_status_of_party_other"
                      value={basicDetails.legal_status_of_party_other}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      // disabled={
                      //   basicDetails.legal_status_of_party === undefined ||
                      //   !canEnableTextBox("9", basicDetails.legal_status_of_party)
                      // }
                      // disabled={basicDetails.legal_status_of_party === undefined && basicDetails.legal_status_of_party !== 9}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="dualColumnRow"
                  style={{ display: "inline-flex", marginTop: "0px" }}
                >
                  <div
                    className="left"
                    style={{
                      marginTop: "0px",
                      width: "310px",
                      marginRight: 40,
                      alignSelf: "flex-start",
                    }}
                  >
                    Nature of the Supplier{" "}
                    <label style={{ color: "red" }}>*</label> :
                  </div>
                  <div className="right">
                    <Radio.Group
                      value={basicDetails.nature_of_supplier}
                      onChange={(e) =>
                        setBasicDetails({
                          ...basicDetails,
                          nature_of_supplier: e.target.value,
                        })
                      }
                      disabled={true}
                    >
                      <Radio value={1}>FARMER</Radio>
                      <Radio value={2}>COMMODITY SUPPLIER</Radio>
                      <Radio value={3}>SERVICE PROVIDER</Radio>
                      <Radio value={4}>MANUFACTURER</Radio>
                      <Radio value={5}>BROKER</Radio>
                      <Radio value={6}>EMPLOYEE</Radio>
                      <Radio value={7}>CAPITAL ASSESTS</Radio>
                      <Radio value={8}>GOVT BODY</Radio>
                      <Radio value={9}>PROCESSOR</Radio>
                      <Radio value={10}>OTHERS</Radio>
                    </Radio.Group>

                    <Input
                      disabled={true}
                      maxLength={30}
                      className="inner-input"
                      name="nature_of_supplier_other"
                      value={basicDetails.nature_of_supplier_other}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      // disabled={
                      //   basicDetails.nature_of_supplier === undefined ||
                      //   !canEnableTextBox("10", basicDetails.nature_of_supplier)
                      // }
                      // disabled={basicDetails.nature_of_supplier === undefined && basicDetails.nature_of_supplier !== 10}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="dualColumnRow"
                  style={{ display: "inline-flex", marginTop: "0px" }}
                >
                  <div
                    className="left"
                    style={{ marginTop: "0px", width: "fit-content" }}
                  >
                    Whether Party is associated with any other Divisions of ITC{" "}
                    <label style={{ color: "red" }}>*</label>:
                  </div>
                  <div className="right">
                    <Radio.Group
                      value={basicDetails.is_association_with_other_division}
                      onChange={(e) =>
                        setBasicDetails({
                          ...basicDetails,
                          is_association_with_other_division: e.target.value,
                        })
                      }
                      disabled={true}
                    >
                      <Radio value={1}>Yes</Radio>
                      <Radio value={2}>No</Radio>
                    </Radio.Group>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="dualColumnRow"
                  style={{ display: "inline-flex", marginTop: "0px" }}
                >
                  <div
                    className="left"
                    style={{ marginTop: "0px", width: "fit-content" }}
                  >
                    If yes, Pls provide details:
                  </div>
                  <div className="right">
                    <Input
                      disabled={true}
                      maxLength={50}
                      style={{ width: "600px" }}
                      className="inner-form-content-input"
                      name="association_details"
                      value={basicDetails.association_details}
                      onChange={(e) => handleChange(e)}
                      // disabled={
                      //   basicDetails.is_association_with_other_division ===
                      //   undefined ||
                      //   basicDetails.is_association_with_other_division === null ||
                      //   canEnableTextBox(
                      //     "2",
                      //     basicDetails.is_association_with_other_division
                      //   )
                      // }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="dualColumnRow"
                  style={{ display: "inline-flex", marginTop: "0px" }}
                >
                  <div
                    className="left"
                    style={{ marginTop: "0px", width: "fit-content" }}
                  >
                    Whether the Party has relationship with any of the ITC's Key Managerial Personnel (as per Annexure){" "}
                    <label style={{ color: "red" }}>*</label>:
                  </div>
                  <div className="right">
                    <Radio.Group
                      value={
                        basicDetails.is_relationship_with_managerial_personnel
                      }
                      onChange={(e) =>
                        setBasicDetails({
                          ...basicDetails,
                          is_relationship_with_managerial_personnel:
                            e.target.value,
                        })
                      }
                      disabled={true}
                    >
                      <Radio value={1}>Yes</Radio>
                      <Radio value={2}>No</Radio>
                    </Radio.Group>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="dualColumnRow"
                  style={{ display: "inline-flex", marginTop: "0px" }}
                >
                  <div
                    className="left"
                    style={{ marginTop: "0px", width: "fit-content" }}
                  >
                    If yes, Pls provide details:
                  </div>
                  <div className="right">
                    <Input
                      disabled={true}
                      maxLength={50}
                      style={{ width: "600px" }}
                      className="inner-input"
                      name="managerial_personnel_details"
                      value={basicDetails.managerial_personnel_details}
                      onChange={(e) => handleChange(e)}
                      // disabled={
                      //   basicDetails.is_relationship_with_managerial_personnel ===
                      //   undefined ||
                      //   basicDetails.is_relationship_with_managerial_personnel ===
                      //   null ||
                      //   canEnableTextBox(
                      //     "2",
                      //     basicDetails.is_relationship_with_managerial_personnel
                      //   )
                      // }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="dualColumnRow"
                  style={{ display: "inline-flex", marginTop: "0px" }}
                >
                  <div
                    className="left"
                    style={{ marginTop: "0px", width: "fit-content" }}
                  >
                    Whether the Party has been Blacklisted/ Debarred/ Delisted by any Division of ITC in the past{" "}
                    <label style={{ color: "red" }}>*</label>:
                  </div>
                  <div className="right">
                    <Radio.Group
                      value={basicDetails.is_blacklisted_debarred_delisted}
                      onChange={(e) =>
                        setBasicDetails({
                          ...basicDetails,
                          is_blacklisted_debarred_delisted: e.target.value,
                        })
                      }
                      disabled={true}
                    >
                      <Radio value={1}>Yes</Radio>
                      <Radio value={2}>No</Radio>
                    </Radio.Group>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="dualColumnRow"
                  style={{ display: "inline-flex", marginTop: "0px" }}
                >
                  <div
                    className="left"
                    style={{ marginTop: "0px", width: "fit-content" }}
                  >
                    If yes, Pls provide details:
                  </div>
                  <div className="right">
                    <Input
                      disabled={true}
                      maxLength={50}
                      className="inner-input"
                      name="blacklisted_details"
                      value={basicDetails.blacklisted_details}
                      onChange={(e) => handleChange(e)}
                      // disabled={
                      //   basicDetails.is_blacklisted_debarred_delisted ===
                      //   undefined ||
                      //   basicDetails.is_blacklisted_debarred_delisted === null ||
                      //   canEnableTextBox(
                      //     "2",
                      //     basicDetails.is_blacklisted_debarred_delisted
                      //   )
                      // }
                      style={{ alignSelf: "top", marginTop: 0, width: "600px" }}
                    />
                  </div>
                </div>
              </div>{" "}
            </>
          )}
          {DataRequired?.existingTrader && (
            <>
              <div className="row" style={{ marginTop: "20px" }}>
                <div
                  className={
                    DataRequired?.existingTrader
                      ? "dualColumnRow-existing"
                      : "dualColumnRow"
                  }
                  style={{ display: "inline-flex" }}
                >
                  {/* <div className="left">
                Branch<label style={{ color: "red" }}>*</label>:
              </div> */}
                  <div
                    className="right select-branch"
                    style={{ minWidth: 600, display: "flex", gap: "2em" }}
                  >
                    <MultiSelect
                      options={branchList}
                      onChange={onBranchSelected}
                      placeholder="Select Branch(s)"
                      head="Branch(s)"
                      type="multi-select"
                      returnId
                      value={basicDetails.registered_sap_branch}
                      className="total-width grey-color"
                      required
                      // value={selectedBranches.branches}
                    />
                    <MultiSelect
                      options={cropsList}
                      onChange={onBranchSelected}
                      placeholder="Select Crops"
                      head="Crops"
                      type="multi-select"
                      returnId
                      value={basicDetails.crops}
                      className="total-width grey-color"
                      required
                    />
                    <MultiSelect
                      options={branchList}
                      onChange={onBranchSelected}
                      placeholder="Select Primary Branch"
                      head="Primary Branch"
                      type="multi-select"
                      returnId
                      value={basicDetails.primary_branch}
                      className="total-width grey-color"
                      required
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {!DataRequired?.existingTrader && (
            <Button
              className="login-btn"
              type="primary"
              size={"large"}
              style={{
                marginLeft: 670,
                alignSelf: "right",
                padding: 0,
                // maxWidth: "max-content",
                borderRadius: 30,
                backgroundColor: "#20346a",
              }}
              block
              htmlType="submit"
              onClick={() => handleRouteClick(1, "two")}
            >
              {/* {registeredAs !== "member" ? "Save and Continue" : "Submit"} */}
              Next
            </Button>
          )}
        </div>
      </div>
    </Spin>
  );
};

const mapStateToProps = createStructuredSelector({
  registrationData: selectRegisterData,
  selectedCropId: selectSelectedCrop,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setRegistrationData: (registrationDataCopy: any) =>
      dispatch(setRegistrationData(registrationDataCopy)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetails);
