import React, { useEffect, useRef } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import "./services/axios-interceptor";

import PrivateRoute from "./components/private-route/private-route.component";
import "./App.scss";

import LoginPage from "./pages/login/login.page";
import LandingPage from "./pages/landing/landing.page";
import Saudha from "./pages/saudha/saudha.page";
import ApprovalDetails from "./pages/approvalDetails/details.page";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Dispatch } from "redux";
import { message, Modal } from "antd";
import {
  selectAuthData,
  selectIdleModal,
  selectIsLogged,
} from "./redux/user/user.selectors";
import { resetUser, setAuthData } from "./redux/user/user.actions";
import CacheBuster from "./components/CacheBuster/CacheBuster.js";
import { refreshService } from "./services/login-services";
import { User } from "./models";
import triggerSleepClient from "./services/timeout-services";
import { resetCommonReducer } from "./redux/common/common.actions";
import Offline from "./pages/offline/index.page";

message.config({
  maxCount: 1,
});

const App: React.FC = (props: any) => {
  const {
    isLogged,
    authData,
    setAuthValue,
    resetUserData,
    idleModal,
    resetReducer,
  } = props;
  const history = useHistory();
  let timeout = useRef<NodeJS.Timeout>();
  let timer = useRef<NodeJS.Timeout>();
  let refreshCallTimer = useRef<NodeJS.Timeout>();
  let refreshErrorSignoutTimer = useRef<NodeJS.Timeout>();
  let instance = useRef<any>();
  const [modal, contextHolder] = Modal.useModal();
  let minutesToGo: number;

  const refreshTimer = () => {
    clearTimeout(timeout.current as NodeJS.Timeout);
    const callingTime =
      authData.access_token_expiry * 1000 -
      (authData.access_token_expiry * 1000) / 60;
    console.log(callingTime);
    timeout.current = setTimeout(() => {
      getRefreshFn();
    }, callingTime);
  };

  useEffect(() => {
    clearTimeout(timeout.current as NodeJS.Timeout);
    if (isLogged) {
      triggerSleepClient.onTouchStart(history);
      (async () => {
        await getRefreshFn();
        refreshTimer();
      })();
    }
  }, []);

  useEffect(() => {
    if (authData.refresh_token) {
      refreshTimer();
      minutesToGo =
        (authData.refresh_token_expiry - authData.access_token_expiry * 1000) /
        60;
    } else {
      instance?.current?.destroy();
      clearTimeout(timeout.current as NodeJS.Timeout);
      clearInterval(timer.current as NodeJS.Timeout);
      clearInterval(refreshCallTimer.current as NodeJS.Timeout);
    }
  }, [authData.refresh_token]);

  const forcedLogout = () => {
    clearTimeout(timeout.current as NodeJS.Timeout);
    clearInterval(timer.current as NodeJS.Timeout);
    clearInterval(refreshCallTimer.current as NodeJS.Timeout);
    instance.current.destroy();
    localStorage.clear();
    sessionStorage.clear();
    resetUserData();
    resetReducer();
    history.push("/login");
  };

  const getRefreshFn = async () => {
    try {
      const refreshResponse = await refreshService({
        access_token: authData.access_token,
        refresh_token: authData.refresh_token,
      });
      setAuthValue({
        ...authData,
        access_token: refreshResponse.data.data.access_token,
        refresh_token: refreshResponse.data.data.refresh_token,
        access_token_expiry: refreshResponse.data.data.access_token_expiry,
        refresh_token_expiry: refreshResponse.data.data.refresh_token_expiry,
      });
      clearInterval(timer.current as NodeJS.Timeout);
      clearInterval(refreshCallTimer.current as NodeJS.Timeout);
      clearTimeout(refreshErrorSignoutTimer.current as NodeJS.Timeout);
      instance.current && instance.current.destroy();
      !triggerSleepClient.idleLogoutTimer &&
        !triggerSleepClient.idleAlertModalTimer &&
        !triggerSleepClient.titleInterval &&
        triggerSleepClient.onInit(history);
    } catch (error: any) {
      if (!instance.current && !idleModal) {
        triggerSleepClient.stopTimers();
        instance.current = modal.warning({
          title: "Connection Alert",
          content: `There is a problem connecting to the server , Please try to refresh the application.`,
          closable: false,
          okButtonProps: {
            style: { border: 0 },
            danger: true,
            type: "primary",
          },
          keyboard: false,
          mask: true,
          centered: true,
          okText: "Logout",
          onOk: () => forcedLogout(),
        });

        timer.current = setInterval(() => {
          minutesToGo -= 1;
          instance.current.update({
            content: `There is a problem connecting to the server , Please try to refresh the application.`,
          });
        }, 60000);

        refreshCallTimer.current = setInterval(() => {
          (async () => await getRefreshFn())();
        }, 180000);

        refreshErrorSignoutTimer.current = setTimeout(() => {
          forcedLogout();
        }, minutesToGo * 60000);
      }
    }
  };
  return (
    <>
      {contextHolder}
      <Modal
        className="temporal-modal"
        title="Session Alert"
        visible={idleModal}
        closable={false}
        maskClosable={false}
        okText="Continue"
        cancelText="Log Out"
        style={{ textAlign: "center" }}
        maskStyle={{ opacity: "1" }}
        centered
        okButtonProps={{
          style: { backgroundColor: "#20346a", border: 0 },
        }}
        cancelButtonProps={{
          danger: true,
          type: "ghost",
        }}
        onOk={() => {
          (async () => {
            await triggerSleepClient.onTouchStart(history);
            getRefreshFn();
          })();
        }}
        onCancel={() => triggerSleepClient.consentLogoutUser(history)}
      >
        <div style={{ color: "black" }}>
          Your session is about to expire. Do you want to continue session?
        </div>
      </Modal>
      <div
        onMouseMove={() => isLogged && triggerSleepClient.onTouchStart(history)}
      >
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }: any) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              refreshCacheAndReload();
            }
            return (
              <Offline>
                <Switch>
                  <Route
                    exact
                    path="/login"
                    render={() =>
                      isLogged ? <Redirect to="/home" /> : <LoginPage />
                    }
                  />
                  <Route
                    exact
                    path="/sauda"
                    render={() =>
                      isLogged ? <Saudha /> : <Redirect to="/login" />
                    }
                  />
                  <Route
                    exact
                    path="/details"
                    render={() =>
                      isLogged ? <ApprovalDetails /> : <Redirect to="/login" />
                    }
                  />
                  <PrivateRoute exact path="/home" component={LandingPage} />
                  <Redirect exact from="*" to="/login" />
                </Switch>
              </Offline>
            );
          }}
        </CacheBuster>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isLogged: selectIsLogged,
  authData: selectAuthData,
  idleModal: selectIdleModal,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setAuthValue: (authData: User) => dispatch(setAuthData(authData)),
    resetReducer: () => dispatch(resetCommonReducer()),
    resetUserData: () => dispatch(resetUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
