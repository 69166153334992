import React from "react";
import "./custom-filter.styles.scss";
import moment from "moment";

import { Select, DatePicker } from "antd";

const { RangePicker } = DatePicker;

const { Option } = Select;

interface SelectProps {
  className?: string;
  placeholder?: string;
  options?: Array<{ id: number | string; name: string }>;
  onChange: (value: string | number | any, option: any) => void;
  value?: string | number | undefined;
  defaultValue?: string | number | undefined;
  startDate?: string | number | undefined | any;
  endDate?: string | number | undefined | any;
  disabled?: boolean | string | number | undefined | any;
  returnId?: boolean;
  required?: boolean;
  head: string;
  type: string; //'range-picker' or 'single-select'
}

const CustomFilter: React.FC<SelectProps> = (props: SelectProps) => {
  const {
    type,
    className,
    placeholder,
    onChange,
    options,
    value,
    defaultValue,
    startDate,
    endDate,
    disabled,
    returnId,
    head,
    required,
  } = props;
  const propsForSelect = {
    ...(value && { value: value }),
    placeholder: placeholder,
    disabled: disabled,
    defaultValue: defaultValue,
    // onSelect: onChange,
    onChange: onChange,
  };
  return (
    <div
      className={`custom-filter-container ${
        type === "range-picker" && "select-date-container"
      } ${className ? className : ""}`}
    >
      <div className="name">
        {head}
        {required && <label style={{ color: "red" }}>*</label>}
      </div>
      {type === "single-select" && (
        <Select {...propsForSelect}>
          {options &&
            options.map((item) => (
              <Option value={returnId ? item.id : item.name} key={item.id}>
                {item.name}
              </Option>
            ))}
        </Select>
      )}
      {type === "range-picker" && (
        <RangePicker
          allowClear={false}
          onChange={(value, dateString) =>
            onChange && onChange(value, dateString)
          }
          defaultValue={[startDate, endDate]}
          disabledDate={(d) =>
            !d ||
            // d.isBefore(moment().subtract(7, "days")) ||
            d.isSameOrAfter(moment())
          }
          ranges={{
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Past 7 days": [moment().subtract(7, "days"), moment()],
          }}
        />
      )}
      {type === "multi-select" && (
        <Select
          {...propsForSelect}
          mode="multiple"
          showSearch={false}
          showArrow={true}
          onChange={(value) => onChange(value, options)}
        >
          {options &&
            options.map((item) => (
              <Option value={returnId ? item.id : item.name} key={item.id}>
                {item.name}
              </Option>
            ))}
        </Select>
      )}
    </div>
  );
};

export default CustomFilter;
