import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "typeface-roboto";

import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./redux/store";

import "antd/dist/antd.min.css";

if (process.env.NODE_ENV === "production") {
  console.log = () => {
    // console.log
  };
  console.warn = () => {
    // console.warn
  };
  console.error = () => {
    // console.error
  };
}

if (window.top === window.self) {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <HashRouter>
          <App />
        </HashRouter>
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
}
reportWebVitals();
