import { createSelector } from "reselect";

const selectRegistationData = (state: any) => state.registraionReducer;

export const selectRegisterData = createSelector(
  [selectRegistationData],
  (data) => data.registrationData
);

// export const selectIsStatutorySubmitted = createSelector(
//   [selectRegisterData],
//   (data) => data.isStatutoryDetailsSubmitted
// );
