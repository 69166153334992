export const canUserAccess = (user: any, featureCode: string) => {
  return (
    user.locationFeatureArray && user.locationFeatureArray.includes(featureCode)
  );
};

export const setBackgroundBlur = (amount: string) => {
  const root = document.getElementById("root");
  if (root !== null) root.style.filter = `blur(${amount})`;
};

// export const isAdminUser = (user: any, roleId: string) => {
//   return user.roleId.includes(roleId);
// };
