import React from "react";
import "./create-auction-landing.styles.scss";

import { createStructuredSelector } from "reselect";
import {
  selectBypassBD,
  selectSelectedCrop,
} from "../../redux/common/common.selectors";
import { connect } from "react-redux";
import CreateAuctionCopyLanding from "../create-auction-langing/create-auction-langing.page";
// import CreateAuctionBD from "../create-auction-bd/create-auction-bd.page";
import CreateAuction from "../create-auction/create-auction.page";

const CreateAuctionLanding: React.FC = (props: any) => {
  const { isBypassedBD, globalSocketData } = props;
  return isBypassedBD ? (
    <CreateAuction />
  ) : (
    <CreateAuctionCopyLanding {...globalSocketData} />
    // <CreateAuctionBD {...globalSocketData} />
  );
};

const mapStateToProps = createStructuredSelector({
  isBypassedBD: selectBypassBD,
  selectedCropId: selectSelectedCrop,
});

export default connect(mapStateToProps)(CreateAuctionLanding);
