import React from "react";
import "./slot-time.styles.scss";
import { Switch, TimePicker } from "antd";
import moment from "moment";
import { ReactComponent as CloseBtn } from "../../assets/cancel.svg";
const { RangePicker } = TimePicker;

interface SlotTimeProps {
  slotId: number;
  slotNumber: number;
  value?: Array<string>;
  onChange?: (id: number, timeString: any) => void;
  edited?: boolean;
  isPermanent?: boolean;
  onToggle: (id: number) => void;
  active?: boolean;
  isEdit?: boolean;
  activeToggle?: boolean;
  addSlot?: boolean;
}

const SlotTime: React.FC<SlotTimeProps> = (props: SlotTimeProps) => {
  const {
    slotId,
    slotNumber,
    value,
    onChange,
    isEdit,
    active,
    edited,
    isPermanent = true,
    onToggle,
    activeToggle,
    addSlot = false,
  } = props;
  const updatedProps = {
    ...(value &&
      value.length === 2 &&
      value[0] !== "" && {
        defaultValue: [
          moment(value[0], "HH:mm:ss"),
          moment(value[1], "HH:mm:ss"),
        ],
      }),
  };

  //   const deleteSlotProps = {
  //     ...(slot && { onClick: () => deleteSlot(slot) }),
  //   };
  return (
    <div
      className={`single-slot-container ${!isEdit && "disabled"} ${
        edited && "edited"
      }`}
    >
      <div
        className={`active-switch ${!isEdit && "disable"}`}
        // onClick={() => deleteSlot(slot)}
      >
        {/* <CloseBtn /> */}
        {activeToggle && (
          <Switch defaultChecked={active} onChange={() => onToggle(slotId)} />
        )}
      </div>
      <div className="header">Slot {slotNumber}</div>
      <RangePicker
        {...updatedProps}
        onChange={(value, timeString) =>
          onChange && onChange(slotId, timeString)
        }
        format={"hh:mm A"}
        minuteStep={5}
        inputReadOnly={true}
      />
      <div className="temp-div">{!isPermanent && "Changed for today"}</div>
    </div>
  );
};

export default SlotTime;
