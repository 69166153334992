import React, { useEffect, useState } from "react";
import "./home.styles.scss";
import { Checkbox, Select, message, Modal } from "antd";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import { connect, useDispatch } from "react-redux";

import { createStructuredSelector } from "reselect";

import {
  selectCrops,
  selectSelectedCrop,
  selectBypassBD,
  selectBypassEnabled,
} from "../../redux/common/common.selectors";
import { getVarietiesService } from "../../services/common-services";
import { selectUserData } from "../../redux/user/user.selectors";

import {
  setSelectedCrop,
  setBypassBD,
  setVarieties,
  setBypassEnabled,
  setCurrentPage,
} from "../../redux/common/common.actions";
import { Dispatch } from "redux";

import { checkAuctionExist } from "../../services/auction-services";

import { ArrayFormat } from "../../models";
import { FeatureCodes } from "../../constants/data-constants";
import { canUserAccess } from "../../utils/utils";

const { Option } = Select;
const { confirm } = Modal;

const Home: React.FC = (props: any) => {
  const {
    crops,
    selectedCropId,
    bypassBD,
    setVarieties,
    setBypassBD,
    setSelectedCrop,
    setBypassEnabled,
    bypassEnabled,
    user,
  } = props;
  const [canBypass, setCanBypass] = useState(true);
  const dispatch = useDispatch();

  // const waitForCropData = () => {
  //   console.log("length: ", crops.length);
  //   if (crops.length !== 0) {
  //     onChangeCrop(crops[0]?.id);
  //   } else {
  //     setTimeout(() => waitForCropData(), 100);
  //   }
  // };

  // const checkAuction = async (cropId: number, value: boolean) => {
  //   try {
  //     const response = await checkAuctionExist({ crop_id: cropId });
  //     if (response.data.status) {
  //       const auctions = parseInt(response.data.data.auctions);
  //       setBypassEnabled(auctions ? false : true);
  //       onCheckChanged(value);
  //       console.log("Checking: ", response, parseInt(response.data.data.auctions));
  //     } else {
  //       console.log("Error checking BD auction..");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const onCheckChanged = (value: boolean) => {
  //   console.log("ByPass BD Out ---->", bypassBD, bypassEnabled, value);

  //   if (bypassEnabled) {
  //     console.log("ByPass BD IF ---->", bypassEnabled, bypassBD);
  //     // showPromiseConfirm(value);
  //     setBypassBD(value);
  //   }
  //   else {
  //     console.log("ByPass BD ELSE ---->", bypassBD);

  //     if (!bypassBD) showPromiseConfirm(value);
  //     else setBypassBD(value);
  //   }
  // };
  const onCheckChanged = (value: boolean) => {
    if (value) {
      // console.log("ByPass BD IF ---->", bypassEnabled, bypassBD);
      showPromiseConfirm(value);
      // setBypassBD(value);
    } else {
      // console.log("ByPass BD ELSE ---->", bypassBD);
      setBypassBD(value);
      // if (!bypassBD) showPromiseConfirm(value);
      // else setBypassBD(value);
    }
  };

  const showPromiseConfirm = (value: boolean) => {
    confirm({
      title: "Bypass Copy Auction ?",
      icon: <ExclamationCircleOutlined />,
      content:
        // "You have Buying decision data, Are you sure you want to bypass ?",
        "Are you sure you want to bypass ?",
      onOk() {
        setBypassBD(value);
        dispatch(setCurrentPage("2"));
      },
      onCancel() {},
    });
  };

  // // let cropi = 0;
  const onChangeCrop = (id: number) => {
    //   // id != 1 && setBypassBD(true);    // uncomment to bypass the bd without copy auctions as well
    //   // sessionStorage.setItem("cropID", JSON.stringify(id));
    setSelectedCrop(id);
    setBypassBD(false);
    //   // cropi = id;
    //   setVarietiesService(id);
  };
  // const setVarietiesService = async (cropParams) => {
  //   const varietiesResponse = await getVarietiesService({
  //     crop_id: cropParams,
  //   });

  //   const varietyData = varietiesResponse.data.data.map((item: any) => {
  //     return {
  //       id: item.variety_id,
  //       name: item.variety_name,
  //       maxPrice: item.max_price,
  //       minPrice: item.min_price,
  //     };
  //   });
  //   console.log(varietyData);
  //   setVarieties(varietyData);
  // };
  // let cropIDSelected = sessionStorage.getItem("cropID");

  return (
    <div className="home">
      <div className="header">Welcome {user.name}, set your preference</div>
      <div className="contents">
        <div className="preference">
          <div>Select Crop</div>
          <div className="select-container">
            <Select
              value={selectedCropId}
              onSelect={(id: number) => onChangeCrop(id)}
            >
              {crops.map((item: any) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          {/* {selectedCropId === 1 &&  */}
          {/* ( */}
          <Checkbox
            checked={bypassBD}
            onChange={(e) => {
              // checkAuction(selectedCropId, e.target.checked)
              onCheckChanged(e.target.checked);
            }}
            disabled={
              !canUserAccess(user, FeatureCodes.AUCTION_MAKER)
              // || selectedCropId !== 1
            }
          >
            Bypass Copy Auction
          </Checkbox>
          {/* ) */}
          {/**/}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setSelectedCrop: (cropId: number) => dispatch(setSelectedCrop(cropId)),
    setBypassBD: (status: boolean) => dispatch(setBypassBD(status)),
    setBypassEnabled: (status: boolean) => dispatch(setBypassEnabled(status)),
    setVarieties: (varieties: Array<ArrayFormat>) =>
      dispatch(setVarieties(varieties)),
  };
};

const mapStateToProps = createStructuredSelector({
  user: selectUserData,
  crops: selectCrops,
  selectedCropId: selectSelectedCrop,
  bypassBD: selectBypassBD,
  bypassEnabled: selectBypassEnabled,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
