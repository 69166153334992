/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import "./approve-sauda.styles.scss";
import { ReactComponent as CloseIcon } from "../../assets/cancel.svg";
import { ReactComponent as CheckedIcon } from "../../assets/checked.svg";

import moment from "moment";

import { ExclamationCircleOutlined, SyncOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
// import { Dispatch } from "redux";

import { Link } from "react-router-dom";

import Timer from "../../components/timer/timer.component";

import {
  selectBranches,
  selectCrops,
  selectSelectedCrop,
  // selectselectedBranch,
  selectCurrentPage,
  selectViewAuctionPage,
} from "../../redux/common/common.selectors";
// import { setBranch } from "../../redux/common/common.actions";

import {
  getOpenAuction,
  cancelAuction,
  postBulkApprove,
} from "../../services/auction-services";
import { getRebateMatrixService } from "../../services/common-services";

import { Table, message, Modal, Button, Checkbox } from "antd";

import { FeatureCodes, dataConstants } from "../../constants/data-constants";
import { canUserAccess } from "../../utils/utils";

import CustomFilter from "../../components/custom-filter/custom-filter.component";
import {
  selectAuthData,
  selectUserData,
} from "../../redux/user/user.selectors";
import Axios from "axios";
const FileDownLoad = require("js-file-download");

const { confirm } = Modal;

const OpenAuction: React.FC = (props: any) => {
  const {
    branches,
    selectedCrop,
    user,
    crops,
    globalSocketData,
    currentpage,
    viewAuctionPage,
    selectedBranch,
    setBranch,
    // auth
  } = props;
  const [openData, setOpenData] = useState([]);
  const [slotTime, setSlotTime] = useState<Array<any>>([]);
  const [bufferTime, setBufferTime] = useState<string>();
  const [filterData, setFilter] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [localCropID, setLocalCropID] = useState<any>();
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [isProgressClicked, setisProgressClicked] = useState(false);
  const [downloaddata, setdownloaddata] = useState<any>();
  const [isSpinnerOnProgress, setIsSpinnerOnProgress] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [tableData, setTableData] = useState<Array<any>>();
  const [bagFilter, setBagFilter] = useState<Array<any>>();
  let openAuctionsIntervalID = useRef<NodeJS.Timeout>();

  const rebateColumns = [
    {
      title: "Head",
      dataIndex: "head",
      key: "head",
      render(_: any, row: any) {
        return {
          children: row.head,
          props: {
            rowSpan: row.rowSpan,
          },
        };
      },
    },
    {
      title: "Slab",
      dataIndex: "slab",
      key: "slab",
    },
    {
      title: "Rebate %",
      dataIndex: "rate_display",
      key: "rate_display",
    },
  ];

  const columns = [
    {
      title: "ID",
      sorter: (a, b) => a.id - b.id,
      children: [
        {
          dataIndex: "id",
          key: "id2",
          responsive: ["md"],
        },
      ],
    },
    {
      title: "Hub",
      sorter: (a, b) => a.hub.localeCompare(b.hub),
      children: [
        {
          dataIndex: "hub",
          key: "hub2",
          width: 100,
          responsive: ["md"],
        },
      ],
    },
    {
      title: "Division",
      sorter: (a, b) => a.division - b.division,
      children: [
        {
          dataIndex: "division",
          key: "division2",
          responsive: ["md"],
          width: 90,
          render: (value: number) => {
            return (
              <div>{value === 1 ? "ABD" : value === 2 ? "FBD" : "NCDEX"}</div>
            );
          },
          align: "center",
        },
      ],
    },
    {
      title: "Claim",
      children: [
        {
          dataIndex: "claim",
          key: "claim2",
          render: (value: number) => {
            return (
              <div className="claim-container">
                {value === 1 ? <CheckedIcon /> : <CloseIcon />}
              </div>
            );
          },
          responsive: ["md"],
        },
      ],
    },
    {
      title: "Variety",
      sorter: (a, b) => a.variety.localeCompare(b.variety),
      children: [
        {
          dataIndex: "variety",
          key: "variety2",
          responsive: ["md"],
          width: 100,
        },
      ],
    },
    {
      title: "Max Price",
      children: [
        {
          title: "₹/MT",
          dataIndex: "maxPrice",
          key: "maxPrice2",
          responsive: ["md"],
        },
      ],
    },
    {
      title: "Qty Reqd",
      children: [
        {
          title: "MT",
          dataIndex: "qtyReqd",
          key: "qtyReqd2",
          responsive: ["md"],
        },
      ],
    },
    {
      title: "No of Bids",
      sorter: (a, b) => a.noOfBids - b.noOfBids,
      filters: bagFilter,
      onFilter: (value, record) => record.noOfBids.toString().includes(value),
      children: [
        {
          dataIndex: "noOfBids",
          key: "noOfBids2",
          // responsive: ["md"],
          width: 90,
        },
      ],
    },
    {
      title: "Total Bid Qty",
      sorter: (a, b) => a.totalBidQty - b.totalBidQty,
      children: [
        {
          title: "MT",
          dataIndex: "totalBidQty",
          key: "totalBidQty2",
          responsive: ["md"],
        },
      ],
    },
    {
      title: "Min Bid Price",
      children: [
        {
          title: "₹/MT",
          dataIndex: "minBid",
          key: "minBid2",
          responsive: ["md"],
        },
      ],
    },
    {
      title: "Max Bid Price",
      children: [
        {
          title: "₹/MT",
          dataIndex: "maxBid",
          key: "maxBid2",
          responsive: ["md"],
        },
      ],
    },

    {
      title: "Sauda Type",
      children: [
        {
          dataIndex: "saudaType",
          key: "saudaType2",
          render: (value: number) => {
            return <div>{value === 1 ? "Net basis" : "Gross basis"}</div>;
          },
          responsive: ["md"],
        },
      ],
    },
    {
      title: "Role",
      children: [
        {
          dataIndex: "roleType",
          key: "Role",
          responsive: ["md", "lg"],
          width: 70,
          render: (value: number) => {
            return (
              <div style={{ whiteSpace: "break-spaces" }}>
                {value === 1
                  ? "Broker/ Trader"
                  : value === 2
                  ? "FPO"
                  : value === 3
                  ? "Broker/Trader & FPO"
                  : "Not Selected"}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Rebate Name",
      children: [
        {
          dataIndex: "rebateName",
          key: "rebateName2",
          responsive: ["md"],
          width: 180,
          render: (value: number, row: any) => {
            return (
              <div
                className={"rebate-info"}
                style={{ whiteSpace: "break-spaces" }}
              >
                {value}
                {value && (
                  <span
                    className="info"
                    onClick={() => {
                      getRebateMatrix(row.rebateId, selectedBranch);
                    }}
                  >
                    ?
                  </span>
                )}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Sauda Status",
      children: [
        {
          dataIndex: "saudaStatus",
          key: "saudaStatus2",
          responsive: ["md", "lg"],
          width: 80,
          render: (value: number) => {
            return (
              <div
                className={`sauda-status ${
                  value === 1
                    ? "green"
                    : value === 2
                    ? "red"
                    : value === -1
                    ? "blue"
                    : ""
                }`}
                style={{ whiteSpace: "break-spaces" }}
              >
                {value === 1
                  ? "Accepted"
                  : value === 2
                  ? "Rejected"
                  : value === -1
                  ? "Approval Pending"
                  : "Not Processed"}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Action",
      children: [
        {
          dataIndex: "action",
          key: "action2",
          render: (value: boolean, rowData: any) => {
            return (
              <div
                className={`cancel-container ${
                  rowData.saudaStatus !== null ||
                  (!canUserAccess(user, FeatureCodes.AUCTION_CHECKER) &&
                    "disable")
                }`}
                onClick={() => showPromiseConfirm(rowData.key)}
              >
                Cancel
              </div>
            );
          },
          responsive: ["md"],
          width: 80,
        },
      ],
    },
    {
      title: "",
      children: [
        {
          dataIndex: "sauda",
          key: "sauda2",
          render: (value: number, rowData: any) => {
            const data = {
              ...rowData,
              slotEndTime: rowData.endTime,
              archives: false,
            };

            return (
              <Link
                to={{
                  pathname: `/sauda`,
                  state: {
                    datas: {
                      ...data,
                      // ...((!canUserAccess(user, FeatureCodes.AUCTION_CHECKER) || !canUserAccess(user, FeatureCodes.AUCTION_MAKER)) && {
                      archives: false,
                      isBm: user.role_name === "Branch Manager",
                      branch_id: selectedBranch,
                      crop_id: selectedCrop,
                      // }),
                    },
                  },
                }}
              >
                {rowData.saudaStatus === null &&
                canUserAccess(user, FeatureCodes.AUCTION_CHECKER)
                  ? "Enter Sauda"
                  : "View Sauda"}
              </Link>
            );
          },
          responsive: ["md"],
        },
      ],
    },
    /* {
      title: (
        <>
          <div
            style={{
              fontSize: "8px",
              color: "white",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              paddingRight: "13px",
              marginRight: "10px",
            }}
          >
            {user.role_name === "Branch Manager" && (
              <>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Checkbox
                    style={{ margin: "auto" }}
                    checked={selectedData && selectedData.length > 0}
                    disabled={user.role_name !== "Branch Manager"}
                    onChange={(e) => {
                      const modifiedData =
                        openData && openData.length
                          ? openData
                              .map((item: any) => {
                                if (
                                  moment(item.endTime, "HH:mm:ss").isBefore(
                                    moment()
                                  ) &&
                                  item.noOfBids !== 0 &&
                                  item.saudaStatus === -1 &&
                                  (!filterData ||
                                    filterData.length === 0 ||
                                    filterData.includes(
                                      item.noOfBids.toString()
                                    ))
                                ) {
                                  return item.id;
                                }
                              })
                              .filter((item) => item !== undefined)
                          : [];
                      console.log(modifiedData);

                      if (e.target.checked === true) {
                        if (modifiedData.length > 0) {
                          setSelectedData(modifiedData);
                        } else {
                          message.error(
                            "Sauda can only be Approved during Auction Buffer Time..!"
                          );
                          return false;
                        }
                      } else {
                        setSelectedData([]);
                      }
                    }}
                  />
                </div>
              </>
            )}
          </div>
          Select All
        </>
      ),
      key: "action",
      dataIndex: "action",
      render: (value: number, row: any) => {
        return (
          <div className="approval-table-checkbox">
            {user.role_name === "Branch Manager" && (
              <Checkbox
                checked={
                  Array.isArray(selectedData) && selectedData.includes(row.id)
                }
                disabled={row.saudaStatus === null}
                onChange={(e) => {
                  if (moment(row.endTime, "HH:mm:ss").isAfter(moment())) {
                    message.error(
                      "Cannot approve sauda during auction time..!"
                    );
                  } else if (row.noOfBids === 0) {
                    message.error(
                      "Cannot approve this sauda, since there are no bids!"
                    );
                  } else {
                    if (e.target.checked === true) {
                      setSelectedData([...selectedData, row.id]);
                    } else {
                      const temp = selectedData.filter(
                        (item) => item !== row.id
                      );
                      setSelectedData(temp);
                    }
                  }
                }}
              />
            )}
          </div>
        );
      },
    }, */
  ];

  const getRebateMatrix = async (
    rebateId: string,
    branchId: number | undefined
  ) => {
    const params = {
      rebate_id: rebateId,
      branch_id: branchId,
      crop_id: selectedCrop,
    };
    try {
      const response = await getRebateMatrixService(params);
      if (response.data.status) {
        setTableData(response.data.data);
        setModalVisible(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   setLocalCropID(selectedCrop);
  //   sessionStorage.setItem(
  //     "openTMParams",
  //     JSON.stringify({ crop_id: selectedCrop, branch_id: selectedBranch })
  //   );
  // }, [selectedCrop, selectedBranch]);

  useEffect(() => {
    if (currentpage === "3" && viewAuctionPage === "34" && globalSocketData) {
      openAuctionsIntervalID.current &&
        clearInterval(openAuctionsIntervalID.current);
      openAuctionsDataSocketEmition();
      openAuctionsIntervalID.current = setInterval(() => {
        openAuctionsDataSocketEmition();
      }, 10000);
    }
    return () => {
      openAuctionsIntervalID.current &&
        clearInterval(openAuctionsIntervalID.current);
    };
  }, [
    currentpage,
    viewAuctionPage,
    globalSocketData,
    selectedBranch,
    selectedCrop,
  ]);

  // useEffect(() => {
  //   onBranchChange(branches[branchIndex].id, []);
  // }, [branchIndex]);

  /**
   *
   * @function
   * @description Establish socket.io connection for getting auction cards data.
   * @memberof App.MainLayout
   */
  const openAuctionsDataSocketEmition = () => {
    // let socketCheck =
    //   globalSocketData !== undefined ? globalSocketData : passedSocket;
    // let params = sessionStorage.getItem("openTMParams");
    // params = params && JSON.parse(params);

    if (globalSocketData) {
      globalSocketData.emit("tm_active_auction_event", {
        crop_id: selectedCrop,
        branch_id: selectedBranch,
      });
      // setIsLoading(true);
      globalSocketData.on("tm_active_auctions", (data: any) => {
        const modifiedData = data
          .map((item: any) => {
            return {
              key: item.auction_id,
              id: item.auction_id,
              hub: item.hub_name,
              // hubId:item.hub_id,
              hubId: item.hub_id,
              division: item.division,
              claim: item.claim_type,
              variety: item.variety_name,
              varietyId: item.variety_id,
              maxPrice: item.max_price,
              qtyReqd: item.qty_rqd,
              noOfBids: item.no_of_bids === null ? 0 : item.no_of_bids,
              totalBidQty: item.total_bid_qty === null ? 0 : item.total_bid_qty,
              minBid: item.min_bid_price === null ? 0 : item.min_bid_price,
              maxBid: item.max_bid_price === null ? 0 : item.max_bid_price,
              saudaType: item.saudha_type,
              roleType: item.role_mapping,
              saudaStatus: item.saudha_status,
              branchId: selectedBranch,
              rebateName: item.rebate_name,
              rebateId: item.rebate_id,
              bufferTime: item.buffer_time,
              startTime: item.start_time,
              endTime: item.end_time,
            };
          })
          .filter((item) => {
            return item.saudaStatus === -1;
          });
        if (data.length !== 0) {
          setSlotTime([data[0].start_time, data[0].end_time]);
          setBufferTime(data[0].buffer_time);
        }
        let uniqueFilterData: any = [];
        modifiedData.map((mainItem: any) =>
          uniqueFilterData.filter(
            (item) => item["noOfBids"] === mainItem["noOfBids"]
          ).length > 0
            ? null
            : uniqueFilterData.push(mainItem)
        );

        uniqueFilterData = uniqueFilterData
          .map((item) => {
            return {
              text: item["noOfBids"],
              value: item["noOfBids"],
            };
          })
          .sort((a, b) => a.text - b.text);
        setBagFilter(uniqueFilterData);
        setOpenData(modifiedData);
        setIsLoading(false);
      });
    }
  };

  // useEffect(() => {
  //   if (auth && auth.access_token && globalSocketData) {
  //     clearInterval(openAuctionsIntervalID);
  //     openAuctionsDataSocketEmition();
  //     openAuctionsIntervalID = setInterval(() => {
  //       openAuctionsDataSocketEmition();
  //     }, 30000);
  //   }
  // }, [auth, globalSocketData, selectedCrop]);

  // const onBranchChange = (value: string | number | any, option: any) => {
  //   sessionStorage.setItem(
  //     "openTMParams",
  //     JSON.stringify({ crop_id: selectedCrop, branch_id: value })
  //   );
  //   setBranch(value);
  //   // setBranchIndex(branches.findIndex((branch) => branch.id == value));
  //   // getOpenData(value);
  //   openAuctionsDataSocketEmition();

  //   // JSON.stringify(circularReference, getCircularReplacer());
  // };
  // const getCircularReplacer = () => {
  //   const seen = new WeakSet();
  //   return (key, value) => {
  //     if (typeof value === "object" && value !== null) {
  //       if (seen.has(value)) {
  //         return;
  //       }
  //       seen.add(value);
  //     }
  //     return value;
  //   };
  // };

  // const getOpenData = async (branchId: number) => {
  //   const params = {
  //     crop_id: localCropID,
  //     branch_id: branchId,
  //   };
  //   setIsLoading(true);
  //   try {
  //     const response = await getOpenAuction(params);
  //     if (response.data.status) {
  //       const data = response.data.data.map((item: any) => {
  //         return {
  //           key: item.auction_id,
  //           id: item.auction_id,
  //           hub: item.hub_name,
  //           // hubId:item.hub_id,
  //           hubId: item.hub_id,
  //           division: item.division,
  //           claim: item.claim_type,
  //           variety: item.variety_name,
  //           varietyId: item.variety_id,
  //           maxPrice: item.max_price,
  //           qtyReqd: item.qty_rqd,
  //           noOfBids: item.no_of_bids === null ? 0 : item.no_of_bids,
  //           totalBidQty: item.total_bid_qty === null ? 0 : item.total_bid_qty,
  //           minBid: item.min_bid_price === null ? 0 : item.min_bid_price,
  //           maxBid: item.max_bid_price === null ? 0 : item.max_bid_price,
  //           saudaType: item.saudha_type,
  //           roleType: item.role_mapping,
  //           saudaStatus: item.saudha_status,
  //           branchId: branchId,
  //           rebateName: item.rebate_name,
  //           rebateId: item.rebate_id,
  //           bufferTime: item.buffer_time,
  //         };
  //       });
  //       if (data.length !== 0) {
  //         setSlotTime([
  //           response.data.data[0].start_time,
  //           response.data.data[0].end_time,
  //         ]);
  //         setBufferTime(response.data.data[0].buffer_time);
  //       }
  //       setOpenData(data);
  //     } else {
  //       message.error("Something went wrong :(", 1);
  //       setOpenData([]);
  //     }
  //   } catch (error) {
  //     message.error("Something went wrong :(", 1);
  //     setOpenData([]);
  //     setIsLoading(false);
  //     console.log(error);
  //   }
  //   setIsLoading(false);
  // };
  const fileDownload = async () => {
    setIsSpinnerOnProgress(true);
    const body = {
      branch_id: selectedBranch,
      crop_id: selectedCrop,
    };
    Axios({
      url: dataConstants.activeAuctionsExportUrl,
      method: "POST",
      data: body,
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        let progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setdownloaddata(progress);
      },
    })
      .then((response: any) => {
        FileDownLoad(
          response.data,
          `BufferTime_Auction_Report_${new Date().toLocaleDateString()}.xlsx`
        );
        message.success("File has been downloaded successfully!");
        setIsSpinnerOnProgress(false);
        setTimeout(
          function () {
            setisProgressClicked(false);
          }.bind(this),
          5000
        );
      })
      .catch((err: any) => {
        message.error("File cannot be downloaded");
        setIsSpinnerOnProgress(false);
        setisProgressClicked(false);
      });
  };
  const cancelAuctionFn = async (auctionId: number) => {
    const dataToSend = {
      auction_id: auctionId,
    };
    try {
      const response = await cancelAuction(dataToSend);
      if (response.data.status) {
        message.success("Auction cancelled successfully...!");
        openAuctionsDataSocketEmition();
      } else message.warning("Cannot cancel auction...!");
    } catch (error) {
      console.log(error);
    }
  };

  function showPromiseConfirm(auctionId: number) {
    confirm({
      title: "Cancel Auction",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to cancel the auction ?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        cancelAuctionFn(auctionId);
      },
      onCancel() {},
    });
  }

  const createNewArr = (data: any) => {
    if (data)
      return data
        .reduce((result: any, item: any) => {
          //First, take the name field as a new array result
          if (result.indexOf(item.head) < 0) {
            result.push(item.head);
          }
          return result;
        }, [])
        .reduce((result: any, head: any) => {
          //Take the data with the same name as a new array, and add a new field * * rowSpan inside it**
          const children = data.filter((item: any) => item.head === head);
          result = result.concat(
            children.map((item: any, index: any) => ({
              ...item,
              rowSpan: index === 0 ? children.length : 0, //Add the first row of data to the rowSpan field
            }))
          );
          return result;
        }, []);
  };
  const handleChange = (pagination, filters, sorter) => {
    setFilter(filters[7]);
  };
  const onBulkApprove = async (dataToPut: any) => {
    setIsLoading(true);
    dataToPut = dataToPut
      .map((element: any) => {
        if (selectedData.includes(element.id)) return element;
        else return undefined;
      })
      .filter((item: any) => item !== undefined);
    const list = dataToPut.map((eachRow: any) => {
      return eachRow.id;
    });
    if (list !== undefined && list !== null && list !== "" && list.length > 0) {
      const reqBody = {
        auction_ids: list,
      };
      try {
        const response = await postBulkApprove(reqBody);
        if (response.data.status) {
          message.success(response.data.message);
          setSelectedData([]);
          openAuctionsDataSocketEmition();
        } else {
          message.warning("Cannot approve auction...!");
        }
      } catch (error: any) {
        message.warning("Cannot approve auction...!");
      }
    }
    setIsLoading(false);
  };
  return (
    <div className="approve-sauda">
      <Modal
        className="rebate-modal"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={false}
      >
        <div className="rebate-header">Rebate Matrix</div>
        <div className="rebate-container">
          <Table
            columns={rebateColumns}
            // dataSource={tableData}
            dataSource={createNewArr(tableData)}
            pagination={false}
            scroll={{ y: window.innerHeight - 165 }}
          />
        </div>
      </Modal>
      <div className="filter-container">
        <CustomFilter
          type="single-select"
          value={selectedBranch}
          onChange={(value: any) => setBranch(value)}
          options={branches}
          placeholder="Select Branch"
          head="ITC Branch Office"
          returnId
        />
        <div className="selected-wheat">
          <span className="selected-wheat-label">Selected Crop</span> <br />
          <span className="selected-wheat-value">
            <b>
              {crops &&
                crops.length &&
                crops.find(
                  (item: { id: number; name: string }) =>
                    item.id == selectedCrop
                ).name}
            </b>
          </span>
        </div>
        {/* <CustomFilter
          type="single-select"
          value={selectedBranch}
          options={branches}
          onChange={onBranchChange}
          head="Type"
          returnId
        /> */}
        <Button
          className="refresh"
          icon={<SyncOutlined spin={isLoading} />}
          onClick={
            () => openAuctionsDataSocketEmition()
            // getOpenData(selectedBranch)
          }
        >
          Refresh
        </Button>
      </div>

      {openData.length === 0 ? (
        // <div
        //   className="time-data-container"
        //   style={{ justifyContent: "center" }}
        // >
        //   <div>No ongoing Auctions</div>
        <div></div>
      ) : (
        // </div>
        <div className="time-data-container">
          <div>
            Slot timing: {moment(slotTime[0], "HH:mm:ss").format("hh:mm A")} to{" "}
            {moment(slotTime[1], "HH:mm:ss").format("hh:mm A")}
          </div>
          {moment(slotTime[1], "HH:mm:ss").isAfter(moment()) ? (
            <Timer
              time={
                slotTime.length !== 0
                  ? slotTime[1]
                  : moment().format("HH:mm:ss")
              }
            />
          ) : (
            <Timer
              time={moment(slotTime[1], "HH:mm:ss")
                .add(moment.duration(bufferTime).asMinutes(), "minutes")
                .format("HH:mm:ss")}
              buffer
            />
          )}
        </div>
      )}
      <div className="label noteText">
        <b>
          NOTE: Bulk Approval for auctions requires at least one bid and can
          only be processed during buffer time.
        </b>
      </div>
      <div className="archives-table-container">
        <Table
          columns={columns}
          onChange={handleChange}
          dataSource={openData}
          pagination={false}
          scroll={{ y: "44vh" }}
          loading={isLoading}
        />
        {openData.length > 0 && (
          <div className="download-button-container">
            {/*  {(!canUserAccess(user, FeatureCodes.AUCTION_APPROVE_VIEW) ||
              canUserAccess(user, FeatureCodes.AUCTION_CHECKER)) && (
              <Button
                className={
                  canUserAccess(user, FeatureCodes.SAUDA_PROCESS)
                    ? "submit-btn"
                    : "submit-btn disabled"
                }
                onClick={() => {
                  onBulkApprove(openData);
                }}
                size="large"
              >
                Approve
              </Button>
            )} */}
            {!moment(slotTime[1], "HH:mm:ss").isAfter(moment()) && (
              <Button
                onClick={() => {
                  setdownloaddata(0);
                  setisProgressClicked(true);
                  fileDownload();
                }}
                type="primary"
                shape="round"
                size="large"
                className="download-btn-styles"
              >
                <div style={{ marginTop: "-7px" }}>Download</div>
                <div
                  style={{
                    fontSize: "11px",
                    lineHeight: "7px",
                    color: "#b4c8dc",
                  }}
                >
                  .xls report
                </div>
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  branches: selectBranches,
  selectedCrop: selectSelectedCrop,
  crops: selectCrops,
  user: selectUserData,
  auth: selectAuthData,
  currentpage: selectCurrentPage,
  viewAuctionPage: selectViewAuctionPage,
});

// const mapDispatchToProps = (dispatch: Dispatch) => {
//   return {
//   };
// };

export default connect(mapStateToProps)(OpenAuction);
