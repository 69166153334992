import React, { Component } from "react";
import "../admin-tabs/admin-tabs.style.scss";
// import { Doughnut, Bar, Line, Pie } from "react-chartjs-2";
import { Tabs } from "antd";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import UserManagement from "../../pages/user-management/user-management.page";
import { selectUserData } from "../../redux/user/user.selectors";

const { TabPane } = Tabs;

/**
 * @component
 * @description The admin tab parent component.
 * Contains user management, alerts, user groups and news as sub-tabs
 */
class AdminTabs extends Component {
  state = { tabKey: 0 };
  render() {
    return (
      <div className="report-tabs">
        <div className="view-auction-landing">
          <Tabs
            defaultActiveKey={"1"}
            // onChange={(activeKey) => setViewAuctionPage(activeKey)}
          >
            <TabPane
              tab={
                <span className="tabname-container">
                  {" "}
                  {/* <ArchiveImage className="tab-img" />{" "} */}
                  <div className="tab-name">User Management</div>
                </span>
              }
              key="1"
            >
              <UserManagement />
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  //   userData: selectUserData,
});

export default connect(mapStateToProps, null)(AdminTabs);
