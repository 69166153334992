import React, { useState, useEffect } from "react";
import "./timer.styles.scss";

import moment from "moment";

interface ITimer {
  time: string;
  saudha?: boolean;
  buffer?: boolean;
}

const Timer: React.FC<ITimer> = (props: ITimer) => {
  const { time, saudha = false, buffer = false } = props;
  const [remainigTime, setRemainingTime] = useState("");

  const msToTime = (s: number) => {
    const pad = (n: number) => {
      return n < 10 ? "0" + n : n;
    };
    var ms: number = s % 1000;
    s = (s - ms) / 1000;
    var secs: number = s % 60;
    s = (s - secs) / 60;
    var mins: number = s % 60;
    var hrs: number = (s - mins) / 60;

    return pad(hrs) + ":" + pad(mins) + ":" + pad(secs);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment(moment(), "HH:mm:ss", true);
      const then = moment(time, "HH:mm:ss", true);

      if (then.isBefore(now)) {
        clearInterval(interval);
      }
      var duration = moment.duration(then.diff(now));
      var hours = duration.asMilliseconds();
      setRemainingTime(msToTime(hours));
    }, 1000);
    return function cleanup() {
      clearInterval(interval);
    };
  }, [time]);

  return (
    <div>
      {!saudha
        ? `${
            buffer ? "Remaining Buffer Time" : "Remaining Time"
          }: ${remainigTime}`
        : `${remainigTime}`}
    </div>
  );
};

export default Timer;
