import axios from "axios";
import { dataConstants } from "../constants/data-constants";

export const getSupplierData = async (params: any) => {
  return axios.post(dataConstants.getSupplierUrl, params);
};
//Selected hub list for saudha page(Hub comparison)
export const getSelectedHubs = async () => {
  return axios.post(dataConstants.getSelectedHubsUrl);
};
export const postSelectedHubs = async (body: any) => {
  return axios.post(dataConstants.postSelectedHubsUrl, body);
};
export const postSaudha = async (body: any) => {
  return axios.post(dataConstants.processSaudhaUrl, body);
};

export const getHubComparison = async (params: any) => {
  return axios.post(dataConstants.hubComparisonUrl, params);
};
export const getBidComparison = async (params: any) => {
  return axios.post(dataConstants.bidComparisonUrl, params);
};
