import axios from "axios";
import { dataConstants } from "../constants/data-constants";

export const getUpcomingAuction = async (params: any) => {
  return axios.post(dataConstants.upcomingAuctionUrl, params);
};
export const getApproval = async (params: any) => {
  return axios.post(dataConstants.getNewVendorUrl, params);
};
export const postApproval = async (params: any) => {
  return axios.put(dataConstants.approvalData, params);
};
export const getExistingApproval = async (params: any) => {
  return axios.post(dataConstants.getExistingVendorUrl, params);
};
export const putExistingApproval = async (params: any) => {
  return axios.put(dataConstants.existingApprovalData, params);
};
export const getSaudaDetails = async (params: any) => {
  return axios.post(dataConstants.SaudaDetailsUrl, params);
};
export const getBagTypeDetails = async () => {
  return axios.post(dataConstants.getBagTypesUrl);
};
export const getDivisionDetails = async () => {
  return axios.post(dataConstants.getdivisionsUrl);
};
export const getCropYearDetails = async () => {
  return axios.post(dataConstants.getCropYearUrl);
};
export const putSaudaDetails = async (data: any) => {
  return axios.put(dataConstants.SaudaDetailsUrl, data);
};
export const getOpenAuction = async (params: any) => {
  return axios.post(dataConstants.openAuctionUrl, params);
};
export const getArchives = async (params: any) => {
  return axios.post(dataConstants.archivesUrl, params);
};
export const getBuyingDecision = async (params: any) => {
  return axios.post(dataConstants.buyingDecisionUrl, params);
};
export const getCopyAuctionsData = async (params: any) => {
  return axios.post(dataConstants.copyAuctionsDataUrl, params);
};
export const getSlotAuctions = async (params: any) => {
  return axios.post(dataConstants.auctionSlotDetailsUrl, params);
};
export const postAuction = async (data: any) => {
  return axios.post(dataConstants.postAuctionUrl, data);
};
export const postBulkApprove = async (data: any) => {
  return axios.post(dataConstants.postBulkApproveUrl, data);
};
export const checkAuctionExist = async (params: any) => {
  return axios.post(dataConstants.checkAuctionExistsUrl, params);
};
export const getMaterialCodes = async (params: any) => {
  return axios.post(dataConstants.MaterialCodesUrl, params);
};
export const getConditionCodes = async (params: any) => {
  return axios.post(dataConstants.ConditionCodesUrl, params);
};
export const getRefundDetails = async (data: any) => {
  return axios.post(dataConstants.getRefundApprovalUrl, data);
};

export const getRefundHistory = async (params: any) => {
  return axios.post(dataConstants.getRefundHistoryUrl, params);
};

export const putRefundDetails = async (data: any) => {
  return axios.put(dataConstants.putRefundApprovalUrl, data);
};
export const cancelAuction = async (data: any) => {
  return axios.put(dataConstants.cancelAuctionUrl, data);
};
export const editAuction = async (data: any) => {
  return axios.put(dataConstants.editAuctionUrl, data);
};

export const getAuctionReports = async (data: any) => {
  return axios.post(dataConstants.auctionReportsUrl, data);
};
export const getBidReportsUrl = async (data: any) => {
  return axios.post(dataConstants.bidReportsUrl, data);
};
export const getVendorReports = async (data: any) => {
  return axios.post(dataConstants.vendorReportsUrl, data);
};
export const getVendorsList = async (data: any) => {
  return axios.post(dataConstants.vendorsListUrl, data);
};
export const checkVendorAvailability = async (params: any) => {
  return axios.post(dataConstants.checkVendorAvailabilityUrl, params);
};
export const getUsersList = async (data: any) => {
  return axios.post(dataConstants.usersListUrl, data);
};
export const putUserStatus = async (data: any) => {
  return axios.put(dataConstants.putUserStatusUrl, data);
};
export const approveAuction = async (params: any) => {
  return axios.put(dataConstants.approveAuctionUrl, params);
};
export const putApproveSauda = async (params: any) => {
  return axios.put(dataConstants.approveSaudaUrl, params);
};
export const generateSaudaUrl = async (data: any) => {
  return axios.post(dataConstants.saudaDownloadUrl, data);
};
