import { UserActionTypes } from "./user.types";

export const setUserLoggedInStatus = (status: boolean): any => {
  return {
    type: UserActionTypes.SET_LOGGED_IN_STATUS,
    payload: status,
  };
};

export const setUserData = (userData: any): any => {
  return {
    type: UserActionTypes.SET_USER_DATA,
    payload: userData,
  };
};

export const setAuthData = (authData: any): any => {
  return {
    type: UserActionTypes.SET_AUTH_DATA,
    payload: authData,
  };
};

export const setIdleModal = (status: boolean): any => {
  return {
    type: UserActionTypes.SET_IDLE_MODAL,
    payload: status,
  };
};

export const resetUser = (): any => {
  return {
    type: UserActionTypes.RESET_USER,
  };
};
