import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";

// import { logger } from "redux-logger";

import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "./root-reducer";

const middlewares: any = [];

// middlewares.push(logger);

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export const persistor = persistStore(store);
