import React, { useEffect, useState } from "react";
import { Result, Space } from "antd";
import { useSelector } from "react-redux";
import triggerSleepClient from "../../services/timeout-services";
import { useHistory } from "react-router";

const Offline: React.FC<any> = (props) => {
  // Online state
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const isLoggedIn: boolean = useSelector((state: any) => state.user.isLogged);
  const history = useHistory();

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      !navigator.onLine &&
        isLoggedIn &&
        triggerSleepClient.logoutUser(history, false);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isLoggedIn]);

  return (
    <>
      {isOnline ? (
        <>{props.children}</>
      ) : (
        <Space
          style={{ display: "flex", height: "100vh", justifyContent: "center" }}
        >
          <Result
            status="500"
            title="No Internet Connection"
            subTitle="Please check your internet connection and try again."
          />
        </Space>
      )}
    </>
  );
};

export default Offline;
