import { createSelector } from "reselect";
import { User } from "../../models";

const selectUser = (state: any): User => state.user;

export const selectIsLogged = createSelector(
  [selectUser],
  (user) => user.isLogged
);

export const selectAuthData = createSelector(
  [selectUser],
  (user) => user.authData
);

export const selectUserData = createSelector(
  [selectUser],
  (user) => user.userData
);

export const selectIdleModal = createSelector(
  [selectUser],
  (user) => user.idleModal
);
