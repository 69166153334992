import React, { useState } from "react";
import "./profile-popover.styles.scss";

import { connect } from "react-redux";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";

import { UserOutlined, LockOutlined, LoginOutlined } from "@ant-design/icons";

import {
  setUserLoggedInStatus,
  setIdleModal,
  resetUser,
} from "../../redux/user/user.actions";
import { selectUserData } from "../../redux/user/user.selectors";

import { logoutService, changePwService } from "../../services/login-services";

import { message, Form, Input, Button, Avatar } from "antd";
import Modal from "antd/lib/modal/Modal";
import { resetCommonReducer } from "../../redux/common/common.actions";
import triggerSleepClient from "../../services/timeout-services";

const ProfilePopover: React.FC<any> = (props: any) => {
  const { userData, resetUserData, resetReducer } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const logoutFn = async () => {
    try {
      const logoutResponse = await logoutService();
      if (logoutResponse.data.status) {
        // sessionStorage.removeItem("cropID");
        // sessionStorage.removeItem("createAuctionBDTMParams");
        // sessionStorage.removeItem("archiveTMParams");
        // sessionStorage.removeItem("openTMParams");
        // sessionStorage.removeItem("upcomingTMParams");
        // sessionStorage.removeItem("refundApprovalTMParams");
        // sessionStorage.removeItem("registrationApprovalTMParams");
        sessionStorage.clear();
        localStorage.clear();
        resetUserData();
        resetReducer();
        triggerSleepClient.stopTimers();
      } else {
        message.warning("Cannot logout..!");
        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
      message.warning("Cannot logout..!");
      //   setUserLoggedInStatus(false);
      //   setUserData({});
      // window.location.reload();
    }
  };

  const onFinish = async (values: {
    old_password: string;
    new_password: string;
    confirm_password: string;
  }) => {
    try {
      const dataToSend = {
        user_name: userData.username,
        old_password: values.old_password,
        new_password: values.new_password,
      };
      setLoading(true);

      const response = await changePwService(dataToSend);

      if (response.status === 200) {
        setModalVisible(false);
        form.resetFields();
        message.success("Password changed successfully..!");
        await logoutFn();
      } else {
        message.error("Cannot reset password..!");
      }
    } catch (error: any) {
      if (error.response?.data?.msg) {
        message.error(error.response?.data?.msg + " !!!");
      } else {
        message.error("Something went wrong...Please try again !!!");
      }
      // message.error(error.response.data.message);
    }
    setLoading(false);
  };
  const onchange = async (e: any) => {
    if (
      e.target.value &&
      e.target.id.split("change_pw_")[1] === "old_password"
    ) {
      form.setFieldsValue({ old_password: e.target.value.replace(/\s/g, "") });
    }
    if (
      e.target.value &&
      e.target.id.split("change_pw_")[1] === "new_password"
    ) {
      form.setFieldsValue({ new_password: e.target.value.replace(/\s/g, "") });
    }
    if (
      e.target.value &&
      e.target.id.split("change_pw_")[1] === "confirm_password"
    ) {
      form.setFieldsValue({
        confirm_password: e.target.value.replace(/\s/g, ""),
      });
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="popover-container">
      <Modal
        visible={modalVisible}
        footer={false}
        onCancel={() => {
          form.resetFields();
          setModalVisible(false);
        }}
        maskClosable={false}
        className="forgot-pw-modal"
      >
        <div className="reset-pw">
          <div className="header">Change Password</div>
          <div className="login-form">
            <Form
              form={form}
              name="change_pw"
              initialValues={{ remember: false }}
              size="large"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onChange={onchange}
            >
              <Form.Item
                name="old_password"
                rules={[
                  {
                    required: true,
                    message: "Please input password !",
                  },
                ]}
                label="Current Password"
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  //   type="password"
                  placeholder={"Current Password"}
                />
              </Form.Item>
              <Form.Item
                name="new_password"
                dependencies={["old_password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please input password !",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("old_password") !== value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The new password should not be same as current password !"
                      );
                    },
                  }),
                ]}
                label="New Password"
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  //   type="password"
                  placeholder={"New Password"}
                />
              </Form.Item>
              <Form.Item
                name="confirm_password"
                dependencies={["new_password"]}
                hasFeedback
                label="Confirm Password"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password !",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Confirm password does not match with new password !"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  //   type="password"
                  placeholder={"Confirm Password"}
                />
              </Form.Item>

              <Form.Item>
                <div className="reset-btn-container">
                  <Button
                    loading={loading}
                    type="primary"
                    size={"large"}
                    block
                    htmlType="submit"
                  >
                    Reset Password
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>

      <div className="details">
        <Avatar
          size={64}
          icon={<UserOutlined />}
          style={{ verticalAlign: "middle" }}
        >
          {/* {userData.name[0]} */}
        </Avatar>
        <div className="userName">{userData.name}</div>
        <div className="role">{userData.role_name}</div>
        {/* <div className="userName">{userData.name}</div> */}
      </div>
      <div className="bottom-container">
        <div
          className="change-pw-container"
          onClick={() => setModalVisible(true)}
        >
          {<LockOutlined />}Change password
        </div>
        <div className="logout-button" onClick={logoutFn}>
          Logout{<LoginOutlined />}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    resetReducer: () => dispatch(resetCommonReducer()),
    resetUserData: () => dispatch(resetUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePopover);
