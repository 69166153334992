/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from "react";
import "./saudha.styles.scss";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { ReactComponent as CropIcon } from "../../assets/wheat.svg";
import { ReactComponent as VarietyIcon } from "../../assets/plant.svg";
import { ReactComponent as HubIcon } from "../../assets/warehouse.svg";
import { ReactComponent as TimerIcon } from "../../assets/timer.svg";
import { setOpenAuctionBranch } from "../../redux/common/common.actions";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import { SyncOutlined } from "@ant-design/icons";
import {
  Button,
  Table,
  Progress,
  Slider,
  Select,
  message,
  Spin,
  Modal,
  Tabs,
  Input,
} from "antd";

import Timer from "../../components/timer/timer.component";
import moment from "moment";
import socketIOClient from "socket.io-client";

import {
  getSupplierData,
  getHubComparison,
  getBidComparison,
  postSaudha,
} from "../../services/saudha-services";

import {
  getRebateMatrixService,
  getHubsService,
} from "../../services/common-services";

import {
  getSelectedHubs,
  postSelectedHubs,
} from "../../services/saudha-services";

import { useHistory, useLocation } from "react-router-dom";

import {
  selectSelectedCrop,
  selectCrops,
  selectOpenAuctionBranch,
  selectHubs,
  selectMinQty,
  selectVarieties,
} from "../../redux/common/common.selectors";
import {
  selectAuthData,
  selectUserData,
} from "../../redux/user/user.selectors";
import { canUserAccess } from "../../utils/utils";
import { FeatureCodes } from "../../constants/data-constants";
import { dataConstants } from "../../constants/data-constants";
import { getSysParams } from "../../services/sys-params-services";
import { table } from "console";
import { AxiosResponse } from "axios";

const { Option } = Select;
const { confirm } = Modal;
const { TabPane } = Tabs;

const rebateColumns = [
  {
    title: "Head",
    dataIndex: "head",
    key: "head",
    render(_: any, row: any) {
      return {
        children: row.head,
        props: {
          rowSpan: row.rowSpan,
        },
      };
    },
  },
  {
    title: "Slab",
    dataIndex: "slab",
    key: "slab",
  },
  {
    title: "Rebate %",
    dataIndex: "rate_display",
    key: "rate_display",
  },
];

// const columns = [
//   {
//     title: "Supplier Name",
//     dataIndex: "supplierName",
//     key: "supplierName",
//   },
//   {
//     title: "Bid ID",
//     dataIndex: "bidId",
//     key: "bidId",
//     width: 150,
//   },
//   {
//     title: "Quantity (MT)",
//     dataIndex: "quantity",
//     key: "quantity",
//   },
//   {
//     title: "Price (₹/MT)",
//     dataIndex: "price",
//     key: "price",
//   },
//   {
//     title: "System Allotted Qty (MT)",
//     dataIndex: "system_alloted_qty ",
//     key: "system_alloted_qty ",
//   },
//   {
//     title: "Allotted Qty (MT)",
//     dataIndex: "alottedQty",
//     key: "alottedQty",
//   },
// ];

// const supplierData=[]

// for()

const Saudha: React.FC = (props: any) => {
  const history = useHistory();
  const location: { state: { datas: any } } = useLocation();
  const dataObject = location?.state?.datas;
  const { selectedCrop, crops, userData, auth, minQuantity, varieties } = props;
  const [cropName, setCropName] = useState("");
  const [urlData, setUrlData] = useState<any>({});
  const [hubId, setHubId] = useState<any>([]);
  const [varieId, setVarietyId] = useState<any>([]);
  const [supplierInfo, setSupplierInfo] = useState<any>({});
  const [tableData, setTableData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingHub, setIsLoadingHub] = useState(false);
  const [isLoadingBid, setIsLoadingBid] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedHubs, setSelectedHubs] = useState<Array<number>>([]);
  const [compareHubs, setCompareHubs] = useState<Array<any>>([]);
  const [compareBids, setCompareBids] = useState<Array<any>>([]);
  const [hubs, setHubs] = useState<any>([]);
  // const [sumOfAllocated, setSumOfAllocated] = useState<any>();
  const [saudaStatus, setSaudaStatus] = useState<string>();
  const [totalQty, setTotalQty] = useState<any>();
  const [avgAllotedQty, setAvgAllotedQty] = useState<any>();
  const [avgBidQty, setAvgBidQty] = useState<any>();
  const [acceptedQty, setAcceptedQty] = useState<Number>();
  const [rebateModalVisible, setRebateModalVisible] = useState(false);
  const [rebateTableData, setRebateTableData] = useState<Array<any>>();
  // const [globalSocketData, setGlobalSocket] = useState<any>();
  // const [parametersData, setParametersData] = useState<any>();
  const [cancelledIndex, setCancelledIndex] = useState<Array<number>>([]);
  const [totalBidQty, setTotalBidQty] = useState<number>(0);
  const [enableRefresh, setEnableRefresh] = useState<boolean>(true);
  const [timeData, setTimeData] = useState<any>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isMasking, setIsMasking] = useState<boolean>(false);
  const [isBufferTime, setIsBufferTime] = useState<boolean>(false);
  // let saudhaSuppliersAuctionsIntervalID;
  let timeout = useRef<NodeJS.Timeout>();
  let buffertimeout = useRef<NodeJS.Timeout>();
  let isArchive = false;

  useEffect(() => {
    if (dataObject === null || crops.length === 0 || dataObject === undefined) {
      history.replace("/home");
    } else {
      isArchive = dataObject.archives;
      setUrlData(dataObject);
      setCropName(crops.find((item: any) => item.id === selectedCrop).name);
      //setSupplierData(dataObject.key);
      //
      // const socketURL = "http://abdsourcingwfe.southindia.cloudapp.azure.com:8085/auctions";
      // const socket = socketIOClient(dataConstants.socketURL, {
      //   transports: ["websocket"],
      //   query: {
      //     Authorization: auth.access_token,
      //   },
      // });
      // socket.on("connect", () => {
      //   if (auth && auth.access_token && socket.connected) {
      //     setGlobalSocket(socket);
      //   }
      // });
      // getParamsFnWithOutLoad(dataObject.branch_id);
      setUrlData(dataObject);
      setSupplierData(dataObject.key, dataObject.archives);
      clearTimeout(timeout.current as NodeJS.Timeout);
      clearTimeout(buffertimeout.current as NodeJS.Timeout);
      if (dataObject && !isArchive) {
        const timeStamp = moment(
          moment(dataObject.slotEndTime, "HH:mm:ss").valueOf() -
            moment().valueOf()
        ).unix();
        console.log("timeStamp", timeStamp);
        if (timeStamp > 0) {
          console.log(moment(dataObject.slotEndTime, "HH:mm:ss"));
          setIsDisabled(true);
          setIsMasking(true);
          setTimeData(
            <DisplayItem
              title="Time Left"
              subtitle={
                dataObject !== undefined
                  ? dataObject.slotEndTime
                  : moment().format("HH:mm:ss")
              }
              Icon={TimerIcon}
              timer
            />
          );
          timeout.current = setTimeout(async () => {
            setIsMasking(false);
            const bufferTimeStamp = moment(
              moment(
                `${moment(dataObject.slotEndTime, "HH:mm:ss")
                  .add(
                    moment.duration(dataObject.bufferTime).asMinutes(),
                    "minutes"
                  )
                  .format("HH:mm:ss")}`,
                "HH:mm:ss"
              ).valueOf() - moment().valueOf()
            ).unix();
            setIsDisabled(false);
            setTimeData(
              <DisplayItem
                title="Buffer Time Left"
                subtitle={
                  dataObject !== undefined
                    ? moment(dataObject.slotEndTime, "HH:mm:ss")
                        .add(
                          moment.duration(dataObject.bufferTime).asMinutes(),
                          "minutes"
                        )
                        .format("HH:mm:ss")
                    : moment().format("HH:mm:ss")
                }
                Icon={TimerIcon}
                timer
              />
            );
            buffertimeout.current = setTimeout(() => {
              setIsBufferTime(true);
            }, bufferTimeStamp * 1000);
          }, timeStamp * 1000);
        } else {
          const bufferTimeStamp = moment(
            moment(
              `${moment(dataObject.slotEndTime, "HH:mm:ss")
                .add(
                  moment.duration(dataObject.bufferTime).asMinutes(),
                  "minutes"
                )
                .format("HH:mm:ss")}`,
              "HH:mm:ss"
            ).valueOf() - moment().valueOf()
          ).unix();
          buffertimeout.current = setTimeout(() => {
            setIsBufferTime(true);
          }, bufferTimeStamp * 1000);
          setTimeData(
            <DisplayItem
              title="Buffer Time Left"
              subtitle={
                dataObject !== undefined
                  ? moment(dataObject.slotEndTime, "HH:mm:ss")
                      .add(
                        moment.duration(dataObject.bufferTime).asMinutes(),
                        "minutes"
                      )
                      .format("HH:mm:ss")
                  : moment().format("HH:mm:ss")
              }
              Icon={TimerIcon}
              timer
            />
          );
        }
      }
      (async () => {
        const hubId: any = await getHubsList();
        getSelectedHubList();
        const getVarietyId = varieties.filter(
          (variety) => variety.name === dataObject.variety
        )[0].id;
        setVarietyId(getVarietyId);
        getHubComparisonFn(getVarietyId);
        getBidComparisonFn(getVarietyId, hubId);
      })();
    }
    return () => {
      clearTimeout(timeout.current as NodeJS.Timeout);
      clearTimeout(buffertimeout.current as NodeJS.Timeout);
    };
  }, []);

  /**
   *
   * @function
   * @description Establish socket.io connection for getting auction cards data.
   * @memberof App.MainLayout
   */
  // const saudhaSuppliersDataSocketEmition = (passedSocket) => {
  //   let socketCheck =
  //     globalSocketData !== undefined ? globalSocketData : passedSocket;
  //   // let params = sessionStorage.getItem("openTMParams");
  //   let params = { auction_id: dataObject.key };

  //   if (socketCheck) {
  //     if (params) {
  //       socketCheck.emit("tm_view_saudha_event", params);
  //     }

  //     socketCheck.on("tm_sauda_bids", (data: any) => {
  //       setSaudaStatus(
  //         data.saudha_status === 1
  //           ? "Accepted"
  //           : data.saudha_status === 2
  //           ? "Rejected"
  //           : "Not Processed"
  //       );
  //       var totalAlloted = 0;
  //       const modifiedData = data.saudhas.map((item: any, idx: number) => {
  //         totalAlloted += item.alloted_qty;
  //         return {
  //           key: idx,
  //           bidId: item.bid_id,
  //           supplierName: item.user_name,
  //           quantity: item.qty,
  //           price: item.price,
  //           saudhaId: item.saudha_id,
  //           tempAlottedQty: item.alloted_qty,
  //           alottedQty: item.alloted_qty,
  //           systemAllottedQty: item.system_allotted_qty,
  //           vendorCode: item.vendor_code,
  //           isEdit: false,
  //         };
  //       });
  //       // setSumOfAllocated(
  //       //   response.data.data.reduce(
  //       //     (sum: number, { qty_fullfilled }: { qty_fullfilled: number }) =>
  //       //       sum + qty_fullfilled,
  //       //     0
  //       //   )
  //       // );
  //       setTableData(modifiedData);
  //       setSupplierInfo({ ...data, alloted_total_qty: totalAlloted });
  //       setTotalBidQty(data.total_bid_qty);
  //       setIsLoading(false);
  //     });
  //   }
  // };

  // const getParamsFnWithOutLoad = async (branchId?: number) => {
  //   try {
  //     console.log(" branch ", dataObject.branch_id, branchId);
  //     const branchResponse = await getSysParams({
  //       branch_id: branchId,
  //       crop_id: selectedCrop,
  //     });
  //     let valueCheck = branchResponse.data.data.filter(
  //       (item) => item.parameter_type === "minimum_quantity"
  //     );
  //     setParametersData(valueCheck[0].parameter_data_value);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   if (userData && userData.access_token && globalSocketData) {
  //     saudhaSuppliersDataSocketEmition(globalSocketData);
  //     saudhaSuppliersAuctionsIntervalID = setInterval(() => {
  //       saudhaSuppliersDataSocketEmition(globalSocketData);
  //     }, 10000);
  //   }
  // }, [userData, globalSocketData]);

  //get methods
  const setSupplierData = async (auctionId: number, archive: any) => {
    setIsLoading(true);
    try {
      let response: AxiosResponse<any>;
      if (archive) {
        response = await getSupplierData({
          auction_id: auctionId,
        });
      } else {
        response = await getSupplierData({
          auction_id: auctionId,
          is_active_auction: true,
        });
      }

      if (response.data.status) {
        const data = response.data.data;
        setSaudaStatus(
          data.saudha_status === 1
            ? "Accepted"
            : data.saudha_status === 2
            ? "Rejected"
            : data.saudha_status === -1
            ? "Waiting"
            : "Not Processed"
        );

        var totalAlloted = 0;
        var acceptedTotal = 0;
        var totalBid = 0;
        var totalBidQty = data.total_bid_qty;
        let isEditableDone = false;
        const dd = data.saudhas.map((item: any, idx: number) => {
          totalAlloted += item.alloted_qty;
          acceptedTotal += item.system_allotted_qty;
          totalBid += item.qty;
          let isEdit =
            canUserAccess(userData, FeatureCodes.AUCTION_CHECKER) &&
            (item.alloted_qty === 0 ||
              idx === data.saudhas.length - 1 ||
              item.alloted_qty === "0" ||
              item.alloted_qty === null ||
              item.alloted_qty === "" ||
              item.alloted_qty === undefined ||
              item.system_allotted_qty < item.qty)
              ? true
              : false;

          if (!isEditableDone) {
            isEditableDone = isEdit;
          } else {
            isEdit = false;
          }
          return {
            key: idx,
            bidId: item.bid_id,
            supplierName: item.user_name,
            quantity: item.qty,
            price: item.price,
            tempAlottedQty: item.alloted_qty,
            alottedQty: item.alloted_qty ? item.alloted_qty : 0,
            systemAllottedQty: item.system_allotted_qty
              ? item.system_allotted_qty
              : 0,
            vendorCode: item.vendor_code,
            brokerRemark: item.broker_bid_remark ? item.broker_bid_remark : "",
            saudhaId: item.saudha_id,
            isEdit: isEdit,
            isSystemAlloted: item.system_allotted_qty ? true : false,
            is_cancelled: item.is_cancelled,
          };
        });

        setTotalQty(totalAlloted);
        setAcceptedQty(acceptedTotal);
        setSupplierInfo({
          ...data,
          alloted_total_qty: totalAlloted,
          total_bid_qty: totalBidQty,
        });
        setTotalBidQty(totalBidQty);

        /***************** */
        var avgAllotedQty = 0;
        var addQty = 0;
        var avgbidQty = 0;
        const dd1 = dd.map((item: any, idx: number) => {
          avgAllotedQty += Number(item.alottedQty) * Number(item.price);
          avgbidQty += Number(item.quantity) * Number(item.price);
          addQty += Number(item.alottedQty);
        });
        if (avgAllotedQty !== 0) {
          avgAllotedQty = Math.round(Number(avgAllotedQty) / Number(addQty));
        }
        // else{ avgAllotedQty = 0;}

        setAvgAllotedQty(avgAllotedQty);
        //***************** */
        if (avgbidQty !== 0) {
          avgbidQty = Math.round(Number(avgbidQty) / Number(totalBid));
        }

        setAvgBidQty(avgbidQty);
        // setSumOfAllocated(
        //   response.data.data.reduce(
        //     (sum: number, { qty_fullfilled }: { qty_fullfilled: number }) =>
        //       sum + qty_fullfilled,
        //     0
        //   )
        // );

        setAvgBidQty(avgbidQty);
        setTableData(dd);
      } else {
        console.log("Error getting supplier list...");
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const getHubsList = async () => {
    try {
      const response = await getHubsService({ branch_id: 0 });
      if (response.data.status) {
        const data = response.data.data.map((item: any) => {
          return {
            id: item.territory_id,
            name: item.territory_name,
          };
        });
        setHubs(data);
        const getHubId = data.filter((hub) => hub.name === dataObject.hub)[0]
          .id;
        setHubId(getHubId);
        return getHubId;
        //TODO: call funtion to get selected hubs
      } else {
        console.log("Error getting hublist...");
      }
      getSelectedHubList();
    } catch (error) {
      console.log(error);
    }
  };

  const getSelectedHubList = async () => {
    try {
      const response = await getSelectedHubs();
      if (response.data.status) {
        setSelectedHubs(response.data.data.user_hub);
        // setSelectedHubs([]);
        //TODO: set selected hubs in state
      } else {
        console.log("Error getting selected hubs");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getHubComparisonFn = async (varietyId: number) => {
    try {
      setIsLoadingHub(true);
      const params = {
        variety_id: varietyId,
        crop_id: selectedCrop,
      };
      const response = await getHubComparison(params);
      setCompareHubs(response.data.data);
      setIsLoadingHub(false);
    } catch (error: any) {
      console.log(error);
      message.error(
        error.response.data.message ||
          "Something went wrong, please try again later"
      );
      setIsLoadingHub(false);
    }
  };
  const getBidComparisonFn = async (varietyId: number, hubId: number) => {
    try {
      setIsLoadingBid(true);
      const params = {
        variety_id: varietyId,
        crop_id: selectedCrop,
        hub_id: hubId,
      };
      const response = await getBidComparison(params);
      setCompareBids(response.data.data);
      setIsLoadingBid(false);
    } catch (error: any) {
      console.log(error);
      message.error(
        error.response.data.message ||
          "Something went wrong, please try again later"
      );
      setIsLoadingBid(false);
    }
  };

  //post methods
  const onAddHubs = async () => {
    const data = {
      hub_list: selectedHubs,
      crop_id: selectedCrop,
    };
    try {
      const response = await postSelectedHubs(data);
      console.log("hub response ", response, selectedHubs);
      if (response.data.status) {
        message.success(
          `${
            selectedHubs.length === 0
              ? "Removed all hubs..!"
              : "Updated hub list successfully..!"
          }`,
          1
        );
        getHubComparisonFn(urlData.varietyId);
      } else {
        message.error("Error adding hubs..!", 1);
      }
    } catch (error) {
      console.log(error);
      message.error("Error adding hubs..!", 1);
      setModalVisible(false);
    }
    setModalVisible(false);
  };

  const submitSaudha = async (status: boolean) => {
    setIsLoading(true);
    setIsPosting(true);
    // let tempQty = 0;
    let cancelled = false;
    let approvalRequired = false;
    const saudha = tableData.map((item: any) => {
      if (
        userData.role_name !== "Branch Manager" &&
        item?.is_cancelled
        // (item.isSystemAlloted || supplierInfo.qty_rqd > tempQty)
      ) {
        cancelled = true;
      }
      if (
        userData.role_name !== "Branch Manager" &&
        cancelled &&
        !item?.is_cancelled &&
        item.tempAlottedQty > 0
      ) {
        approvalRequired = true;
      }
      // tempQty += item.quantity;
      return {
        saudha_id: item.saudhaId,
        system_allotted_qty: item.systemAllottedQty,
        allotted_qty: Number(item.tempAlottedQty),
        is_cancelled: item?.is_cancelled || false,
      };
    });
    const data = {
      accepted: status,
      no_of_bids: supplierInfo.no_of_bids,
      total_bid_qty: totalQty,
      auction_id: urlData.key,
      saudha_details: saudha,
      approval_required: approvalRequired,
    };
    try {
      const response = await postSaudha(data);
      if (response.data.status) {
        message.success(
          status
            ? approvalRequired
              ? "Sent for BM approval..!"
              : "Accepted sauda..!"
            : "Rejected sauda..!"
        );
        await setSupplierData(dataObject.key, dataObject.archives);
        // saudhaSuppliersDataSocketEmition(globalSocketData);
        setIsLoading(false);
      } else {
        console.log("Cannot submit sauda, please retry...");
      }
    } catch (error: any) {
      console.log(error);
      message.error(error.response.data.message || "Something went wrong!");
      setIsPosting(false);
      setIsLoading(false);
      setIsDisabled(false);
    }
    setIsPosting(false);
    setIsLoading(false);
  };

  //general methods
  const onChangeSelect = (value: any, option: any) => {
    setSelectedHubs(value);
  };

  let warningMessage = "";

  function showPromiseConfirm(status: boolean) {
    if (
      moment(urlData.slotEndTime, "HH:mm:ss")
        .add(moment.duration(urlData.bufferTime).asMinutes(), "minutes")
        .isAfter(moment())
    ) {
      if (validate() || status === false) {
        const msg = status
          ? "Are you sure you want to accept this sauda ?"
          : "Are you sure you want to reject this sauda ?";
        confirm({
          title: status ? "Accept sauda ?" : "Reject sauda ?",
          icon: <ExclamationCircleOutlined />,
          content: msg,
          onOk() {
            setEnableRefresh(false);
            submitSaudha(status);
            setIsDisabled(true);
          },
          onCancel() {},
        });
      } else {
        message.error(warningMessage);
      }
    } else {
      message.warning("Cannot accept/reject sauda after buffer time..!");
    }
  }

  const validate = () => {
    if (tableData.length > 0) {
      let total: number = 0;
      let minQtyValidation: boolean = false;
      tableData.forEach((item: any) => {
        /* if (
          item.tempAlottedQty !== 0 &&
          item.tempAlottedQty !== "0" &&
          item.tempAlottedQty !== "" &&
          parseInt(item.tempAlottedQty) < parseInt(minQuantity)
        ) {
          warningMessage = `Minimum truckable quantity is ${minQuantity}!`;
          minQtyValidation = true;
          return false;
        } */
        total += item.tempAlottedQty;
        // else {
        //   var i = item.key;
        //   if (i < tableData.length - 1) {
        //     // for (var i=0; i < tableData.length-1;i++) {
        //     if (
        //       Number(tableData[i + 1].tempAlottedQty) !== 0 &&
        //       tableData[i + 1].tempAlottedQty !== "" &&
        //       tableData[i + 1].tempAlottedQty !== "0" &&
        //       Number(tableData[i + 1].tempAlottedQty) !== null &&
        //       item.tempAlottedQty < item.quantity &&
        //       isDone === false
        //     ) {
        //       warningMessage =
        //         "Previous bid alloted qty is less than the bid qty";
        //       isDone = true;
        //       return false;
        //     }
        //   }
        // }
      });
      if (minQtyValidation) {
        return false;
      } else if (total === 0) {
        warningMessage =
          "Cannot accept sauda with zero total allocated quantity!";
        return false;
      } else return true;
    } else {
      return false;
    }
  };

  const getRebateMatrix = async (
    rebateId: string,
    branchId: number | undefined
  ) => {
    const params = {
      rebate_id: rebateId,
      branch_id: branchId,
      crop_id: selectedCrop,
    };
    try {
      const response = await getRebateMatrixService(params);
      if (response.data.status) {
        setRebateTableData(response.data.data);
        setRebateModalVisible(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createNewArr = (data: any) => {
    if (data)
      return data
        .reduce((result: any, item: any) => {
          //First, take the name field as a new array result
          if (result.indexOf(item.head) < 0) {
            result.push(item.head);
          }
          return result;
        }, [])
        .reduce((result: any, head: any) => {
          //Take the data with the same name as a new array, and add a new field * * rowSpan inside it**
          const children = data.filter((item: any) => item.head === head);
          result = result.concat(
            children.map((item: any, index: any) => ({
              ...item,
              rowSpan: index === 0 ? children.length : 0, //Add the first row of data to the rowSpan field
            }))
          );
          return result;
        }, []);
  };

  const handleChange = (e, row, index, name, isPopup) => {
    // let name = name;
    if (name === "Cancel") {
      setIsLoading(true);
      setTableData((prevState) => {
        const newBody = prevState;
        setCancelledIndex([...cancelledIndex, newBody[index].bidId]);
        newBody[row.key].tempAlottedQty = 0;
        newBody[row.key].systemAllottedQty = 0;
        newBody[row.key].is_cancelled = true;
        console.log("td", newBody[row.key].isEdit);
        if (newBody[row.key].isEdit === true) {
          newBody[row.key].isEdit = false;
          let i = row.key + 1;
          for (; i < newBody.length; i++) {
            console.log("i", newBody[i]);
            if (
              newBody[i].isEdit === false &&
              (newBody[i].is_cancelled === false ||
                newBody[i].is_cancelled === null)
            ) {
              console.log("newBid", newBody[i]);
              newBody[i].isEdit = true;
              break;
            } else if (newBody[i].isEdit === true) {
              break;
            }
          }
          if (i === newBody.length) {
            i = newBody.length - 1;
            for (; i >= 0; i--) {
              console.log("i", newBody[i]);
              if (
                newBody[i].isEdit === false &&
                (newBody[i].is_cancelled === false ||
                  newBody[i].is_cancelled === null)
              ) {
                console.log("newBid", newBody[i]);
                newBody[i].isEdit = true;
                break;
              } else if (newBody[i].isEdit === true) {
                break;
              }
            }
          }
        }
        const totalTempAllotedQty = newBody.reduce(
          (sum, acc) => sum + acc.tempAlottedQty,
          0
        );
        const totalTempAllotedPrice = newBody.reduce(
          (sum, acc) => sum + acc.tempAlottedQty * acc.price,
          0
        );
        const totalBidQty = newBody
          .filter(
            (item) =>
              !cancelledIndex.includes(item.bidId) &&
              item.bidId !== newBody[index].bidId
          )
          .reduce((sum, acc) => sum + acc.quantity, 0);
        const totalBidQtyPrice = newBody
          .filter(
            (item) =>
              !cancelledIndex.includes(item.bidId) &&
              item.bidId !== newBody[index].bidId
          )
          .reduce((sum, acc) => sum + acc.quantity * acc.price, 0);
        const totalAcceptedQty = newBody.reduce(
          (sum, acc) => sum + acc.systemAllottedQty,
          0
        );
        setTotalQty(totalTempAllotedQty);
        setAcceptedQty(totalAcceptedQty);
        setTotalBidQty(totalBidQty);
        setAvgAllotedQty(
          isNaN(totalTempAllotedPrice / totalTempAllotedQty)
            ? 0
            : Math.round(totalTempAllotedPrice / totalTempAllotedQty)
        );
        setAvgBidQty(
          isNaN(totalBidQtyPrice / totalBidQty)
            ? 0
            : Math.round(totalBidQtyPrice / totalBidQty)
        );
        return newBody;
      });
      setIsLoading(false);
    } else {
      let a;
      // var alphaNumericRegex = /^[a-zA-Z0-9]*$/;
      var numberRegex = /^[0-9.]+$/;
      if (isPopup === false) {
        a = tableData;

        // if (e.target.value !== 0 && e.target.value < parametersData) {
        //   message.error(`Minimum truckable quantity is ${parametersData}..!`);
        //   setTableData(a);
        //   return false;
        // } else
        {
          const data = tableData.map((item: any, idx: number) => {
            let isExecuted = false;
            console.log("check ", item.key, index);
            if (isExecuted === false) {
              if (item.key < index) {
                // for (var i=0; i < index; i++) {
                if (Number(item.quantity) > Number(item.tempAlottedQty)) {
                  // message.error(
                  //   "Previous bid alloted qty is less than the bid qty"
                  // );
                  isExecuted = true;
                  a[row.key].alottedQty = a[row.key].tempAlottedQty;
                  setTableData(a);

                  return false;
                }

                // }
              } else if (item.key > index) {
                if (
                  Number(item.tempAlottedQty) !== 0 &&
                  a[row.key].quantity > e.target.value
                ) {
                  message.error(" Previous bid alloted qty greater than 0");
                  isExecuted = true;

                  a[row.key].tempAlottedQty = a[row.key].alottedQty;

                  setTableData(a);
                  return false;
                }
              }
              // else if (item.key === 0) {
              //   if (
              //     Number(a[1] && a[1].tempAlottedQty) !== 0 &&
              //     a[row.key].quantity > e.target.value
              //   ) {
              //     message.error("Previous bid alloted qty greater than 0");
              //     isExecuted = true;
              //     a[row.key].tempAlottedQty = a[row.key].alottedQty;
              //     setTableData(a);
              //     return false;
              //   }
              // }
            }

            // if (item.key < index) {
            //   console.log(
            //     "inner function executed",
            //     item,
            //     item.quantity,
            //     item.alottedQty
            //   );

            //           if (Number(item.quantity) > Number(item.alottedQty)) {
            //             console.log("item qty ", item.quantity, item.alottedQty);
            //             message.error("enter previous bid ");
            // isExecuted = true;
            //             return false;
            //           }
            // } else if(!isExecuted) {
            if (a[row.key].quantity < e.target.value) {
              a[row.key].alottedQty = "";
              setTableData(a);

              var avgAllotedQty1 = 0;
              var addQty1 = 0;
              const dd3 = tableData.map((item: any, idx: number) => {
                if (
                  item.allotedQty !== undefined ||
                  item.allotedQty !== null ||
                  item.allotedQty !== 0
                ) {
                  avgAllotedQty += Number(item.alottedQty) * Number(item.price);
                  addQty1 += Number(item.alottedQty);
                }
              });

              if (
                avgAllotedQty1 !== undefined ||
                addQty1 !== undefined ||
                item.avgAllotedQty1 !== 0
              ) {
                avgAllotedQty1 = Math.round(
                  Number(avgAllotedQty1) / Number(addQty1)
                );
              } else {
                avgAllotedQty1 = 0;
              }

              setAvgAllotedQty(avgAllotedQty1);

              message.error("Allotted qty cannot be more than bid qty");
              return false;
            } else {
              console.log("value", e.target.value);
              if (e.target.value === "") {
                a[row.key].tempAlottedQty = 0;
              } else if (e.target.value.match(numberRegex)) {
                a[row.key].tempAlottedQty = Number(e.target.value);
              }
              setTableData(a);
              var totalAlloted = 0;
              const dd = tableData.map((item: any, idx: number) => {
                totalAlloted += Number(item.tempAlottedQty);
              });
              setTotalQty(totalAlloted);
              /***************** */
              var avgAllotedQty = 0;
              var addQty = 0;
              const dd1 = tableData.map((item: any, idx: number) => {
                if (
                  item.tempAlottedQty !== undefined ||
                  item.price !== undefined ||
                  item.tempAlottedQty !== 0
                ) {
                  avgAllotedQty +=
                    Number(item.tempAlottedQty) * Number(item.price);
                  addQty += Number(item.tempAlottedQty);
                }
              });

              if (avgAllotedQty !== 0) {
                avgAllotedQty = Math.round(
                  Number(avgAllotedQty) / Number(addQty)
                );
              }

              setAvgAllotedQty(avgAllotedQty);
              //***************** */
              // var avgbidQty = 0;
              // const dd2 = tableData.map((item: any, idx: number) => {
              //   avgbidQty += Number(item.quantity) * Number(item.price);
              // });

              // console.log("avg ", avgbidQty, supplierInfo.total_bid_qty);
              // avgbidQty = Math.round(
              //   Number(avgbidQty) / Number(supplierInfo.total_bid_qty)
              // );

              // setAvgBidQty(avgbidQty);
            }
          });

          let b = tableData;

          if (
            b[index].tempAlottedQty === "" ||
            b[index].tempAlottedQty === undefined ||
            b[index].tempAlottedQty === "0" ||
            b[index].tempAlottedQty === null ||
            Number(b[index].tempAlottedQty) === 0
          ) {
            for (let i = b[index].key - 1; i >= 0; i--) {
              if (
                b[i].isEdit === false &&
                (b[i].is_cancelled === false || b[i].is_cancelled === null)
              ) {
                b[i].isEdit = true;
                break;
              } else if (b[i].isEdit === true) {
                break;
              }
            }
          } else {
            for (let i = b[index].key - 1; i >= 0; i--) {
              if (
                b[i].isEdit === true &&
                (b[i].is_cancelled === false || b[i].is_cancelled === null)
              ) {
                b[i].isEdit = false;
                break;
              } else if (b[i].isEdit === true) {
                break;
              }
            }
          }
          if (
            a.length > row.key + 1 &&
            a[row.key + 1] &&
            Number(e.target.value) !== 0
          ) {
            console.log(" quantity", a[row.key].quantity);
            console.log(" value ", Number(e.target.value));
            if (Number(a[row.key].quantity) === Number(e.target.value)) {
              for (let i = b[index].key + 1; i < b.length; i++) {
                if (
                  b[i].isEdit === false &&
                  (b[i].is_cancelled === false || b[i].is_cancelled === null)
                ) {
                  b[i].isEdit = true;
                  console.log(b[i]);
                  break;
                }
              }
              // a[row.key + 1].isEdit = false;
            } else if (Number(a[row.key].quantity) > Number(e.target.value)) {
              for (let i = b[index].key + 1; i < b.length; i++) {
                if (
                  b[i].isEdit === true &&
                  (b[i].is_cancelled === false || b[i].is_cancelled === null)
                ) {
                  b[i].isEdit = false;
                  break;
                }
              }
            }
            // } else {
            //   console.log(
            //     " a.length , quantity ",
            //     Number(a[row.key].quantity),
            //     Number(e.target.value),
            //     Number(a[row.key].quantity) > Number(e.target.value)
            //   );
            //   for(let i = b[index].key+1;i<b.length;i++){
            //     if(b[i].isEdit===false && (b[i].is_cancelled===false||b[i].is_cancelled===null)){
            //       b[i].isEdit=true;
            //       break;
            //     }
            // }
            //   a[row.key + 1].isEdit = true;
            // }
          } else {
            for (let i = b[index].key + 1; i < b.length; i++) {
              if (
                b[i].isEdit === true &&
                (b[i].is_cancelled === false || b[i].is_cancelled === null)
              ) {
                b[i].isEdit = false;
                console.log(b[i]);
                break;
              }
            }
          }
          setTableData([...b]);
        }

        // var val = row.key - 1;
        // let x = a[Number(val)];
        // console.log("item qty val ", x);
        // var data = tableData.map((it : any)=>{
        //   console.log("running  table data map funtion ",index, it);
        //   var isExecuted = false;
        // if(it.key < index  && isExecuted === false)
        // {
        //   isExecuted = true;
        //   console.log("item mmm ");
        //   if(Number(it.quantity)<Number(it.allotedQty)){
        //     console.log("item qty ", it.quantity, it.allotedQty);
        //     message.error("enter previous bid ");
        //     return false;
        //   }
        //   return it;
        // }

        // });

        // if(x!== undefined ){
        //   console.log("x value ",x , x.quantity , x.allotedQty,Number(x.quantity),Number(x.alloted_qty ));
        //   if (Number(x.quantity)>Number(x.allotedQty)){
        //   console.log("item qty ", x.quantity, x.allotedQty);
        //   message.error("enter previous bid ");
        //   return;
        // }
        // }

        //       if(a[row.key].quantity < e.target.value){
        //         console.log("running  table data comparative funtion ");
        //         a[row.key].alottedQty = "";
        //         setTableData(a);

        //         var avgAllotedQty1 = 0;
        //         var addQty1 = 0;
        //         const dd3 = tableData.map((item: any, idx: number) => {
        //           if(item.allotedQty !== undefined || item.allotedQty !== null){
        //           avgAllotedQty += Number(item.alottedQty) *  Number(item.price);
        //           addQty1 += Number(item.alottedQty);
        //           }
        //         });

        // console.log("avg ", avgAllotedQty1,addQty1 );
        // if(avgAllotedQty1 === undefined || addQty1 === undefined)
        // {
        // avgAllotedQty1 = Math.round((Number(avgAllotedQty1)/Number(addQty1)))
        // }
        // else {
        //   avgAllotedQty1 = 0;
        // }

        //           setAvgAllotedQty(avgAllotedQty1);

        //         message.error("Allotted qty cannot be more than bid qty");
        //         return;
        //       }
        //       else{
        //         console.log("running  table else  funtion ");
        //       console.log("table ",a );
        //       if (e.target.value === "") {
        //         a[row.key].alottedQty = "";
        //       } else if (e.target.value.match(numberRegex)) {
        //         a[row.key].alottedQty = e.target.value;
        //       }

        //       console.log("table ",a );
        //       setTableData(a);

        //       var totalAlloted = 0;
        //       const dd = tableData.map((item: any, idx: number) => {
        //         totalAlloted += Number(item.alottedQty);
        //       });

        //         setTotalQty(totalAlloted);
        // /***************** */
        //         var avgAllotedQty = 0;
        //         var addQty = 0;
        //         const dd1 = tableData.map((item: any, idx: number) => {
        //           avgAllotedQty += Number(item.alottedQty) *  Number(item.price);
        //           addQty += Number(item.alottedQty);
        //         });

        // console.log("avg ", avgAllotedQty,totalQty );
        //         avgAllotedQty = Math.round((Number(avgAllotedQty)/Number(addQty)))

        //           setAvgAllotedQty(avgAllotedQty);
        // //***************** */
        //           var avgbidQty = 0;
        //         const dd2 = tableData.map((item: any, idx: number) => {
        //           avgbidQty += Number(item.quantity) *  Number(item.price);
        //         });

        // console.log("avg ", avgbidQty,supplierInfo.total_bid_qty );
        //         avgbidQty = Math.round((Number(avgbidQty)/Number(supplierInfo.total_bid_qty)))

        //           setAvgBidQty(avgbidQty);
        //       }
      }
    }
  };

  const archiveColumnData = [
    {
      title: "Sauda ID",
      dataIndex: "saudhaId",
      key: "saudhaId",
      width: 80,
    },
    {
      title: "Vendor Code",
      dataIndex: "vendorCode",
      key: "vendorCode",
      width: 120,
      render: (record) => {
        return isMasking ? "*".repeat(6) : record;
      },
    },
    {
      title: "Firm Name",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 100,
      render: (record) => {
        return isMasking ? "*".repeat(6) : record;
      },
    },
    {
      title: "Broker Comment",
      dataIndex: "brokerRemark",
      key: "brokerRemark",
      width: 90,
      render: (record) => {
        return isMasking
          ? record && record.substring(0, 2) + "*".repeat(4)
          : record;
      },
    },
    {
      title: "Bid Quantity (MT)",
      dataIndex: "quantity",
      key: "quantity",
      width: 70,
    },
    {
      title: "Price (₹/MT)",
      dataIndex: "price",
      key: "price",
      width: 70,
    },
    {
      title: "System Allotted Qty (MT)",
      dataIndex: "systemAllottedQty",
      key: "systemAllottedQty ",
      width: 90,
    },
    {
      title: "Allotted Qty (MT)",
      dataIndex: "tempAlottedQty",
      key: "tempAlottedQty",
      width: 70,
      render: (record, rowRecord, index) => {
        return (
          <>
            <div
              style={{
                marginLeft: "-20px",
              }}
            >
              <Input
                style={{ marginRight: "2px" }}
                maxLength={100}
                disabled={
                  (urlData.archives ? true : isBufferTime) ||
                  supplierInfo.submitted === 1 ||
                  !rowRecord.isEdit ||
                  !canUserAccess(userData, FeatureCodes.SAUDA_PROCESS) ||
                  rowRecord?.is_cancelled === true ||
                  isDisabled
                }
                value={rowRecord.tempAlottedQty}
                name="tempAlottedQty"
                // onBlur={(e) =>
                //   handleChangeBlur(
                //     e,
                //     rowRecord,
                //     index
                //   )
                // }
                onChange={(e) =>
                  handleChange(e, rowRecord, index, "alottedQty", false)
                }
              />
            </div>
          </>
        );
      },
    },
  ];

  const auctionColumnData = [
    ...archiveColumnData,
    {
      title: "Cancel Bid",
      dataIndex: "",
      key: "cancelAction",
      width: 60,
      render: (record, rowRecord, index) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Button
              type={
                rowRecord?.is_cancelled === true ||
                isDisabled ||
                (urlData.archives ? true : isBufferTime) ||
                saudaStatus !== "Not Processed"
                  ? "ghost"
                  : "primary"
              }
              size="middle"
              danger
              disabled={
                rowRecord?.is_cancelled === true ||
                isDisabled ||
                (urlData.archives ? true : isBufferTime) ||
                saudaStatus !== "Not Processed"
              }
              htmlType="button"
              onClick={(e) =>
                handleChange(e, rowRecord, index, "Cancel", false)
              }
            >
              X
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <div className="saudha">
      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={false}
        className="add-hub-modal"
        maskClosable={false}
      >
        <div className="add-hub-head">Add hubs for comparison</div>
        <div className="modal-container">
          <div>Select Hubs</div>
          <Select
            onChange={(value, option) => onChangeSelect(value, option)}
            mode="multiple"
            placeholder="Select hubs"
            value={selectedHubs}
            showSearch
            filterOption={(input: any, option: any) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {hubs.map((item: any) => (
              <Option
                label={item.name}
                value={item.id}
                id={item.id}
                key={item.id}
              >
                {item.name}
              </Option>
            ))}
          </Select>
          <div className="btn-container">
            <Button onClick={onAddHubs}>Update Hub List</Button>
          </div>
        </div>
      </Modal>
      <Modal
        className="rebate-modal"
        visible={rebateModalVisible}
        onCancel={() => setRebateModalVisible(false)}
        footer={false}
      >
        <div className="rebate-header">Rebate Matrix</div>
        <div className="rebate-container">
          <Table
            columns={rebateColumns}
            // dataSource={tableData}
            dataSource={createNewArr(rebateTableData)}
            pagination={false}
            scroll={{ y: window.innerHeight - 165 }}
          />
        </div>
      </Modal>
      <div className="header">
        <div
          className="back-btn"
          onClick={() => {
            history.goBack();
          }}
        >
          ←
        </div>
        <div className="title">SAUDA</div>
      </div>
      <div className="content-container">
        <div className="top-container">
          <div className="left-container">
            <div className="top-display">
              <DisplayItem
                title="Crop"
                subtitle={
                  crops?.find((item: any) => item.id === selectedCrop).name
                }
                Icon={CropIcon}
              />
              <DisplayItem
                title="Variety"
                subtitle={urlData.variety}
                Icon={VarietyIcon}
              />
              <DisplayItem title="Hub" subtitle={urlData.hub} Icon={HubIcon} />
              {timeData}
            </div>
            <div className="content-display">
              <div className="table-container">
                {!urlData.archives && (
                  <div className="accept-reject">
                    <Button
                      className={`refresh`}
                      disabled={
                        !enableRefresh ||
                        isBufferTime ||
                        saudaStatus !== "Not Processed"
                      }
                      icon={<SyncOutlined spin={isLoading} />}
                      onClick={() =>
                        // saudhaSuppliersDataSocketEmition(globalSocketData)
                        {
                          setCancelledIndex([]);
                          setSupplierData(dataObject.key, dataObject.archives);
                        }
                      }
                    >
                      Refresh
                    </Button>
                    {saudaStatus === "Waiting" && !dataObject?.isBm ? (
                      <div className={`buttons disabled`}>
                        <Button className="waiting">
                          Waiting For Approval
                        </Button>
                      </div>
                    ) : dataObject?.isBm ? (
                      <div
                        className={`buttons ${
                          (isDisabled ||
                            isPosting ||
                            isLoading ||
                            tableData.length === 0) &&
                          "disabled"
                        }`}
                      >
                        <Button
                          className="red"
                          onClick={() => showPromiseConfirm(false)}
                        >
                          Reject
                        </Button>
                        <Button
                          className="green"
                          onClick={() => showPromiseConfirm(true)}
                        >
                          Accept
                        </Button>
                      </div>
                    ) : (
                      <div
                        className={`buttons ${
                          (isDisabled ||
                            supplierInfo.submitted === 1 ||
                            isPosting ||
                            isLoading ||
                            !canUserAccess(
                              userData,
                              FeatureCodes.SAUDA_PROCESS
                            ) ||
                            // userData.role_id !== 2 ||
                            // supplierInfo.alloted_total_qty === 0 ||
                            tableData.length === 0) &&
                          "disabled"
                        }`}
                      >
                        <Button
                          className="red"
                          onClick={() => showPromiseConfirm(false)}
                        >
                          Reject
                        </Button>
                        <Button
                          className="green"
                          onClick={() => showPromiseConfirm(true)}
                        >
                          Accept
                        </Button>
                      </div>
                    )}
                  </div>
                )}
                {urlData.archives && (
                  <div className={`accepted-rejected`}>
                    {/* ${urlData.archives && (supplierInfo.saudha_status === 1 || supplierInfo.saudha_status === 2 || supplierInfo.saudha_status === null) ? "move-up" : "move-down"} */}
                    {/* {console.log("saudaStatus", moment(urlData.slotEndTime, "HH:mm:ss").isBefore(moment()), "urlData", urlData, "supplierInfo =========> ",urlData.archives, supplierInfo.submitted, "Over ALL __________> ", ((urlData !== undefined && */}
                    {/* moment(urlData.slotEndTime, "HH:mm:ss").isBefore(moment())) || isPosting || isLoading))} */}
                    <div className={`buttons`}>
                      <div
                        className={`${
                          saudaStatus === "Accepted"
                            ? "green"
                            : saudaStatus === "Rejected"
                            ? "red"
                            : saudaStatus === "Waiting"
                            ? "red"
                            : saudaStatus === "Not Processed"
                            ? "notProcessed"
                            : "display-none"
                        }`}
                      >
                        {saudaStatus}
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={`supplier-table ${
                    tableData.length === 0 && "supplier-table-color"
                  }`}
                >
                  <Table
                    loading={isLoading}
                    size="small"
                    style={{ overflowX: "hidden" }}
                    rowClassName={(record, index) =>
                      urlData.archives
                        ? record.is_cancelled
                          ? "table-row-dark"
                          : "table-row-light"
                        : (cancelledIndex.length > 0 &&
                            cancelledIndex.includes(record.bidId)) ||
                          record.is_cancelled
                        ? "table-row-dark"
                        : "table-row-light"
                    }
                    dataSource={tableData}
                    pagination={false}
                    scroll={{ y: window.innerHeight - 350 }}
                    columns={auctionColumnData}
                  />
                </div>
              </div>
              <div className="details-container">
                <div className="details">
                  <div className="items">
                    <div className="label">No of Bids</div>
                    <div className="value">{supplierInfo.no_of_bids}</div>
                  </div>
                  <div className="items">
                    <div className="label">Max Price (₹/MT)</div>
                    <div className="value">
                      {supplierInfo.max_price ? supplierInfo.max_price : 0}{" "}
                      {/* <div className="unit">₹/MT</div> */}
                    </div>
                  </div>
                  <div className="items">
                    <div className="label">Required Qty (MT)</div>
                    <div className="value">
                      {supplierInfo.qty_rqd}
                      {/* <div className="unit">MT</div> */}
                    </div>
                  </div>
                  <div className="items">
                    <div className="label">Total Bid Qty (MT)</div>
                    <div className="value">
                      {totalBidQty}
                      {/* <div className="unit">MT</div> */}
                    </div>
                  </div>
                  {urlData.archives && (
                    <div className="items">
                      <div className="label">Accepted Qty (MT)</div>
                      <div className="value">
                        {acceptedQty}
                        {/* <div className="unit">MT</div> */}
                      </div>
                    </div>
                  )}
                  <div className="items">
                    <div className="label">Total Allotted Qty (MT)</div>
                    <div className="value">
                      {totalQty}
                      {/* <div className="unit">MT</div> */}
                    </div>
                  </div>
                  <hr></hr>
                  <div className="items">
                    <div className="label">WT.Avg Allotted Price (₹/MT)</div>
                    <div className="value">
                      {isNaN(Number(avgAllotedQty)) ? 0 : avgAllotedQty}
                      {/* <div className="unit">MT</div> */}
                    </div>
                  </div>
                  <div className="items">
                    <div className="label">WT.Avg Bid Price (₹/MT)</div>
                    <div className="value">
                      {avgBidQty}
                      {/* <div className="unit">MT</div> */}
                    </div>
                  </div>
                </div>
                {urlData.rebateName !== null && (
                  <div className="rebate">
                    <div className="items">
                      <div className="label">Rebate Name</div>
                      <div className="value">
                        {/* {urlData.rebateName} */}
                        <div className={"rebate-info-sauda"}>
                          {urlData.rebateName}
                          {urlData.rebateName && (
                            <span
                              className="info"
                              onClick={() => {
                                getRebateMatrix(
                                  urlData.rebateId,
                                  urlData.branch_id
                                );
                              }}
                            >
                              ?
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!urlData.archives && (
            <div className="right-container">
              <Tabs defaultActiveKey="1" className="comparison-tab">
                <TabPane tab="Hub Comparison" key="1">
                  <Spin spinning={isLoadingHub}>
                    <div className="hub-comparison">
                      {/* <div className="title">Hub Comparison</div> */}
                      <div className="add-hub">
                        <Button onClick={() => setModalVisible(true)}>
                          Add Hubs
                        </Button>
                      </div>
                      <div className="content">
                        {compareHubs.map((item: any) => (
                          <HubComparisonItem
                            key={item.hub_id}
                            hubName={item.hub_name}
                            minPrice={item.min_price}
                            maxPrice={item.max_price}
                            avgPrice={item.avg_price}
                          />
                        ))}
                      </div>
                    </div>
                  </Spin>
                </TabPane>
                <TabPane tab="Bid Comparison" key="2">
                  <div className="bid-comparison">
                    <Spin spinning={isLoadingBid}>
                      <div className="content">
                        {compareBids.map((item: any) => (
                          <BidComparisonItem
                            key={item.auction_id}
                            auctionDate={item.auction_date}
                            minPrice={item.min_bid_price}
                            maxPrice={item.max_bid_price}
                            fulfilledQty={item.qty_fullfilled}
                          />
                        ))}
                      </div>
                    </Spin>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          )}
        </div>
        <div className="bottom-container">
          <div className="content">
            <div className="head">
              <div className="left">
                Todays buying target in {urlData.hub}(in MT)
              </div>
              <div className="right">
                {totalQty} of {supplierInfo.qty_rqd}
              </div>
            </div>
            <Progress
              percent={(totalQty / supplierInfo.qty_rqd) * 100}
              showInfo={false}
              status={
                (totalQty / supplierInfo.qty_rqd) * 100 !== 100
                  ? "active"
                  : "normal"
              }
            />
          </div>
          <div className="content right">
            <div className="head">
              <div className="left">
                Todays buying range in {urlData.hub}(in ₹)
              </div>
              <div className="right">
                {supplierInfo.min_price} - {supplierInfo.max_price}
              </div>
            </div>
            {/* <Progress percent={40} showInfo={false} status="active" /> */}
            <Slider
              range
              step={10}
              value={[supplierInfo.min_price, supplierInfo.max_price]}
              min={supplierInfo.min_price - 100}
              max={supplierInfo.max_price + 100}
              //   onChange={onChange}
              //   onAfterChange={onAfterChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface IDisplayProps {
  title: string;
  subtitle: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  timer?: boolean;
}

const DisplayItem: React.FC<IDisplayProps> = (props: IDisplayProps) => {
  const { title, subtitle, Icon, timer = false } = props;
  return (
    <div className="display-item">
      <Icon className="icon" />
      <div className="title-container">
        <div className="title">{title}</div>
        <div className="subtitle">
          {timer ? (
            <Timer
              time={
                moment(subtitle, "HH:mm:ss").isBefore(moment())
                  ? moment().add(1, "second").format("HH:mm:ss")
                  : subtitle
              }
              saudha
            />
          ) : (
            subtitle
          )}
        </div>
      </div>
    </div>
  );
};

interface IHubItem {
  hubName: string;
  minPrice: number;
  maxPrice: number;
  avgPrice: number;
}
const HubComparisonItem: React.FC<IHubItem> = (props: IHubItem) => {
  const { hubName, minPrice, maxPrice, avgPrice } = props;
  return (
    <div className="individual-content">
      <div className="hub-name">{hubName}</div>
      <div className={`line ${avgPrice > maxPrice ? "red" : "green"}`}></div>
      <div className="data">
        <div className="range">
          <div>Target Range</div>
          <div>{`₹ ${minPrice.toFixed(2)} - ₹ ${maxPrice.toFixed(2)}`}</div>
        </div>
        <div className="price">
          <div>Average Price</div>
          <div className={avgPrice > maxPrice ? "red" : "green"}>
            {avgPrice !== null ? `₹ ${avgPrice.toFixed(2)}` : "₹ 0.00"}
          </div>
        </div>
      </div>
    </div>
  );
};
interface IBidItem {
  auctionDate: string;
  minPrice: number;
  maxPrice: number;
  fulfilledQty: number;
}
const BidComparisonItem: React.FC<IBidItem> = (props: IBidItem) => {
  const { auctionDate, minPrice, maxPrice, fulfilledQty } = props;
  return (
    <div className="individual-content">
      <div className="auction-date">
        {" "}
        <div>Auction Date</div> <div>{auctionDate}</div>{" "}
      </div>
      <div className={`line green`}></div>
      <div className="data">
        <div className="data-item">
          <div>Minimum Bid Price (₹)</div>
          <div>{`${minPrice !== null ? minPrice.toFixed(2) : 0}`}</div>
        </div>
        <div className="data-item">
          <div>Maximum Bid Price (₹)</div>
          <div>{`${maxPrice !== null ? maxPrice.toFixed(2) : 0}`}</div>
        </div>
        <div className="data-item">
          <div>Fulfilled Quantity (MT)</div>
          <div className="green">{`${
            fulfilledQty !== null ? fulfilledQty : 0
          }`}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  userData: selectUserData,
  auth: selectAuthData,
  selectedCrop: selectSelectedCrop,
  openAuctionBranch: selectOpenAuctionBranch,
  crops: selectCrops,
  hubs: selectHubs,
  minQuantity: selectMinQty,
  varieties: selectVarieties,
});

export default connect(mapStateToProps)(Saudha);
