import axios from "axios";
import { dataConstants } from "../constants/data-constants";

export const getMobileUpdateDetails = async () => {
  return axios.post(dataConstants.getMobileAppVersionUrl);
};

// export const postRegisterUser = async (body: any) => {
//   return axios.post(dataConstants.postRegisterUrl, body);
// };

export const putMobileUpdateDetails = async (data: any) => {
  return axios.put(dataConstants.updateMobileAppVersionUrl, data);
};
