import React, { Component } from "react";
import "./create-auction-langing.styles.scss";

import { ReactComponent as UpcomingImage } from "../../assets/upcoming-auction.svg";
import { ReactComponent as OpenImage } from "../../assets/open-auction.svg";
import { ReactComponent as ArchiveImage } from "../../assets/archive.svg";
import { ReactComponent as IntermediateImage } from "../../assets/intermediate.svg";
import { ReactComponent as ApprovalImage } from "../../assets/approved.svg";
import { ReactComponent as RefundImage } from "../../assets/refund.svg";

import Archives from "../archives/archives.page";
import OpenAuction from "../open-auction/open-auction.page";
import ApproveAuction from "../auction-approval/auction-approval.page";
import UpcomingAuction from "../upcoming-auction/upcoming-auction.page";
import { selectBypassBD } from "../../redux/common/common.selectors";
import CreateAuctionBD from "../create-auction-bd/create-auction-bd.page";
import CopyAuction from "../copy-auction/copy-auction.page";
import CreateAuction from "../create-auction/create-auction.page";
import { canUserAccess } from "../../utils/utils";
import { FeatureCodes } from "../../constants/data-constants";
import { selectUserData } from "../../redux/user/user.selectors";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Dispatch } from "react";

import { setViewAuctionPage } from "../../redux/common/common.actions";
import {
  selectSelectedCrop,
  selectViewAuctionPage,
} from "../../redux/common/common.selectors";

import { Tabs } from "antd";

const { TabPane } = Tabs;

const CreateAuctionCopyLanding: React.FC = (props: any) => {
  const {
    setViewAuctionPage,
    selectedKey,
    selectedCrop,
    globalSocket,
    userData,
    isBypassedBD,
  } = props;

  return (
    <div className="intemediate-sauda-landing">
      <Tabs
        defaultActiveKey={selectedKey}
        onChange={(activeKey) => setViewAuctionPage(activeKey)}
      >
        {canUserAccess(userData, FeatureCodes.AUCTION_MAKER) &&
          selectedCrop === 20 && (
            <TabPane
              tab={
                <span className="tabname-container">
                  {" "}
                  <ApprovalImage className="tab-img" />{" "}
                  <div className="tab-name">Create Auction</div>
                </span>
              }
              key="1"
            >
              <CreateAuction {...props} />
            </TabPane>
          )}
        {canUserAccess(userData, FeatureCodes.AUCTION_MAKER) && (
          <TabPane
            tab={
              <span className="tabname-container">
                {" "}
                <ApprovalImage className="tab-img" />{" "}
                <div className="tab-name">Copy Auction</div>
              </span>
            }
            key="2"
          >
            <CopyAuction {...props} />
          </TabPane>
        )}
        {/* {selectedCrop === 1 && (
          <TabPane
            tab={
              <span className="tabname-container">
                {" "}
                <IntermediateImage className="tab-img" />{" "}
                <div className="tab-name">Buying Decision</div>
              </span>
            }
            key="3"
          >
            <CreateAuctionBD {...props} />
          </TabPane>
        )} */}
      </Tabs>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedKey: selectViewAuctionPage,
  selectedCrop: selectSelectedCrop,
  userData: selectUserData,
  isBypassedBD: selectBypassBD,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setViewAuctionPage: (activeKey: string) =>
      dispatch(setViewAuctionPage(activeKey)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAuctionCopyLanding);
