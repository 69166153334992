/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./define-slots-today.styles.scss";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectCrops,
  selectSelectedCrop,
  selectBranches,
  selectCurrentPage,
  selectFreezingTime,
  selectSelectedBranch,
  selectClosingDuration,
  selectSlotPage,
  selectViewAuctionPage,
} from "../../redux/common/common.selectors";

// import { setBranchIndex } from "../../redux/common/common.actions";

import { Dispatch } from "redux";

import { ExclamationCircleOutlined, SyncOutlined } from "@ant-design/icons";

import { Button, Checkbox, Spin, Modal, message } from "antd";

import {
  getSlotServiceToday,
  postAddSlotToday,
  putUpdateSlotToday,
} from "../../services/define-slot-services";
import {
  getSysParams,
  getCompanyParams,
} from "../../services/sys-params-services";

import SlotTime from "../../components/slot-time/slot-time.component";
import CustomSelect from "../../components/custom-select/custom-select.component";

import { FeatureCodes } from "../../constants/data-constants";
import { canUserAccess } from "../../utils/utils";

import * as Moment from "moment";
import { extendMoment } from "moment-range";
import { selectUserData } from "../../redux/user/user.selectors";
import { setSelectedBranch } from "../../redux/common/common.actions";

const moment = extendMoment(Moment);

interface ISlots {
  end_time: string;
  slot_id: number;
  start_time: string;
}
interface IStateSlots {
  slot_id: number;
  slot_number: number;
  value: Array<string>;
  active: boolean;
  permanent: boolean;
  edited: boolean;
  // show_toggle: boolean;
}
// interface IGetResponseObject {
//   changed_slots: Array<ISlots>;
//   master_slots: Array<ISlots>;
// }
interface IGetResponseObject {
  end_time: string;
  is_active: boolean;
  master: boolean;
  slot_id: number;
  slot_number: number;
  start_time: string;
}
// let tempSlots: Array<IStateSlots>;
const DefineSlotToday: React.FC<any> = (props: any) => {
  const {
    branches,
    user,
    crops,
    selectedCrop,
    currentpage,
    slotPageIndex,
    freezingTime,
    selectedBranch,
    setSelectedBranchIndex,
    bufferTime,
    viewAuctionPage,
  } = props;

  const [slots, setSlots] = useState<IStateSlots[]>([]);
  // const [tempSlots, setTempSlots] = useState<IStateSlots[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  // const [selectedBranch, setSelectedBranch] = useState(branches[0]?.id);
  // const [bufferTime, setBufferTime] = useState(0);
  const [loader, setLoader] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [slotToPost, setSlotToPost] = useState(["", ""]);
  // const [isUpdateUpcomingSlots, setIsUpcomingSlots] = useState(false);

  useEffect(() => {
    if (
      currentpage === "7" &&
      viewAuctionPage === "70" &&
      slotPageIndex === "702"
    ) {
      getSlotsFn();
    }
    // onBranchSelected(selectedBranch);
  }, [
    currentpage,
    viewAuctionPage,
    slotPageIndex,
    selectedBranch,
    selectedCrop,
  ]);

  // useEffect(() => {
  //   // onBranchSelected(selectedBranch);
  //   getBufferTime(selectedBranch);
  // }, []);

  const getSlotsFn = async () => {
    setLoader(true);
    const params = {
      branch_id: selectedBranch,
      crop_id: selectedCrop,
    };
    try {
      const slotsResponse = await getSlotServiceToday(params);
      if (slotsResponse.status) {
        setSlotData([]);
        setSlotData(slotsResponse.data.data);
      } else {
        setSlotData([]);
      }
    } catch (error) {
      console.log(error);
      // if (error.response.status === 400) setSlotData([]);
    }
    setLoader(false);
    // setIsEdit(!loader);
  };

  const setSlotData = (data: Array<IGetResponseObject>) => {
    const updatedSlots = data.map((slot: IGetResponseObject) => {
      return {
        slot_id: slot.slot_id,
        slot_number: slot.slot_number,
        value: [slot.start_time, slot.end_time],
        active: slot.is_active,
        permanent: slot.master,
        edited: false,
        // show_toggle: true,
      };
    });

    setSlots(updatedSlots);
  };

  const onChange = (id: number, timeString: Array<string>) => {
    const tempSlot = slots.map((item) => {
      if (item.slot_id === id) {
        item.value = [
          moment(timeString[0], "hh:mm A").format("HH:mm:ss"),
          moment(timeString[1], "hh:mm A").format("HH:mm:ss"),
        ];
        item.edited = true;
      }
      return item;
    });
    setSlots(tempSlot);
  };

  const onChangeAddSlot = (id: number, timeString: Array<string>) => {
    setSlotToPost(timeString);
  };

  const onAddSlotSubmit = async () => {
    const postData = {
      branch_id: selectedBranch,
      start_time: moment(slotToPost[0], "hh:mm A").format("HH:mm:ss"),
      end_time: moment(slotToPost[1], "hh:mm A").format("HH:mm:ss"),
      crop_id: selectedCrop,
    };

    let valid = true;
    let messageString = "";
    const validate = () => {
      if (postData.start_time === "" && postData.end_time === "") {
        messageString = "Please select a time..!";
        return false;
      }
      if (postData.start_time === postData.end_time) {
        messageString = "Time should not be same..!";
        return false;
      }
      // const buffer = moment
      //   .duration(moment(bufferTime).format("HH:mm"))
      //   .asMinutes();
      const buffer = moment
        .duration(moment("00:00").format("HH:mm"))
        .asMinutes();
      slots.map((item) => {
        const itemRange = moment.range(
          moment(item.value[0], "HH:mm:ss"),
          moment(item.value[1], "HH:mm:ss").add(buffer, "m")
        );
        const newRange = moment.range(
          moment(slotToPost[0], "hh:mm A"),
          moment(slotToPost[1], "hh:mm A").add(buffer, "m")
        );
        //Changed the Format in newRange from hh:mm A to HH:mm:ss "_______", newRange, "__________>", itemRange.intersect(newRange), "hh:mm A"

        if (itemRange.intersect(newRange)) {
          messageString = "Time selected is overlaping an existing slot..!";
          valid = false;
          return;
        }
      });

      if (moment(postData.start_time, "HH:mm:ss").isBefore(moment())) {
        messageString = "Cannot create slot for an older time interval..!";
        getSlotsFn();
        return false;
      }

      // for (let i = 0; i < slots.length; i++) {
      //   if (
      //     moment(slots[i].value[0], "HH:mm:ss").isAfter(
      //       moment(slotToPost[0], "hh:mm A")
      //     )
      //   ) {
      //     messageString = "Time should be in ascending order..!";
      //     return false;
      //   }
      // }
      if (valid) {
        return true;
      }
      return false;
    };

    if (validate()) {
      setIsSubmitting(true);

      try {
        const postResponse = await postAddSlotToday(postData);
        if (postResponse.data.status) {
          message.success("Slot added successfully..!", 1);
          getSlotsFn();
          setAddModalVisible(false);
        } else {
          console.log("Something went wrong !");
        }
      } catch (error: any) {
        console.log(error);
        message.error(error.response.data.message, 1);
        setIsSubmitting(false);
      }
    } else {
      message.warning(messageString, 2);
    }
    setIsSubmitting(false);
  };

  const onToggle = (id: number) => {
    const tempSlot = slots.map((item) => {
      if (item.slot_id === id) {
        item.active = !item.active;
        item.edited = true;
      }
      return item;
    });
    setSlots(tempSlot);
  };

  // const onBranchSelected = (value: number) => {
  // setSelectedBranchIndex(value);
  // setBranchIndex(branches.findIndex((branch) => branch.id == value));
  // getBufferTime(value);
  // getSlotsFn(value, true);
  // };

  // const getBufferTime = async (branchId: number) => {
  //   const params = {
  //     branch_id: branchId,
  //     parameter_type: "auction_closing_duration",
  //     crop_id: selectedCrop,
  //   };
  //   try {
  //     const response = await getSysParams(params);
  //     if (response.data.status) {
  //       const time = moment(
  //         response.data.data[0].parameter_data_value,
  //         "HH:mm"
  //       );

  //       setBufferTime(moment.duration(time.format("HH:mm")).asMinutes());
  //     } else {
  //       console.log("Could not fetch buffer time !");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getFreezeTime = async () => {
  //   // const params = {
  //   //   branch_id: branchId,
  //   //   // parameter_type: "auction_closing_duration",
  //   // };
  //   try {
  //     const response = await getCompanyParams({});
  //     if (response.data.status) {
  //       const time = moment(
  //         response.data.data[2].parameter_data_value,
  //         "HH:mm:ss"
  //       );
  //       setFreezingTime(response.data.data[2].parameter_data_value);
  //     } else {
  //       console.log("Could not fetch Freeze time !");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const updateSlots = async () => {
    let dataToSend = {
      branch_id: selectedBranch,
      modified_slots: [{}],
      crop_id: selectedCrop,
    };
    const data = slots.filter((item) => item.edited);
    let valid = true;
    let messageString = "";

    dataToSend.modified_slots = data.map((item) => {
      return {
        slot_id: item.slot_id,
        start_time: item.value[0],
        end_time: item.value[1],
        is_active: item.active,
        is_today: true,
      };
    });

    const validate = () => {
      if (data.length === 0) {
        messageString = "Nothing to update...";
        valid = false;
        return;
      }

      slots.map((modifiedItem) => {
        if (modifiedItem.value[0] === "" || modifiedItem.value[1] === "") {
          valid = false;
          messageString = "Please select a time...";
          return;
        }
        if (modifiedItem.value[0] === modifiedItem.value[1]) {
          messageString = "Time should not be same..!";
          valid = false;
          return;
        }
        // const buffer = moment
        //   .duration(moment(bufferTime).format("HH:mm"))
        //   .asMinutes();
        const buffer = moment
          .duration(moment("00:00").format("HH:mm"))
          .asMinutes();
        slots.map((item) => {
          if (modifiedItem.slot_id !== item.slot_id) {
            const itemRange = moment.range(
              moment(item.value[0], "HH:mm:ss"),
              moment(item.value[1], "HH:mm:ss").add(buffer, "m")
            );
            const newRange = moment.range(
              moment(modifiedItem.value[0], "HH:mm:ss"),
              moment(modifiedItem.value[1], "HH:mm:ss").add(buffer, "m")
            );

            if (itemRange.intersect(newRange)) {
              messageString = "Time selected is overlaping an existing slot..!";
              valid = false;
              return;
            }
          }
        });
      });

      // Commented the code to check the Time should be in ascending order

      // for (let i = 0; i < slots.length; i++) {
      //   for (let j = i; j < slots.length; j++) {
      //     if (
      //       moment(slots[i].value[0], "HH:mm:ss").isAfter(
      //         moment(slots[j].value[0], "HH:mm:ss")
      //       )
      //     ) {
      //       messageString = "Time should be in ascending order..!";
      //       getSlotsFn(selectedBranch, false);
      //       return false;
      //     }
      //   }
      // }

      // for (let i = 0; i < data.length; i++) {
      //   if (moment(data[i].value[0], "HH:mm:ss").isBefore(moment())) {
      //     messageString = "Cannot update a past time..!";
      //     getSlotsFn(selectedBranch, false);
      //     return false;
      //   }
      // }

      if (valid) {
        return true;
      } else {
        return false;
      }
    };

    if (validate()) {
      // CheckSlotsForTodayPresent(data, dataToSend);
      showConfirmWarning(dataToSend);
    } else {
      message.warning(messageString, 2);
      if (data.length === 0) {
        setIsEdit(false);
      }
    }
  };

  //Not required
  // const CheckSlotsForTodayPresent = (data: any, dataToSend: any) => {
  //   let isAnyUpcomingSlotPresent = false;
  //   for (let i = 0; i < data.length; i++) {
  //     if (moment(data[i].value[0], "HH:mm:ss").isAfter(moment())) {
  //       isAnyUpcomingSlotPresent = true;
  //       setIsUpcomingSlots(true);
  //       break;
  //     }
  //   }

  //   if (isAnyUpcomingSlotPresent) {
  //     showPromiseConfirm(dataToSend);
  //   } else {
  //     updateSlotsConditionally(dataToSend, true);
  //   }
  // };

  const updateSlotsConditionally = async (dataToSend: any) => {
    try {
      const response = await putUpdateSlotToday(dataToSend);
      if (response.data.status) {
        message.success(`Updated today slots..!`);
        getSlotsFn();
      }
    } catch (error: any) {
      message.error(error.response.data.message, 1);
    }
  };

  function showConfirmWarning(dataToSend: any) {
    Modal.confirm({
      title: "Slot allotment",
      icon: <ExclamationCircleOutlined />,
      content:
        "The existing auction scheduled for this slot will be rescheduled to a new time. Are you sure you want to proceed with this change?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        updateSlotsConditionally(dataToSend);
      },
      onCancel() {
        getSlotsFn();
      },
    });
  }

  return (
    <div className="define-slots">
      <Modal
        className="add-slot-modal"
        visible={addModalVisible}
        onCancel={() => setAddModalVisible(false)}
        onOk={() => setAddModalVisible(false)}
        footer={null}
        width="448px"
      >
        <div className="add-slot-container">
          <div className="add-slot-header">Add Slot</div>
          <div className="add-slot-content">
            <SlotTime
              slotId={1}
              slotNumber={
                slots.length !== 0 ? slots[slots.length - 1].slot_number + 1 : 1
              }
              value={slotToPost}
              onChange={onChangeAddSlot}
              isEdit={true}
              onToggle={onToggle}
              addSlot
            />
            <div className="btn-container">
              <Button loading={isSubmitting} onClick={onAddSlotSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Spin spinning={loader}>
        <div className="define-slots-title">
          {canUserAccess(user, FeatureCodes.SLOTS) ? "Define" : "View"} Slots
          for{" "}
          {
            <CustomSelect
              options={branches}
              value={selectedBranch}
              onChange={(value: any) => setSelectedBranchIndex(value)}
              returnId
            />
          }{" "}
          for{" "}
          {crops &&
            crops.length &&
            crops.find(
              (item: { id: number; name: string }) => item.id == selectedCrop
            ).name}
        </div>
        {canUserAccess(user, FeatureCodes.SLOTS) && (
          <div className="add-slot-top-container">
            <Button
              onClick={() => setAddModalVisible(true)}
              disabled={
                slots.length !== 0 &&
                (!slots[slots.length - 1].active || isEdit)
                // slots[slots.length - 1].edited)
              }
            >
              Add Slot
            </Button>
            <div className="label noteText">
              <b>
                {freezingTime !== 0
                  ? ` NOTE: Auction cannot be created ${freezingTime} minutes before the slot start time.`
                  : ""}
              </b>
            </div>
            {slots.length !== 0 && (
              <div
                className="edit-button"
                onClick={() => {
                  setIsEdit(!isEdit);
                  isEdit && getSlotsFn();
                }}
              >
                {!isEdit ? "Edit" : "Cancel"}
              </div>
            )}
          </div>
        )}
        <div className="slots-container">
          {slots.map((item, idx) => (
            <>
              <SlotTime
                key={idx}
                slotId={item.slot_id}
                slotNumber={
                  item.slot_number === undefined ? 1 : item.slot_number
                }
                value={item.value}
                onChange={onChange}
                isEdit={isEdit}
                edited={item.edited}
                active={item.active}
                onToggle={onToggle}
                isPermanent={item.permanent}
                //activeToggle
              />
            </>
          ))}
        </div>
        <div className="bottom-container">
          <div className="update-time">
            {/* {isEdit && (
              <Checkbox
                onChange={() => setIsPermanent(!isPermanent)}
                checked={isPermanent}
              >
                Update permanently
              </Checkbox>
            )} */}
            {isEdit && (
              <Button
                onClick={() => {
                  updateSlots();
                  // setIsEdit(false);
                }}
              >
                {/* {console.log(slots.find((item) => item.edited)?.edited)} */}
                Update Today's Time
              </Button>
            )}
          </div>
        </div>
      </Spin>
    </div>
  );
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setSelectedBranchIndex: (index: number) =>
      dispatch(setSelectedBranch(index)),
  };
};
const mapStateToProps = createStructuredSelector({
  branches: selectBranches,
  user: selectUserData,
  crops: selectCrops,
  selectedCrop: selectSelectedCrop,
  currentpage: selectCurrentPage,
  slotPageIndex: selectSlotPage,
  freezingTime: selectFreezingTime,
  selectedBranch: selectSelectedBranch,
  bufferTime: selectClosingDuration,
  viewAuctionPage: selectViewAuctionPage,
});

export default connect(mapStateToProps, mapDispatchToProps)(DefineSlotToday);
