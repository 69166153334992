export const CommonActionTypes = {
  SET_BRANCHES: "SET_BRANCHES",
  SET_VARIETIES: "SET_VARIETIES",
  SET_BAGTYPE: "SET_BAGTYPE",
  SET_DIVISION: "SET_DIVISION",
  SET_CROPS: "SET_CROPS",
  SET_HUBS: "SET_HUBS",
  SET_REFETCH: "SET_REFETCH",
  SET_REBATES: "SET_REBATES",
  SET_SELECTED_CROP: "SET_SELECTED_CROP",
  SET_SELECTED_BRANCH: "SET_SELECTED_BRANCH",
  SET_BYPASS_BD: "SET_BYPASS_BD",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  SET_VIEW_AUCTION_PAGE: "SET_VIEW_AUCTION_PAGE",
  SET_SLOT_PAGE: "SET_SLOT_PAGE",
  SET_OPEN_AUCTION_BRANCH: "SET_OPEN_AUCTION_BRANCH",
  SET_BYPASS_ENABLED: "SET_BYPASS_ENABLED",
  SET_TC: "SET_TC",
  SET_DEFAULT_PARAMS: "SET_DEFAULT_PARAMS",
  SET_BRANCH_PARAMS: "SET_BRANCH_PARAMS",
  SET_CROP_PARAMS: "SET_CROP_PARAMS",
  RESET_REDUCER: "RESET_REDUCER",
};
