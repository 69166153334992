import { createSelector } from "reselect";
import { Common } from "../../models/";

const selectCommon = (state: any): Common => state.common;

// export const selectedBranchIndex = createSelector(
//   [selectCommon],
//   (common) => common.selectedBranchIndex
// );
export const selectBranches = createSelector(
  [selectCommon],
  (common) => common.branches
);
export const selectVarieties = createSelector(
  [selectCommon],
  (common) => common.varieties
);
export const selectBagtypeList = createSelector(
  [selectCommon],
  (common) => common.bagtypeList
);
export const selectDivisionList = createSelector(
  [selectCommon],
  (common) => common.divisionList
);
export const selectCrops = createSelector(
  [selectCommon],
  (common) => common.crops
);
export const selectHubs = createSelector(
  [selectCommon],
  (common) => common.hubs
);
export const selectRebates = createSelector(
  [selectCommon],
  (common) => common.rebates
);
export const selectRefetch = createSelector(
  [selectCommon],
  (common) => common.refetch
);
export const selectSelectedCrop = createSelector(
  [selectCommon],
  (common) => common.selectedCrop
);
export const selectSelectedBranch = createSelector(
  [selectCommon],
  (common) => common.selectedBranch
);
export const selectBypassBD = createSelector(
  [selectCommon],
  (common) => common.bypassBD
);
export const selectCurrentPage = createSelector(
  [selectCommon],
  (common) => common.currentPage
);
export const selectViewAuctionPage = createSelector(
  [selectCommon],
  (common) => common.viewAuctionPage
);
export const selectSlotPage = createSelector(
  [selectCommon],
  (common) => common.slotPage
);
export const selectOpenAuctionBranch = createSelector(
  [selectCommon],
  (common) => common.openAuctionBranch
);
export const selectBypassEnabled = createSelector(
  [selectCommon],
  (common) => common.bypassEnabled
);
export const selectTC = createSelector([selectCommon], (common) => common.tc);
export const selectDefaultParams = createSelector(
  [selectCommon],
  (common) => common.default_params
);
export const selectFreezingTime = createSelector(
  [selectCommon],
  (common) => common.default_params.auction_freeze_period
);
export const selectClosingDuration = createSelector(
  [selectCommon],
  (common) => common.branch_level_params.auction_closing_duration
);
export const selectBranchParams = createSelector(
  [selectCommon],
  (common) => common.branch_level_params
);
export const selectMinQty = createSelector(
  [selectCommon],
  (common) => common.branch_level_params.minimum_quantity
);
export const selectSaudhaType = createSelector(
  [selectCommon],
  (common) => common.branch_level_params.saudha_type
);
export const selectCropParams = createSelector(
  [selectCommon],
  (common) => common.crop_level_params
);
