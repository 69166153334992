/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "./archives.styles.scss";
import { ReactComponent as CloseIcon } from "../../assets/cancel.svg";
import { ReactComponent as CheckedIcon } from "../../assets/checked.svg";

import { SyncOutlined } from "@ant-design/icons";

import moment from "moment";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getArchives } from "../../services/auction-services";

import {
  // getHubsService,
  getSlotIdsService,
} from "../../services/common-services";

import { Table, message, Button } from "antd";
import { Link } from "react-router-dom";

import CustomFilter from "../../components/custom-filter/custom-filter.component";
import {
  selectSelectedCrop,
  selectCrops,
  selectBranches,
  selectVarieties,
  selectCurrentPage,
  selectViewAuctionPage,
  selectHubs,
} from "../../redux/common/common.selectors";
import { selectAuthData } from "../../redux/user/user.selectors";
// import { setBranchIndex } from "../../redux/common/common.actions";
// import { Dispatch } from "redux";

const status = [
  { name: "Accepted", id: 1 },
  { name: "Rejected", id: 2 },
  { name: "Not Processed", id: 3 },
];

const Archives: React.FC = (props: any) => {
  const {
    branches,
    selectedCropId,
    // auth,
    globalSocketData,
    // selectedKey,
    crops,
    currentpage,
    viewAuctionPage,
    setBranch,
    selectedBranch,
    hubs,
    varietiesList,
  } = props;
  // const [hubs, setHubs] = useState();
  // const [slots, setSlots] = useState();
  const [selectedVariety, setSelectedVariety] = useState();
  const [selectedDates, setSelectedDates] = useState([
    moment().format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ]);
  const [selectedHub, setSelectedHub] = useState();
  // const [selectedSlot, setSelectedSlot] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [archiveData, setArchiveData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refetch, setRefetch] = useState(true);
  // const [selectedCropId, setselectedCropId] = useState<any>();
  // const [varietiesList, setVarietiesData] = useState<any>([]);

  let archiveAuctionsIntervalID = useRef<NodeJS.Timeout>();

  const columns = [
    {
      title: "Id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      children: [
        {
          dataIndex: "id",
          key: "id2",
          width: 50,
        },
      ],
    },
    {
      title: "Date",
      sorter: (a, b) => a.date.localeCompare(b.date),
      children: [
        {
          dataIndex: "date",
          key: "date2",
          width: 80,
        },
      ],
    },
    {
      title: "Hub",
      sorter: (a, b) => a.hub.localeCompare(b.hub),
      children: [
        {
          dataIndex: "hub",
          key: "hub2",
          width: "8%",
        },
      ],
    },
    {
      title: "Division",
      sorter: (a, b) => a.division - b.division,
      children: [
        {
          dataIndex: "division",
          key: "division2",
          render: (value: number) => {
            return (
              <div>{value === 1 ? "ABD" : value === 2 ? "FBD" : "NCDEX"}</div>
            );
          },
          width: 90,
          align: "center",
        },
      ],
    },
    {
      title: "Claim",
      children: [
        {
          dataIndex: "claim",
          key: "claim2",
          width: 50,
          render: (value: number) => {
            return (
              <div className="claim-container">
                {value === 1 ? <CheckedIcon /> : <CloseIcon />}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Variety",
      sorter: (a, b) => a.variety.localeCompare(b.variety),
      children: [
        {
          dataIndex: "variety",
          key: "variety2",
          width: 100,
        },
      ],
    },
    {
      title: "Max Price",
      children: [
        {
          title: "₹/MT",
          dataIndex: "maxPrice",
          key: "maxPrice2",
        },
      ],
    },
    {
      title: "Qty Reqd",
      children: [
        {
          title: "MT",
          dataIndex: "qtyReqd",
          key: "qtyReqd2",
        },
      ],
    },
    {
      title: "Qty Fulfilled",
      children: [
        {
          title: "MT",
          dataIndex: "qtyFulfilled",
          key: "qtyFulfilled2",
        },
      ],
    },
    {
      title: "Avg Price",
      children: [
        {
          title: "₹/MT",
          dataIndex: "avgPrice",
          key: "avgPrice2",
        },
      ],
    },
    {
      title: "Start Time",
      children: [
        {
          dataIndex: "startTime",
          key: "startTime2",
          render: (value: string) => {
            return <div>{moment(value, "HH:mm:ss").format("hh:mm A")}</div>;
          },
        },
      ],
    },
    {
      title: "End Time",
      children: [
        {
          dataIndex: "endTime",
          key: "endTime2",
          render: (value: string) => {
            return <div>{moment(value, "HH:mm:ss").format("hh:mm A")}</div>;
          },
        },
      ],
    },
    {
      title: "Sauda Type",
      children: [
        {
          dataIndex: "saudaType",
          key: "saudaType2",
          render: (value: number) => {
            return <div>{value === 1 ? "Net basis" : "Gross basis"}</div>;
          },
        },
      ],
    },
    {
      title: "Role",
      children: [
        {
          dataIndex: "roleType",
          key: "Role",
          width: 90,
          render: (value: number) => {
            return (
              <div>
                {value === 1
                  ? "Broker/Trader"
                  : value === 2
                  ? "FPO"
                  : value === 3
                  ? "Broker/Trader & FPO"
                  : "Broker/Trader"}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Status",
      children: [
        {
          dataIndex: "status",
          key: "status2",
          render: (value: number) => (
            <div className={`status red`}>
              {value === 1
                ? "Upcoming"
                : value === 2
                ? "Open"
                : value === 3
                ? "Closed"
                : "Cancelled"}
            </div>
          ),
        },
      ],
    },
    {
      title: "Sauda Status",
      children: [
        {
          dataIndex: "saudaStatus",
          key: "saudaStatus2",
          width: 100,
          render: (value: number) => {
            return (
              <div
                className={`sauda-status ${
                  value === 1 ? "green" : value === 2 ? "red" : ""
                }`}
              >
                {value === 1
                  ? "Accepted"
                  : value === 2
                  ? "Rejected"
                  : "Not Processed"}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "",
      children: [
        {
          dataIndex: "sauda",
          key: "sauda2",
          render: (value: number, rowData: any) => {
            const data = {
              ...rowData,
              archives: true,
              branch_id: selectedBranch,
            };

            return (
              <Link
                to={{
                  pathname: `/sauda`,
                  state: {
                    datas: {
                      ...data,
                    },
                  },
                }}
              >
                {"View Sauda"}
              </Link>
            );
          },
          width: 90,
        },
      ],
    },
  ];

  useEffect(() => {
    if (currentpage === "3" && viewAuctionPage === "33") {
      archiveAuctionsIntervalID.current &&
        clearInterval(archiveAuctionsIntervalID.current);
      archiveAuctionsDataSocketEmition();
      archiveAuctionsIntervalID.current = setInterval(() => {
        archiveAuctionsDataSocketEmition();
      }, 10000);
    }
    return () => {
      archiveAuctionsIntervalID.current &&
        clearInterval(archiveAuctionsIntervalID.current);
    };
  }, [
    selectedStatus,
    selectedHub,
    selectedVariety,
    currentpage,
    viewAuctionPage,
    globalSocketData,
    selectedBranch,
    selectedCropId,
    selectedDates,
    refetch,
  ]);

  // useEffect(() => {
  //   onBranchChange(branches[branchIndex].id, []);
  // }, [branchIndex]);

  /**
   *
   * @function
   * @description Establish socket.io connection for getting auction cards data.
   * @memberof App.MainLayout
   */
  const archiveAuctionsDataSocketEmition = () => {
    // let socketCheck =
    //   globalSocketData !== undefined ? globalSocketData : passedSocket;
    // let params = sessionStorage.getItem("archiveTMParams");
    // params = params && JSON.parse(params);

    if (globalSocketData) {
      globalSocketData.emit("tm_archive_auction_event", {
        start_date: selectedDates[0],
        end_date: selectedDates[1],
        crop_id: selectedCropId,
        branch_id: selectedBranch,
        variety_id: selectedVariety,
        hub_id: selectedHub,
        saudha_status: selectedStatus,
      });

      globalSocketData.on("tm_archive_auctions", (data: any) => {
        const modifiedData = data.map((item: any) => {
          return {
            key: item.auction_id,
            id: item.auction_id,
            date: item.auction_date,
            hub: item.hub_name,
            division: item.division,
            claim: item.claim_type,
            variety: item.variety_name,
            maxPrice: item.max_price,
            qtyReqd: item.qty_rqd,
            rebateId: item.rebate_id,
            rebateName: item.rebate_name,
            branchId: selectedBranch,
            qtyFulfilled:
              item.qty_fullfilled === null ? 0 : item.qty_fullfilled,
            avgPrice: item.avg_price === null ? 0 : item.avg_price,
            startTime: item.start_time,
            endTime: item.end_time,
            saudaType: item.saudha_type,
            roleType: item.role_mapping,
            status: item.status,
            saudaStatus: item.saudha_status,
          };
        });
        setArchiveData(modifiedData);
        setIsLoading(false);
      });
    }
  };

  // useEffect(() => {
  //   setselectedCropId(selectedCropId);
  //   sessionStorage.setItem(
  //     "archiveTMParams",
  //     JSON.stringify({
  //       start_date: selectedDates[0],
  //       end_date: selectedDates[1],
  //       crop_id: selectedCropId,
  //       branch_id: selectedBranch,
  //       variety_id: selectedVariety,
  //       hub_id: selectedHub,
  //       saudha_status: selectedStatus,
  //     })
  //   );
  // }, [selectedCropId, selectedBranch]);

  useEffect(() => {
    setSelectedVariety(undefined);
  }, [varietiesList]);

  // useEffect(() => {
  //   if (auth && auth.access_token && globalSocketData) {
  //     clearInterval(archiveAuctionsIntervalID);
  //     archiveAuctionsDataSocketEmition(globalSocketData);
  //     archiveAuctionsIntervalID = setInterval(() => {
  //       archiveAuctionsDataSocketEmition(globalSocketData);
  //     }, 30000);
  //   }
  // }, [auth, globalSocketData, selectedCropId]);

  // const getArchiveData = async (
  //   startDate?: string,
  //   endDate?: string,
  //   branchId?: number,
  //   varietyId?: number,
  //   hubId?: number,
  //   status?: number
  // ) => {
  //   setIsLoading(true);
  //   const params = {
  //     start_date: startDate,
  //     end_date: endDate,
  //     crop_id: selectedCropId,
  //     branch_id: branchId,
  //     variety_id: varietyId,
  //     hub_id: hubId,
  //     saudha_status: status,
  //   };
  //   try {
  //     const response = await getArchives(params);
  //     if (response.data.status) {
  //       const data = response.data.data.map((item: any) => {
  //         return {
  //           key: item.auction_id,
  //           id: item.auction_id,
  //           date: item.auction_date,
  //           hub: item.hub_name,
  //           division: item.division,
  //           claim: item.claim_type,
  //           variety: item.variety_name,
  //           maxPrice: item.max_price,
  //           qtyReqd: item.qty_rqd,
  //           rebateId: item.rebate_id,
  //           rebateName: item.rebate_name,
  //           branchId: branchId,
  //           qtyFulfilled:
  //             item.qty_fullfilled === null ? 0 : item.qty_fullfilled,
  //           avgPrice: item.avg_price === null ? 0 : item.avg_price,
  //           startTime: item.start_time,
  //           endTime: item.end_time,
  //           saudaType: item.saudha_type,
  //           roleType: item.role_mapping,
  //           status: item.status,
  //           saudaStatus: item.saudha_status,
  //         };
  //       });
  //       setArchiveData(data);
  //     } else {
  //       message.warning("Cannot fetch data...");
  //       setArchiveData([]);
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoading(false);
  //     message.warning("Cannot fetch data...");
  //     setArchiveData([]);
  //   }
  // };

  // const onBranchChange = (value: string | number | any, option: any) => {
  //   console.log("first")
  //   sessionStorage.setItem(
  //     "archiveTMParams",
  //     JSON.stringify({
  //       start_date: selectedDates[0],
  //       end_date: selectedDates[1],
  //       crop_id: selectedCropId,
  //       branch_id: value,
  //       variety_id: selectedVariety,
  //       hub_id: selectedHub,
  //       saudha_status: selectedStatus,
  //     })
  //   );
  //   setBranch(value);
  //   // setBranchIndex(branches.findIndex((branch) => branch.id == value));
  //   setSelectedHub(undefined);
  //   setSelectedSlot(undefined);
  //   // getSlotsFn(value);
  //   // getHubsFn(value);
  //   // getArchiveData(
  //   //   selectedDates[0],
  //   //   selectedDates[1],
  //   //   value,
  //   //   selectedVariety,
  //   //   selectedHub,
  //   //   selectedStatus
  //   // );
  //   archiveAuctionsDataSocketEmition(globalSocketData);
  // };

  // const onVarietyChange = (value: string | number | any, option: any) => {
  //   if (value === selectedVariety) {
  //     setSelectedVariety(undefined);
  //   } else {
  //     setSelectedVariety(value);
  //   }
  //   archiveAuctionsDataSocketEmition();

  //   // getArchiveData(
  //   //   selectedDates[0],
  //   //   selectedDates[1],
  //   //   selectedBranch,
  //   //   value === selectedVariety ? null : value,
  //   //   selectedHub,
  //   //   selectedStatus
  //   // );
  // };

  // const onHubChange = (value: string | number | any, option: any) => {
  //   if (value === selectedHub) {
  //     setSelectedHub(undefined);
  //   } else {
  //     setSelectedHub(value);
  //   }
  //   archiveAuctionsDataSocketEmition();

  //   // getArchiveData(
  //   //   selectedDates[0],
  //   //   selectedDates[1],
  //   //   selectedBranch,
  //   //   selectedVariety,
  //   //   value === selectedHub ? null : value,
  //   //   selectedStatus
  //   // );
  // };

  // const onStatusChange = (value: string | number | any, option: any) => {
  //   if (value === selectedStatus) {
  //     setSelectedStatus(undefined);
  //   } else {
  //     setSelectedStatus(value);
  //   }
  //   archiveAuctionsDataSocketEmition();
  //   // getArchiveData(
  //   //   selectedDates[0],
  //   //   selectedDates[1],
  //   //   selectedBranch,
  //   //   selectedVariety,
  //   //   selectedHub,
  //   //   value === selectedStatus ? null : value
  //   // );
  // };

  // const onDateChange = (value: string | number | any, timeString: any) => {
  //   // console.log(timeString)
  //   setSelectedDates(timeString);
  //   archiveAuctionsDataSocketEmition(timeString);
  //   // getArchiveData(
  //   //   timeString[0],
  //   //   timeString[1],
  //   //   selectedBranch,
  //   //   selectedVariety,
  //   //   selectedHub,
  //   //   selectedSlot
  //   // );
  // };

  // const getHubsFn = async (branchId: number) => {
  //   try {
  //     const response = await getHubsService({ branch_id: branchId });
  //     const data = response.data.data.map((item: any) => {
  //       return { id: item.territory_id, name: item.territory_name };
  //     });
  //     setHubs(data);
  //   } catch (error) {}
  // };

  // const getSlotsFn = async (branchId: number) => {
  //   try {
  //     const response = await getSlotIdsService({
  //       branch_id: branchId,
  //       crop_id: selectedCropId,
  //     });
  //     const data = response.data.data.map((item: any) => {
  //       return { id: item.slot_id, name: item.slot_name };
  //     });
  //     setSlots(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div className="archives">
      <div className="filter-container">
        <CustomFilter
          type="range-picker"
          head="Date Range"
          startDate={moment()}
          endDate={moment()}
          onChange={(_, timestring) => setSelectedDates(timestring)}
        />
        {/* <CustomFilter
          type="single-select"
          options={crops}
          placeholder="Select Crop"
          head="Crop"
        /> */}
        <CustomFilter
          type="single-select"
          value={selectedBranch}
          options={branches}
          onChange={(value: any) => {
            setSelectedHub(undefined);
            setBranch(value);
          }}
          placeholder="Select Branch"
          head="ITC Branch Office"
          returnId
        />
        <CustomFilter
          type="single-select"
          value={selectedHub}
          options={hubs}
          onChange={(value: any) => setSelectedHub(value)}
          placeholder="Select Hub"
          head="Hub"
          returnId
        />
        <CustomFilter
          type="single-select"
          value={selectedVariety}
          onChange={(value: any) => setSelectedVariety(value)}
          options={varietiesList}
          placeholder="Select Variety"
          head="Variety"
          returnId
        />
        {/* <CustomFilter
          type="single-select"
          value={selectedSlot}
          options={slots}
          onChange={onSlotChange}
          placeholder="Select Slot"
          head="Slot"
          returnId
        /> */}
        <CustomFilter
          type="single-select"
          value={selectedStatus}
          options={status}
          onChange={(value: any) => setSelectedStatus(value)}
          placeholder="Select Status"
          head="Status"
          returnId
        />
        {/* <CustomFilter
          type="single-select"
          value={selectedBranch}
          options={branches}
          onChange={onBranchChange}
          head="Type"
          returnId
        /> */}
        <div className="selected-wheat">
          <span className="selected-wheat-label">Selected Crop</span> <br />
          <span className="selected-wheat-value">
            <b>
              {crops &&
                crops.length &&
                crops.find(
                  (item: { id: number; name: string }) =>
                    item.id === selectedCropId
                ).name}
            </b>
          </span>
        </div>
        <Button
          className="refresh"
          icon={<SyncOutlined spin={isLoading} />}
          onClick={
            () => setRefetch(!refetch)

            // getArchiveData(
            //   selectedDates[0],
            //   selectedDates[1],
            //   selectedBranch,
            //   selectedVariety,
            //   selectedHub,
            //   selectedStatus
            // )
          }
        >
          Refresh
        </Button>
      </div>

      <div className="archives-table-container">
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={archiveData}
          pagination={false}
          scroll={{ y: window.innerHeight - 300 }}
        />
      </div>
    </div>
  );
};
// const mapDispatchToProps = (dispatch: Dispatch) => {
//   return {
//     setBranchIndex: (index: number) => dispatch(setBranchIndex(index)),
//   };
// };
const mapStateToProps = createStructuredSelector({
  branches: selectBranches,
  crops: selectCrops,
  selectedCropId: selectSelectedCrop,
  auth: selectAuthData,
  currentpage: selectCurrentPage,
  viewAuctionPage: selectViewAuctionPage,
  hubs: selectHubs,
  varietiesList: selectVarieties,
});

export default connect(mapStateToProps)(Archives);
