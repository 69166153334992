import React, { useEffect, useState } from "react";
import "./mobile-apk-updation.styles.scss";
import {
  putMobileUpdateDetails,
  getMobileUpdateDetails,
} from "../../services/mobile-apk-updation-services";
import { Input, Button, message, Modal } from "antd";

const MobileUpdation: React.FC = (props: any) => {
  const { user } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateDetails, setupdateDetails] = useState({
    mobile_app_build_no: undefined,
    mobile_app_version_no: undefined,
    no_of_days_force_update: undefined,
    apk_update_date: undefined,
  });

  useEffect(() => {
    if (updateDetails !== undefined) {
      getMobileUpdationDetails();
    }
  }, []);

  async function getMobileUpdationDetails() {
    const response = await getMobileUpdateDetails();
    if (response.data.status) {
      const data = response.data.data;
      setupdateDetails(data);
    } else {
      console.log("Change response: ", response.data.message);
    }
  }

  const handleChange = (e: any) => {
    setupdateDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSave = async (values: {
    mobile_app_build_no: undefined;
    mobile_app_version_no: undefined;
    no_of_days_force_update: undefined;
    apk_update_date: undefined;
  }) => {
    var warningMessage = "";
    const validate = () => {
      if (
        updateDetails.mobile_app_build_no === "" ||
        updateDetails.mobile_app_build_no === undefined
      ) {
        warningMessage = "Enter build number..!";
        return false;
      }

      if (
        updateDetails.mobile_app_version_no === "" ||
        updateDetails.mobile_app_version_no === undefined
      ) {
        warningMessage = "Enter version number..!";
        return false;
      }

      if (
        updateDetails.no_of_days_force_update === "" ||
        updateDetails.no_of_days_force_update === undefined
      ) {
        warningMessage = "Enter force update days..!";
        return false;
      }

      return true;
    };

    if (validate()) {
      const dataToSend = {
        build_no: updateDetails.mobile_app_build_no,
        app_version: updateDetails.mobile_app_version_no,
        force_update_days: updateDetails.no_of_days_force_update,
      };

      try {
        const response = await putMobileUpdateDetails(dataToSend);

        if (response.status === 200) {
          message.success("Data submitted successfully..!");
          setIsModalVisible(false);
        } else {
          console.log("Change response: ", response.data.message);
        }
      } catch (error) {
        message.error(error);
        console.log("Error caught :", error);
      }
    } else {
      message.error(warningMessage);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="mobile_home">
      <div className="mobile_preference">
        <div className="select-container">
          <Modal
            title="Confirm!"
            visible={isModalVisible}
            footer={false}
            onCancel={handleCancel}
            style={{ textAlign: "center" }}
          >
            {
              <>
                <p>Are you sure you want to submit?</p>
              </>
            }
            <div className="btn-container">
              <Button
                onClick={(e) => {
                  onSave(updateDetails);
                }}
                className="btn-container"
                style={{
                  marginTop: "-25px",
                  marginLeft: "15px",
                  padding: "2px 8px !important",
                  backgroundColor: "#20346a",
                  width: "fit-content !important",
                  borderRadius: "20px",
                  color: "white",
                  float: "right",
                }}
              >
                {" "}
                Submit{" "}
              </Button>
            </div>
          </Modal>

          <div className="row">
            <div className="dualColumnRow" style={{ display: "inline-flex" }}>
              <div className="left">
                Mobile app build no <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  maxLength={10}
                  type="number"
                  required={true}
                  value={updateDetails.mobile_app_build_no}
                  name="mobile_app_build_no"
                  // onBlur={() => checkUserExists()}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="dualColumnRow" style={{ display: "inline-flex" }}>
              <div className="left">
                Mobile app version app <label style={{ color: "red" }}>*</label>
                :
              </div>
              <div className="right">
                <Input
                  maxLength={10}
                  type="number"
                  required={true}
                  value={updateDetails.mobile_app_version_no}
                  name="mobile_app_version_no"
                  // onBlur={() => checkUserExists()}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="dualColumnRow" style={{ display: "inline-flex" }}>
              <div className="left">
                Force update days <label style={{ color: "red" }}>*</label>:
              </div>
              <div className="right">
                <Input
                  maxLength={10}
                  type="number"
                  required={true}
                  value={updateDetails.no_of_days_force_update}
                  name="no_of_days_force_update"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>

          <Button
            className="login-btn"
            type="primary"
            size={"large"}
            style={{
              marginLeft: 250,
              alignSelf: "right",
              padding: 0,
              borderRadius: 20,
              backgroundColor: "#20346a",
            }}
            block
            htmlType="submit"
            onClick={(e) => {
              setIsModalVisible(true);
              // onSave(updateDetails);
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MobileUpdation;
